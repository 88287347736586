import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const PdfFileIcon: React.FC<IconSvgProps> = ({ width = 30, height = 30, fill = '#D2D2D2' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 32'
			version='1.1'
		>
			<title>Руководство оператора</title>

			<g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' >
				<g id='Icon-Set'  transform='translate(-412.000000, -99.000000)' fill={fill}>
					<path
						d='M442,124 C442,125.104 441.073,125.656 440,126 C440,126 434.557,127.515 429,128.977 L429,104 L440,101 C441.104,101 442,101.896 442,103 L442,124 L442,124 Z M427,128.998 C421.538,127.53 416,126 416,126 C414.864,125.688 414,125.104 414,124 L414,103 C414,101.896 414.896,101 416,101 L427,104 L427,128.998 L427,128.998 Z M440,99 C440,99 434.211,100.594 428.95,102 C428.291,102.025 427.627,102 426.967,102 C421.955,100.656 416,99 416,99 C413.791,99 412,100.791 412,103 L412,124 C412,126.209 413.885,127.313 416,128 C416,128 421.393,129.5 426.967,131 L428.992,131 C434.612,129.5 440,128 440,128 C442.053,127.469 444,126.209 444,124 L444,103 C444,100.791 442.209,99 440,99 L440,99 Z'
						id='book-album'
					></path>
				</g>
			</g>
		</svg>
	);
};

export default PdfFileIcon;
