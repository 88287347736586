import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const TableIcon: React.FC<IconSvgProps> = ({ width = 19, height = 19, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Таблица</title>
			<path
				d='M16.625 1H2V4.375H16.625V1Z'
				stroke={fill}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.625 7.375H2V10.75H16.625V7.375Z'
				stroke={fill}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.625 13.75H2V17.125H16.625V13.75Z'
				stroke={fill}
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default TableIcon;
