import React, {useEffect, useRef} from 'react';
import './DashboardMap.css';
import { observer } from 'mobx-react-lite';
import L, {LatLngBoundsLiteral} from 'leaflet';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import DashboardPlacesMarker from './DashboardPlacesMarker/DashboardPlacesMarker';
import mapState from '@store/mapState/mapState';
import connectState from '@store/connectState/connectState';
import placeState from '@store/placeState/placeState';

const DashboardMap: React.FC = observer(() => {
	const mapRef = useRef(null);
	const { centerMap, zoomMap, setZoomMap, setCenterMap } = mapState;
	const { selectPlaceId, places } = placeState;
	const { linkMap } = connectState;

	const mapPath = `${linkMap}{z}/{x}/{y}.png`;

	const maxBounds: LatLngBoundsLiteral = [
		[60.9, 30.1],
		[49.4, 57.0]
	];

	useEffect(() => {
		const selectPlace = places.find(({id}) => id === selectPlaceId);
		setCenterMap(selectPlace?.coords ?? [54.19514, 37.6225]);
	},[selectPlaceId, places]);

	const createClusterCustomIcon = function (cluster: L.MarkerCluster) {
		return L.divIcon({
			html: `<span>${cluster.getChildCount()}</span>`,
			className: 'custom-marker-cluster',
			iconSize: L.point(34, 34, true),
		});
	};

	const MapEvents = () => {
		const map = useMap();

		useMapEvents({
			zoomend (e){
				setZoomMap(e.target._zoom);
				setCenterMap(map.getCenter());
			}
		});
		return <></>;
	};

	const ChangeMapView = () => {
		const map = useMap();

		map.setView(centerMap);
		map.getZoom();

		return null;
	};

	return (
		<MapContainer
			ref={mapRef}
			center={centerMap}
			zoom={zoomMap}
			scrollWheelZoom={true}
			className='container'
			zoomControl={false}
			minZoom={10}
			maxZoom={13}
			maxBounds={maxBounds}
		>
			<TileLayer url={mapPath} />

			<ChangeMapView />

			<MapEvents />

			<MarkerClusterGroup
				iconCreateFunction={createClusterCustomIcon}
				showCoverageOnHover={true}
				chunkedLoading
			>
				<DashboardPlacesMarker />
			</MarkerClusterGroup>
		</MapContainer>
	);
});

export default DashboardMap;
