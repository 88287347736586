import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Redirect from '../helpers/components/Redirect/Redirect';
import SigninPage from '../pages/SigninPage/SigninPage';
import { MetaTags } from '../pages/Pages.interface';

const NoAccessRouter: React.FC = () => {
	const path = '/signin';

	const metaSinginPage: MetaTags = {
		title: 'Авторизация',
		description: 'Авторизация',
		keywords: 'ключевые слова',
	};

	return (
		<Routes>
			<Route path='/signin' element={<SigninPage metaTags={metaSinginPage}/>} />
			<Route path='*' element={<Redirect to={path} />} />
		</Routes>
	);
};

export default NoAccessRouter;
