import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ControlIconsBottom.module.scss';
import MainButton from '@helpers/components/MainButton/MainButton';
import ClockIcon from '@assets/Icons/Translation/ClockIcon';
import GridIcon from '@assets/Icons/ControlPage/GridIcon';
import translationState from '@store/translationState/translationState';
import BrushIcon from '@assets/Icons/Translation/BrushIcon';
import canvasState from '@store/canvasState/canvasState';
import autorizeState from '@store/accoutState/autorizeState';
import UpdateIcon from '@assets/Icons/ControlPage/UpdateIcon';
import SettingsEventIcon from '@assets/Icons/ControlPage/SettingsEventIcon';
import presetState from '@store/presetState/presetState';

const ControlIconsBottom = observer(() => {
	const { isCreatePolygon, isEditPolygon, isShowCanvas, setCanvasVisible} = canvasState;
	const {isVisibleTempGrid, isMouseControl, isOpenModal, contentTypeModal, setOpenModal, setVisibleTempGrid, setContentType, setIsGetEventPOLL} = translationState;
	const {userRole} = autorizeState;
	const {selectPresetId} = presetState;

	const isVisibleCanvas = () => {
		setCanvasVisible(!isShowCanvas);
		if(isShowCanvas) setVisibleTempGrid(false); 
		if(isVisibleTempGrid || isShowCanvas) return;
		setVisibleTempGrid(!isVisibleTempGrid);
	};

	const isGridVisible = () => {
		if(isShowCanvas) return;
		setVisibleTempGrid(!isVisibleTempGrid);
	};

	const openParamsPatrol = () => {
		setOpenModal(true);
		setContentType('PatrolParams');
	};

	const settingsEvent = () => {
		setOpenModal(true);
		setContentType('SettingsEvent');
	};

	const handleEventPOLL = () => {
		setIsGetEventPOLL();
	};

	const menuIcons = [
		{ id: 1, Icon: GridIcon, fill: isVisibleTempGrid && !isShowCanvas ? '#00C2FD' : '#D2D2D2', disable: isShowCanvas || isMouseControl, visibleClick: isGridVisible},
		{ id: 2, Icon: BrushIcon, fill: isShowCanvas ? '#00C2FD' : '#D2D2D2', disable: isMouseControl || !selectPresetId, visibleClick: isVisibleCanvas},
		{ id: 3, Icon: SettingsEventIcon, fill: contentTypeModal === 'SettingsEvent' && isOpenModal ? '#00C2FD' : '#D2D2D2', disable: (userRole === 'spotoperator') || isMouseControl || isShowCanvas || !selectPresetId, visibleClick: settingsEvent },
		{ id: 4, Icon: ClockIcon, fill: contentTypeModal === 'PatrolParams' && isOpenModal ? '#00C2FD' : '#D2D2D2', disable: (userRole === 'spotoperator') || isMouseControl || isShowCanvas || !selectPresetId, visibleClick: openParamsPatrol},
		{ id: 5, Icon: UpdateIcon, disable: isMouseControl || isCreatePolygon || isEditPolygon, visibleClick: handleEventPOLL},
	];

	return (
		<div className={styles.container}>
			{menuIcons.map((icon) => {
				const { id, Icon, fill, disable, visibleClick } = icon;
				const key = `Menu-Icon-${id}`;

				return (
					<div key={key} className={styles.bottomIcons}>
						<MainButton onClick={visibleClick} disabled={disable}>
							<Icon fill={fill} disabled={disable}/>
						</MainButton>
					</div>
				);
			})}
		</div>
	);
});

export default ControlIconsBottom;
