import React from 'react';
import { Marker } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import { observer } from 'mobx-react-lite';
import { LatLngTuple, Icon } from 'leaflet';
import placeState from '@store/placeState/placeState';
import mapState from '@store/mapState/mapState';
import { PlaceDevices as Place } from '@store/placeState/interfaces';
import PlaceIcon from '@assets/Icons/Map/PlaceIcon';

const DashboardPlacesMarker: React.FC = observer(() => {
	const { places, setSelectedPlaces, setSelectPlaceId } = placeState;
	const { setPopupPosition, setZeroPopupPosition, setCenterMap, setNamePopup } = mapState;

	const svgString = (color: string) => renderToString(<PlaceIcon fill={color} />);

	const itemIcon = (color: string) =>
		new Icon({
			iconUrl: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString(color))}`,
			iconSize: [65, 65],
		});

	const handlePositionMarker = (id: string, name: string, center: LatLngTuple) => {
		const delayTimeMS = 300;

		setSelectPlaceId(id);
		setSelectedPlaces(id, true);
		setCenterMap(center);

		setNamePopup(name);
		setZeroPopupPosition();
		setTimeout(() => {
			setPopupPosition(center);
		}, delayTimeMS);
	};

	const createPlaceMarkerOnMap = ({ id, name, coords, isSelected }: Place) => {
		const key = `Marker-Place-Item-${id}-${name}`;
		const iconColor = isSelected ? '#00C2FD' : '#939393';

		return (
			<div key={key}>
				<Marker
					icon={itemIcon(iconColor)}
					position={coords}
					eventHandlers={{
						click: () => {
							handlePositionMarker(id, name, coords);
						},
					}}
				/>
			</div>
		);
	};

	return (
		<div>
			{places.map(createPlaceMarkerOnMap)}
		</div>
	);
});

export default DashboardPlacesMarker;
