import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const CameraIcon: React.FC<IconSvgProps> = ({ width = 32, height = 32, fill = '#D2D2D2', title = 'Трансляция' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 192 192' xmlns='http://www.w3.org/2000/svg' fill='none'>
			<title>{title}</title>
			<path
				fill={fill}
				d='M60 50v6a6 6 0 0 0 4.8-2.4L60 50Zm12-16v-6a6 6 0 0 0-4.8 2.4L72 34Zm60 16-4.8 3.6A6 6 0 0 0 132 56v-6Zm-12-16 4.8-3.6A6 6 0 0 0 120 28v6Zm44 32v76h12V66h-12Zm-10 86H38v12h116v-12ZM28 142V66H16v76h12Zm10-86h22V44H38v12Zm26.8-2.4 12-16-9.6-7.2-12 16 9.6 7.2ZM132 56h22V44h-22v12Zm4.8-9.6-12-16-9.6 7.2 12 16 9.6-7.2ZM120 28H72v12h48V28ZM38 152c-5.523 0-10-4.477-10-10H16c0 12.15 9.85 22 22 22v-12Zm126-10c0 5.523-4.477 10-10 10v12c12.15 0 22-9.85 22-22h-12Zm12-76c0-12.15-9.85-22-22-22v12c5.523 0 10 4.477 10 10h12ZM28 66c0-5.523 4.477-10 10-10V44c-12.15 0-22 9.85-22 22h12Z'
			/>
			<circle cx='96' cy='102' r='28' stroke={fill} strokeWidth='12' />
		</svg>
	);
};

export default CameraIcon;
