import React from 'react';
import { TableProps as Props } from './ReportTable.interface';
import { columnsReportTable } from '../../data';
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(() => ({
	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},

	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},

	'& .MuiDataGrid-cell': {
		fontSize: '16px',
	},

	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},

	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},

	'& ::-webkit-scrollbar': {
		width: '6px',
		borderLeft: '1px solid #D2D2D2',
	},

	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	},
}));

const ReportTable: React.FC<Props> = ({ reportData, isLoad }) => {

	return (
		<StyledDataGrid
			rows={reportData}
			columns={columnsReportTable}
			loading={isLoad}
			disableColumnMenu
			initialState={{
				pagination: { paginationModel: { pageSize: 10 } },
			}}
			pageSizeOptions={[10, 25, 50]}
			hideFooterSelectedRowCount={true}
			localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
		/>
	);
};

export default ReportTable;