import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import MainButton from '@helpers/components/MainButton/MainButton';
import HandClickIcon from '@assets/Icons/Translation/HandClickIcon';
import translationState from '@store/translationState/translationState';
import canvasState from '@store/canvasState/canvasState';

const MouseControl: React.FC = observer(() => {
	const { isShowCanvas, setCanvasVisible } = canvasState;
	const { setIsMouseControl, setVisibleTempGrid } = translationState;
	const [isSelected, setIsSelected] = useState(false);

	const handleClickKeyboardControl = () => {
		if(isShowCanvas) return;
		setIsSelected((prevIsSelected) => {
			const isSelected = !prevIsSelected;
			setIsMouseControl(isSelected);
			setVisibleTempGrid(false);
			setCanvasVisible(false);
			
			return isSelected;
		});
	};

	const fill = isSelected ? '#00c2fd' : '#D2D2D2';

	return (
		<MainButton onClick={handleClickKeyboardControl} disabled={isShowCanvas}>
			<HandClickIcon fill={fill} disabled={isShowCanvas}/>
		</MainButton>
	);
});

export default MouseControl;
