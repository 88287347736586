import styles from './ItemValues.module.scss';
import {ItemValuesProps as Props} from './ItemValues.interface';

const ItemValues: React.FC<Props> = ({valuesArray}) => {

	return (
		<div className={styles.card}>
			{valuesArray.map(({title, value, sing})=> {
				return (
					<div key={title} className={styles.right}>
						<div className={styles.rightTitle}>{title}</div>
						<div className={styles.rightValue}>{value} {sing}</div>
					</div>
				);
			})}
		</div>
	);
};

export default ItemValues;