import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const PlaceIcon: React.FC<IconSvgProps> = ({ width = 45, height = 45, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 89 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_d_113_446)'>
				<rect
					x='42.1624'
					y='20.4055'
					width='34'
					height='34'
					rx='17'
					transform='rotate(39.4241 42.1624 20.4055)'
					fill='#373737'
				/>
				<rect
					x='42.712'
					y='26.0356'
					width='26'
					height='26'
					rx='13'
					transform='rotate(39.4241 42.712 26.0356)'
					stroke={fill}
					strokeWidth='8'
				/>
			</g>
		</svg>
	);
};

export default PlaceIcon;
