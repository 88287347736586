import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserAccessRouter from './routes/UserAccessRouter';
import NoAccessRouter from './routes/NoAccessRouter';
import autorizeState from './store/accoutState/autorizeState';
import { setConnections } from './helpers/functions/helpFunctions';
import useWindowDimensions from './helpers/functions/useWindowDimensions';
import MobilePage from './pages/MobilePage/MobilePage';

const App: React.FC = observer(() => {
	const { isAuthorize } = autorizeState;
	const { width } = useWindowDimensions();
	const mobileBreakpoint = 1024;

	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		if(width < mobileBreakpoint) {
			setMobile(true);
		} else {
			setMobile(false);
		}
	}, [width]);

	useEffect(() => {
		setConnections();
	}, []);

	const routes = {
		true: <UserAccessRouter />,
		false: <NoAccessRouter />,
	};

	return  isMobile ? <MobilePage /> : routes[`${isAuthorize}`];
});

export default App;
