import { Notifications } from '../../api/Notifications/prepareNotifications.interface';
import placeState from '../placeState/placeState';
class NotificationsState {
	counter = 0;
	notifications: Notifications[] = [];
	eventID = '';


	setNotifications = (notifications: Notifications[], count: number) => {
		this.notifications = notifications;
		this.counter = count;
		notifications.forEach(({spot}) => {
			placeState.setFindPlaceEvent(spot);
		});
	};

	setEventID = (eventID: string) => {
		this.eventID = eventID;
	};

	addNotification = (notifications: Notifications) => {
		this.notifications.push(notifications);

		placeState.setFindPlaceEvent(notifications.spot);
	};

}
const notificationsState = new NotificationsState();
export default notificationsState;

