import React from 'react';
import styles from './MainButton.module.scss';
import { MainButtonProps as Props } from './MainButton.interface';


const MainButton: React.FC<Props> = ({ className, disabled = false, onClick, children }) => {
	const { mainButton, mainButtonDisabled } = styles;
	
	const mainButtonStyle = disabled ? mainButtonDisabled : mainButton;
	
	return (
		<div className={className} onClick={onClick}>
			<button disabled={disabled} className={mainButtonStyle}>
				{children}
			</button>
		</div>
	);
};

export default MainButton;