import React, { useState } from 'react';
import styles from './ChangeRole.module.scss';
import Autocomplete from '@mui/material/Autocomplete';
import {
	AutorizeKeysUserRoles,
	AutorizeStateUserRoles as UserRoles
} from '../../../../../../store/accoutState/interfaces';
import { AccessPlace } from '../../../../../../store/placeState/interfaces';
import { Props, SpotsObject } from './ChangeRole.interface';
import autorizeState from '../../../../../../store/accoutState/autorizeState';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import ConfirmButtons from '../../../../../../helpers/components/ConfirmButtons/ConfirmButtons';
import { apiPost, apiPut } from '../../../../../../api/api';
import connectState from '../../../../../../store/connectState/connectState';
import CloseIcon from '../../../../../../assets/Icons/Map/CloseIcon';
import Confirm from '../../../../CustomCard/ItemCard/Confirm/Confirm';
import Modal from '@mui/material/Modal';
import { RoleInfoRuNameRolesKeys } from '../RoleInfo.interface';
import {postUserAction} from '../../../../../../pages/AccountPage/AccountPage';
import AgreeIcon from '../../../../../../assets/Icons/ControlPage/AgreeIcon';


const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const CustomTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#858585',
			borderRadius: 10,
			height: '47px',
		},
		'&:hover fieldset': {
			borderColor: '#00C2FD',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#00C2FD',
		},
		color: '#cfcfcf',
		fontSize: '20px',
		padding: '2px 0 0 10px',
	},
});

const ChangeRole: React.FC<Props> = ({type = 'create', handleClose, selectedID, data, name}) => {
	const { user, setUpdateData, accountPlaces, setLoadingTables} = autorizeState;
	const { postUserAccess, deleteUserAccess, changeUserAccess } = connectState;

	const spots: SpotsObject = {
		create: {id: accountPlaces[0]?.id, name: accountPlaces[0]?.name},
		change: {id: data?.spot, name: data?.spotName}
	};

	const spotsRole = {
		create: 'spotoperator',
		change: data?.role
	};

	const role: keyof UserRoles = user.role;

	const roles: RoleInfoRuNameRolesKeys = {
		staff: 'суперпользователь',
		spotadmin: 'администратор спота',
		spotoperator: 'оператор',
	};

	const [userRole, setUserRole] = useState<string | null>(roles[spotsRole[type] as keyof AutorizeKeysUserRoles]);
	const [spotArr, setSpotArr] = useState<AccessPlace | null>(spots[type]);
	const [isOpen, setOpen] = useState(false);
	const [isDisableButton, setDisableButton] = useState(true);

	const roleIndex = Object
		.keys(roles)
		.findIndex(tRole => tRole === role);

	const isCorrectRole = roleIndex !== -1;
	const isSplice = user.grouprole === 'groupadmin' ? 1 : 0;

	const ruRolesAccess = Object
		.values(roles)
		.splice(roleIndex + isSplice);

	if (!isCorrectRole) {
		handleClose();
	}

	const handleSubmit = () => {
		const reversedRoles = Object.fromEntries(
			Object.entries(roles).map(([key, value]) => [value, key])
		);

		const currentDate = new Date();

		const data = {
			spot: spotArr?.id,
			spot_name: spotArr?.name,
			user: selectedID,
			role: reversedRoles[`${userRole}`],
			date_joined: currentDate.toISOString()
		};

		apiPost(postUserAccess, data, {}).then(({ error }) => {
			const { isError } = error;
			if (isError) return;
			postUserAction('Добавление прав', name);
			setUpdateData('isGetAccess');
			setLoadingTables('isGetAccess', true);
		});

		handleClose();
	};

	const handleChange = () => {
		const url = `${changeUserAccess}${data.id}/`;
		const reversedRoles = Object.fromEntries(
			Object.entries(roles).map(([key, value]) => [value, key])
		);

		const putData = {
			spot: spotArr?.id,
			user: selectedID,
			role: reversedRoles[`${userRole}`],
		};
		apiPut(url,putData, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			postUserAction('Изменение прав', name);
			setUpdateData('isGetAccess');
			setLoadingTables('isGetAccess', true);
		});

		handleClose();
	};

	const handleDelete = () => {
		setOpen(true);
	};

	const isOptionEqualToValue = (option: AccessPlace, value: AccessPlace) => {
		return option.id === value.id && option.name === value.name;
	};


	return (
		<ThemeProvider theme={darkTheme}>
			<div className={styles.box}>
				<Modal
					className={styles.modal}
					open={isOpen}
					onClose={handleClose}
				>
					<div>
						<Confirm
							id={spotArr?.id as string}
							name={spotArr?.name as string}
							url={`${deleteUserAccess}${data?.id}`}
							handleClose={handleClose}
							isDisableButton={isDisableButton}
							setDisableButton={setDisableButton}
							updateTitle={'isGetAccess'}
							title={'доступа к месту'}
						/>
					</div>
				</Modal>

				<div className={styles.container}>
					<div className={styles.row}>
						<Autocomplete
							disablePortal
							fullWidth
							id="multiple-limit-tags"
							options={accountPlaces}
							getOptionLabel={(option) => option.name}
							value={spotArr}
							renderInput={(params) => (
								<CustomTextField  {...params} fullWidth/>
							)}
							onChange={(event, newValue) => {
								setSpotArr(newValue);
							}}
							isOptionEqualToValue={isOptionEqualToValue}
						/>
					</div>
					<div className={styles.row}>
						<Autocomplete
							disablePortal
							options={ruRolesAccess}
							value={userRole}
							fullWidth
							onChange={(event, newValue) => {
								setUserRole(newValue);
							}}
							renderInput={(params) => (
								<CustomTextField
									{...params}
									fullWidth
									variant='outlined'
								/>
							)}
						/>
					</div>
					<div className={styles.agreeIcon} onClick={handleChange}>
						<AgreeIcon width={25} height={25}/>
					</div>
					{type === 'change' &&
						(
							<div className={styles.closeIcon} onClick={handleDelete}>
								<CloseIcon title={'Удалить'}/>
							</div>
						)
					}
				</div>

				{type === 'create' &&
					(
						<div className={styles.btnContainer}>
							<ConfirmButtons
								isDisabled={false}
								handleOk={handleSubmit}
								handleCancel={handleClose}
								nameNo={'Назад'}
							/>
						</div>
					)
				}
			</div>
		</ThemeProvider>
	);
};

export default ChangeRole;