import loadingState from '../../../store/loadingState/loadingState';
import { AllPagesGetData as ReadyPages, AnaliticaPageIsReadyData as AnaliticaIsReady } from '../../../store/loadingState/loading.interface';

interface DataWithDelay {
	(page: keyof ReadyPages, setReadyPages: (key: keyof ReadyPages, isValue: boolean) => void): void;
}

const setDataWithDelay: DataWithDelay = (page, setReadyPages) => {
	setTimeout(() => {
		setReadyPages(page, true);
	}, 1500);
};

export const checkPageReady = (key: keyof AnaliticaIsReady, isValue = false) => {
	const { analiticaReady, setAnaliticaReady, setReadyPages } = loadingState;
	const page: keyof ReadyPages = 'analitica';

	const editData: AnaliticaIsReady = {...analiticaReady};
	editData[key] = isValue;

	setAnaliticaReady(editData);

	const readyData = Object.values(editData);
	const isPageReady = readyData.reduce((isReady, isItem) => isReady && isItem, true);

	isPageReady && setDataWithDelay(page, setReadyPages);
};