import React, { useEffect, useState } from 'react';
import styles from './NotificationsItems.module.scss';
import { ClickAwayListener, ListItem } from '@mui/material';
import notificationsState from '../../../store/notificationsState/notificationsState';
import { NavLink } from 'react-router-dom';
import { NotificationsProps } from './NotificationsItems.interface';
import {observer} from 'mobx-react-lite';
import { Notifications } from '../../../api/Notifications/prepareNotifications.interface';
import { Notification } from './Notification/Notification';
import connectState from '../../../store/connectState/connectState';
import {apiPost} from '../../../api/api';
import autorizeState from '../../../store/accoutState/autorizeState';


const NotificationsItems: React.FC<NotificationsProps> = observer(({ closeNotification }) => {
	const { notifications, counter, setEventID } = notificationsState;
	const { readAllNotificationsLink } = connectState;
	const { setUpdateData } = autorizeState;

	const [notificationsEvent, setNotificationsEvent] = useState<Notifications[] | null>([]);

	useEffect(() => {
		setNotificationsEvent(notifications);
	}, [notifications, counter]);

	const handleWatchAllEvents = () => {
		readAllNotifications(readAllNotificationsLink);
		setEventID('');
	};
	const handleClickAwayEvent = () => {
		closeNotification();
	};

	const readAllNotifications = (url: string) => {
		apiPost(url, {}, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			setUpdateData('isNotifications');
			closeNotification();
		});
	};

	return (
		<ClickAwayListener onClickAway={handleClickAwayEvent}>

			<div className={styles.popoverStyle}>
				<ListItem className={styles.listItem}>
					<div className={styles.listTitle}>
						События
					</div>
					<NavLink
						className={styles.navLinkWatch}
						to={'/control'}
						onClick={handleWatchAllEvents}
					>
						посмотреть все
					</NavLink>
				</ListItem>

				{notificationsEvent !== null &&
					notificationsEvent
						.slice(0, 10)
						.map((notification) => {
							const { id, message, eventId } = notification;
							return (
								<Notification
									key={`Notification-${id}-${message}-${eventId}`}
									event={notification}
									handleClosePopover={closeNotification}
								/>
							);
						})}
			</div>
		</ClickAwayListener>
	);
});

export default NotificationsItems;