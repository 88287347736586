import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { List, Modal } from '@mui/material';
import styles from './PresetsList.module.scss';
import PresetsModals from './PresetsModals/PresetsModals';
import { PresetsListProp } from '../../FieldSearchPlaces.interface';
import SettingIcon from '@assets/Icons/Translation/SettingIcon';
import PlayIcon from '@assets/Icons/Translation/PlayIcon';
import PauseIcon from '@assets/Icons/Translation/PauseIcon';
import PlusIcon from '@assets/Icons/Translation/PlusIcon';
import DeleteIcon from '@assets/Icons/Translation/DeleteIcon';
import PencilIcon from '@assets/Icons/Translation/Pencil';
import { Preset } from '@store/presetState/interfaces';
import devicesState from '@store/devicesState/devicesState';
import placeState from '@store/placeState/placeState';
import presetState from '@store/presetState/presetState';
import autorizeState from '@store/accoutState/autorizeState';

const styleForList = () => ({
	'&.MuiList-root': {
		width: 'calc(100% - 20px)',

		padding: '0 10px',

		top: '52px',
		bottom: '0',

		transition: 'height 0.3s ease-in-out',

		'&::-webkit-scrollbar': {
			width: '6px',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ccc',
			borderRadius: '3px',
		},
	},
});

const StyledList = styled(List)(styleForList);

const PresetsList: React.FC<PresetsListProp> = observer(
	({ isVisibleList, isSearchPreset, arrayItems, isPatrol, clickPreset, setIsPatrol, closeMenu }) => {
		const { selectDeviceId, devices } = devicesState;
		const { selectPlaceId, places } = placeState;
		const { selectPresetId } = presetState;
		const { userRole } = autorizeState;

		const { pathname } = useLocation();
		const deviceName = devices.find((device) => device.id === selectDeviceId);
		const placeName = places.find((place) => place.id === selectPlaceId);
		const selectName = arrayItems.find(({ isActive }) => isActive);

		const [isOpenModal, setIsOpenModal] = useState(false);
		const [isEditPreset, setEditPreset] = useState(false);
		const [selectPreset, setSelectPreset] = useState<Preset | undefined>();
		const [modalType, setModalType] = useState('');
		const [isAnalitica, setIsAnalitica] = useState(false);

		useEffect(() => {
			setIsAnalitica(pathname === '/analitica');
		}, [pathname]);

		const listItemsStyle = {
			height: isVisibleList ? 'calc(40vh - 52px)' : '0',
			overflow: isVisibleList ? 'auto' : 'hidden',
		};

		const editIcons = [
			{ id: 1, icon: <PencilIcon width={22} height={22} />, click: () => setModalType('editName') },
			{ id: 2, icon: <DeleteIcon width={23} height={23} />, click: () => setModalType('delete') },
		];

		useEffect(() => {
			setSelectPreset(selectName);
		}, [arrayItems, selectPresetId, selectName]);

		useEffect(() => {
			if (!isVisibleList) {
				setEditPreset(false);
			}
		}, [isVisibleList]);

		useEffect(() => {
			if (modalType !== '') setIsOpenModal(true);
		}, [modalType]);

		const handlePatrolList = () => {
			setIsPatrol(!isPatrol);
		};

		const handleCloseModal = () => {
			setIsOpenModal(false);
			setEditPreset(false);
			setModalType('');
		};

		const iconsItem: { [key: string]: () => JSX.Element } = {
			true: () => (
				<div style={{ display: 'flex', gap: 6 }}>
					{editIcons.map(({ id, icon, click }) => {
						return (
							<div key={`${id}-${name}`} className={styles.iconSet} onClick={click}>
								{icon}
							</div>
						);
					})}
				</div>
			),
			false: () => (
				<div className={styles.iconSet} onClick={() => setEditPreset(true)}>
					<SettingIcon width={23} height={23} />
				</div>
			),
		};

		const createPresetItem = ({ id, name, isActive }: Preset) => {
			const key = `Control-Item-${id}-${name}`;
			const resultStyle = `${styles.placeItem} ${isActive && styles.show}`;

			return (
				<div key={key} className={resultStyle} onClick={() => clickPreset(id)}>
					<div className={styles.leftPartItem}>
						{userRole !== 'spotoperator' && (
							<div
								className={`${styles.iconPatrol} ${isAnalitica && styles.analiticaStyle}`}
								onClick={handlePatrolList}
							>
								{isPatrol ? (
									<div className={`${isAnalitica && styles.analiticaStyle}`}>
										<PauseIcon />
									</div>
								) : (
									<div className={`${isAnalitica && styles.analiticaStyle}`}>
										<PlayIcon />
									</div>
								)}
							</div>
						)}

						<div onClick={closeMenu}>{name}</div>
					</div>

					{userRole !== 'spotoperator' && (
						<div className={`${isAnalitica && styles.analiticaStyle}`}>
							{iconsItem[isEditPreset.toString()]()}
						</div>
					)}
				</div>
			);
		};

		return (
			<StyledList sx={listItemsStyle}>
				{isSearchPreset && (
					<div className={`${isAnalitica && styles.analiticaStyle}`}>
						<div className={styles.patrolHeader}>
							<div>Патрулирование: {isPatrol ? 'Включено' : 'Выключено'}</div>
							<div>{selectPreset?.name}</div>
						</div>

						{userRole !== 'spotoperator' && (
							<div className={styles.addPreset} onClick={() => setModalType('create')}>
								<div className={styles.iconAdd}>
									<PlusIcon />
								</div>
								Создать пресет
							</div>
						)}
					</div>
				)}

				{arrayItems.map(createPresetItem)}

				<Modal className={styles.modal} open={isOpenModal} onClose={handleCloseModal}>
					<div className={styles.modalContent}>
						<PresetsModals
							type={modalType}
							selectPreset={selectPreset}
							idDevice={deviceName?.id}
							deviceName={deviceName?.name}
							placeName={placeName?.name}
							setOpenModal={setIsOpenModal}
							setEditPreset={setEditPreset}
							setModalType={setModalType}
						/>
					</div>
				</Modal>
			</StyledList>
		);
	}
);

export default PresetsList;
