import loadingState from '../../../store/loadingState/loadingState';
import { AllPagesGetData as ReadyPages, MapPageReadyData as MapIsReady } from '../../../store/loadingState/loading.interface';

interface DataWithDelay {
	(page: keyof ReadyPages, setReadyPages: (key: keyof ReadyPages, isValue: boolean) => void): void;
}

const setDataWithDelay: DataWithDelay = (page, setReadyPages) => {
	setTimeout(() => {
		setReadyPages(page, true);
	}, 1500);
};

export const checkPageReady = (key: keyof MapIsReady, isValue = false) => {
	const { mapReady, setMapReady, setReadyPages } = loadingState;
	const page: keyof ReadyPages = 'map';

	const editData: MapIsReady = {...mapReady};
	editData[key] = isValue;

	setMapReady(editData);

	const readyData = Object.values(editData);
	const isPageReady = readyData.reduce((isReady, isItem) => isReady && isItem, true);

	isPageReady && setDataWithDelay(page, setReadyPages);
};
