import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import styles from './Analitica.module.scss';
import CustomAnaliticaCard from './CustomAnaliticaCard/CustomAnaliticaCard';
import {AnaliticaData, AnaliticaProps} from './Analitica.interface';
import analiticaState from '@store/analiticaState/analiticaState';
import IRAnaliticaCard from '@pages/AnaliticaPage/IRAnaliticaCard/IRAnaliticaCard';
import devicesState from '@store/devicesState/devicesState';
import presetState from '@store/presetState/presetState';
import {IRAnaliticaCardProps} from '@pages/AnaliticaPage/IRAnaliticaCard/IRAnaliticaCard.interface';
import {HeatAnalitica, IRAnalitica, ZonesHeat} from '@store/analiticaState/interface';
import connectState from '@store/connectState/connectState';


const Analitica: React.FC<AnaliticaProps> = observer(({ countRows, countRowsIR, prevLinkIR,prevLink, updateHeat, updateIR, setPageCounter, nextLink, nextLinkIR,setPageCounterIR, selectePeriod, setSelectedPeriodIR, setSelectedPeriod, selectePeriodIR, pageCounterIR, pageCounter}) => {
	const { heatAnalitica, irAnalitica } = analiticaState;
	const { selectDeviceId } = devicesState;
	const { selectPresetId } = presetState;
	const { aiEventLink } = connectState;

	const [heatData, setHeatData] = useState<HeatAnalitica>(heatAnalitica[0]);
	const [irData, setIRData] = useState<IRAnalitica>(irAnalitica[0]);
	const [selectedIDDevice, setSelectedIDDevice] = useState<string | null>('');


	useEffect(() => {
		setIRData(irAnalitica[0]);
		setHeatData(heatAnalitica[0]);
		setSelectedIDDevice(selectDeviceId);
	}, [selectDeviceId, selectPresetId, selectePeriodIR, pageCounterIR, irAnalitica, heatAnalitica]);


	const newAnalitica: IRAnaliticaCardProps[] = [
		{
			title: 'Аналитика зон нагрева',
			zones: heatData?.zones,
			type: 'temp',
			data: heatData,
			updateEvents: updateHeat,
			selectePeriod: selectePeriod,
			setSelectedPeriod: setSelectedPeriod,
			pageCounter: pageCounter,
			setPageCounter: setPageCounter,
			nextLink: nextLink,
			prevLink: prevLink,
			url: `${aiEventLink}?id_psw=${selectedIDDevice}&analytic_type=heat&preset=${selectPresetId}&period_type=${selectePeriod}`,
			countRows: countRows
		},
		{
			title: 'Анализ пользовательских зон нагрева',
			zones: irData?.zones,
			type: 'ir',
			data: irData,
			selectePeriod: selectePeriodIR,
			updateEvents: updateIR,
			setSelectedPeriod: setSelectedPeriodIR,
			pageCounter: pageCounterIR,
			setPageCounter: setPageCounterIR,
			nextLink: nextLinkIR,
			prevLink: prevLinkIR,
			url: `${aiEventLink}?id_psw=${selectedIDDevice}&preset=${selectPresetId}&analytic_type=ir&period_type=${selectePeriodIR}`,
			countRows: countRowsIR
		}
	];
	const analiticaCard: AnaliticaData[] = [
		{title: 'Анализ коронных разрядов', condition: 'red', type: 'energe',isLastCard: true, },
	];

	const createNewCard = ({ title, condition, isLastCard, type }: AnaliticaData) => {
		const data =  { title, condition, isLastCard, type };
		const key = `CustomAnaliticaCard-${title}-${condition}-${type}`;

		return <CustomAnaliticaCard key={key} item={data}/>;
	};

	const createAnaliticaCard = ({ title, zones, data, setSelectedPeriod, selectePeriod, type, setPageCounter, pageCounter, url, prevLink, nextLink, updateEvents, countRows }: IRAnaliticaCardProps) => {
		const key = `Custom-Analitica-Card-${title}-${zones}`;
		// const checkArray = type === 'ir' ? irAnalitica : heatAnalitica;
		const defZones = [] as ZonesHeat[];

		return (
			<div className={styles.box} key={key}>
				<div className={styles.card}>
					<div className={styles.header}>
						{title}
					</div>
					<IRAnaliticaCard
						title={title}
						zones={zones ? zones : defZones}
						data={data}
						pageCounter={pageCounter}
						setSelectedPeriod={setSelectedPeriod}
						selectePeriod={selectePeriod}
						type={type}
						setPageCounter={setPageCounter}
						updateEvents={updateEvents}
						url={url}
						prevLink={prevLink}
						nextLink={nextLink}
						countRows={countRows}
					/>
				</div>
			</div>
		);
	};

	return (
		<Box className={styles.container}>
			<div className={styles.boxCards}>
				{newAnalitica.map(createAnaliticaCard)}
				{analiticaCard.map(createNewCard)}
			</div>
		</Box>
	);
});
export default Analitica;

