import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, List} from '@mui/material';
import styles from './SettingsEvent.module.scss';
import {InfoKeyDataSettingsEvent, KeyForFields} from './SettingsEvent.interface';
import InfoInput from './InfoInput/InfoInput';
import InfoBox from './InfoBox/InfoBox';
import {InputData} from './InfoInput/InfoInput.interface';
import {apiGet, apiPatch} from '@api/api';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import SettingIcon from '@assets/Icons/Translation/SettingIcon';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import {PrepareSettings, PresetSettings} from '@api/Translation/prepareTranslationData.interface';
import presetState from '@store/presetState/presetState';
import devicesState from '@store/devicesState/devicesState';
import connectState from '@store/connectState/connectState';
import {preperaPresetSettings} from '@api/Translation/prepareTranslationData';
import {observer} from 'mobx-react-lite';

const SettingsEvent: React.FC = observer(() => {
	const { presetSettings, selectPresetId, presets } = presetState;
	const { selectDeviceId } = devicesState;
	const {getCameraInfo, getEventsLink} = connectState;

	const [settings, setSettings] = useState<PresetSettings>(presetSettings);
	const [isSettings, setIsSettings] = useState(false);
	const [isDisableButton, setDisableButton] = useState(false);
	const [currentType, setCurrentType] = useState<keyof KeyForFields>('box');

	useEffect(() => {
		const controller = new AbortController();

		const selectedNum = presets.find((preset) => preset.id === selectPresetId)?.number;

		if (!selectedNum || !selectDeviceId || !getEventsLink) return;

		const url = `${getEventsLink}${selectDeviceId}/getpresetparameters/${selectedNum}/`;

		getSettingsData(url, controller);

		return () => {
			controller.abort();
		};
	}, [getEventsLink, selectPresetId, presets]);


	const getSettingsData = (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error }) => {
			const { isError } = error;
			if (isError) return;
			
			const presetsSettings = preperaPresetSettings(data as PrepareSettings);
			setSettings(presetsSettings);
		});
	};


	const {/*dataSoundEnergy, dataSoundFrequency, dataSoundCorr,*/ presetMaxTemp, presetMinTemp} = settings;

	// const soundInf: InfoKeyDataSettingsEvent[] = [
	// 	{ id: 1, name: 'dataSoundEnergy', value: settings.dataSoundEnergy, title: 'Энергия:', simbol: '%' },
	// 	{ id: 2, name: 'dataSoundCorr', value: settings.dataSoundCorr, title: 'Корреляция:', simbol: '%'},
	// 	{ id: 3, name: 'dataSoundFrequency', value: settings.dataSoundFrequency, title: 'Частота:', simbol: '%'},
	// ];

	const tempInf: InfoKeyDataSettingsEvent[] = [
		{ id: 4, name: 'presetMinTemp', value: settings.presetMinTemp, title: 'Минимальная температура:', simbol: '℃'},
		{ id: 5, name: 'presetMaxTemp', value: settings.presetMaxTemp, title: 'Максимальная температура:', simbol: '℃'},
	];

	const handleToggleSettings = () => {
		setIsSettings(!isSettings);
		setCurrentType(prevType => prevType === 'box' ? 'input' : 'box');
	};

	// const fieldsSound = {
	// 	box: <InfoBox data={soundInf} simbol={'%'} type={'sound'}/>,
	// 	input: <InfoInput data={soundInf} setDisableButton={setDisableButton} type={'sound'}/>
	// };

	const fieldsTemp = {
		box: <InfoBox data={tempInf} simbol={'℃'} type={'temp'}/>,
		input: <InfoInput data={tempInf} setDisableButton={setDisableButton} type={'temp'}/>
	};

	const item: InputData = {
		// dataSoundEnergy: dataSoundEnergy,
		// dataSoundCorr: dataSoundCorr,
		// dataSoundFrequency: dataSoundFrequency,
		presetMinTemp: presetMinTemp,
		presetMaxTemp: presetMaxTemp,
	};
	const [inputData, setInputData] = useState<InputData>(item as InputData);

	const handleChangeInput = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputData((prevInput) => ({
			...prevInput,
			[name]: value,
		}));
	};

	const sendCahngeInput = () => {
		const selectedPreset = presets.find(preset => preset.id === selectPresetId);
		const number = selectedPreset ? selectedPreset.number : null;
		const newUrl = `${getCameraInfo}${selectDeviceId}/updatepreset/${number}/`;
		const combinedData = {
			data_sound_corr: 10000,
			data_sound_energy: 10000,
			data_sound_frequency: 10000,
			preset_max_temp: inputData.presetMaxTemp,
			preset_min_temp: inputData.presetMinTemp,
		};

		apiPatch(newUrl, combinedData, {}).then(({ error }) => {
			const { isError } = error;
			setCurrentType('box');

			if (isError) return;
		});
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.header}>
				<div className={styles.titleInfo}>Настройка событий</div>
				<div className={styles.iconButton} onClick={handleToggleSettings}>
					{currentType === 'box'
						? <SettingIcon width={25} height={25}/>
						: <CloseIcon width={25} height={25}/>
					}
				</div>
			</div>
			<List className={`${styles.listContent} ${isSettings ? styles.boxList : styles.boxList}`}>
				{/* <Box>Параметры звука</Box>
				{currentType === 'box'
					? (fieldsSound[currentType])
					:
					(<div className={styles.contentBox}>
						{soundInf.map(({id, name, title}) => (
							<Box className={styles.labelAndBox} key={`Input-Settings-Event-${id}-${name}`}>
								{title}
								<div className={styles.conteiner}>
									<div className={styles.inputBox}>
										<input
											className={styles.styleInput}
											maxLength={4}
											value={inputData[name]}
											type={'number'}
											onChange={handleChangeInput(name)}
										/>
									</div>
									<p>%</p>
								</div>
							</Box>
						))}
					</div>)
				} */}
				<Box>Параметры температуры</Box>
				{currentType === 'box'
					? (fieldsTemp[currentType])
					:
					(<div className={styles.contentBox}>
						{tempInf.map(({id, name, title}) => (
							<Box className={styles.labelAndBox} key={`Input-Settings-Event-${id}-${name}`}>
								{title}
								<div className={styles.conteiner}>
									<div className={styles.inputBox}>
										<input
											className={styles.styleInput}
											maxLength={4}
											value={inputData[name]}
											type={'number'}
											onChange={handleChangeInput(name)}
										/>
									</div>
									<p>℃</p>
								</div>
							</Box>
						))}
					</div>)
				}

				{currentType === 'input' &&
                    <ConfirmButtons isDisabled={isDisableButton} handleOk={sendCahngeInput} handleCancel={handleToggleSettings}/>
				}
			</List>
		</div>
	);
});

export default SettingsEvent;
