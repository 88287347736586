import React from 'react';
import styles from './IconsBox.module.scss';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';

const IconsBox: React.FC = () => {
	const { iconContainer, icon } = styles;

	return (
		<div className={iconContainer}>
			<LocalPhoneIcon className={icon}/>
			<EmailIcon className={icon}/>
			<TelegramIcon className={icon}/>
		</div>
	);
};

export default IconsBox;
