import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './PrepareProtocol.module.scss';
import { Box } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import file from '../../../assets/pdf/file.jpg';
import PTSans from '../../../assets/pdf/PTSans.ttf';
import PTSansBold from '../../../assets/pdf/PTSans-Bold.ttf';
import eventState from '../../../store/eventState/eventState';
import { getDefEvent } from '../../../helpers/defaultsData/defaultsData';
import ConfirmButtons from '../../../helpers/components/ConfirmButtons/ConfirmButtons';
import AddIcon from '../../../assets/Icons/Account/AddIcon';
import devicesState from '../../../store/devicesState/devicesState';
import CloseIcon from '../../../assets/Icons/Map/CloseIcon';

let id = 1;
let yCoord = 137;

const PrepareProtocol = observer(() => {
	const { selectEventId, events, eventScreen, setShowPrepareProtocol } = eventState;
	const { devices } = devicesState;

	const deviceName = devices.find(({isSelected}) => isSelected).name;

	const [event, setEvent] = useState(getDefEvent());
	const [pao, setPao] = useState('');
	const [branch, setBranch] = useState('');
	const [sector, setSector] = useState('');
	const [location, setLocation] = useState('');
	const [address, setAddress] = useState('');
	const [phone, setPhone] = useState('');
	const [registration, setRegistration] = useState('');
	const [gived, setGived] = useState('');
	const [period, setPeriod] = useState('');
	const [organization, setOrganization] = useState('');
	const [place, setPlace] = useState('');
	const [equipment, setEquipment] = useState('');
	const [purposes, setPurposes] = useState('');
	const [numberProt, setNumberProt] = useState('');
	const [dates, setDates] = useState('');
	const [temperature, setTemperature] = useState('');
	const [humidity, setHumidity] = useState('');
	const [pressure, setPressure] = useState('');
	const [accordance, setAccordance] = useState('');
	const [rowsBaseData, setRowsBaseData] = useState([{id: 0, cell1: '', cell2: '', cell3: '', yCoord}]);
	const [downHeight, setDownHeight] = useState(yCoord + rowsBaseData.length * 10);

	const index = events.findIndex(({ id }) => id === selectEventId);
	const { viewData } = events[index === -1 ? 0 : index];

	const values = viewData.temperGrid.flatMap((arr) => arr.map((item) => item));
	const maxTemp = Math.max(...values);
	const minTemp = Math.min(...values);

	useEffect(() => {
		setEvent(events[index === -1 ? 0 : index]);
		setDates(events[index === -1 ? 0 : index].date);
	}, [selectEventId]);

	const customer = [[organization]];
	const object = [[place, equipment]];

	const arrayHead = [
		['ПАО/АО', pao],
		['Филиал', branch],
		['Участок', sector],
		['Район', location],
		['Индекс, адрес\n', address],
		['Телефон:', phone],
		['Свидетельство о регистрации №:', registration],
		['Выдано:', gived],
		['Срок действия до:', period],
	];

	const arrayResults = [
		['Тип сообщения', event.messageType],
		['Дата', event.date],
		['Время', event.time],
		['Температура нагрева оборудования, макс °С', maxTemp],
		['Температура нагрева оборудования, мин °С', minTemp],
		['Изменение звука от базового значения, %', event.soundCorrelation],
		['Частота звука, Гц', event.soundFrequency],
	];


	const filePDF = () => {
		const dateReg = new Date();
		const purpose = [[purposes]];
		const date = dates;

		const temp = temperature;
		const humid = humidity;
		const press = pressure;

		const columns1 = [['Номер протокола', 'Дата регистрации']];
		const data1 = [[numberProt, dateReg.toLocaleDateString('ru-RU')]];

		const columns2 = [['Присоединение', 'Iраб, A', 'Iмакс, A']];

		const columns3 = [['Элементный состав', 'Результаты осмотра']];
		const data3 = [['Соответствие контактных требований ГОСТ', accordance]];

		const doc = new jsPDF();
		const imgData = file;

		const pageHeight = doc.internal.pageSize.height;
		const pageWidth = doc.internal.pageSize.width;

		const tableWidth = pageWidth / 3 - 10.5;
		const isAddnNewPageForDownPath = rowsBaseData.length > 7 && rowsBaseData.length < 14;
		const downPathHeight = isAddnNewPageForDownPath ? 0 : downHeight;


		doc.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);
		doc.addFont(PTSans, 'PTSans', 'normal');
		doc.addFont(PTSansBold, 'PTSansBold', 'normal');
		doc.setFont('PTSans'); // set font
		doc.setFont('PTSans'); // set font

		doc.autoTable({
			startY: 10,
			margin: 20,
			body: arrayHead,
			styles: {
				font: 'PTSans',
				// valign: 'middle',
				cellPadding: 0.5,
				fontSize: 10,
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				textColor: 0,
				fillColor: [255, 255, 255],
			},
			alternateRowStyles: {
				fillColor: [255, 255, 255],
			},
			columnStyles: {
				0: { cellWidth: 53 },
				1: { cellWidth: 45 },
			},
		});
		doc.setFontSize(10);

		doc.text(date, 69, 120.5);

		doc.text(temp, 112, 125.5);
		doc.text(humid, 132, 125.5);
		doc.text(press, 152, 125.5);

		doc.text('г.', 119, 53);
		doc.text('г.', 119, 58);

		doc.autoTable({
			startY: 67,
			margin: 51,
			body: customer,
			styles: {
				font: 'PTSans',
				halign: 'center',
				cellPadding: 0.5,
				fontSize: 10,
				lineColor: [255, 255, 255],
				lineWidth: 0.1,
				cellWidth: 124,
				textColor: 0,
			},
			alternateRowStyles: {
				fillColor: [0, 0, 0, 0],
			},
		});

		doc.autoTable({
			startY: 76,
			margin: 50,
			body: object,
			styles: {
				font: 'PTSans',
				halign: 'center',
				cellPadding: 0.5,
				fontSize: 10,
				lineColor: [255, 255, 255],
				lineWidth: 0.1,
				cellWidth: 62,
				textColor: 0,
			},
			alternateRowStyles: {
				fillColor: [0, 0, 0, 0],
			},
		});

		doc.autoTable({
			startY: 85,
			margin: 51,
			body: purpose,
			styles: {
				font: 'PTSans',
				halign: 'center',
				cellPadding: 0.5,
				fontSize: 10,
				lineColor: [255, 255, 255],
				lineWidth: 0.1,
				cellWidth: 124,
				textColor: 0,
			},
			alternateRowStyles: {
				fillColor: [0, 0, 0, 0],
			},
		});

		doc.setDrawColor(0, 0, 0);
		doc.line(51, 71.5, 175, 71.5);
		doc.line(51, 80.5, 175, 80.5);
		doc.line(51, 89.5, 175, 89.5);

		doc.autoTable({
			startY: 94,
			margin: 130,
			head: columns1,
			body: data1,
			styles: {
				font: 'PTSans',
				valign: 'middle',
				halign: 'center',
				cellPadding: 0.5,
				fontSize: 10,
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				cellWidth: 33,
				textColor: 0,
				fillColor: [255, 255, 255],
			},
			alternateRowStyles: {
				fillColor: [255, 255, 255],
			},
		});

		{rowsBaseData.slice(0, 13).map(({yCoord, cell1, cell2, cell3}) => (
			doc.autoTable({
				startY: yCoord,
				margin: 20,
				head: columns2,
				body: [[cell1, cell2, cell3]],
				styles: {
					font: 'PTSans',
					valign: 'middle',
					halign: 'center',
					cellPadding: 0.5,
					fontSize: 10,
					lineColor: [0, 0, 0],
					lineWidth: 0.1,
					textColor: 0,
					fillColor: [255, 255, 255],
				},
				alternateRowStyles: {
					fillColor: [255, 255, 255],
				},
				columnStyles: {
					0: { cellWidth: tableWidth},
					1: { cellWidth: tableWidth},
					2: { cellWidth: tableWidth},
				},
			})
		));
		}

		{rowsBaseData.slice(14, 18).map(({yCoord, cell1, cell2, cell3}) => (
			doc.autoTable({
				startY: yCoord,
				margin: 20,
				head: columns2,
				body: [[cell1, cell2, cell3]],
				styles: {
					font: 'PTSans',
					valign: 'middle',
					halign: 'center',
					cellPadding: 0.5,
					fontSize: 10,
					lineColor: [0, 0, 0],
					lineWidth: 0.1,
					textColor: 0,
					fillColor: [255, 255, 255],
				},
				alternateRowStyles: {
					fillColor: [255, 255, 255],
				},
				columnStyles: {
					0: { cellWidth: tableWidth},
					1: { cellWidth: tableWidth},
					2: { cellWidth: tableWidth},
				},
			})
		));
		}

		if (isAddnNewPageForDownPath) doc.addPage();
		doc.setFont('PTSansBold');
		doc.setFontSize(10);
		doc.text('2. Результат технического осмотра', 20, downPathHeight + 15);

		doc.autoTable({
			startY: downPathHeight + 19,
			margin: 20,
			head: columns3,
			body: data3,
			styles: {
				font: 'PTSans',
				valign: 'middle',
				halign: 'center',
				cellPadding: 0.5,
				fontSize: 10,
				cellWidth: 88,
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				textColor: 0,
				fillColor: [255, 255, 255],
			},
			alternateRowStyles: {
				fillColor: [255, 255, 255],
			},
		});

		doc.setFont('PTSansBold');
		doc.setFontSize(10);

		doc.text('3. Результат технического осмотра', 20,  downPathHeight + 35);
		doc.setFont('PTSansBold');

		doc.setFontSize(10);
		doc.text(`3.1 Оборудование: ${deviceName}`, 20, downPathHeight + 40);

		doc.addImage(eventScreen, 'jpeg', 113, downPathHeight + 46, 83.5, 47);
		doc.autoTable({
			startY: downPathHeight + 46,
			margin: 20,
			body: arrayResults,
			styles: {
				font: 'PTSans',
				cellPadding: 0.5,
				fontSize: 10,
				cellWidth: 50,
				lineColor: [0, 0, 0],
				lineWidth: 0.1,
				textColor: 0,
				fillColor: [255, 255, 255],
			},
			alternateRowStyles: {
				fillColor: [255, 255, 255],
			},
		});

		doc.output('save', 'protocol.pdf');
	};

	const createFile = () => {
		filePDF();
		setShowPrepareProtocol();
	};

	const addNewRows = () => {
		if (rowsBaseData.length > 19) return;

		const isResetHeight = rowsBaseData.length > 14;
		const downPathHeight = isResetHeight ? (rowsBaseData.length - 13) * 10 + 10 : rowsBaseData.length * 10 + yCoord;
		setDownHeight(downPathHeight);

		const newRow = {id: id++, cell1: '', cell2: '', cell3: '', yCoord: downPathHeight};
		setRowsBaseData(prevRows => [...prevRows, newRow]);
	};

	const deleteRow = (id) => {
		setRowsBaseData(prevRows => prevRows.filter(row => row.id !== id));
	};

	function addDotsToDates(dateString) {
		const regex = /(\d{2})(\d{2})(\d{4})/;
		const formattedDate = dateString.replace(regex, '$1.$2.$3');
		return formattedDate;
	}

	const formattedDates = addDotsToDates(dates);

	return (
		<Box className={styles.container}>
			<Box>
				<Box className={styles.headBas}>Протокол контроля</Box>
				<Box className={styles.obj}>
					<Box className={styles.els}>
						<Box className={styles.item}>
							ПАО/АО:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={pao}
									onChange={(e) => setPao(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Филиал:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={branch}
									onChange={(e) => setBranch(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Участок:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={sector}
									onChange={(e) => setSector(e.target.value)}
								/>
							</div>
						</Box>
					</Box>

					<Box className={styles.els}>
						<Box className={styles.item}>
							Район:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={location}
									onChange={(e) => setLocation(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Индекс, адрес:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Телефон:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='number'
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</div>
						</Box>
					</Box>

					<Box className={styles.els}>
						<Box className={styles.item}>
							Регистрация №:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='number'
									value={registration}
									onChange={(e) => setRegistration(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Выдано:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='number'
									value={gived}
									onChange={(e) => setGived(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Срок действия до:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									maxLength='10'
									value={period}
									onChange={(e) => setPeriod(e.target.value)}
								/>
							</div>
						</Box>
					</Box>
				</Box>

				<Box className={styles.headProt}>
					<div>Центральная диагностическая лаборатория</div>
					<div>Служба диагностики</div>
				</Box>
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<p style={{ marginRight: '20px' }}>Заказчик:</p>
					<div className={styles.inputBox}>
						<input
							className={styles.styleInput}
							type='text'
							value={organization}
							onChange={(e) => setOrganization(e.target.value)}
						/>
					</div>
				</Box>
				<Box className={styles.obj}>
					<Box className={styles.els}>
						<Box className={styles.item}>
							Место испытания:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={place}
									onChange={(e) => setPlace(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Цель испытаний:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={purposes}
									onChange={(e) => setPurposes(e.target.value)}
								/>
							</div>
						</Box>
						<Box className={styles.item}>
							Оборудование:
							<div className={styles.inputBox}>
								<input
									className={styles.styleInput}
									type='text'
									value={equipment}
									onChange={(e) => setEquipment(e.target.value)}
								/>
							</div>
						</Box>
					</Box>
				</Box>
				<Box style={{ display: 'flex', justifyContent: 'center' }}>
					<p style={{ marginRight: '20px' }}>Номер протокола:</p>
					<div className={styles.inputBox}>
						<input
							className={styles.styleInput}
							type='text'
							value={numberProt}
							onChange={(e) => setNumberProt(e.target.value)}
						/>
					</div>
				</Box>

				<Box className={styles.headProt}>Протокол контроля модуля стационарной диагностики</Box>
				<Box className={styles.elements}>
					<Box style={{ display: 'flex', justifyContent: 'center' }}>
						<p style={{ marginRight: '20px' }}>Дата проведения испытания:</p>
						<div className={styles.inputBox}>
							<input
								className={styles.styleInput}
								type='text'
								value={formattedDates}
								maxLength='10'
								onChange={(e) => setDates(addDotsToDates(e.target.value))}
							/>
						</div>
					</Box>
					<Box className={styles.obj}>
						<Box className={styles.title}>Климатические условия проведения испытаний:</Box>
						<Box className={styles.els}>
							<Box className={styles.item}>
								Температура, °C
								<div className={styles.inputBox}>
									<input
										className={styles.styleInput}
										type='number'
										value={temperature}
										onChange={(e) => setTemperature(e.target.value)}
									/>
								</div>
							</Box>
							<Box className={styles.item}>
								Влажность, %
								<div className={styles.inputBox}>
									<input
										className={styles.styleInput}
										type='number'
										value={humidity}
										onChange={(e) => setHumidity(e.target.value)}
									/>
								</div>
							</Box>
							<Box className={styles.item}>
								Давление, Па
								<div className={styles.inputBox}>
									<input
										className={styles.styleInput}
										type='number'
										value={pressure}
										onChange={(e) => setPressure(e.target.value)}
									/>
								</div>
							</Box>
						</Box>
					</Box>

					<Box className={styles.obj}>
						<Box className={styles.title}>Основные данные:
							<div className={styles.icon} onClick={addNewRows}>
								<AddIcon />
							</div>
						</Box>
						{rowsBaseData.map(({id, cell1, cell2, cell3}) => (
							<Box key={`Input-PDF-Text-${id}`} className={styles.els}>
								<Box className={styles.item}>
									Присоединение
									<div className={styles.inputBox}>
										<input
											className={styles.styleInput}
											type='number'
											value={cell1}
											onChange={(e) => {
												const updatedRows = rowsBaseData.map(r => {
													if (r.id === id) {
														return { ...r, cell1: e.target.value };
													}
													return r;
												});
												setRowsBaseData(updatedRows);
											}}
										/>
									</div>
								</Box>
								<Box className={styles.item}>
									Iраб, A
									<div className={styles.inputBox}>
										<input
											className={styles.styleInput}
											type='number'
											value={cell2}
											onChange={(e) => {
												const updatedRows = rowsBaseData.map(r => {
													if (r.id === id) {
														return { ...r, cell2: e.target.value };
													}
													return r;
												});
												setRowsBaseData(updatedRows);
											}}
										/>
									</div>
								</Box>
								<Box className={styles.item}>
									Iмакс, A
									<div className={styles.inputBox}>
										<input
											className={styles.styleInput}
											type='number'
											value={cell3}
											onChange={(e) => {
												const updatedRows = rowsBaseData.map(r => {
													if (r.id === id) {
														return { ...r, cell3: e.target.value };
													}
													return r;
												});
												setRowsBaseData(updatedRows);
											}}
										/>
									</div>
								</Box>

								<div className={styles.iconDeleteItem} onClick={() => deleteRow(id)}>
									<CloseIcon/>
								</div>
							</Box>
						))}
					</Box>

					<Box className={styles.objTwo}>
						<Box className={styles.title}>Результаты внешнего осмотра:</Box>
						<Box className={styles.els}>
							<Box className={styles.item}>
								Элементный состав
								<div>Соответствие контактных требований ГОСТ</div>
							</Box>
							<Box className={styles.item}>
								Результаты осмотра:
								<div className={styles.inputBox}>
									<input
										className={styles.styleInput}
										type='text'
										id='accordance'
										value={accordance}
										onChange={(e) => setAccordance(e.target.value)}
									/>
								</div>
							</Box>
						</Box>
					</Box>
				</Box>

				<div className={styles.modalButtons}>
					<ConfirmButtons
						nameOk={'Загрузить'}
						handleOk={createFile}
						handleCancel={setShowPrepareProtocol}
					/>
				</div>

			</Box>
		</Box>
	);
});

export default PrepareProtocol;
