import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ChartIcon: React.FC<IconSvgProps> = ({ width = 19, height = 19, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>График</title>
			<path
				d="M7 17V7M13 17V1M1 17V13"
				stroke={fill}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default ChartIcon;
