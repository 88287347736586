import {GridRenderCellParams} from '@mui/x-data-grid';

export const cards = [
	'Контроль движения',
	'События по температуре',
	'События по ультрафиолету',
	'Звуковые события'
];

export const cardsRightCard = [
	'Контроль движения',
	'Событие по температуре',
	'Событие по ультрафиолету',
	'Звуковое событие'
];

export const columns = [
	[
		{ field: 'id', headerName: 'id', width: 70 },
		{ field: 'cameraName', headerName: 'Устройство', flex: 1 },
		{ field: 'presetName', headerName: 'Позиция камеры', flex: 1 },
		{ field: 'zoneName', headerName: 'Зона', flex: 1 },
		{ field: 'date', headerName: 'Дата', width: 110 },
		{ field: 'time', headerName: 'Время', width: 90 },
		{ field: 'img', headerName: 'Изображение', flex:1, height: 100, filterable: false, renderCell: (params: GridRenderCellParams) => <img src={params.value}  alt='image' height='80px' />},
	],
	[
		{ field: 'id', headerName: 'id', width: 70 },
		{ field: 'cameraName', headerName: 'Устройство', flex: 1 },
		{ field: 'presetName', headerName: 'Позиция камеры', flex: 1 },
		{ field: 'zoneName', headerName: 'Зона', flex: 1 },
		{ field: 'date', headerName: 'Дата', width: 110 },
		{ field: 'time', headerName: 'Время', width: 110 },
		{ field: 'img', headerName: 'Изображение', flex:1, height: 100, filterable: false, renderCell: (params: GridRenderCellParams) => <img src={params.value}  alt='image' height='80px' />},
	],
	[
		{ field: 'id', headerName: 'id', width: 70 },
		{ field: 'cameraName', headerName: 'Устройство', flex: 1 },
		{ field: 'presetName', headerName: 'Позиция камеры', flex: 1 },
		{ field: 'zoneName', headerName: 'Зона', flex: 1 },
		{ field: 'date', headerName: 'Дата', width: 110 },
		{ field: 'time', headerName: 'Время', width: 110 },
		{ field: 'img', headerName: 'Изображение', flex:1, height: 100, filterable: false, renderCell: (params: GridRenderCellParams) => <img src={params.value}  alt='image' height='80px' />},
	],
	[
		{ field: 'id', headerName: 'id', width: 70 },
		{ field: 'cameraName', headerName: 'Устройство', flex: 1 },
		{ field: 'presetName', headerName: 'Позиция камеры', flex: 1 },
		{ field: 'zoneName', headerName: 'Зона', flex: 1 },
		{ field: 'date', headerName: 'Дата', width: 110 },
		{ field: 'time', headerName: 'Время', width: 110 },
		{ field: 'img', headerName: 'Изображение', flex:1, height: 100, filterable: false, renderCell: (params: GridRenderCellParams) => <img src={params.value}  alt='image' height='80px' />},
	],
];

export const columnsReport = [
	{ field: 'id', headerName: 'id', flex: 1 },
	{ field: 'date', headerName: 'Дата', flex: 1 },
	{ field: 'time', headerName: 'Время', flex: 1 },
	{ field: 'messageType', headerName: 'Тип сообщения', flex: 1 },
	{ field: 'maxTemp', headerName: 'Т макс', flex: 1 },
	{ field: 'meanTemp', headerName: 'T сред', flex: 1 },
	{ field: 'minTemp', headerName: 'T мин', flex: 1 },
	{ field: 'soundFrequency', headerName: 'Частота звука', flex: 1 },
	{ field: 'soundCorr', headerName: 'Корреляция звука', flex: 1 },
	{ field: 'soundEnergy', headerName: 'Энергия звука', flex: 1 },
	{ field: 'img', headerName: 'Изображение', flex: 1 },
];

export const columnsReportTable = [
	{ field: 'id', headerName: 'id', flex: 1 },
	{ field: 'date', headerName: 'Дата', flex: 1 },
	{ field: 'time', headerName: 'Время', flex: 1 },
	{ field: 'messageType', headerName: 'Тип сообщения', flex: 1 },
];