import React from 'react';
import styles from './CardSet.module.scss';
import { CardProps } from './Card/Card.interface';
import {observer} from 'mobx-react-lite';
import Card from './Card/Card';
import RightCard from './RightCard/RightCard';
import {cards, cardsRightCard, columns} from './data';
import dashboardState from '@store/dashboardState/dashboardState';

const CardSet: React.FC = observer(() => {
	const {numberFullTable, isOpenFullWin} = dashboardState;

	const fullWinCardData: CardProps = { columns, cardData: cards[numberFullTable], num: numberFullTable, numberRowsOnPage: 10 };
	
	const setCards = (cardData: string, index: number) => {
		const key = `CardSet-Card-Item-${index}`;
		const dataCard: CardProps = { columns, cardData: cardData, num: index, numberRowsOnPage: 3 };

		return (
			<Card key={key} {...dataCard} />
		);
	};

	return (
		<div className={styles.set}>
			{isOpenFullWin
				? <Card {...fullWinCardData} />
				: <div className={styles.allCards}>
					<div className={styles.cards}>
						{cards.map(setCards)}
					</div>

					<RightCard cardsData={cardsRightCard} />
				</div>
			}
		</div>
	);
});

export default CardSet;