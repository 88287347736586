import loadingState from '../../../store/loadingState/loadingState';
import { AllPagesGetData as ReadyPages, AccountPageIsReadyData as AccountIsReady } from '../../../store/loadingState/loading.interface';

interface DataWithDelay {
	(page: keyof ReadyPages, setReadyPages: (key: keyof ReadyPages, isValue: boolean) => void): void;
}

const setDataWithDelay: DataWithDelay = (page, setReadyPages) => {
	setTimeout(() => {
		setReadyPages(page, true);
	}, 1500);
};

export const checkPageReady = (key: keyof AccountIsReady, isValue = false) => {
	const { accountReady, setAccountReady, setReadyPages } = loadingState;
	const page: keyof ReadyPages = 'account';

	const editData: AccountIsReady = {...accountReady};
	editData[key] = isValue;

	setAccountReady(editData);

	const readyData = Object.values(editData);
	const isPageReady = readyData.reduce((isReady, isItem) => isReady && isItem, true);

	isPageReady && setDataWithDelay(page, setReadyPages);
};