import styles from './SensorItem.module.scss';
import SettingIcon from '../../../../assets/Icons/Translation/SettingIcon';
import FullWinIcon from '../../../../assets/Icons/Dashboard/FullWinIcon';
import {SensorItemProps} from './SensorItem.interface';
import ItemValues from './ItemsValues/ItemValues';
import Chart from './Chart/Chart';
import React, { useState } from 'react';
import BackIcon from '../../../../assets/Icons/LeftMenu/BackIcon';
import CloseIcon from '../../../../assets/Icons/Map/CloseIcon';
import AgreeIcon from '../../../../assets/Icons/ControlPage/AgreeIcon';
import Box from '@mui/material/Box';
import {DiscreteData, SensorsData} from '../Sensors.interface';

const SensorItem: React.FC<SensorItemProps> = ({item, icon, isDiscrete, event, type, valueType }) => {
	const [color, setColor] = useState('#D2D2D2');
	const [isSettings, setSettings] = useState(false);
	const currentValue = item as SensorsData;

	const sensorsValues = [
		{title: 'Тип', value: type, sing: ''},
		{title: 'Расположение', value: item.spot, sing: valueType},
		{title: 'Текущее значение', value: currentValue.currentValue, sing: valueType},
		{title: 'Максимальное значение', value: 60.99, sing: valueType},
		{title: 'Минимальное значение', value: 10.99, sing: valueType},
	];

	const [isChart, setChart] = useState(false);


	const openChart = () => {
		setChart((prevState) => !prevState);
	};

	const discreteValue = item as DiscreteData;

	const discreteValues = [
		{title: 'Тип', value: type},
		{title: 'Расположение', value: item.spot},
		{title: 'Значение', value: discreteValue.discreteValue},
	];

	const isDiscreteContent = {
		true: <ItemValues valuesArray={discreteValues}/>,
		false: <ItemValues valuesArray={sensorsValues}/>
	};

	const settings = () => {
		return (
			<div className={styles.form}>
				<div>Тип устройства: {type}</div>
				Порог значений,  {valueType}
				<div className={styles.inputBox}>
					<input
						className={styles.styleInput}
						placeholder={'max'}
					/>
				</div>
				<div className={styles.inputBox}>
					<input
						className={styles.styleInput}
						placeholder={'min'}
					/>
				</div>
			</div>
		);
	};

	const isSettingsComponent = {
		true: settings(),
		false: isDiscreteContent[`${isDiscrete}`],
	};

	const handleMouseEnter = () => {
		setColor('#00C2FD');
	};

	const handleMouseLeave = () => {
		setColor('#D2D2D2');
	};

	const defaultIcons = () => {
		const visibilityIcon = isChart || isSettings;
		return (
			<div className={`${styles.iconsDef} ${visibilityIcon && styles.closedIcons}`}>
				<div className={styles.icon} onClick={openSettings}>
					<SettingIcon width={15} height={15} />
				</div>
				<div
					className={styles.icon}
					onClick={openChart}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<FullWinIcon width={15} height={15} fill={color}/>
				</div>
			</div>
		);
	};

	const openSettings = () => {
		setSettings(!isSettings);
	};

	const formIcons = () => {
		return (
			<div className={`${styles.iconsDef}`}>
				<div className={styles.agreeIcon} onClick={openSettings}>
					<AgreeIcon width={15} height={15} />
				</div>
				<div
					className={styles.icon}
					onClick={openSettings}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<CloseIcon width={15} height={15} />
				</div>
			</div>
		);
	};

	const isSettingsComponents = {
		true: {
			icons: formIcons(),
			action: () => setSettings(false)
		},
		false:{
			icons: defaultIcons(),
			action: () => setChart(false)
		}
	};

	const action = isSettingsComponents[`${isSettings}`].action;
	const headerIcons =  isSettingsComponents[`${isSettings}`].icons;

	const disableBackIcon = !isChart && !isSettings;

	return (
		<Box className={`${styles.card} ${isChart && styles.chart}`}>
			<div className={`${styles.header} ${isChart && styles.chartsHeader}`}>
				<div
					className={`${disableBackIcon && styles.closedIcons} ${styles.icon}`}
					onClick={action}
				>
					<BackIcon width={15} height={15}/>
				</div>
				<div>{headerIcons}</div>
			</div>
			<div className={styles.content}>
				<div className={styles.iconContainer}>{icon}</div>

				{isSettingsComponent[`${isSettings}`]}
				{isChart && (
					<Chart dateInfo={event?.date} />
				)}
			</div>
		</Box>
	);
};

export default SensorItem;