import React, { useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import styles from './Loading.module.scss';
import LogoIconAuth from '@assets/Auth/LogoIconAuth';

const Loading: React.FC = () => {
	const windowWidth = useRef(window.innerWidth);
	const isLittle = windowWidth.current < 500;

	const logoSize = [
		{ width: 340, height: 118 },
		{ width: 240, height: 83 },
	];

	return (
		<div className={styles.main}>
			<LogoIconAuth width={logoSize[+isLittle].width} height={logoSize[+isLittle].height} fill='#00C2FD' />
			<div className={styles.loadingTitleCard}>
				<div className={styles.loadingIcon}>
					<ClipLoader size={15} color={'#B1B1B1'} />
				</div>
				<p className={styles.loadingTitle}>Инициализация загрузки</p>
			</div>
			<div className={styles.downInf}>
				<div className={styles.loadingTitle}>разработано:</div>
				<div className={styles.devCard}>
					<div className={styles.devTitle}>SERVICE SOFT</div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
