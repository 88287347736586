import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
	ContentModalActionType as ActionType,
	ContentModalField as Field,
	ContentModalFields as Fields,
	ContentModalInputData,
	ContentModalProps as Props,
	ContentModalTypeModal,
	ContentModalTypeObject,
	ContentModalKeyForInput as KeyForInput,
	ContentModalTitle as Title,
	ContentModalSpotInfo,
} from './ContentModal.interface';
import styles from './ContentModal.module.scss';
import { apiPost, apiPatch } from '@api/api';
import connectState from '@store/connectState/connectState';
import { AccountPlace } from '@store/placeState/interfaces';
import autorizeState from '@store/accoutState/autorizeState';
import { Autocomplete, createTheme, styled, ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import ConfirmButtons from '../../../helpers/components/ConfirmButtons/ConfirmButtons';
import { Group } from '@store/accoutState/interfaces';
import {getUserDevice, postUserAction} from '@pages/AccountPage/AccountPage';
import DeleteIcon from '../../../assets/Icons/Translation/DeleteIcon';
import Confirm from '../CustomCard/ItemCard/Confirm/Confirm';
import Modal from '@mui/material/Modal';
import { TechUser } from '@api/AccountPage/prepareAccountData.interface';


const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const CustomTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#858585',
			borderRadius: 10,
			height: 48,

		},
		'&:hover fieldset': {
			borderColor: '#00C2FD',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#00C2FD',
		},
		color: '#cfcfcf',
		fontSize: '18px',
		padding: '2px 0 0 10px',
	},
});

const ContentModal: React.FC<Props> = ({
	title,
	handleClose,
	id,
	typeModal,
	selectedItem,
	spot = autorizeState.accountPlaces[0],
	techUser = autorizeState.techUsers[0],
}) => {
	const { postPlaceLink, getCameraInfo, getPlaceInfo, getDevicesLink } = connectState;
	const { setUpdateData, user, accountPlaces, allGroups, techUsers } = autorizeState;
	const spotInfo = selectedItem as ContentModalSpotInfo;

	const firstTitle = {
		create: 'Создать',
		change: 'Изменить',
	};

	const secondTitle: Title = {
		camera: 'камеру',
		location: 'локацию',
		manage: 'пользователя',
		action: 'действия',
	};

	const cameraFields: Field[] = [
		{ title: 'Имя', name: 'name', type: 'text' },
		{ title: 'Широта', name: 'lat', type: 'number' },
		{ title: 'Долгота', name: 'lng', type: 'number' },
		{ title: 'ID PSW', name: 'idPsw', type: 'text' },
	];

	const locationFields: Field[] = [
		{ title: 'Имя', name: 'name', type: 'text' },
		{ title: 'Широта', name: 'lat', type: 'number' },
		{ title: 'Долгота', name: 'lng', type: 'number' },
	];

	const fields: Fields = {
		camera: cameraFields,
		location: locationFields,
		manage: locationFields,
		action: locationFields,
	};

	const locationUpd = {
		item: selectedItem as KeyForInput,
		url: getPlaceInfo,
	};
	const locationPost = {
		item: { name: '', lng: '', lat: '' },
		url: postPlaceLink,
	};
	const camerasUpd = {
		item: selectedItem as KeyForInput,
		url: `${getCameraInfo}${id}/`,
	};
	const camerasPost = {
		item: { name: '', idPsw: '', lat: '', lng: '',  },
		url: getDevicesLink,
	};
	const dataByAction = {
		create: {
			camera: camerasPost,
			location: locationPost,
		},
		change: {
			camera: camerasUpd,
			location: locationUpd,
		},
	};

	const typeByAction = typeModal as keyof ContentModalTypeModal;
	const typeByObject = title as keyof ContentModalTypeObject;
	const typeForButton = title as keyof ActionType;
	const placeItem = selectedItem as AccountPlace;

	const selectedItems = dataByAction[typeByAction][typeByObject];
	const { item, url }: { item: KeyForInput; url: string } = selectedItems;

	const getGroup = (id: string) => {
		const groupIndex = allGroups.findIndex((group) => group.id === id);
		if (groupIndex === -1) return allGroups[0];
		return allGroups[groupIndex];
	};

	const isCamera = typeByObject === 'camera';
	const group = typeModal === 'create' ? allGroups[0] : getGroup(spotInfo.spot_group);

	const [inputData, setInputData] = useState<ContentModalInputData>(item);
	const [selectedPlaces, setSelectedPlaces] = useState<AccountPlace | null>(spot);
	const [selectedTechUser, setSelectedTechUser] = useState<TechUser | null>(techUser);
	const [selectedPlacesId, setSelectedPlacesId] = useState<string | null>(accountPlaces[0]?.id);
	const [selectedTechUserId, setSelectedTechUserId] = useState<number | null>(techUsers[0]?.id);
	const [isDisableButton, setDisableButton] = useState(true);
	const [selectedGroup, setSelectedGroup] = useState<Group>(group);


	const checkDisableButton = () => {
		const isAllFieldsFilled = checkMatch() && selectedPlaces !== null;
		setDisableButton(isAllFieldsFilled);
	};

	const checkIDSpot = () => {
		const isAdminSpot = spotInfo.spot_group === user.group?.id;
		if ( isAdminSpot || isCamera || user.role === 'staff' ) checkDisableButton();
	};

	useEffect(() => {
		if (user.role === 'spotoperator') return;
		checkIDSpot();
		checkDisableButton();
	}, [inputData, selectedPlaces]);


	const handleChangeInput = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputData((prevInput) => ({
			...prevInput,
			[name]: value,
		}));
		checkDisableButton();
	};

	const checkMatch = () => {
		const values = Object.values(inputData);

		return values.every((value) => value !== '');
	};

	const postPlaces = () => {
		const actionObjects = {
			true:
				{
					url: `${postPlaceLink}${selectedGroup.id}/createspot/`,
					spotGroup: selectedGroup.id
				},
			false:
				{
					url: `${postPlaceLink}${user?.group?.id}/createspot/`,
					spotGroup: user?.group?.id
				}
		};
		const item = actionObjects[`${user.role === 'staff'}`];
		const newUrl = item.url;

		const combinedData = { ...inputData, spot_group: item.spotGroup};

		apiPost(newUrl, combinedData, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			const seconds = 3000;
			setTimeout(() => {
				setUpdateData('isUser');
			}, seconds);
		});

		postUserAction('Добавление места', inputData.name);

		handleClose();
	};

	const postCameras = () => {
		const combinedData = {
			name: inputData.name,
			spot: selectedPlacesId,
			id_psw: inputData.idPsw,
			lat: inputData.lat,
			lng: inputData.lng,
			cameraUser: selectedTechUserId,
			cameraUserEmail: selectedTechUser?.email
		};
		const newUrl = `${getDevicesLink}${selectedPlacesId}/createcamera/`;
		apiPost(newUrl, combinedData, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			const seconds = 3000;
			setTimeout(() => {
				getUserDevice();
			}, seconds);
		});

		postUserAction('Добавление камеры', inputData.name);
		handleClose();
	};

	const updatePlaces = () => {
		const newUrl = `${url}${spotInfo.id}/updatespot/`;
		const spotGroup = {
			true: selectedGroup.id,
			false: spotInfo.spot_group
		};

		const combinedData = {
			name: inputData.name,
			spot_group: spotGroup[`${user.role === 'staff'}`],
			lat: inputData.lat,
			lng: inputData.lng,
		};


		apiPatch(newUrl, combinedData, {}).then(({ error }) => {
			const { isError } = error;
			if (isError) return;

			const seconds = 3000;
			setTimeout(() => {
				getUserDevice();
			}, seconds);
		});
		postUserAction('Изменение места', inputData.name);

		handleClose();
	};

	const updateCameras = () => {
		const { idPsw: id_psw } = inputData;

		const newUrl = `${url}updatecamera/`;

		const combinedData = {
			...inputData,
			id_psw,
			spot: selectedPlacesId,
			cameraUser: selectedTechUserId,
			cameraUserEmail: selectedTechUser?.email
		};

		apiPatch(newUrl, combinedData, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			const seconds = 3000;
			setTimeout(() => {
				getUserDevice();
			}, seconds);
		});
		postUserAction('Изменение камеры', inputData.name);

		handleClose();
	};

	const addAction = {
		location: postPlaces,
		camera: postCameras,
	};

	const changeAction = {
		location: updatePlaces,
		camera: updateCameras,
	};

	const actionModal = {
		create: addAction[typeForButton],
		change: changeAction[typeForButton],
	};

	const handleChangePlace = (event: SyntheticEvent<Element, Event>, newValue: AccountPlace | null) => {
		const idPlace: string | null = newValue?.id ?? null;
		setSelectedPlaces(newValue);
		setSelectedPlacesId(idPlace);
	};
	const handleChangeTechUser = (event: SyntheticEvent<Element, Event>, newValue: TechUser | null) => {
		const idTechUser: number | null = newValue?.id ?? null;
		setSelectedTechUser(newValue);
		setSelectedTechUserId(idTechUser);
	};

	const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
	const [isDisableDeleteButton, setDisableDeleteButton] = useState(true);
	const [deleteUrl, setDeleteUrl] = useState(url);

	const handleOpenDeleteModal = () => {
		const newUrl = `${url}deletecamera/`;
		setDeleteUrl(newUrl);
		setOpenDeleteModal(true);
	};

	const handleCloseDeviceModal = () => {
		getUserDevice();
		handleClose();
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<div className={styles.modal}>
				<div className={styles.titleBox}>
					{(typeModal === 'change' && isCamera) &&
						<div className={styles.titleIcon} onClick={handleOpenDeleteModal}>
							<DeleteIcon/>
						</div>
					}

					{firstTitle[typeModal]} {secondTitle[title]}
				</div>

				{fields[title].map(({ title, name, type }) => {
					const key = `Modal-Add-${title}-${name}`;

					return (
						<div key={key} className={styles.box}>
							<div className={styles.container}>
								<p className={styles.title}>{title}</p>
								<div className={styles.inputBox}>
									<input
										className={styles.styleInput}
										id={name}
										value={inputData[name]}
										onChange={handleChangeInput(name)}
										type={type}
									/>
								</div>
							</div>
						</div>
					);
				})}
				{isCamera && (
					<div className={styles.box}>
						<div className={styles.container}>
							<p className={styles.title}>Локация</p>
							<Autocomplete
								disablePortal
								id='combo-box-demo'
								options={accountPlaces}
								getOptionLabel={(option) => option.name}
								value={selectedPlaces}
								renderInput={(params) => <CustomTextField {...params} variant='outlined'/>}
								onChange={handleChangePlace}
							/>
						</div>
					</div>
				)}
				{isCamera && (
					<div className={styles.box}>
						<div className={styles.container}>
							<p className={styles.title}>Пользователь</p>
							<Autocomplete
								disablePortal
								id='combo-box-demo'
								options={techUsers}
								getOptionLabel={(option) => option.email}
								value={selectedTechUser}
								renderInput={(params) => <CustomTextField {...params} variant='outlined'/>}
								onChange={handleChangeTechUser}
							/>
						</div>
					</div>
				)}
				{(user.role === 'staff' && !isCamera) &&
					(
						<div className={styles.box}>
							<div className={styles.container}>
								<p className={styles.title}>Группа</p>
								<Autocomplete
									disablePortal
									options={allGroups}
									getOptionLabel={(option) => option.name}
									value={selectedGroup}
									fullWidth
									onChange={(event, newValue) => {
										setSelectedGroup(newValue ?? {id: '', name: ''});
									}}
									renderInput={(params) => (
										<CustomTextField
											{...params}
											variant='outlined'
										/>
									)}
									isOptionEqualToValue={(option, value) => option.id === value.id}
								/>
							</div>

						</div>
					)
				}
				{(placeItem?.isCanEdit || typeModal === 'create') &&
					(
						<div className={styles.btnContainer}>
							<ConfirmButtons
								isDisabled={!isDisableButton}
								handleOk={actionModal[typeByAction]}
								handleCancel={handleClose}
							/>
						</div>
					)
				}
			</div>
			<Modal
				className={styles.modalDelete}
				open={isOpenDeleteModal}
				onClose={handleClose}
			>
				<div>
					<Confirm
						name={placeItem?.name}
						url={deleteUrl}
						id={id as string | number}
						handleClose={isCamera ? handleCloseDeviceModal : handleClose}
						isDisableButton={isDisableDeleteButton}
						setDisableButton={setDisableDeleteButton}
						updateTitle={isCamera ? 'isDevices' : 'isUser'}
					/>
				</div>
			</Modal>
		</ThemeProvider>
	);
};

export default ContentModal;
