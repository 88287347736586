import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const FullWinIcon: React.FC<IconSvgProps> = ({ width = 19, height = 19, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Развернуть</title>
			<path
				d='M10 1H14.5M14.5 1V5.5M14.5 1L9.25 6.25M5.5 14.5H1M1 14.5V10M1 14.5L6.25 9.25'
				stroke={fill}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default FullWinIcon;
