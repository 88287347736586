import React, {useEffect, useMemo, useState} from 'react';
import styles from './TableEvents.module.scss';
import { observer } from 'mobx-react-lite';
import { ruRU, DataGrid, getGridDateOperators, getGridStringOperators, GridCellParams, GridFilterModel, GridSortModel, GridFilterOperator } from '@mui/x-data-grid';
import {styled, createTheme, ThemeProvider} from '@mui/material';
import { PaginationProps, TableEventsProps } from './TableEvents.interface';
import useWindowDimensions from '@helpers/functions/useWindowDimensions';
import { filterOperator } from '@helpers/defaultsData/defaultsData';
import eventState from '@store/eventState/eventState';
import devicesState from '@store/devicesState/devicesState';
import notificationsState from '@store/notificationsState/notificationsState';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const ModDataGrid = styled(DataGrid)({
	width: '100%',
	overflow: 'auto',
	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},
	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},
	'& .MuiDataGrid-cell': {
		fontSize: '16px',
	},
	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},
	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},
	'& ::-webkit-scrollbar': {
		width: '6px',
		borderLeft: '1px solid #D2D2D2',
	},
	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	},
	'& .MuiSelect-icon': {
		color: 'inherit',
	},
});

export const columns = [
	{ field: 'id', headerName: 'ID', flex: 1.5 },
	{ field: 'messageType', headerName: 'Тип сообщения', flex: 1.5 },
	{ field: 'presetName', headerName: 'Позиция Камеры', flex: 1.5 },
	{ field: 'zoneName', headerName: 'Зона', flex: 1.5 },
	{ field: 'date', headerName: 'Дата', flex: 1 },
	{ field: 'time', headerName: 'Время', flex: 1 },
];

const sortedFields: { [key: string]: string } = {
	id: 'id',
	messageType: 'event_type',
	presetName: 'preset_name',
	zoneName: 'zone_name',
	date: 'date',
	time: 'time',
};

const filteredFields: { [key: string]: string } = {
	id: 'id',
	messageType: 'event_type',
	presetName: 'preset__name',
	zoneName: 'zone__name',
	date: 'date',
	time: 'time',
};

const signSort = {
	desc: '-',
	asc: '',
};

const TableEvents: React.FC<TableEventsProps> = observer(
	({ rowCount, prevLink, nextLink, currentLink, isLoad, eventId, pageSize, setLoad, updateEvents, handlePageSize }) => {
		const { events, setZoneSelectEvent, setSelectPresetId, setSelectedEventId, setIsGetNewEvents } = eventState;
		const { eventID, setEventID } = notificationsState;
		const { selectDeviceId } = devicesState;

		const isResetFilter = !prevLink && !nextLink && !events.length;

		const [pageCounter, setPageCounter] = useState(0);
		const [isSort, setSort] = useState(false);
		const [sortedMod, setSortedMod] = useState('');
		const [isFilter, setFilter] = useState(false);
		const [filteredMod, setFilteredMod] = useState('');
		const [filterModel, setFilterModel] = useState<GridFilterModel>();
		const [heightTable, setHeightTable] = useState(452);

		const { height } = useWindowDimensions();

		useEffect(() => {
			setPageCounter(0);
		}, [selectDeviceId]);

		useEffect(() => {
			setHeightTable((height - 176) * 0.57);
		}, [height]);

		useEffect(() => {
			const urlParts = currentLink.split('?');
			let updatedLink = currentLink;

			if (urlParts.length > 1) {
				const queryParams = new URLSearchParams(urlParts[1]);
				queryParams.set('ordering', sortedMod);
				updatedLink = `${urlParts[0]}?${queryParams.toString()}`;
			} else {
				updatedLink = `${currentLink}?ordering=${sortedMod}`;
			}

			if (!isSort) {
				const searchParams = new URLSearchParams(urlParts[1]);
				searchParams.delete('ordering');

				updatedLink = `${urlParts[0]}?${searchParams.toString()}`;
			}

			updateEvents(updatedLink);
		}, [sortedMod, isSort]);

		useEffect(() => {
			const urlParts = currentLink.split('?');
			let updatedLink = currentLink;

			if (urlParts.length > 1) {
				const searchParams = new URLSearchParams(urlParts[1]);
				const ordering = searchParams.get('ordering');
				const pageSize = searchParams.get('page_size');
				const pageNumber = searchParams.get('page');

				const newQueryParams = new URLSearchParams();

				newQueryParams.set('ordering', ordering ? ordering : '');
				newQueryParams.set('page_size', pageSize ? pageSize : '');
				newQueryParams.set('page', pageNumber ? pageNumber : '1');

				if (eventID !== '') {
					newQueryParams.set('id', eventID);
					setFilterModel({ items: [{ field: 'id', operator: 'equals', value: eventID }] });
				} else {
					setFilterModel({ items: [{ field: 'id', operator: 'equals', value: '' }] });
				}

				updatedLink = `${urlParts[0]}?${newQueryParams.toString()}&${filteredMod}`;
			} else {
				updatedLink = `${currentLink}?${filteredMod}`;
			}

			updateEvents(updatedLink);
		}, [filteredMod, isFilter, eventID]);

		const handleClick = (eventTable: GridCellParams) => {
			setSelectedEventId(eventTable.id as string);
			setZoneSelectEvent(eventTable.row.zone);
			setSelectPresetId(eventTable.row.idPreset);
		};

		const handleChangePage = (pageInfo: PaginationProps) => {
			setIsGetNewEvents(true);
			handlePageSize(pageInfo.pageSize);
			
			let linkMove = pageCounter < pageInfo.page ? nextLink : prevLink;

			if (!linkMove) {
				linkMove = currentLink;
			}

			const urlParts = linkMove.split('?');
			let updatelink = linkMove;

			if (urlParts.length > 1) {
				const queryParams = new URLSearchParams(urlParts[1]);
				queryParams.set('page_size', pageInfo.pageSize.toString());

				updatelink = `${urlParts[0]}?${queryParams.toString()}`;
			} else {
				updatelink = `${linkMove}?page_size=${pageInfo.pageSize}`;
			}

			updateEvents(updatelink);

			setLoad(true);
			setPageCounter(pageInfo.page);
		};

		const handleSortedModelChange = (model: GridSortModel) => {
			setSort(false);
			if (!model.length) return;
			const { field, sort } = model[0];

			setLoad(true);
			setSort(true);
			setSortedMod(`${signSort[sort ?? 'desc']}${sortedFields[field]}`);
		};

		const handleFilterModelChange = (model: GridFilterModel) => {
			setFilter(false);

			if (!model.items.length) return;
			setLoad(true);

			const { field, operator, value } = model.items[0];
			if (value === undefined || value === '') {
				setFilteredMod('');
				setEventID('');
				setFilterModel(undefined);
				return;
			}

			setFilter(true);

			if (field === 'time') {
				const dateTime = value.split('T');

				setFilteredMod(`date${filterOperator[operator]}=${dateTime[0]}&time${filterOperator[operator]}=${dateTime[1]}:00`);
				return;
			}

			setFilteredMod(`${filteredFields[field]}${filterOperator[operator]}=${value}`);
		};

		const columnsWithFilter = useMemo(() => {
			const defValues = ['is', 'onOrAfter', 'onOrBefore', 'before', 'after'];
			const defOpefators = ({ value }: { value: string }) => defValues.includes(value);

			const commonFilterOperators = getGridStringOperators().filter(
				(operator) =>
					operator.value === 'equals' || operator.value === 'contains'
			);

			return columns.map((col) => {
				let filterOperators: GridFilterOperator[] = [...commonFilterOperators];

				switch (col.field) {
				case 'id':
				case 'messageType':
					filterOperators = getGridStringOperators().filter(
						(operator) => operator.value === 'equals'
					);
					break;

				case 'time':
					filterOperators = getGridDateOperators(true).filter(defOpefators);
					break;

				case 'date':
					filterOperators = getGridDateOperators().filter(defOpefators);
					break;

				default:
					break;
				}

				return {
					...col,
					filterOperators,
				};
			});
		}, [columns]);

		const CustomFooterButton = () => {
			const handleButtonClick = () => {
				setFilteredMod('');
				setEventID('');
				setFilterModel(undefined);
			};

			return (
				<div className={styles.footerResetFilter}>
					<button className={styles.resetFilterButton} onClick={handleButtonClick}>
						Сбросить фильтр
					</button>
				</div>
			);
		};

		return (
			<ThemeProvider theme={darkTheme}>
				<div style={{ height: heightTable, width: '100%' }}>
					<ModDataGrid
						rows={events}
						columns={columnsWithFilter}
						loading={isLoad}
						paginationMode={'server'}
						filterMode={'server'}
						sortingMode={'server'}
						rowCount={rowCount}
						density={'compact'}
						onCellClick={handleClick}
						rowSelectionModel={eventId ?? '0'}
						localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
						initialState={{
							pagination: { paginationModel: { pageSize: 10 }},
						}}
						slots={isResetFilter ? { footer: CustomFooterButton } : {}}
						paginationModel={{ page: pageCounter, pageSize: pageSize }}
						filterModel={filterModel}
						pageSizeOptions={[10, 25, 50]}
						rowSelection={false}
						onSortModelChange={handleSortedModelChange}
						onPaginationModelChange={handleChangePage}
						onFilterModelChange={handleFilterModelChange}
					/>
				</div>
			</ThemeProvider>
		);
	}
);

export default TableEvents;
