import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './RoleInfo.module.scss';
import {RoleInfoProps as Props, RoleInfoRuNameRolesKeys} from './RoleInfo.interface';
import { UserAccess } from '../../../../../api/AccountPage/prepareAccountData.interface';

const RoleInfo: React.FC<Props> = observer(({selectedItem}) => {
	const listitem = (data: UserAccess) => {
		const {  id,  spot, spotName, role, dateJoined } = data;
		const key = `User-Access-Card-${spot}-${spotName}-${id}`;

		const ruRole: RoleInfoRuNameRolesKeys = {
			staff: 'суперпользователь',
			spotadmin: 'администратор спота',
			spotoperator: 'оператор',
		};

		return (
			<div key={key} className={styles.tableText}>
				<div className={styles.row}>
					{spotName}
				</div>
				<div className={styles.row}>
					{ruRole[role]}
				</div>
				<div className={styles.row}>
					{dateJoined}
				</div>
			</div>
		);
	};

	return (
		<div className={styles.listStyle}>
			{selectedItem.length === 0
				? <p>Нет подключенных мест</p>
				: selectedItem.map(listitem)
			}
		</div>
	);
});

export default RoleInfo;
