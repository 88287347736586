import { observer } from 'mobx-react-lite';
import MainButton from '@helpers/components/MainButton/MainButton';
import ControllerIcon from '@assets/Icons/Translation/ControllerIcon';
import translationState from '@store/translationState/translationState';
import canvasState from '@store/canvasState/canvasState';

const Controller = observer(() => {
	const { isVisibleController, isMouseControl, setVisibleController } = translationState;
	const { isShowCanvas } = canvasState;

	const disableIcon = isMouseControl || isShowCanvas;
	const fill = isVisibleController ? '#00c2fd' : '#D2D2D2';

	return (
		<MainButton onClick={setVisibleController} disabled={disableIcon}>
			<ControllerIcon fill={fill} disabled={disableIcon} />
		</MainButton>
	);
});

export default Controller;
