import styles from './Chart.module.scss';
import { ChartProps as Props } from './Chart.interface';
import React, {useEffect, useState} from 'react';
import CheckEmpty from '../../../helpers/components/CheckEmpty/CheckEmpty';
import ChartLineBar from '../../Dashboard/Chart/ChartTypes/ChartLineBar/ChartLineBar';
import {GraficInfo} from '../../Control/InfographicsEvent/InfographicsEvent.interface';

const Chart: React.FC<Props> = ({ dataInfo, type, numberChart }) => {
	const [chartsData, setChartsData] = useState<GraficInfo[]>([]);

	useEffect(() => {
		if (!dataInfo[0]) return;

		const dataChart = numberChart === 0 ? dataInfo[0].chart.chartCorona : dataInfo[0].chart.chartNoise;

		const chartsEvents: GraficInfo[] = Object.values(dataChart).map(event => {
			const { dateTime, count, state } = event;

			const newDate = new Date(dateTime);

			if (type === 0) {
				return { date: newDate.toLocaleDateString(), id: '', time: newDate.toLocaleTimeString(), [`${state}`]: count };
			} else {
				return { date: '', id: '', time: newDate.toLocaleDateString(), [`${state}`]: count };
			}
		});

		setChartsData(chartsEvents);
	}, [dataInfo]);

	return (
		<div className={styles.container}>
			<CheckEmpty isCheck={!!chartsData.length}>
				<ChartLineBar dataChart={chartsData} firstType={true} />
			</CheckEmpty>
		</div>
	);
};

export default Chart;
