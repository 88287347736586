import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const BackIcon: React.FC<IconSvgProps> = ({ width = 14, height = 21, fill = '#D2D2D2', title = 'Назад' }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<title>{title}</title>
			<path
				d="M8.88768 0.36853L0.756226 8.49999L8.88768 16.6314L10.2437 15.2764L3.46639 8.49999L10.2437 1.72361L8.88768 0.36853Z"
				fill={fill}
			/>
		</svg>
	);
};

export default BackIcon;
