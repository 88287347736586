import { IconSvgProps as Props } from '../../../helpers/Interfaces/IconSvg.interface';

const CircleIcon: React.FC<Props> = ({ width = 20, height = 20, fill = '#D2D2D2' }) => {
	return (
		<svg fill={fill} width={width} height={height} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='16' cy='16' r='16' />
		</svg>
	);
};

export default CircleIcon;
