import { makeAutoObservable } from 'mobx';
import {
	AllPagesGetData as ReadyPages,
	MapPageReadyData as MapIsReady,
	MapPageDevicesIsReadyData as MapDevicesIsReady,
	ControlsPageIsReadyData as ControlsIsReady,
	TranslationPageIsReadyData as TranslationIsReady,
	AccountPageIsReadyData as AccountIsReady,
	DashboardPageIsReadyData as DashboardIsReady,
	AnaliticaPageIsReadyData as AnaliticaIsReady
} from './loading.interface';

class LoadingState {
	readyPages: ReadyPages = {
		map: false,
		mapDevices: true,
		controls: false,
		translation: false,
		account: false,
		dashboard: false,
		analitica: false
	};

	mapReady: MapIsReady = { 
		isPlaces: false, 
		isDevices: true,
	};

	mapDevicesReady: MapDevicesIsReady = {
		isDevices: true,
	};

	controlsReady: ControlsIsReady = { 
		isPlaces: false, 
		isDevices: true
	};

	translationReady: TranslationIsReady = {
		isPlaces: false,
		isDevices: true
	};

	accountReady: AccountIsReady = {
		isUser: false,
	};

	dashboardReady: DashboardIsReady = {
		isPlaces: false,
		isDevices: true
	};

	analiticaReady: AnaliticaIsReady = {
		isPlaces: false,
		isDevices: true
	};

	constructor() {
		makeAutoObservable(this);
	}

	setReadyPages = (key: keyof ReadyPages, isValue = false) => {
		this.readyPages[key] = isValue;
	};

	setMapReady = (data: MapIsReady) => {
		this.mapReady = data;
	};

	setMapDevicesReady = (data: MapDevicesIsReady) => {
		this.mapDevicesReady = data;
	};

	setControlsReady = (data: ControlsIsReady) => {
		this.controlsReady = data;
	};

	setTranslationReady = (data: TranslationIsReady) => {
		this.translationReady = data;
	};

	setAccountReady = (data: AccountIsReady) => {
		this.accountReady = data;
	};

	setDashboardReady = (data: DashboardIsReady) => {
		this.dashboardReady = data;
	};
	setAnaliticaReady = (data: AnaliticaIsReady) => {
		this.analiticaReady = data;
	};
}

const loadingState = new LoadingState();
export default loadingState;
