import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Polygon, { ZONE_TYPE_DEFAULT } from '../../components/Translation/Canvas/Polygon';

class CanvasState {
	canvas = null;
	socket = null;
	sessionId = null;

	userName = 'alex';

	allPolygons = null;
	tempPolygons = [];

	size = {
		width: 1920,
		height: 1080,
	};

	pointCoefficient = 1;

	/* Размеры элемента Canvas */
	canvasReSize = {
		coefficient: {
			width: 1,
			height: 1,
		},

		oldSize: {
			width: 1,
			height: 1,
		},

		newSize: {
			width: 1,
			height: 1,
		},
	};

	readyRectCounter = null;

	isCreatePolygon = false;
	isEditPolygon = false;
	isDeletePolygon = false;

	isPolygonSelected = false;
	isPolygonChanged = false;
	isPointChanged = false;
	currentPolygonNum = -1;

	polygonItem = null;
	saveDataTest = {};
	rawData = {};

	isShowCanvas = false;
	isOneReadyPolygon = false;

	constructor() {
		makeAutoObservable(this, {}, { autoBind: true });

		makePersistable(this, {
			name: 'CanvasStore',
			properties: [
				'size',
				'pointCoefficient',
				'canvasReSize',
				'readyRectCounter',
				'rawData',
			],
			storage: window.localStorage,
		});

		setTimeout(this.checkDataAvailability);
	}
	setCanvasVisible = (isVisible) => {
		this.isShowCanvas = isVisible;
	};

	changeCanvasVisible = () => {
		this.isShowCanvas = !this.isShowCanvas;
	};

	setCanvasReSize = (width, height) => {
		const { oldSize, newSize, coefficient } = this.canvasReSize;

		oldSize.width = newSize.width;
		oldSize.height = newSize.height;

		newSize.width = width;
		newSize.height = height;

		coefficient.width = newSize.width / oldSize.width;
		coefficient.height = newSize.height / oldSize.height;

		for (const camId in this.saveDataTest) {
			for (const presId in this.saveDataTest[camId]) {
				this.saveDataTest[camId][presId].forEach((polygon) => {
					const points = polygon.getPoints();
					const { width, height } = coefficient;

					const newPoints = points.map((point) => ({
						...point,
						x: point.x * width,
						y: point.y * height,
					}));

					polygon.setPoints(newPoints);
				});
			}
		}

		this.dataSynchronization();
	};

	setAllPolygons = (camId, presId, polygons) => {
		if (!this.rawData[camId]) this.rawData[camId] = {};
		if (!this.saveDataTest[camId]) this.saveDataTest[camId] = {};

		this.rawData[camId][presId] = [];
		this.saveDataTest[camId][presId] = [];

		this.saveDataTest[camId][presId] = polygons;
		this.dataSynchronization();

		const FW = 1920;
		const FH = 1080;

		const NW = this.canvas.width;
		const NH = this.canvas.height;

		// this.pointCoefficient

		polygons.map((item) => {
			const points = item.getPoints();

			points.map((point) => {
				const newPoint = { x: (point.x / NW) * FW, y: (point.y / NH) * FH };
				const isStop = false;

				if (isStop) console.log(newPoint);
			});
		});
	};

	setSessionId(id) {
		this.sessionId = id;
	}

	setSocket(socket) {
		this.socket = socket;
	}

	setCanvas(canvas) {
		this.canvas = canvas;
	}

	incReadyRectCounter() {
		return this.readyRectCounter++;
	}
	setReadyAreasCounter(camId, presId) {
		this.readyRectCounter = this.saveDataTest[camId][presId].length - 1;
	}

	setPolygonInCamera = (camId, presId) => {
		const isStop =
			Number.isInteger(this.saveDataTest[camId][presId]?.length) ||
			Number.isInteger(this.rawData[camId][presId]?.length);
		if (isStop) return;

		this.saveDataTest[camId][presId] = [];
		this.rawData[camId][presId] = [];
	};

	addPolygon(camId, presId, polygon) {
		if (!this.rawData[camId]) this.rawData[camId] = {};
		if (!this.rawData[camId][presId]) this.rawData[camId][presId] = [];

		if (!this.saveDataTest[camId]) this.saveDataTest[camId] = {};
		if (!this.saveDataTest[camId][presId]) this.saveDataTest[camId][presId] = [];

		polygon.setAttributeType(ZONE_TYPE_DEFAULT);
		this.saveDataTest[camId][presId].push(polygon);
		this.rawData[camId][presId].push(this.polygonRawData(polygon));
	}

	changePolygon(camId, presId, index, polygon) {
		this.saveDataTest[camId][presId].splice(index, 1, polygon);
		this.rawData[camId][presId][index] = this.polygonRawData(polygon);
	}

	clearCurrentCameraData = (camId, presId) => {
		if (!this.saveDataTest[camId]) this.saveDataTest[camId] = {};
		this.saveDataTest[camId][presId] = [];
	};

	setRawData = (camId, presId, rawData) => {
		if (!this.rawData[camId]) this.rawData[camId] = {};
		this.rawData[camId][presId] = [];
		
		this.rawData[camId][presId] = rawData;

		this.isPolygonChanged = !this.isPolygonChanged;
		this.clearCurrentCameraData(camId, presId);
		this.checkDataAvailability();
	};
	setRawPolygonPoints = (camId, presId, index, points) => {
		this.rawData[camId][presId][index].points = points;
	};
	changePolygonName = (camId, presId, index, name) => {
		this.saveDataTest[camId][presId][index].setName(name);
		this.rawData[camId][presId][index].name = name;
	};
	changePolygonAttributeType = (camId, presId, index, type) => {
		this.saveDataTest[camId][presId][index].setAttributeType(type);
	};
	changePolygonAttributeColor = (camId, presId, index, rgba) => {
		const { r, g, b } = rgba;
		this.saveDataTest[camId][presId][index].setAttributeFillColor(r, g, b);
	};

	setPolygonSelect = (isSelect) => {
		this.isPolygonSelected = isSelect;
	};

	setCurrentPolygonNum = (number) => {
		this.currentPolygonNum = number;
	};

	setPointCoefficient = (coefficient) => {
		this.pointCoefficient = coefficient;
	};
	setPolygonChanged = () => {
		this.isPolygonChanged = !this.isPolygonChanged;
	};
	setPointChanged = (isChange = false) => {
		this.isPointChanged = isChange;
	};
	setIsCreatePolygon = (isCreate = false) => {
		this.isCreatePolygon = isCreate;
	};
	setIsEditPolygon = (isEdit = false) => {
		this.isEditPolygon = isEdit;
	};
	setIsDeletePolygon = () => {
		this.isDeletePolygon = !this.isDeletePolygon;
	};

	setTempPolygons = (tempPolygons) => {
		this.tempPolygons = tempPolygons;
	};

	polygonRawData = (polygon) => ({
		id: polygon.id,
		name: polygon.getName(),
		startSize: polygon.getStartSize(),
		points: polygon.getPoints(),
		attributes: polygon.getAttribute(),
	});

	checkDataAvailability = () => {
		for (const camId in this.rawData) {
			for (const presId in this.rawData[camId]) {
				if (!this.saveDataTest[camId]) this.saveDataTest[camId] = {};
				if (!this.saveDataTest[camId][presId]) this.saveDataTest[camId][presId] = [];

				if (this.rawData[camId][presId].length && !this.saveDataTest[camId][presId]?.length) {
					this.rawData[camId][presId].forEach((area) => this.restoreAreas(camId, presId, area));
				}
			}
		}
	};

	restoreAreas = (camId, presId, area) => {
		const { x, y, w, h } = area.startSize;
		const polygon = new Polygon(area.id, x, y, w, h);
		polygon.setName(area.name);
		polygon.setPoints(area.points);
		polygon.setMin(area.temp_min);
		polygon.setMax(area.temp_max);
		polygon.setAttribute(area.attributes);

		this.saveDataTest[camId][presId].push(polygon);
	};
	dataSynchronization = () => {
		for (const camId in this.saveDataTest) {
			for (const presId in this.saveDataTest[camId]) {
				this.rawData[camId][presId] = [];
				this.saveDataTest[camId][presId].forEach((area, index) => {
					this.rawData[camId][presId][index] = this.polygonRawData(area);
				});
			}
		}
	};

	deletePolygon(camId, presId, index) {
		this.saveDataTest[camId][presId].splice(index, 1);
		this.dataSynchronization();
	}

	setIsOneReadyPolygon = (isReady = false) => {
		this.isOneReadyPolygon = isReady;
	};
}

export default new CanvasState();