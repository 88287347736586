import React from 'react';
import styles from './SensorsButtons.module.scss';
import SensorItem from '../SensorItem/SensorItem';
import { SensorsButtonsProps } from './SensorsButtons.interface';


const SensorsButtons: React.FC<SensorsButtonsProps> = ({ item, event }) => {
	const { data, icon, isDiscrete, type, valueType } = item;

	return (
		<div className={styles.content}>
			{data.map((item) => (
				<SensorItem
					key={`Sensor-Item-Scroll-${item.id}-${item.spot}`}
					item={item}
					icon={icon}
					isDiscrete={isDiscrete}
					event={event}
					type={type}
					valueType={valueType}
				/>
			))}
		</div>
	);
};

export default SensorsButtons;

