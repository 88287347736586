import React from 'react';
import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ClosedEyeIcon: React.FC<IconSvgProps> = ({ width = 20, height = 20, fill = '#D2D2D2' }) => {
	return(
		<svg width={width} height={height} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Скрыть</title>
			<path d='M36.6667 16.5C36.6667 16.5 36.0795 17.7235 34.8334 19.2765M22.0001 25.6667C19.0521 25.6667 16.5878 24.9109 14.5725 23.8333M22.0001 25.6667C24.9481 25.6667 27.4123 24.9109 29.4276 23.8333M22.0001 25.6667V32.0833M7.33341 16.5C7.33341 16.5 7.98182 17.8508 9.36159 19.5154M14.5725 23.8333L9.16675 29.3333M14.5725 23.8333C12.2636 22.5988 10.544 20.9419 9.36159 19.5154M29.4276 23.8333L33.9167 29.3333M29.4276 23.8333C31.8667 22.5292 33.6481 20.7538 34.8334 19.2765M9.36159 19.5154L3.66675 22M34.8334 19.2765L40.3334 22' stroke={fill} strokeLinecap='round'/>
		</svg>
	);
};

export default ClosedEyeIcon;