import React, { ChangeEvent, useEffect, useState } from 'react';
import {
	PersonInfoInputData as InputData,
	PersonInfoProps,
	PersonInfoPostData as PostData,
	PersonInfoField as Field,
} from './PersonInfo.interface';
import styles from './PersonInfo.module.scss';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import autorizeState from '@store/accoutState/autorizeState';
import RoleInfo from '../RoleInfo/RoleInfo';
import {apiGet, apiPostUser, apiPost, apiPatch} from '@api/api';
import connectState from '@store/connectState/connectState';
import { prepareAccessPlaces, prepareUserAccess } from '@api/AccountPage/prepareUserData';
import { PrepareUserAccess, UserAccess } from '@api/AccountPage/prepareAccountData.interface';
import AddIcon from '@assets/Icons/Account/AddIcon';
import ChangeRole from '../RoleInfo/ChangeRole/ChangeRole';
import {Group, UserRolesGroup, UserRolesGroupCreate} from '@store/accoutState/interfaces';
import SettingIcon from '@assets/Icons/Translation/SettingIcon';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import Confirm from '../../../CustomCard/ItemCard/Confirm/Confirm';
import Modal from '@mui/material/Modal';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import { observer } from 'mobx-react-lite';
import {getUsers, postUserAction} from '@pages/AccountPage/AccountPage';
import ResetPassword from '../../../PersonCard/PersonCardHeader/ResetPassword/ResetPassword';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const CustomTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#858585',
			borderRadius: 10,
			height: '47px',

		},
		'&:hover fieldset': {
			borderColor: '#00C2FD',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#00C2FD',
		},
		color: '#cfcfcf',
		fontSize: '20px',
		padding: '2px 0 0 10px',
	},
});

export const PersonInfo: React.FC<PersonInfoProps> = observer(({ handleClose, selectedItem, type, openRoleAccess, setOpenRoleAccess, isProfile = false}) => {
	const { postData, user, allGroups, updateData, setUpdateData, setAccessPlaces, accountPlaces, setLoadingTables } = autorizeState;
	const { postUserLink, allUserAccess, changeUserLink, deleteUser, postUserAccess, userLink } = connectState;

	const isAccess = user.grouprole !== 'groupuser';

	const manageUpdate = {
		item: {
			firstName: selectedItem.firstName,
			lastName: selectedItem.lastName,
			email: selectedItem.email,
			phone: selectedItem.phone,
		}
	};

	const manageCreate = {
		item: {
			firstName: postData.first_name,
			lastName: postData.last_name,
			email: postData.email,
			phone: postData.phone,
			password: postData.password,
		}
	};

	const userCreateFields: Field[] = [
		{ title: 'Имя', name: 'firstName' },
		{ title: 'Фамилия', name: 'lastName' },
		{ title: 'Пароль', name: 'password' },
		{ title: 'Почта', name: 'email' },
		{ title: 'Телефон', name: 'phone' },
	];

	const userChangeFields: Field[] = [
		{ title: 'Имя', name: 'firstName' },
		{ title: 'Фамилия', name: 'lastName' },
		{ title: 'Почта', name: 'email' },
		{ title: 'Телефон', name: 'phone' },
	];

	const dataByAction = {
		create: {
			selectedItems: manageCreate,
			inputDataItem: userCreateFields
		},
		change: {
			selectedItems: manageUpdate,
			inputDataItem: userChangeFields
		}
	};

	const selectedItems = dataByAction[type].selectedItems;
	const { item } = selectedItems;

	const role: keyof UserRolesGroupCreate = user.grouprole;

	const roles: UserRolesGroupCreate = {
		groupadmin: 'администратор группы',
		groupuser: 'оператор',
		camerauser: 'техническая',
	};

	const roleIndex = Object
		.keys(roles)
		.findIndex(tRole => tRole === role);

	const isCorrectRole = roleIndex !== -1;
	const isSplice = role === 'groupuser' ? 1 : 0;

	const ruRolesAccess = Object
		.values(roles)
		.splice(roleIndex + isSplice);

	if (!isCorrectRole) {
		handleClose();
	}

	const ruGroup = type === 'create' ? ruRolesAccess[1] : roles[selectedItem.grouprole];
	const group = type === 'create' ? allGroups[0] : selectedItem.group;
	const fields = dataByAction[type].inputDataItem;

	const [inputData, setInputData] = useState<InputData>(item);
	const [userAccess, setUserAccess] = useState<UserAccess[]>([]);
	const [isDisableButton, setDisableButton] = useState(false);
	const [isDisableButtonDelete, setDisableButtonDelete] = useState(true);
	const [userID, setUserID] = useState<number>(selectedItem.id);
	const [groupRole, setGroupRole] = useState<string | null>(ruGroup);
	const [selectedGroup, setSelectedGroup] = useState<Group>(group);
	const [isChangeRole, setChangeRole] = useState(false);
	const [isSettings, setSettings] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [isOpenResetPassword, setOpenResetPassword] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | string>('');

	useEffect(() => {
		checkDisableButton();
	},[inputData,  groupRole]);


	useEffect(() => {
		if (!openRoleAccess) return;
		const controller = new AbortController();
		getRoles(userID, controller);
	},[openRoleAccess, updateData['isGetAccess'], isSettings, userID]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (!file) return;
		setSelectedFile(file);
	};

	const checkMatch = () => {
		const values = Object.values(inputData);
		return values.every((value) => value !== '');
	};

	const checkDisableButton = () => {
		const isAllFieldsFilled = checkMatch() && !!groupRole;
		setDisableButton(!isAllFieldsFilled);
	};

	const handleChangeInput = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputData((prevInput) => ({
			...prevInput,
			[name]: value,
		}));
		checkDisableButton();
	};


	const createNewUser = () => {
		const group = user.role === 'staff' ? selectedGroup.id : user.group.id;
		const enGroupRole = Object.keys(roles).find(key => roles[key as keyof UserRolesGroup] === groupRole);


		typeActions[type](group as string, enGroupRole as string);
	};

	const createUser = (group: string , groupRole: string ) => {
		const formData = new FormData();

		formData.append('grouprole', groupRole);
		formData.append('group', group);
		formData.append('email', inputData.email);
		formData.append('password', inputData.password as string);
		formData.append('phone', inputData.phone);
		formData.append('first_name', inputData.firstName);
		formData.append('last_name', inputData.lastName);
		formData.append('image', selectedFile as string | Blob);

		apiPostUser(postUserLink, formData, {}).then(({ data, error }) => {
			const { isError } = error;

			if (isError) return;
			const getIDUser = data as PostData;
			getRoles(getIDUser.id);
			getAccessPlaces(user.id);
			postUserAction('Создание пользователя', inputData.firstName);
			setLoadingTables('isGetAccess', true);
			getUsers();
			const grouprole = Object.keys(roles).find(key => roles[key as keyof UserRolesGroup] === groupRole);

			if (grouprole === 'groupadmin') addAdminAccess(getIDUser.id);
			handleClose();
		});
	};

	const changeUser = (group: string , groupRole: string ) => {
		const changeData = {
			id: userID,
			email: inputData.email,
			phone: inputData.phone,
			first_name: inputData.firstName,
			last_name:  inputData.lastName,
			group: group,
			grouprole: groupRole
		};
		const links = {
			true: userLink,
			false: `${changeUserLink}${userID}/`
		};

		const link = links[`${isProfile}`];

		apiPatch(link, changeData, {}).then(({ error }) => {
			const { isError } = error;
			if (isError) return;
			getRoles(userID);
			getAccessPlaces(user.id);

			postUserAction('Изменение пользователя', changeData.first_name);
			setUpdateData('isUser');
			getUsers();
			handleClose();
		});
	};

	const typeActions = {
		create: (group: string , groupRole: string)  => createUser(group, groupRole),
		change: (group: string , groupRole: string) => changeUser(group, groupRole),
	};

	const addAdminAccess = (id: number) => {
		const currentDate = new Date();
		if (!id) return;

		accountPlaces.map((place) => {
			const data = {
				spot: place.id,
				spot_name: place.name,
				user: id,
				role: 'spotadmin',
				date_joined: currentDate.toISOString()
			};

			apiPost(postUserAccess, data, {}).then(({ error }) => {
				const { isError } = error;
				if (isError) return;

				postUserAction('Добавление прав', user.firstName);
				setUpdateData('isGetAccess');
			});
		});
		handleClose();
	};

	const getRoles = async (id: number, controller = new AbortController()) => {
		const url = `${allUserAccess}${id}/`;
		return apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;
			const userAccess = prepareUserAccess(data as PrepareUserAccess[]);
			const checkUserAccess = userAccess.length > 0 ? userAccess : [];
			setUserAccess(checkUserAccess as UserAccess[]);

			setUserID(id);
			getAccessPlaces(user.id);
		});
	};
	const getAccessPlaces = async (id: number, controller = new AbortController()) => {
		const url = `${allUserAccess}${id}/`;

		return apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;

			if (isError) return;
			const accessPlaces = prepareAccessPlaces(data as PrepareUserAccess[]);
			setAccessPlaces(accessPlaces);
			setOpenRoleAccess(true);
		});
	};

	const createFields = ({ title, name }: Field) => {
		const key = `Modal-Add-${title}-${name}`;
		return (
			<div key={key} className={styles.box}>
				<div className={styles.container}>
					<div className={styles.title}>{title}</div>
					<div className={styles.inputBox}>
						<input
							className={styles.styleInput}
							id={name}
							value={inputData[name] || ''}
							onChange={handleChangeInput(name)}
						/>
					</div>
				</div>
			</div>
		);
	};

	const handleAddIconClick = () => {
		setChangeRole(!isChangeRole);
	};

	const handleSettings = () => {
		setSettings(!isSettings);
	};

	const changeUserAccess = (data: UserAccess) => {
		const key = `ChangeUserAccess-${data.user}-${data.spot}-${data.dateJoined}-${data.id}`;

		return (
			<ChangeRole
				type={'change'}
				handleClose={handleSettings}
				selectedID={userID}
				data={data}
				key={key}
				name={item.firstName}
			/>
		);
	};

	const userAccessInfo = () => {
		return (
			<RoleInfo
				selectedItem={userAccess}
				type={type}
			/>
		);
	};

	const userAccessComponent = () => {
		return (
			<ChangeRole
				type={'create'}
				handleClose={handleAddIconClick}
				selectedID={userID}
				data={userAccess[0]}
				name={item.firstName}
			/>
		);
	};

	const handleDelete = () => {
		setOpen(true);
	};

	const isRoleInfoComponets = {
		true: userAccessInfo(),
		false: userAccessComponent()
	};

	const settingsComponent = {
		true: userAccess?.map(changeUserAccess),
		false: isRoleInfoComponets[`${!isChangeRole}`]
	};

	const openResetPassword = () => {
		setOpenResetPassword(true);
	};

	const closeResetPassword = () => {
		setOpenResetPassword(false);
	};

	const closeUsersModal = () => {
		setTimeout(() => {
			getUsers();
		}, 3000);
		handleClose();
	};

	return (
		<ThemeProvider theme={darkTheme}>
			{openRoleAccess ?
				(
					<div className={styles.modal}>
						<div className={styles.header}>
							<div className={styles.headerContent}>
								<div className={styles.iconContainer}>
									{(isAccess && selectedItem.grouprole !== 'camerauser') &&
										(
											<div className={styles.icon} onClick={handleAddIconClick}>
												<AddIcon />
											</div>
										)
									}
									{(userAccess.length !== 0 && isAccess) &&
										(
											<div className={styles.icon}  onClick={handleSettings}>
												<SettingIcon width={22} height={22} title={'Изменить'}/>
											</div>
										)
									}
								</div>
							</div>
						</div>
						<div className={styles.box}>
							{settingsComponent[`${isSettings}`]}
						</div>
					</div>
				)
				:
				(
					<div className={styles.modal}>
						{(type === 'change' && isAccess) &&
							(
								<div className={styles.closeIcon}>
									<div onClick={openResetPassword} className={styles.icon} >
										<SettingIcon width={22} height={22} title={'Сменить пароль'}/>
									</div>
									<div onClick={handleDelete} className={styles.icon} >
										<CloseIcon title={'Удалить'}/>
									</div>
								</div>
							)
						}
						<Modal
							className={styles.modalDelete}
							open={isOpen}
							onClose={handleClose}
						>
							<div>
								<Confirm
									id={userID}
									name={selectedItem.firstName}
									url={`${deleteUser}${userID}/`}
									handleClose={isProfile ? handleClose : closeUsersModal}
									isDisableButton={isDisableButtonDelete}
									setDisableButton={setDisableButtonDelete}
									updateTitle={isProfile ? 'isUser' : 'isUsers'}
									title={'пользователя'}
									placeholder={'Введите имя'}
								/>
							</div>
						</Modal>
						{fields.map(createFields)}

						{user.role === 'staff' &&
							(
								<div className={styles.box}>
									<div className={styles.container}>
										<p className={styles.title}>Группа</p>
										<Autocomplete
											disablePortal
											options={allGroups}
											getOptionLabel={(option) => option.name}
											value={selectedGroup}
											fullWidth
											onChange={(event, newValue) => {
												setSelectedGroup(newValue ?? {id: '', name: ''});
											}}
											renderInput={(params) => (
												<CustomTextField
													{...params}
													fullWidth
													variant='outlined'
												/>
											)}
											isOptionEqualToValue={(option, value) => option.id === value.id}
										/>
									</div>

								</div>
							)
						}
						<div className={styles.box}>
							<div className={styles.container}>
								<p className={styles.title}>Доступ</p>
								<Autocomplete
									disablePortal
									options={ruRolesAccess}
									value={groupRole}
									fullWidth
									onChange={(event, newValue) => {
										setGroupRole(newValue);
									}}
									renderInput={(params) => (
										<CustomTextField
											{...params}
											fullWidth
											variant='outlined'
										/>
									)}
								/>
							</div>
						</div>
						{type === 'create' &&
							<div className={styles.box}>
								<div className={styles.container}>
									<div className={styles.title}>Фотография</div>
									<div className={styles.inputBox}>
										<input
											type="file"
											className={styles.styleInput}
											id="image"
											onChange={handleFileChange}
										/>
									</div>
								</div>
							</div>
						}
						<div className={styles.btnContainer}>
							<ConfirmButtons
								isDisabled={isDisableButton}
								handleOk={createNewUser}
								handleCancel={handleClose}
								nameOk={'Сохранить'}
							/>
						</div>
					</div>
				)
			}
			<Modal className={styles.modalResetPassword} open={isOpenResetPassword} onClose={closeResetPassword}>
				<div>
					<ResetPassword
						isDisableButton={isDisableButton}
						setDisableButton={setDisableButton}
						handleClose={closeResetPassword}
						url={`${changeUserLink}${userID}/`}
					/>
				</div>
			</Modal>
		</ThemeProvider>
	);
});