import {IconSvgProps} from '../../../helpers/Interfaces/IconSvg.interface';

const AgreeIcon: React.FC<IconSvgProps> = ({ width = 38, height = 38, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M3 12L9 18L21 6' stroke={fill} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
		</svg>
	);
};

export default AgreeIcon;