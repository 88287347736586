import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const MapIcon: React.FC<IconSvgProps> = ({ width = 30, height = 30, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 25 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Карта</title>
			<g clipPath='url(#clip0_113_563)'>
				<path
					d='M23.5 6.70825V0.958252H17.5V2.87492H7.5V0.958252H1.5V6.70825H3.5V16.2916H1.5V22.0416H7.5V20.1249H17.5V22.0416H23.5V16.2916H21.5V6.70825H23.5ZM3.5 2.87492H5.5V4.79159H3.5V2.87492ZM5.5 20.1249H3.5V18.2083H5.5V20.1249ZM17.5 18.2083H7.5V16.2916H5.5V6.70825H7.5V4.79159H17.5V6.70825H19.5V16.2916H17.5V18.2083ZM21.5 20.1249H19.5V18.2083H21.5V20.1249ZM19.5 4.79159V2.87492H21.5V4.79159H19.5ZM14.235 13.4166H10.75L10.02 15.3333H8.395L11.795 6.70825H13.2L16.605 15.3333H14.975L14.235 13.4166ZM11.185 12.2091H13.795L12.49 8.53867L11.185 12.2091Z'
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id='clip0_113_563'>
					<rect width={width} height={height} fill='white' transform='translate(0.5)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default MapIcon;
