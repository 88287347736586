import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import KeyboardIcon from '@assets/Icons/Translation/KeyboardIcon';
import MainButton from '@helpers/components/MainButton/MainButton';
import { KeyboardControlArrow as Arrow, KeyboardControlSignalKeys as SignalKeys } from './KeyboardControl.interface';
import translationState from '@store/translationState/translationState';
import canvasState from '@store/canvasState/canvasState';

const KeyboardControl: React.FC = observer(() => {
	const { isMouseControl } = translationState;
	const { isShowCanvas } = canvasState;

	const [isSelected, setIsSelected] = useState(false);

	const disableIcon = isMouseControl || isShowCanvas;

	const signalKeysDown: SignalKeys = {
		ArrowUp: (key) => handleKey(key, 'mousedown'),
		ArrowRight: (key) => handleKey(key, 'mousedown'),
		ArrowDown: (key) => handleKey(key, 'mousedown'),
		ArrowLeft: (key) => handleKey(key, 'mousedown'),
	};

	const signalKeysUp: SignalKeys = {
		ArrowUp: (key) => handleKey(key, 'mouseup'),
		ArrowRight: (key) => handleKey(key, 'mouseup'),
		ArrowDown: (key) => handleKey(key, 'mouseup'),
		ArrowLeft: (key) => handleKey(key, 'mouseup'),
	};

	useEffect(() => {
		if (isMouseControl) setIsSelected(false);
	}, [isMouseControl]);

	useEffect(() => {
		isSelected ? addEvents() : removeEvents();

		return () => {
			removeEvents();
		};
	}, [isSelected]);

	const addEvents = () => {
		window.addEventListener('keydown', keyDownMessage);
		window.addEventListener('keyup', keyUpMessage);
	};

	const removeEvents = () => {
		window.removeEventListener('keydown', keyDownMessage);
		window.removeEventListener('keyup', keyUpMessage);
	};

	const handleKey: Arrow = (message, mouseClickType) => {
		const { keyboardKeys } = translationState;

		if (keyboardKeys[message]?.current) {
			const event = new MouseEvent(mouseClickType, {
				bubbles: true,
				cancelable: true,
				view: window,
			});

			keyboardKeys[message]?.current?.dispatchEvent(event);
		}
	};

	const keyDownMessage = (event: KeyboardEvent) => {
		const { key, repeat } = event;
		const { setKeysSelected } = translationState;

		if (repeat) {
			event.preventDefault(); // Отключаем повторное срабатывание события клавиатуры
			return;
		}

		const isCorrectKey = key in signalKeysDown;
		if (!isCorrectKey) return;

		const curKey = key as keyof SignalKeys;

		setKeysSelected(curKey, true);
		signalKeysDown[curKey](curKey);
	};

	const keyUpMessage = (event: KeyboardEvent) => {
		const { key, repeat } = event;
		const { setKeysSelected } = translationState;

		if (repeat) {
			event.preventDefault(); // Отключаем повторное срабатывание события клавиатуры
			return;
		}

		const isCorrectKey = key in signalKeysDown;
		if (!isCorrectKey) return;

		const curKey = key as keyof SignalKeys;

		setKeysSelected(curKey, false);
		signalKeysUp[curKey](curKey);
	};

	const handleClickKeyboardControl = () => {
		setIsSelected((prevIsSelected) => (isMouseControl ? prevIsSelected : !prevIsSelected));
	};

	const fill = isSelected ? '#00c2fd' : '#D2D2D2';

	return (
		<MainButton onClick={handleClickKeyboardControl} disabled={disableIcon}>
			<KeyboardIcon fill={fill} disabled={disableIcon} />
		</MainButton>
	);
});

export default KeyboardControl;
