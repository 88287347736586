import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {Device, DeviceAccount} from './interface';
import {UserAccess} from '../../api/AccountPage/prepareAccountData.interface';
import autorizeState from '../accoutState/autorizeState';

class DevicesState {
	devices: Device[] = [];
	accountDevices: DeviceAccount[] = [];
	selectDeviceId: Device['id'] | null = null;

	isUpdateData = false;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: 'deviceId',
			properties: ['selectDeviceId'],
			storage: window.localStorage,
		});
	}

	setDevices = (newDevices: Device[]) => {
		this.devices = newDevices;
	};

	setAddAccessDevices = (newDevicesAccess: UserAccess[]) => {
		const spotAdminIds = newDevicesAccess
			.filter(item => item.role === 'spotadmin')
			.map(item => item.spot);
		this.accountDevices.forEach((device) => {
			if (spotAdminIds.includes(device.spot)) {
				device.isCanEdit = true;
			}
		});
	};

	setAccountDevices = (newDevices: DeviceAccount[]) => {
		this.accountDevices = newDevices.map((camera)=>{
			const groupRole = autorizeState.user.grouprole === 'groupadmin';
			const {id, idPsw, name, spot, spotName} = camera;
			return {
				id,
				idPsw,
				name,
				spot,
				spotName,
				isCanEdit: groupRole
			};
		});
	};

	changeUpdateData = () => {
		setTimeout(() => {
			this.isUpdateData = !this.isUpdateData;
		}, 500);
	};

	setselectDeviceId = (id: Device['id'] | null) => {		
		this.selectDeviceId = id;
	};

	setSelectedDevices = (idDevice: Device['id']) => {		
		try {
			this.devices.forEach((device) => (device.isSelected = false));

			const index = this.devices.findIndex(({ id }: Device) => id === idDevice);

			this.devices[index].isSelected = true;
		} catch (error) {
			console.log(error);
		}
	};
}

const devicesState = new DevicesState();
export default devicesState;
