import React from 'react';
import styles from './PlaceMarker.module.scss';
import { Marker, Popup } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { LatLngTuple, Icon } from 'leaflet';
import placeState from '@store/placeState/placeState';
import mapState from '@store/mapState/mapState';
import { PlaceDevices as Place } from '@store/placeState/interfaces';
import PlaceIcon from '@assets/Icons/Map/PlaceIcon';
import notificationsState from '@store/notificationsState/notificationsState';

const PlacesMarkers: React.FC = observer(() => {
	const navigate = useNavigate();

	const { places, selectPlaceId, setSelectedPlaces, setSelectPlaceId } = placeState;
	const { popupPosition, namePopup, setPopupPosition, setZeroPopupPosition, setCenterMap, setNamePopup } = mapState;
	const { notifications } = notificationsState;

	const svgString = (color: string) => renderToString(<PlaceIcon fill={color} />);

	const itemIcon = (color: string) =>
		new Icon({
			iconUrl: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString(color))}`,
			iconSize: [65, 65],
		});

	const handlePositionMarker = (id: string, name: string, center: LatLngTuple) => {
		const delayTimeMS = 300;

		setSelectPlaceId(id);
		setSelectedPlaces(id, true);
		setCenterMap(center);

		setNamePopup(name);
		setZeroPopupPosition();
		setTimeout(() => {
			setPopupPosition(center);
		}, delayTimeMS);
	};

	const selectDevices = () => {
		const place = places.find(({ id }) => id === selectPlaceId);

		if (place === undefined) return;

		setZeroPopupPosition();

		const placeName = place.name.replace(/ /g, '_').toLowerCase();
		navigate(`/device/${placeName}`);
	};

	const findPlaceEvent = (id: string): boolean => {
		const index = notifications.findIndex((notif) => notif.spot === id);
		return index !== -1;
	};

	const createPlaceMarkerOnMap = ({ id, name, coords, isSelected, isHaveEvents }: Place) => {
		const [ppLat, ppLng] = popupPosition as LatLngTuple;
		const isActivePopPos = Boolean(ppLat && ppLng);
		const key = `Marker-Place-Item-${id}-${name}`;

		const selectedColor = {
			true: '#00C2FD',
			false: '#939393',
		};
		const eventsColor = {
			true: '#F6BA1B',
			false: selectedColor[`${isSelected as boolean}`],
		};

		const isEvent = isHaveEvents || findPlaceEvent(id);

		const iconColor = eventsColor[`${isEvent}`];

		return (
			<div key={key}>
				<Marker
					icon={itemIcon(iconColor)}
					position={coords}
					eventHandlers={{
						click: () => {
							handlePositionMarker(id, name, coords);
						},
					}}
				/>

				{isActivePopPos && (
					<Popup position={popupPosition}>
						<div className={styles.popupContainer}>
							<div style={{ display: 'flex', justifyContent: 'space-between'}}>
								{namePopup}
								<div style={{width: '15px'}}>
									{isEvent && <div className={styles.listItemIcon} />}
								</div>
							</div>

							<button className={styles.navigateButton} onClick={selectDevices}>
								к устройствам
							</button>
						</div>
					</Popup>
				)}
			</div>
		);
	};

	return <div>{places.map(createPlaceMarkerOnMap)}</div>;
});

export default PlacesMarkers;
