import { IconSvgProps as Props } from '../../../helpers/Interfaces/IconSvg.interface';

const KeyboardIcon: React.FC<Props> = ({ width = 24, height = 24, fill = '#D2D2D2', disabled = false }) => {
	const newFill = disabled ? 'hsl(0, 0%, 50%)' : fill;

	return (
		<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<title>Управление с клавиатуры</title>
			<path fill={newFill} d="M15 5v7h-14v-7h14zM16 4h-16v9h16v-9z" />
			<path fill={newFill} d="M4 10h8v1h-8v-1z" />
			<path fill={newFill} d="M2 10h1v1h-1v-1z" />
			<path fill={newFill} d="M13 10h1v1h-1v-1z" />
			<path fill={newFill} d="M11 8h1v1h-1v-1z" />
			<path fill={newFill} d="M9 8h1v1h-1v-1z" />
			<path fill={newFill} d="M7 8h1v1h-1v-1z" />
			<path fill={newFill} d="M5 8h1v1h-1v-1z" />
			<path fill={newFill} d="M3 8h1v1h-1v-1z" />
			<path fill={newFill} d="M10 6h1v1h-1v-1z" />
			<path fill={newFill} d="M12 6v1h1v2h1v-3z" />
			<path fill={newFill} d="M8 6h1v1h-1v-1z" />
			<path fill={newFill} d="M6 6h1v1h-1v-1z" />
			<path fill={newFill} d="M4 6h1v1h-1v-1z" />
			<path fill={newFill} d="M2 6h1v1h-1v-1z" />
		</svg>
	);
};

export default KeyboardIcon;
