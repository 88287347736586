import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const CloseIcon: React.FC<IconSvgProps> = ({ width = 25, height = 25, fill = '#D2D2D2', title = '' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 24 24' fill={fill} xmlns='http://www.w3.org/2000/svg'>
			<title>{title}</title>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z'
				fill={fill}
			/>
		</svg>
	);
};

export default CloseIcon;
