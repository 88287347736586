import React, { FC, useEffect, useRef } from 'react';
import styles from './ViewBox.module.scss';
import { observer } from 'mobx-react-lite';
import { PropsViewBox } from './ViewBox.interface';
import CanvasGridTemp from '../../CanvasGridTemp/CanvasGridTemp';
import PolygonMenu from '../PolygonMenu/PolygonMenu';
import MouseControlCameraView from '../FourLeafCloverControl/MouseControl/MouseControlCameraView/MouseControlCameraView';
import translationState from '@store/translationState/translationState';
import canvasState from '@store/canvasState/canvasState';
import devicesState from '@store/devicesState/devicesState';

const ViewBox: FC<PropsViewBox> = observer(
	({ width, height, img, isVisibleMax, tempGrid, minTemp, maxTemp, clickPosition, setTCurrent, setClickPosition }) => {
		const { isVisibleTempGrid, isMouseControl } = translationState;
		const { isShowCanvas, isCreatePolygon, isEditPolygon, setCanvas } = canvasState;
		const { selectDeviceId } = devicesState;

		const canvasRef = useRef(null);

		useEffect(() => {
			if (!isShowCanvas) return;

			setCanvas(canvasRef.current);
		}, [selectDeviceId, isShowCanvas]);

		return (
			<div
				style={{
					width: width,
					height: height,
					backgroundImage: `url(${img})`,
				}}
				className={styles.container}
			>
				{isVisibleTempGrid && !isMouseControl && (
					<>
						<div className={styles.tempZone}>
							<CanvasGridTemp
								width={width * 0.55}
								height={height * 0.55}
								data={tempGrid}
								minTemp={minTemp}
								maxTemp={maxTemp}
								isTempMax={isVisibleMax}
								handleCur={(temp) => setTCurrent(temp)}
							/>

							{isShowCanvas && (
								<canvas
									ref={canvasRef}
									className={styles.areaCanvas}
									width={width * 0.55}
									height={height * 0.55}
								/>
							)}
						</div>

						<div style={{ width: width * 0.55 }} className={styles.colorLine}>
							<div style={{ marginLeft: '10px' }}>{Math.round(minTemp)}°С</div>
							<div className={styles.colorBlock} />
							<div style={{ marginRight: '10px' }}>{Math.round(maxTemp)}°С</div>
						</div>
					</>
				)}

				{isShowCanvas && (
					<span className={styles.tempButton}>
						{`Режим ${isCreatePolygon ? 'Создания' : isEditPolygon ? 'Редактирования' : 'Отображения'} Зоны`}
						{!isCreatePolygon && !isEditPolygon && <div className={styles.warnText}>Для отображения текущей уставки опросите устройство</div>}
					</span>
				)}

				{isShowCanvas && (
					<div className={styles.listArea}>
						<PolygonMenu />
					</div>
				)}

				{isMouseControl && (
					<div className={styles.mouseControl}>
						<MouseControlCameraView clickPosition={clickPosition} setClickPosition={setClickPosition} />
					</div>
				)}
			</div>
		);
	}
);

export default ViewBox;
