import React, { useState } from 'react';
import { ItemCardProps as Props } from './ItemCard.interface';
import styles from './ItemCard.module.scss';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '../../../../assets/Icons/Map/CloseIcon';
import Modal from '@mui/material/Modal';
import Confirm from './Confirm/Confirm';
import {CustomHeaderUserGroupRole} from '../../CustomHeader/CustomHeader.inteface';
import { AccountTypeForModal } from '../../Account.interface';

const ItemCard:  React.FC<Props> = ({data, change, url, groupRole}) => {
	const { name, isWorking, id, type, isCanEdit} = data;

	const background = isWorking ?  '#000000' : '#00C2FD';

	const [isOpen, setOpen] = useState(false);
	const [isDisableButton, setDisableButton] = useState(true);
	const [deleteUrl, setDeleteUrl] = useState(url);

	const handleOpenModal = () => {
		const urlType: AccountTypeForModal = {
			location: '/deletespot/',
			camera: '/deletecamera/',
			action: '',
			manage: ''
		};
		const newUrl = `${url}${id}${urlType[type]}`;
		setDeleteUrl(newUrl);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setDisableButton(true);
	};

	const groupRoleComponents: CustomHeaderUserGroupRole = {
		groupadmin: true,
		groupuser: isCanEdit,
		camerauser: false,
	};

	const roleComponent = groupRoleComponents[groupRole];
	const doubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		change(id, isCanEdit);
	};

	return (
		<ListItem >
			<div className={styles.tableText} onDoubleClick={(e) => doubleClick(e)}>
				<div
					className={styles.imgConditional}
					style = {{background}}
				/>
				<div className={styles.row}>
					{name}
				</div>
				{roleComponent && (
					<div
						className={styles.closeIcon}
						onClick={() => handleOpenModal()}
					>
						<CloseIcon title={'Удалить'}/>
					</div>
				)}
			</div>

			<Modal
				className={styles.modal}
				open={isOpen}
				onClose={handleClose}
			>
				<div>
					<Confirm
						name={name}
						url={deleteUrl}
						id={id}
						handleClose={handleClose}
						isDisableButton={isDisableButton}
						setDisableButton={setDisableButton}
						updateTitle={'isUser'}
					/>
				</div>
			</Modal>
		</ListItem>
	);
};
export default ItemCard;
