import React from 'react';
import styles from './Parameters.module.scss';
import {ParametersProps as Props} from './Parameters.interface';
import ItemTimePicker from '../ItemTimePicker/ItemTimePicker';
// import CloseIcon from '../../../../../assets/Icons/Map/CloseIcon';

const Parameters: React.FC<Props> = ({ /*pickers,*/ valueTimePicker, setValueTimePicker/*, setPickers*/ }) => {
	// const maxTimePickers = 24;

	// const handleAddNewTimeValue = () => {
	// 	setPickers([...pickers, '00:00:00']);
	// };
	//
	// const handleDeleteTimePicker = (index: number) => {
	// 	const newPickers = [...pickers];
	// 	newPickers.splice(index, 1);
	//
	// 	setPickers(newPickers);
	// };
	//
	// const handleChangeTimePicker = (index: number, picker: string) => {
	// 	const newPickers = [...pickers];
	// 	newPickers[index] = picker;
	//
	// 	setPickers(newPickers);
	// };

	const handleChangeSchedulePicker = (newTimePicker: string) => {
		setValueTimePicker(newTimePicker.slice(0, 2));
	};

	return (
		<div className={styles.typePicker}>
			<div className={styles.pickerTitles}>
				<div>Автоматически, раз в </div>
				{/*<div>По расписанию </div>*/}
			</div>

			<div className={styles.timePickers}>
				<div className={styles.timePickerTop}>
					<ItemTimePicker fullTime={false} valueTimePicker={valueTimePicker} setValueTimePicker={(newTimePicker) => handleChangeSchedulePicker(newTimePicker)} />
					ч.
				</div>
				{/*<span>*/}
				{/*	{pickers.length <= maxTimePickers &&*/}
				{/*		pickers.map((picker, index) => (*/}
				{/*			<div key={`TimePicker-${index}`}>*/}
				{/*				<div className={styles.secondTimePicker}>*/}
				{/*					<ItemTimePicker fullTime={true} valueTimePicker={picker} setValueTimePicker={(newTimePicker) => handleChangeTimePicker(index, newTimePicker)} />*/}

				{/*					<div className={styles.closeIcon} onClick={() => handleDeleteTimePicker(index)}>*/}
				{/*						{index !== 0*/}
				{/*							? <CloseIcon />*/}
				{/*							: <div className={styles.empty}></div>*/}
				{/*						}*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		))}*/}

				{/*	{pickers.length < maxTimePickers &&*/}
				{/*		<p onClick={handleAddNewTimeValue}>Добавить время</p>*/}
				{/*	}*/}
				{/*</span>*/}
			</div>
		</div>
	);
};

export default Parameters;
