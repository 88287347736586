import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const AdminIcon: React.FC<IconSvgProps> = ({ width = 36, height = 36, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Аккаунт</title>
			<path
				d='M12.6596 12.12C12.6296 12.12 12.6096 12.12 12.5796 12.12C12.5296 12.11 12.4596 12.11 12.3996 12.12C9.49957 12.03 7.30957 9.75 7.30957 6.94C7.30957 4.08 9.63957 1.75 12.4996 1.75C15.3596 1.75 17.6896 4.08 17.6896 6.94C17.6796 9.75 15.4796 12.03 12.6896 12.12C12.6796 12.12 12.6696 12.12 12.6596 12.12ZM12.4996 3.25C10.4696 3.25 8.80957 4.91 8.80957 6.94C8.80957 8.94 10.3696 10.55 12.3596 10.62C12.4096 10.61 12.5496 10.61 12.6796 10.62C14.6396 10.53 16.1796 8.92 16.1896 6.94C16.1896 4.91 14.5296 3.25 12.4996 3.25Z'
				fill={fill}
			/>
			<path
				d='M12.6696 23.05C10.7096 23.05 8.73961 22.55 7.24961 21.55C5.85961 20.63 5.09961 19.37 5.09961 18C5.09961 16.63 5.85961 15.36 7.24961 14.43C10.2496 12.44 15.1096 12.44 18.0896 14.43C19.4696 15.35 20.2396 16.61 20.2396 17.98C20.2396 19.35 19.4796 20.62 18.0896 21.55C16.5896 22.55 14.6296 23.05 12.6696 23.05ZM8.07961 15.69C7.11961 16.33 6.59961 17.15 6.59961 18.01C6.59961 18.86 7.12961 19.68 8.07961 20.31C10.5696 21.98 14.7696 21.98 17.2596 20.31C18.2196 19.67 18.7396 18.85 18.7396 17.99C18.7396 17.14 18.2096 16.32 17.2596 15.69C14.7696 14.03 10.5696 14.03 8.07961 15.69Z'
				fill={fill}
			/>
		</svg>
	);
};

export default AdminIcon;
