export const customStyles = {
	container: (provider) => ({
		...provider,
		width: '100%',
	}),
	
	// стили для контейнера
	control: (provided) => ({
		...provided,
		border: '1px solid #858585',
		borderRadius: '10px',
		padding: '3px',
		fontSize: '16px',
		backgroundColor: 'transparent',
		cursor: 'pointer',

		'&:hover' :{
			border: '1px solid #00c2fd'
		},

		'&:focus-within' :{
			border: '1px solid #00c2fd'
		}
	}),

	// стили для выпадающего списка
	menu: (provided) => ({
		...provided,
		borderRadius: '10px',
		border: '1px solid #858585',
		overflow: 'hidden',
		backgroundColor: '#272727',
	}),

	// стили для опций
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isFocused ? '#00c2fd' : 'transparent',
		color: '#d2d2d2',
		cursor: 'pointer',
	}),

	valueContainer: (provided) => ({
		...provided,
		padding: '0 5px'
	}),

	singleValue: (provided) => ({
		...provided,
		color: '#d2d2d2',
	}),
};