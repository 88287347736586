import styles from './InfographicsEvent.module.scss';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import connectState from '../../../store/connectState/connectState';
import devicesState from '../../../store/devicesState/devicesState';
import { apiGet } from '../../../api/api';
import { prepareInfoGraficEvents } from '../../../api/Control/prepareControlData';
import {GraficBarInfo, GraficInfo, InfographicsProps} from './InfographicsEvent.interface';
import { RawGraficEvent } from '../../../api/Control/prepareControlData.interface';
import CheckEmpty from '../../../helpers/components/CheckEmpty/CheckEmpty';
import ChartLineBar from '../../Dashboard/Chart/ChartTypes/ChartLineBar/ChartLineBar';
import {ChartGraficMergeInfo as GraficMergeInfo} from '../../Dashboard/Chart/Chart.interface';

const InfographicsEvent: React.FC<InfographicsProps> = observer(({ dateInfo }) => {
	const { getEventsLink } = connectState;
	const { selectDeviceId } = devicesState;

	const [dataGrafic, setDataGrafic] = useState<GraficInfo[]>([]);
	const [barEvents, setBarEvents] = useState<GraficBarInfo[]>([]);
	const [isFirstTypeChart, setFirstTypeChart] = useState(true);
	const [typeMergeEvents, setTypeMergeEvents] = useState<number>(0);

	const candles: { [key: string]: GraficMergeInfo } = {};
	const groupedEvents: { [key: string]: { [key: string]: number | string} } = {};
	
	useEffect(() => {
		if (!dateInfo || getEventsLink === '' || selectDeviceId === '') return;
		const partsDate = dateInfo.split('.');
		
		const formatDate = `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`;

		const datePartLink = `/dayevents/?search=${formatDate}&ordering=id_message`;
		const datePartBarLink = `/dayeventsforbars/?search=${formatDate}&ordering=id_message`;

		getEvents(`${getEventsLink}${selectDeviceId}${datePartLink}`);
		getBarEvents(`${getEventsLink}${selectDeviceId}${datePartBarLink}`);
	}, [getEventsLink, selectDeviceId, dateInfo]);


	const getBarEvents = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			setBarEvents(data as GraficBarInfo[]);
		});
	};

	dataGrafic.forEach(item => {
		const time = item.time;
		const hour = Number(time.split(':')[0]);

		const key1Hour = `${item.date} ${hour}:00:00`;
		const key2Hours = `${item.date} ${Math.floor(hour / 2) * 2}:00:00`;
		const key3Hours = `${item.date} ${Math.floor(hour / 3) * 3}:00:00`;

		const keys = [key1Hour, key2Hours, key3Hours];

		const key = keys[typeMergeEvents-1];

		if (!candles[key]) {
			candles[key] = {
				date: item.date,
				id: item.id,
				time: `${hour}:00 - ${hour + typeMergeEvents-1}:59`,
				count: 1,
				totalAverageTemperature: item['Средняя Температура'] || 0,
				totalMinTemperature: item['Минимальная Температура'] || 0,
				totalMaxTemperature: item['Максимальная Температура'] || 0
			};
		} else {
			candles[key].count += 1;
			candles[key].totalAverageTemperature += item['Средняя Температура'] || 0;
			candles[key].totalMinTemperature += item['Минимальная Температура'] || 0;
			candles[key].totalMaxTemperature += item['Максимальная Температура'] || 0;
		}
	});

	barEvents.forEach(item => {
		const eventType = item.event_type;
		const time = item.time;
		const hour = Number(time.split(':')[0]);

		const key1Hour = `${item.date} ${hour}:00:00`;
		const key2Hours = `${item.date} ${Math.floor(hour / 2) * 2}:00:00`;
		const key3Hours = `${item.date} ${Math.floor(hour / 3) * 3}:00:00`;

		const keys = [key1Hour, key2Hours, key3Hours];

		const key = keys[typeMergeEvents-1];

		if (!candles[key]) {
			if (!groupedEvents[key]) {
				groupedEvents[key] = {
					date: item.date,
					id: item.id,
					time: `${hour}:00 - ${hour + typeMergeEvents-1}:59`,
				};
			}

			if (!groupedEvents[key][eventType]) {
				groupedEvents[key][eventType] = 0;
			}

			groupedEvents[key][eventType] = (groupedEvents[key][eventType] || 0) as number + 1;
		}
	});

	const candleEvents: GraficInfo[] = Object.values(candles).map(candle => {
		const { date, id, time, count, totalAverageTemperature, totalMinTemperature, totalMaxTemperature } = candle;
		const averageTemperature = totalAverageTemperature / count;
		const minTemperature = totalMinTemperature / count;
		const maxTemperature = totalMaxTemperature / count;

		return { date, id, time, ['Средняя Температура']: Number(averageTemperature.toFixed(2)), ['Минимальная Температура']: Number(minTemperature.toFixed(2)), ['Максимальная Температура']: Number(maxTemperature.toFixed(2)) };
	});

	const candleBarEvents: GraficInfo[] = Object.values(groupedEvents).map(candle => {
		const { date, id, time, ir, uv, sound, vd, pl } = candle;
		const newIr = ir !== undefined ? ir : 0;

		return { date: String(date), id: String(id), time: String(time), ir: newIr, uv, sound, vd, pl };
	});

	const getEvents = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const infografics = prepareInfoGraficEvents(data as RawGraficEvent[]);
			setDataGrafic(infografics);
		});
	};

	return (
		<CheckEmpty isCheck={!!dateInfo && !!dataGrafic.length}>
			<div className={styles.container}>
				<ChartLineBar
					dataChart={typeMergeEvents === 0 ? dataGrafic : isFirstTypeChart ? candleEvents : candleBarEvents}
					firstType={isFirstTypeChart}
					dateInfo={dateInfo}
					isFirstTypeChart={isFirstTypeChart}
					setFirstTypeChart={setFirstTypeChart}
					typeMergeEvents={typeMergeEvents}
					setTypeMergeEvents={setTypeMergeEvents}
				/>
			</div>
		</CheckEmpty>
	);
});

export default InfographicsEvent;
