import { useEffect } from 'react';
import styles from './CreateMenu.module.scss';
import { CircularProgress, styled } from '@mui/material';
import { Box } from '@mui/material';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../helpers/components/SelectStyle/SelectStyles';
import canvasState from '../../../../store/canvasState/canvasState';
import { typesArea } from '../PolygonMenu';

const ModLoaded = styled(CircularProgress)({
	position: 'absolute',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	zIndex: '1000',
	margin: 'auto',
	color: '#00c2fd',
});

const CreateMenu = ({ isLoading, name, minTemp, maxTemp, setName, setMin, setMax, setType, handleDisable}) => {
	const {setIsOneReadyPolygon} = canvasState;

	useEffect(() => {
		const isDisable = !!name && !!minTemp && !!maxTemp;

		handleDisable(isDisable);
	}, [name, minTemp, maxTemp]);

	useEffect(() => {
		return () => setIsOneReadyPolygon(); 
	}, []);

	return (
		<>
			<Box className={styles.titleBox}>
				<span className={styles.title}>Создание зоны</span>
			</Box>

			{isLoading && <ModLoaded size={50}/>}

			<div className={styles.content}>
				<div>
					<div className={styles.subTitle}>Название</div>
					<div className={styles.search}>
						<input
							className={styles.searchInput}
							value={name}
							placeholder='Введите название'
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				</div>

				<div className={styles.subTitle}>Минимальная температура</div>
				<div className={styles.search}>
					<input
						className={styles.searchInput}
						value={minTemp}
						placeholder='Укажите температуру'
						type='number'
						onChange={(e) => setMin(e.target.value)}
					/>
				</div>

				<div className={styles.subTitle}>Максимальная температура</div>
				<div className={styles.search}>
					<input
						className={styles.searchInput}
						value={maxTemp}
						placeholder='Укажите температуру'
						type='number'
						onChange={(e) => setMax(e.target.value)}
					/>
				</div>

				<div className={styles.subTitle}>Тип зоны</div>
				<Select
					styles={customStyles}
					defaultValue={typesArea[0]}
					options={typesArea}
					components={makeAnimated}
					onChange={(e) => setType(e.value)}
				/>
			</div>
		</>
	);
};

export default CreateMenu;
