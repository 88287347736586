import React from 'react';
import styles from './InfoInput.module.scss';
// import {Box} from '@mui/material';
import { Props} from './InfoInput.interface';
// import {apiPatch} from '../../../../../api/api';

const InfoInput: React.FC<Props> = () => {

	//
	// const soundInput = {
	// 	energy: data[0].value,
	// 	correlation: data[1].value,
	// 	frequency: data[2]?.value
	// };
	// const tempInput = {
	// 	min: data[0].value,
	// 	max: data[1].value
	// };
	//
	// const item = {
	// 	sound: soundInput,
	// 	temp: tempInput
	// };


	return (
		<div className={styles.contentBox}>
			{/*{data.map(({ id, name, title}) => (*/}
			{/*	<Box className={styles.labelAndBox} key={`Input-Settings-Event-${id}-${name}`}>*/}
			{/*		{title}*/}
			{/*		<div className={styles.conteiner}>*/}
			{/*			<div className={styles.inputBox}>*/}
			{/*				<input*/}
			{/*					className={styles.styleInput}*/}
			{/*					maxLength={4}*/}
			{/*					value={inputData[name]}*/}
			{/*					type={'number'}*/}
			{/*					onChange={handleChangeInput(name)}*/}
			{/*				/>*/}
			{/*			</div>*/}
			{/*			<p>{data[3].simbol}</p>*/}
			{/*		</div>*/}
			{/*	</Box>*/}
			{/*))}*/}
		</div>
	);
};


export default InfoInput;
