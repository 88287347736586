import { InfoData } from '../InfoEvent.interface';
import styles from './BaseInfoEvent.module.scss';
import { Box, List, ListItem } from '@mui/material';

interface BaseInfoProps {
    eventsData: InfoData[];
}

const BaseInfoEvent: React.FC<BaseInfoProps> = ({eventsData}) => {
	return (
		<div className={styles.container}>
			<div className={styles.titleInfo}>Информация о событии</div>
			<List sx={{ padding: '0px' }}>
				{eventsData.map(({ name, value }, index) => {
					const key = `View-Event-Data-${name}-${index}`;
					
					return (
						<ListItem 
							key={key} 
							sx={{ 
								justifyContent: 'space-between', 
								padding: '5px 10px' 
							}}
						>
							<Box>{`${name}: `}</Box>

							<Box>{`${value}`}</Box>
						</ListItem>
					);
				})}
			</List>
		</div>
	);
};

export default BaseInfoEvent;
