import { useState, useEffect } from 'react';
import styles from './InfoEvent.module.scss';
import { observer } from 'mobx-react-lite';
import controlState from '../../../store/controlState/controlState';
import { ResultEventData, SoundCardContent } from '../../../store/eventState/interface';
import { InfoData, InfoEventProps, InfoKeyData } from './InfoEvent.interface';
import SoundOptions from './SoundOptions/SoundOptions';
import BaseInfoEvent from './BaseInfoEvent/BaseInfoEvent';
import CheckEmpty from '../../../helpers/components/CheckEmpty/CheckEmpty';

const InfoEvent: React.FC<InfoEventProps> = observer(({ event }) => {
	const { isVisibleSoundInfo } = controlState;

	const [isEventSelected, setEventSelected] = useState(false);

	useEffect(() => {
		setEventSelected(!!event.id);
	}, [event.id]);

	const eventsData: InfoData[] = [];

	const keys = Object.keys(event);

	for (let i = 0; i < keys.length; ++i) {
		if (
			keys[i] === 'id_message' ||
			keys[i] === 'idCamera' ||
			keys[i] === 'idPreset' ||
			keys[i] === 'cameraName' ||
			keys[i] === 'zone' ||
			keys[i] === 'zoneName' ||
			keys[i] === 'name' ||
			keys[i] === 'counter' ||
			keys[i] === 'viewData' ||
			keys[i] === 'img' ||
			keys[i] === 'temperImage' ||
			keys[i] === 'sound' ||
			keys[i] === 'spot' ||
			keys[i] === 'protocolImage' ||
			keys[i] === 'soundEnergy' ||
			keys[i] === 'soundCorrelation' ||
			keys[i] === 'soundFrequency' 
		)
			continue;

		const rus: InfoKeyData = {
			id: 'ID',
			messageType: 'Тип сообщения',
			date: 'Дата',
			time: 'Время',
			presetName: 'Позиция Камеры',
			t_max: 'Максимальная температура',
			t_mean: 'Средняя температура',
			t_min: 'Минимальная температура',
		};

		const key = keys[i] as keyof ResultEventData;

		eventsData.push({
			name: rus[key as keyof InfoKeyData],
			value: event[key],
		});
	}
	const soundInfoContent: SoundCardContent = {
		sound: <SoundOptions event={event} />,
		base: <BaseInfoEvent eventsData={eventsData} />
	};

	const keyForTable = isVisibleSoundInfo ? 'sound' : 'base';

	return (
		<div className={styles.container}>
			<CheckEmpty isCheck={!!event && isEventSelected}>
				<>{soundInfoContent[keyForTable]}</>
			</CheckEmpty>
		</div>
	);
});

export default InfoEvent;
