import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ChartLineIcon: React.FC<IconSvgProps> = ({ width = 24, height = 24, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 15L12 9L16 13L21 7"
				stroke={fill}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChartLineIcon;
