import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const FullWinOffIcon: React.FC<IconSvgProps> = ({ width = 19, height = 19, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Свернуть</title>
			<path
				d='M15 7.5L10.5 7.5M10.5 7.5V3M10.5 7.5L15.75 2.25'
				stroke={fill}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3 10.5L7.5 10.5M7.5 10.5L7.5 15M7.5 10.5L2.25 15.75'
				stroke={fill}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default FullWinOffIcon;
