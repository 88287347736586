import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ClockIcon: React.FC<IconSvgProps> = ({ width = 40, height = 40, fill = '#D2D2D2', disabled = false }) => {
	const disableFill = disabled ? 'hsl(0, 0%, 50%)' : fill;
	
	return (
		<svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Параметры патрулирования</title>
			<polyline
				fill='none'
				points='11.9 5.9 11.9 11.9 12 12 14.1 14.1'
				stroke={disableFill}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
			/>

			<circle
				cx='12'
				cy='12'
				data-name='Circle'
				fill='none'
				id='Circle-2'
				r='10'
				stroke={disableFill}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
			/>
		</svg>
	);
};

export default ClockIcon;
