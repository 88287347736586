import React from 'react';
import styles from './ConfirmButtons.module.scss';
import { PropsConfirmButton } from './ConfirmButtons.interface';

const ConfirmButtons: React.FC<PropsConfirmButton> = ({
	nameOk = 'Сохранить',
	nameNo = 'Отмена',
	isDisabled = false,
	fontSize = '20px',
	handleOk,
	handleCancel,
}) => {
	return (
		<div className={styles.modalButtons}>
			<button
				style={{fontSize}}
				className={styles.modalButton}
				disabled={isDisabled}
				onClick={handleOk}
			>
				{nameOk}
			</button>
			<button 
				style={{fontSize}} 
				className={styles.modalCancelButton} 
				onClick={handleCancel}
			>
				{nameNo}
			</button>
		</div>
	);
};

export default ConfirmButtons;
