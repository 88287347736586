import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import './UnloadingEvents.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, styled, ThemeProvider, createTheme } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { DataGrid } from '@mui/x-data-grid';
import devicesState from '../../../store/devicesState/devicesState';
import connectState from '../../../store/connectState/connectState';
import { dateToEnDate } from '../../../helpers/functions/helpFunctions';
import { apiGet } from '../../../api/api';
import { prepareUnloadingEvents } from '../../../api/Control/prepareControlData';
import { RawEvent } from '../../../api/Control/prepareControlData.interface';
import { RowTable } from './UnloadingEvents.interface';
import { columns } from '../TableEvents/TableEvents';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const headerNames = [
	{ id: 0, name: 'ID' },
	{ id: 1, name: 'Дата' },
	{ id: 2, name: 'Время' },
	{ id: 3, name: 'Позиция камеры' },
	{ id: 4, name: 'Зона' },
	{ id: 5, name: 'Тип сообщения' },
	{ id: 6, name: 'T max' },
	{ id: 7, name: 'T mean' },
	{ id: 8, name: 'T min' },
	{ id: 9, name: 'Частота звука' },
	{ id: 10, name: 'Изображение' },
];

const ModDataGrid = styled(DataGrid)({
	width: '100%',
	overflow: 'auto',
	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},
	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},
	'& .MuiDataGrid-cell': {
		fontSize: '16px',
	},
	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},
	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},
	'& ::-webkit-scrollbar': {
		width: '6px',
		borderLeft: '1px solid #D2D2D2',
	},
	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	},
	'& .MuiSelect-icon': {
		color: 'inherit',
	},
});

addLocale('ru', {
	firstDayOfWeek: 1,
	dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
	monthNames: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],
	monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
});

const localizedTextsMap = {
	columnMenuUnsort: 'Отменить сортировку',
	columnMenuSortAsc: 'По возрастанию',
	columnMenuSortDesc: 'По убыванию',
	columnMenuFilter: 'Фильтр',
	columnMenuHideColumn: 'Скрыть',
	columnMenuManageColumns: 'Показать столбцы',

	columnsPanelTextFieldLabel: 'Поиск столбца',
	columnsPanelTextFieldPlaceholder: 'Название столбца',
	columnsPanelShowAllButton: 'Показать все',
	columnsPanelHideAllButton: 'Скрыть все',
};

const UnloadingEvents: React.FC = () => {
	const [dates, setDates] = useState<Date[]>([new Date(), new Date()]);
	// const [isDisabledBut, setDisabledBut] = useState(false);

	const handleChangeDates = (e: CalendarChangeEvent) => {
		setDates(e.value as Date[]);
	};

	const [serverEvents, setServerEvents] = useState<RowTable[]>([]);
	const [isLoad, setLoad] = useState(false);

	useEffect(() => {
		const { selectDeviceId } = devicesState;
		const { getEventsLink } = connectState;

		const start = dateToEnDate(dates[0]);
		const end = dates[1] ? dateToEnDate(dates[1]) : dateToEnDate(dates[0]);

		setLoad(true);
		const cameraLink = `${selectDeviceId}/eventsfullnopag/?`;
		const startPartLink = `date__gte=${start}`;
		const endPartLink = `date__lte=${end}`;
		const resultLink = `${getEventsLink}${cameraLink}${startPartLink}&${endPartLink}`;

		getEvents(resultLink);
	}, [dates]);

	const getEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareUnloadingEvents(data as RawEvent[]);

			setServerEvents(eventsData);
			setLoad(false);
		});
	};

	// const handleDisabled = () => {
	// 	setDisabledBut(true);
	//
	// 	setTimeout(() => {
	// 		setDisabledBut(false);
	// 	}, 5000);
	// };

	const archiveTable = useMemo(() => {
		return (
			<ThemeProvider theme={darkTheme}>
				<ModDataGrid
					rows={serverEvents}
					columns={columns}
					loading={isLoad}
					rowCount={serverEvents.length}
					density={'compact'}
					disableColumnFilter
					localeText={localizedTextsMap}
					initialState={{
						pagination: { paginationModel: { pageSize: 10 } },
					}}
					pageSizeOptions={[10, 25, 50]}
				/>
			</ThemeProvider>
		);
	}, [serverEvents, isLoad]);

	return (
		<Box className='modalContainer'>
			<div className='modalInfo'>
				<div className='title'>
					<Calendar
						value={dates}
						onChange={handleChangeDates}
						selectionMode='range'
						readOnlyInput
						locale='ru'
						dateFormat='dd.mm.yy'
						showIcon
					/>

					<div className='downBut'>
						{/*<button className='excel' disabled={isDisabledBut} onClick={handleDisabled}>*/}
						{/*	CSV*/}
						{/*</button>*/}

						{/*<button className='excel' disabled={isDisabledBut} onClick={handleDisabled}>*/}
						{/*	PDF*/}
						{/*</button>*/}

						{/* <button className='excel' >EXCEL</button> */}

						<ReactHTMLTableToExcel
							className='excel'
							table='archiveTable'
							filename='ReportExcel'
							sheet='Sheet'
							buttonText='EXCEL'
						/>
					</div>
				</div>

				<div className='tableMain'>
					<div style={{ height: '500px', width: '100%' }}>{archiveTable}</div>
				</div>
			</div>

			<TableContainer sx={{ maxHeight: 200, display: 'none' }}>
				<Table id='archiveTable' sx={{ maxWidth: 300 }} size='small' aria-label='customized table'>
					<TableHead>
						<TableRow>
							<TableCell align='center' style={{ fontSize: '18px' }}>
								Тип отчета:
							</TableCell>
							<TableCell align='center' style={{ fontSize: '18px' }}>
								по данным с камеры
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell align='center'>За период</TableCell>
							<TableCell align='center'>
								с {dates[0].toLocaleDateString()} по{' '}
								{dates[1] ? dates[1].toLocaleDateString() : dates[0].toLocaleDateString()}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='center'></TableCell>
							<TableCell align='center'></TableCell>
						</TableRow>
					</TableBody>

					<TableHead>
						<TableRow>
							{headerNames.map(({ id, name }) => {
								return (
									<TableCell
										key={`Cell-table-${id}`}
										content='center'
										align='center'
										style={{ fontSize: '18px' }}
									>
										{name}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{serverEvents.map((row) => {
							const { id } = row;
							const key = `Event-Dates-${id}`;

							return (
								<TableRow key={key}>
									{Object.entries(row).map(([key, value]: [string, string]) => {
										if (key === 'id') return;
										const imgKey = key === 'img';

										return (
											<TableCell
												height={130}
												key={`Table-cell-${key}`}
												scope='row'
												component='th'
												align='center'
											>
												{imgKey ? <img width={190} src={value} /> : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default UnloadingEvents;
