import { useState, useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import canvasState from '../../../store/canvasState/canvasState';
import devicesState from '../../../store/devicesState/devicesState';
import presetState from '../../../store/presetState/presetState';
import Polygons from '../Canvas/Polygons';
import styles from './PolygonMenu.module.scss';
import connectState from '../../../store/connectState/connectState';
import ConfirmButtons from '../../../helpers/components/ConfirmButtons/ConfirmButtons';
import autorizeState from '../../../store/accoutState/autorizeState';
import { apiPatch, apiPost } from '../../../api/api';
import ListMenu from './ListMenu/ListMenu';
import EditMenu from './EditMenu/EditMenu';
import CreateMenu from './CreateMenu/CreateMenu';

export const typesArea = [
	{ id: 1, value: 'IR', label: 'Контроль IR' },
	{ id: 2, value: 'mov_con', label: 'Подвижный контакт' },
	{ id: 3, value: 'fix_con', label: 'Неподвижный контакт' },
	{ id: 4, value: 'iso', label: 'Изолятор' },
	{ id: 5, value: 'entry', label: 'Ввод' },
	{ id: 6, value: 'corp', label: 'Корпус' },
	{ id: 7, value: 'elec_line', label: 'Токопровод' },
];

const EmptyMessage = ({ title = '', subTitle = '' }) => {
	return (
		<div className={styles.emptyMessage}>
			<div className={styles.erTitle}>{title}</div>
			<div className={styles.erTitle}>{subTitle}</div>
			<p className={styles.erMessage}>Сообщить о проблеме</p>
		</div>
	);
};

const PolygonMenu = observer(() => {
	const { selectDeviceId, changeUpdateData } = devicesState;
	const { selectPresetId } = presetState;
	const {
		isCreatePolygon,
		isEditPolygon,
		saveDataTest,
		canvas,
		currentPolygonNum,
		isOneReadyPolygon,
		setIsCreatePolygon,
		setIsEditPolygon,
	} = canvasState;
	const { userRole } = autorizeState;

	const listRef = useRef(null);
	const polygons = saveDataTest?.[selectDeviceId]?.[selectPresetId] ?? [];
	const selectPolygon = polygons[currentPolygonNum];

	const [tempAreas, setTempAreas] = useState('');
	const [selectPolygonsMenu, setSelectPolygonsMenu] = useState('listPolygons');
	const [isCreateDisable, setCreateDisable] = useState(true);

	const [nameNewPolygon, setNameNewPolygon] = useState('');
	const [minTempNewPolygon, setMinTempNewPolygon] = useState('');
	const [maxTempNewPolygon, setMaxTempNewPolygon] = useState('');
	const [typeNewPolygon, setTypeNewPolygon] = useState('IR');

	const [selectIdPolygon, setSelectIdPolygon] = useState(null);

	const [nameEditPolygon, setNameEditPolygon] = useState('');
	const [minTempEditPolygon, setMinTempEditPolygon] = useState(0);
	const [maxTempEditPolygon, setMaxTempEditPolygon] = useState(0);
	const [typeEditPolygon, setTypeEditPolygon] = useState('IR');

	const [isLoading, setLoading] = useState(false);

	const EditZonesButton = useCallback(() => {
		const changeZone = () => {
			setSelectPolygonsMenu('editPolygon');
			setIsEditPolygon(!isEditPolygon);
			setSelectIdPolygon(selectPolygon.id);
		};

		const createNewZone = () => {
			setSelectPolygonsMenu('createPolygon');
			setIsCreatePolygon(!isCreatePolygon);
		};

		const contentButton = {
			true: (
				<Button className={styles.save} onClick={changeZone}>
					Редактировать зону
				</Button>
			),
			false: (
				<Button className={styles.save} onClick={createNewZone}>
					Создать зону
				</Button>
			),
		};

		return <>{contentButton[currentPolygonNum !== -1 && !!polygons.length]}</>;
	}, [currentPolygonNum, polygons, selectPresetId]);

	useEffect(() => {
		if (!isCreatePolygon && !isEditPolygon) return;

		const areas = new Polygons(canvas);

		if (currentPolygonNum !== -1) {
			areas.setCurPolygon(currentPolygonNum);
			areas.polygonSelection();
		}

		setTempAreas(areas);

		return () => {
			setIsCreatePolygon(false);
			setIsEditPolygon(false);
		};
	}, [selectDeviceId, selectPresetId, canvasState.canvas, isCreatePolygon, isEditPolygon]);

	useEffect(() => {
		if (!selectPolygon) return;

		setNameEditPolygon(selectPolygon.getName());
		setMinTempEditPolygon(selectPolygon.getMin());
		setMaxTempEditPolygon(selectPolygon.getMax());
	}, [selectPolygon]);

	const handleCreatePolygon = () => {
		const { canvas, saveDataTest, setAllPolygons, setIsCreatePolygon, setCurrentPolygonNum } = canvasState;

		if (!tempAreas.polygons.length) return;

		setLoading(true);

		const newPolygons = tempAreas.polygons
			.filter(({ id }) => {
				const index = saveDataTest?.[selectDeviceId]?.[selectPresetId]?.findIndex((tPol) => tPol.id === id);

				return index === -1;
			})
			.map((polygon) => ({
				preset: selectPresetId,
				name: nameNewPolygon,
				type_zone: typeNewPolygon,
				temp_max: +maxTempNewPolygon,
				temp_min: +minTempNewPolygon,
				irPoints: JSON.parse(JSON.stringify(polygon.getPoints())),
				startSize: polygon.getStartSize(),
				analytic_points: JSON.parse(JSON.stringify(polygon.getPoints())),
			}));

		newPolygons[0].irPoints.forEach((point) => {
			const { newSize } = canvasState.canvasReSize;
			const FW = 1920;
			const FH = 1080;

			const NW = newSize.width;
			const NH = newSize.height;

			point.x = Math.round((point.x / NW) * FW);
			point.y = Math.round((point.y / NH) * FH);
		});

		newPolygons[0].analytic_points.forEach((point) => {
			const { newSize } = canvasState.canvasReSize;

			const NW = newSize.width;
			const NH = newSize.height;

			point.x = Math.ceil(point.x / (NW / 32).toFixed(2));
			point.y = Math.ceil(point.y / (NH / 24).toFixed(2));
		});

		const url = `${connectState.linkPolygons}${selectDeviceId}/createzone/`;

		let isError = false;

		sendPolygon(url, newPolygons[0]);
		changeUpdateData();
		setTempAreas(new Polygons(canvas));

		if (isError) return;

		setTimeout(() => {
			setLoading(false);
			setAllPolygons(selectDeviceId, selectPresetId, tempAreas.polygons);
			setSelectPolygonsMenu('listPolygons');
			setIsCreatePolygon(false);
			setCurrentPolygonNum(-1);
			setNameNewPolygon('');
			setTypeNewPolygon('');
			setMinTempNewPolygon('');
			setMaxTempNewPolygon('');
		}, 500);
	};

	const handleEditPolygon = () => {
		const editPolygon = tempAreas.polygons.find(({ id }) => id === selectIdPolygon);

		setLoading(true);

		const resultEditPolygon = {
			preset: selectPresetId,
			name: nameEditPolygon,
			type_zone: typeEditPolygon,
			temp_max: +maxTempEditPolygon,
			temp_min: +minTempEditPolygon,
			irPoints: JSON.parse(JSON.stringify(editPolygon.getPoints())),
			startSize: editPolygon.getStartSize(),
			analytic_points: JSON.parse(JSON.stringify(editPolygon.getPoints())),
		};

		resultEditPolygon.irPoints.forEach((point) => {
			const { newSize } = canvasState.canvasReSize;
			const FW = 1920;
			const FH = 1080;

			const NW = newSize.width;
			const NH = newSize.height;

			point.x = Math.round((point.x / NW) * FW);
			point.y = Math.round((point.y / NH) * FH);
		});

		resultEditPolygon.analytic_points.forEach((point) => {
			const { newSize } = canvasState.canvasReSize;

			const NW = newSize.width;
			const NH = newSize.height;

			point.x = Math.ceil(point.x / (NW / 32).toFixed(2));
			point.y = Math.ceil(point.y / (NH / 24).toFixed(2));
		});

		const url = `${connectState.linkPolygons}${selectDeviceId}/updatezone/${editPolygon.id}/`;

		setTimeout(() => {
			setLoading(false);
			updatePolygon(url, resultEditPolygon);
			setIsEditPolygon(false);
			setSelectPolygonsMenu('listPolygons');
			changeUpdateData();
		}, 2000);
	};

	const sendPolygon = (url, polygon) => {
		let isError = false;
		apiPost(url, polygon).then(({ data, error }) => {
			console.log(data);
			isError = error.isError;
		});

		return isError;
	};

	const updatePolygon = (url, polygon) => {
		let isError = false;
		apiPatch(url, polygon).then(({ data, error }) => {
			console.log(data);
			isError = error.isError;
		});

		return isError;
	};

	const handleCancel = () => {
		const { canvas, setIsCreatePolygon, setIsEditPolygon, setCurrentPolygonNum } = canvasState;

		setNameNewPolygon('');
		setTypeNewPolygon('');
		setMinTempNewPolygon('');
		setMaxTempNewPolygon('');

		setSelectPolygonsMenu('listPolygons');

		setIsCreatePolygon(false);
		setIsEditPolygon(false);
		setCurrentPolygonNum(-1);
		setTempAreas(new Polygons(canvas));
	};

	const handleFreeClick = (event) => {
		const { setCurrentPolygonNum } = canvasState;

		if (listRef.current && !listRef.current.contains(event.target)) {
			setCurrentPolygonNum(-1);
		}
	};

	const polygonsMenu = {
		listPolygons: <ListMenu polygons={polygons} listRef={listRef} />,
		createPolygon: (
			<CreateMenu
				isLoading={isLoading}
				name={nameNewPolygon}
				minTemp={minTempNewPolygon}
				maxTemp={maxTempNewPolygon}
				setName={setNameNewPolygon}
				setMin={setMinTempNewPolygon}
				setMax={setMaxTempNewPolygon}
				setType={setTypeNewPolygon}
				handleDisable={setCreateDisable}
			/>
		),
		editPolygon: (
			<EditMenu
				isLoading={isLoading}
				name={nameEditPolygon}
				minTemp={minTempEditPolygon}
				maxTemp={maxTempEditPolygon}
				setName={setNameEditPolygon}
				setMin={setMinTempEditPolygon}
				setMax={setMaxTempEditPolygon}
				setType={setTypeEditPolygon}
			/>
		),
	};

	const buttonBottom = {
		listPolygons: userRole !== 'spotoperator' && <EditZonesButton />,
		createPolygon: (
			<ConfirmButtons
				fontSize="18px"
				isDisabled={!isCreateDisable || !isOneReadyPolygon}
				handleOk={handleCreatePolygon}
				handleCancel={handleCancel}
			/>
		),
		editPolygon: <ConfirmButtons fontSize="18px" handleOk={handleEditPolygon} handleCancel={handleCancel} />,
	};

	return (
		<div className={styles.polygonMenu}>
			{canvasState.isShowCanvas ? (
				<>
					<div style={{ height: 'calc(100% - 74px)' }} onClick={handleFreeClick}>
						{polygonsMenu[selectPolygonsMenu]}
					</div>

					<div className={styles.confirmButtonsPos}>{buttonBottom[selectPolygonsMenu]}</div>
				</>
			) : (
				<div style={{ height: 'calc(100% - 74px)' }}>
					<EmptyMessage title={'Пресет не выбран'} subTitle={'Редактирование запрещено'} />
				</div>
			)}
		</div>
	);
});

export default PolygonMenu;
