import {Notifications, NotificationsServer, PrepareNotifications} from './prepareNotifications.interface';


export const prepareNotifications = (data: PrepareNotifications, isControl: boolean) => {
	const { count, next, previous, results } = data;

	const pagination = { next, previous, count };

	const page = next
		? parseInt(next.split('page=').pop() || '0') - 2
		: previous
			? parseInt(previous.split('page=').pop() || '0') + 2
			: 1;

	const notifications: Notifications[] = results.map((item, index) => {
		const { id, spot, id_psw, cameraName, eventId, message, isReaded, datetime } = item;
		const dateObj = new Date(datetime);

		const time = dateObj.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'});
		const day = dateObj.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
		const counter = 10 * page + (index + 1);

		const newDate = `Дата ${day} Время ${time}`;
		return {
			id,
			spot,
			id_psw,
			cameraName,
			eventId,
			message,
			datetime: newDate,
			isReaded,
			counter,
			isControlPage: isControl
		};
	});

	return {notifications, pagination};
};


export const prepareNotification = (data: NotificationsServer, counter: number, isControl: boolean): Notifications => {
	const { id, spot, id_psw, cameraName, eventId, message, isReaded, datetime } = data;
	const dateObj = new Date(datetime);

	const time = dateObj.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'});
	const day = dateObj.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});

	const newDate = `Дата ${day} Время ${time}`;
	return {
		id,
		spot,
		id_psw,
		cameraName,
		eventId,
		message,
		datetime: newDate,
		isReaded,
		counter: counter + 1,
		isControlPage: isControl
	};

};
