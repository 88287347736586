import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
	AuthData,
	AutorizeStateUserRoles as UserRoles,
	UpdateData,
	Group,
	UserDataFix,
} from './interfaces';
import { PersonInfoPostData as PostData } from '../../components/Account/ContentModal/ContentForManager/PersonInfo/PersonInfo.interface';
import { AccessPlace, AccountPlace } from '../placeState/interfaces';
import { TechUser, UserAccess } from '../../api/AccountPage/prepareAccountData.interface';
import connectState from '../connectState/connectState';


const getIsAuthorize = (): boolean => {
	const authData = localStorage.getItem('isAuthorize');

	if (authData !== null) {
		const parsedAuthData: AuthData = JSON.parse(authData);
		return parsedAuthData.isAuthorize;
	}
	return false;
};

class AutorizeState {
	demoUser = {
		email: 'testLogIn@testLogIn.com',
		password: 'testPassword',
	};

	errorText = 401;

	updateData: UpdateData = {
		isDevices: false,
		isUser: false,
		isUsersActions: false,
		isGetAccess: false,
		isNotifications: false,
		isGroups: false,
		isUsers: false,
		isPlaces: false,
	};

	loadingTables: UpdateData = {
		isDevices: false,
		isUser: false,
		isUsersActions: false,
		isGetAccess: false,
		isNotifications: false,
		isGroups: false,
		isUsers: false,
		isPlaces: false,
	};


	emptyData: PostData = {
		id: -1,
		email: '',
		password : '',
		phone: '',
		first_name: '',
		last_name:  '',
		grouprole: '',
		group: ''
	};

	postData: PostData = this.emptyData;

	authData = localStorage.getItem('AuthStore');

	filterLinks = {
		cameraLink: connectState.userCameras,
		userLink: connectState.getAllUsersLink
	};
	
	user: UserDataFix = {
		id: 0,

		firstName: '',
		lastName: '',
		email: '',
		group: {
			id: '',
			name: ''
		},
		grouprole: 'groupuser',
		phone: '',
		image: '',
		role: 'spotoperator',
		ruRole: 'оператор',
		ruGroupRole: ''
	};

	allGroups: Group[] = [];

	users: UserDataFix[] = [];
	techUsers: TechUser[] = [];
	selectedUserID = -1;
	
	isAuthorize = false;
	token = '';
	userRole: keyof UserRoles = 'spotoperator';

	accountPlaces: AccountPlace[] = [];
	accessPlaces: AccessPlace[] = [];

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: 'AuthStore',
			properties: ['isAuthorize', 'token', 'userRole'],
			storage: window.localStorage,
		});

		this.isAuthorize = getIsAuthorize();
	}

	setIsAutorize = (isAuthorize = false) => {
		this.isAuthorize = isAuthorize;
	};
	
	setUserData = (userData: UserDataFix) => {
		this.user = userData;
	};

	setUsersData = (usersData: UserDataFix[]) => {
		this.users = usersData;
	};

	setTechUsers = (usersData: TechUser[]) => {
		this.techUsers = usersData;
	};

	setAccountPlaces = (newPlaces: AccountPlace[]) => {
		this.accountPlaces = newPlaces;
	};

	setAddAccessPlaces = (newPlaces: UserAccess[]) => {
		const spotAdminIds = newPlaces
			.filter(item => item.role === 'spotadmin')
			.map(item => item.spot);
		this.accountPlaces.map((place) => {
			if (spotAdminIds.includes(place.id)) {
				place.isCanEdit = true;
			}
		});
	};

	setAccessPlaces = (newPlaces: AccessPlace[]) => {
		this.accessPlaces = newPlaces;
	};

	setToken = (token: string) => {
		this.token = token;
	};

	setUserRole = (role: keyof UserRoles) => {
		this.userRole = role;
	};

	setPostData = (postData: PostData) => {
		this.postData = postData;
	};

	setSelectedUserID = (id: number) => {
		this.selectedUserID = id;
	};
	
	setUpdateData = (item: keyof UpdateData) => {
		const updatedData: UpdateData = {...this.updateData};
		updatedData[item] = !updatedData[item];
		this.updateData = updatedData;
	};

	setLoadingTables = (item: keyof UpdateData, value: boolean) => {
		const updatedData: UpdateData = {...this.loadingTables};
		updatedData[item] = value;
		this.loadingTables = updatedData;
	};


	checkAuth = (errorText: number | null) => {
		const isAuthError = errorText === 401;
		this.isAuthorize = !isAuthError;
	};

	setAllGroups = (groups: Group[]) => {
		this.allGroups = groups;
	};

	setCameraFilterLink = (link: string) => {
		this.filterLinks.cameraLink = link;
		this.updateData.isDevices = !this.updateData.isDevices;
	};

	setUsersFilterLink = (link: string) => {
		this.filterLinks.userLink = link;
		this.updateData.isUsers = !this.updateData.isUsers;
	};
}

const autorizeState = new AutorizeState();
export default autorizeState;
