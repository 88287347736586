import React, {useState} from 'react';
import styles from './ControlTypeChartIcons.module.scss';
import { ControlTypeChartIconsProps as Props } from './ControlTypeChartIcons.interface';
import {ClickAwayListener, IconButton} from '@mui/material';
import ChartLineIcon from '@assets/Icons/Dashboard/ChartLineIcon';
import ChartBarIcon from '@assets/Icons/Dashboard/ChartBarIcon';
import SettingsEventIcon from '@assets/Icons/ControlPage/SettingsEventIcon';

const ControlTypeChartIcons: React.FC<Props> = ({ isFirstTypeChart, setFirstTypeChart, typeMergeEvents, setTypeMergeEvents, visible }) => {
	const [selected, setSelected] = useState<number | null>(0);
	const [hovered, setHovered] = useState<number | null>(null);
	const [isSettings, setIsSettings] = useState(false);

	const typesMerge = [1, 2, 3];
	const chartsVisible = visible === undefined ? true : visible;

	const handleSetBarChart = () => {
		setFirstTypeChart(false); 
		setTypeMergeEvents(1);
	};

	const handleSetLineChart = () => {
		setFirstTypeChart(true);
		setTypeMergeEvents(0);
	};
	
	const icons = [
		{
			id: 0,
			name: 'Line',
			icon: (fill = '#D2D2D2') => <ChartLineIcon fill={fill} />,
			action: handleSetLineChart,
			visible: chartsVisible,
		},
		{
			id: 1,
			name: 'Bar',
			icon: (fill = '#D2D2D2') => <ChartBarIcon fill={fill} />,
			action: handleSetBarChart,
			visible: chartsVisible,
		},
		{
			id: 2,
			name: 'Settings',
			icon: (fill = '#D2D2D2') => <SettingsEventIcon fill={fill} width={24} height={24} />,
			action: () => setIsSettings(!isSettings),
			visible: true,
		}
	];

	const handleSelectedItem = (index: number) => {
		const tSelected = selected === index ? null : index;

		setSelected(tSelected);
		icons[index].action();
	};

	const handleHoveredItem = (index: number) => {
		const hoveredIndex = selected === index ? null : index;
		setHovered(hoveredIndex);
	};

	return (
		<div className={styles.setIcons}>
			{icons.map(({ icon }, index) => {
				if (!icons[index].visible) return;

				const selectedChart = isFirstTypeChart ? 0 : 1;
				const isSelected = selected === index || selectedChart === index;
				const isHovered = hovered === index;
				const isActiveItem = isSelected || isHovered;

				const color = isActiveItem ? '#00C2FD' : '#D2D2D2';

				return (
					<IconButton
						key={`ChartsIcon-Icon-${index}`}
						onMouseEnter={() => handleHoveredItem(index)}
						onMouseLeave={() => setHovered(null)}
						onClick={() => handleSelectedItem(index)}
					>
						{icon(color)}
					</IconButton>
				);
			})}

			{isSettings &&
				<ClickAwayListener onClickAway={() => setIsSettings(false)}>
					<div className={styles.settings}>
						<p>Объединить: </p>
						{typesMerge.map((timeType, index) => {
							const isActive = typeMergeEvents === timeType;
							const isUnButton = isFirstTypeChart || visible === undefined;

							return(
								<p
									key={index}
									className={`${isActive && styles.activeType }`}
									onClick={() => setTypeMergeEvents(!isActive ? timeType : isUnButton ? 0 : timeType)}
								>
									{timeType} ч
								</p>
							);
						})}
					</div>
				</ClickAwayListener>
			}
		</div>
	);
};

export default ControlTypeChartIcons;