import React, { useEffect, useState } from 'react';
import styles from './FieldSearchPlaces.module.scss';
import { observer } from 'mobx-react-lite';
import PlacesMenu from './PlacesMenu/PlacesMenu';
import { useLocation } from 'react-router-dom';
import PresetsMenu from './PresetsMenu/PresetsMenu';
import {PathNameFieldSearchPlaces, FieldSearchPlacesProps as Props} from './FieldSearchPlaces.interface';

const FieldSearchPlaces: React.FC<Props> = observer(({ isDashboardPage = false, isMapPage = false, isDeviceMap = false }) => {
	const { pathname } = useLocation();
	const [isPresetsMenu, setPresetsMenu] = useState(false);

	const styleContainer = `${styles.container} ${(isMapPage || isDeviceMap) && styles.mapContainer}`;

	const presetsOnPage: PathNameFieldSearchPlaces = {
		'/analitica': true,
		'/translation': true,
		'/account': false,
		'/dashboard': false,
		'/control': false,
	};

	useEffect(() => {
		setPresetsMenu(presetsOnPage[`${pathname}`]);
	}, [pathname]);

	return (
		<div className={styleContainer}>
			<PlacesMenu isDashboardPage={isDashboardPage} isMapPage={isMapPage} isDeviceMap={isDeviceMap} />

			{isPresetsMenu && <PresetsMenu />}
		</div>
	);
});

export default FieldSearchPlaces;
