import React, {useEffect, useState} from 'react';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import {addLocale} from 'primereact/api';
import {dateToEnDate} from '@helpers/functions/helpFunctions';
import dashboardState from '@store/dashboardState/dashboardState';

addLocale('ru', {
	firstDayOfWeek: 1,
	dayNamesMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
	monthNames: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],
	monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
});

const DownloadReport: React.FC = () => {
	const [dates, setDates] = useState<Date[]>([]);
	const [isClickCalendar, setClickCalendar] = useState(false);

	useEffect(() => {
		if(!isClickCalendar) return;

		const { setReportDates } = dashboardState;

		const start = dateToEnDate(dates[0]);
		const end = dates[1] ? dateToEnDate(dates[1]) : dateToEnDate(dates[0]);

		setReportDates(start, 0);
		setReportDates(end, 1);
	}, [dates]);
	
	const handleChangeDates = (e: CalendarChangeEvent) => {
		setDates(e.value as Date[]);
		setClickCalendar(true);
	};

	return (
		<Calendar
			value={dates}
			onChange={handleChangeDates}
			selectionMode='range'
			readOnlyInput
			locale='ru'
			dateFormat='dd.mm.yy'
			showIcon
		/>
	);
};

export default DownloadReport;