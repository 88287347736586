import React from 'react';
import styles from './MapDevices.module.scss';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { renderToString } from 'react-dom/server';
import { LatLngTuple, Icon } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { MarkerDeviceItemProps as PropsMarker } from './DeviceMarkers.interface';
import mapState from '@store/mapState/mapState';
import devicesState from '@store/devicesState/devicesState';
import notificationsState from '@store/notificationsState/notificationsState';
import PlaceIcon from '@assets/Icons/Map/PlaceIcon';

const DeviceMarkers: React.FC = observer(() => {
	const { popupPosition, namePopup, setZeroPopupPosition, setPopupPosition, setCenterMap, setNamePopup } = mapState;
	const { devices, setSelectedDevices } = devicesState;
	const { notifications } = notificationsState;

	const svgString = (color: string) => renderToString(<PlaceIcon fill={color} />);

	const itemIcon = (color: string) =>
		new Icon({
			iconUrl: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString(color))}`,
			iconSize: [65, 65],
		});

	const handlePositionDevice = (id: string, name: string, center: LatLngTuple) => {
		const delayTimeMS = 300;

		setSelectedDevices(id);
		setCenterMap(center);

		setNamePopup(name);
		setZeroPopupPosition();
		setTimeout(() => {
			setPopupPosition(center);
		}, delayTimeMS);
	};

	const findPlaceEvent = (id: string): boolean => {
		const index = notifications.findIndex((notif) => notif.id_psw === id);
		return index !== -1;
	};

	const createDeviceMarkerOnMap = ({ id, name, coords, isSelected }: PropsMarker) => {
		const [ppLat, ppLng] = popupPosition as LatLngTuple;
		const isActivePopPos = Boolean(ppLat && ppLng);
		const key = `Marker-Device-Item-${id}-${name}`;

		const selectedColor = {
			true: '#00C2FD',
			false: '#939393',
		};
		const eventsColor = {
			true: '#F6BA1B',
			false: selectedColor[`${isSelected as boolean}`],
		};

		const isEvent = findPlaceEvent(id);
		const iconColor = eventsColor[`${isEvent}`];

		return (
			<div key={key}>
				<Marker
					icon={itemIcon(iconColor)}
					position={coords}
					eventHandlers={{
						click: () => {
							handlePositionDevice(id, name, coords);
						},
					}}
				/>

				{isActivePopPos && (
					<Popup position={popupPosition}>
						<div className={styles.popupContainer}>
							<div style={{ display: 'flex', justifyContent: 'space-between'}}>
								{namePopup}
								<div style={{width: '15px'}}>
									{isEvent && <div className={styles.listItemIcon} />}
								</div>
							</div>

							<div className={styles.links}>
								<Link to={'/control'} className={styles.link}>События</Link>
								<Link to={'/translation'} className={styles.link}>Трансляция</Link>
							</div>
						</div>
					</Popup>
				)}
			</div>
		);
	};

	return <div>{devices.map(createDeviceMarkerOnMap)}</div>;
});

export default DeviceMarkers;
