import React, {useEffect, useState} from 'react';
import MainButton from '../../helpers/components/MainButton/MainButton';
import styles from './Account.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { AdminIcon } from '../../assets/Icons/LeftMenu/index';
import PdfFileIcon from '../../assets/Icons/LeftMenu/PdfFileIcon';
import PdfFile from './PdfFile/PdfFile';
import Modal from '@mui/material/Modal';
import notificationsState from '../../store/notificationsState/notificationsState';
import NotificationIcon from '../../assets/Icons/Account/NotificationIcon';
import {styled} from '@mui/material';
import Badge from '@mui/material/Badge';
import NotificationsItems from './Notifications/NotificationsItems';
import { observer } from 'mobx-react-lite';
import connectState from '../../store/connectState/connectState';
import autorizeState from '../../store/accoutState/autorizeState';
import {apiGet, apiPost} from '../../api/api';
import { prepareNotification, prepareNotifications } from '../../api/Notifications/prepareNotifications';
import { PrepareNotifications, Notifications,  NotificationsServer } from '../../api/Notifications/prepareNotifications.interface';
import DoorIcon from '../../assets/Icons/Account/DoorIcon';

const CustomBadge = styled(Badge)({
	'& .MuiBadge-badge': {
		color: 'white',
		backgroundColor: '#00C2FD',
	},
});

const AccountMenu: React.FC = observer(() => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { getNotificationsLink, readNotificationLink, postLogoutLink } = connectState;
	const { setNotifications, addNotification, counter, notifications } = notificationsState;
	const { updateData, setUpdateData } = autorizeState;
	const isAccount = pathname === '/account';
	const isControl = pathname === '/control';
	const fill = isAccount ? '#00C2FD' : '#D2D2D2';

	const [isPdf, setPdf] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [isReconnect, setIsReconnect] = useState(false);
	const [count, setCount] = useState<number>(counter);

	const fillPdfButton = isPdf ? '#00C2FD' : '#D2D2D2';

	const fillNotifIcon = isOpen ? '#00C2FD' : '#D2D2D2';

	useEffect(() => {
		const controller = new AbortController();

		if (!getNotificationsLink) return;

		getNotifications(getNotificationsLink, controller);

		return () => controller.abort();
	}, [getNotificationsLink, updateData.isNotifications]);


	useEffect(() => {
		setCount(counter);

		if (!isControl) return;
		handleSelectItem();
	},[notifications, counter]);

	const handleSelectItem = () => {
		const notReaded = notifications.filter(notification => notification.isReaded);
		notReaded.filter(notification => notification.isControlPage).forEach(notification => {
			const url = `${readNotificationLink}${notification.id}/read/`;
			readNotification(url);
		});
	};

	const readNotification = (url: string) => {
		apiPost(url, {}, {}).then(({ error }) => {
			const { isError } = error;
			if (isError) return;
		});
	};

	const getNotifications = (link: string, controller: AbortController) => {
		apiGet(link, {}, controller).then(({ data, error }) => {
			const { isError } = error;
			if (isError) return;

			const dataNotifications = prepareNotifications(data as PrepareNotifications, isControl);
			const { pagination, notifications } = dataNotifications;

			setCount(pagination.count);
			setNotifications(notifications, pagination.count);
		});
	};

	useEffect(() => {
		let socket: WebSocket | string = '';
		const intervalId = setInterval(() => {
			try {
				const { wsLinkNotifications } = connectState;
				const { token } = autorizeState;

				socket = new WebSocket(`${wsLinkNotifications}/?token=${token}`);

				socket.onopen = () => socketOpen(socket as WebSocket);
				socket.onmessage = (e) => socketMessage(e);
				socket.onerror = (e) => socketError(e);
				socket.onclose = (e) => socketClose(e);

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 2000);

		return () => {
			try {
				if (socket instanceof WebSocket) socket.close();
			} catch (error) {
				console.error(error);
			}
			clearInterval(intervalId);
		};
	}, [isReconnect]);

	// eslint-disable-next-line
	const socketOpen = (socket: WebSocket) => {};

	const socketMessage = (event: MessageEvent) => {
		try {
			const { data: eventSocket } = JSON.parse(event.data);
			if (!eventSocket) return;
			const newNotification = prepareNotification(eventSocket as NotificationsServer, count, isControl);
			addNotification(newNotification as Notifications);
			setUpdateData('isNotifications');

		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const socketError = (error: Event) => {
		return;
	};

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';

		if (event.wasClean) console.log(closeConnectMsg);
		else {
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const handleShowPdf = () => {
		setPdf((prevShowPdf) => !prevShowPdf);
	};

	const handleStopShowPdf = () => {
		setPdf(false);
	};

	const closeNotification = () => {
		setOpen(false);
	};

	const handleClickLogout = () => {
		const { setIsAutorize, setToken, user } = autorizeState;
		const { email } = user;
		const logoutData = { email, password: '' };

		apiPost(postLogoutLink, logoutData, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			setIsAutorize(false);
			setToken('');
		});
	};

	return (
		<div className={styles.main}>
			<div className={`${styles.container} ${isAccount && styles.threeIcons}`}>
				{isAccount &&
					(
						<MainButton onClick={() => handleShowPdf()}>
							<PdfFileIcon fill={fillPdfButton}/>
						</MainButton>
					)
				}
				<MainButton onClick={() => setOpen(true)}>
					<CustomBadge
						badgeContent={count}
						max={10}
					>
						<NotificationIcon fill={fillNotifIcon}/>
					</CustomBadge>
				</MainButton>

				<MainButton onClick={() => navigate('/account')}>
					<AdminIcon fill={fill} />
				</MainButton>
				{isAccount &&
					(
						<MainButton onClick={() => handleClickLogout()}>
							<DoorIcon />
						</MainButton>
					)
				}

				<Modal
					className={styles.pdf}
					open={isPdf}
					onClose={handleStopShowPdf}
				>
					<div>
						<PdfFile handleShowPdf={handleShowPdf}/>
					</div>
				</Modal>
			</div>
			{isOpen && <NotificationsItems closeNotification={closeNotification}/>}
		</div>
	);
});

export default AccountMenu;
