import { useRef } from 'react';

interface ClickPosition {
	x: number | null;
	y: number | null;
}

interface MouseProps {
	clickPosition: ClickPosition;
	setClickPosition: (point: ClickPosition) => void;
}

const MouseControlCameraView: React.FC<MouseProps> = ({ clickPosition, setClickPosition }) => {
	const circleRef = useRef<HTMLDivElement>(null);

	const handleClick = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		
		const rect = target.getBoundingClientRect();
		const x = Math.abs(event.clientX - rect.left - target.offsetLeft);
		const y = Math.abs(event.clientY - rect.top - target.offsetTop);

		setClickPosition({ x, y });
	};

	return (
		<div
			onClick={handleClick}
			style={{ position: 'relative', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.2)' }}
		>
			{clickPosition.x !== null && clickPosition.y !== null && (
				<div
					ref={circleRef}
					style={{
						width: 10,
						height: 10,

						position: 'absolute',
						left: clickPosition.x - 5, 	// вычитаем половину диаметра круга (5 пикселей) для выравнивания по центру
						top: clickPosition.y - 5, 	// вычитаем половину диаметра круга (5 пикселей) для выравнивания по центру
					
						borderRadius: '50%',
						backgroundColor: '#00c2fd',

						boxShadow: '0px 0px 10px #00c2fd',
						// transform: `translate(${circleRef?.current?.getBoundingClientRect().left}px, ${
						// 	circleRef?.current?.getBoundingClientRect().top
						// }px)`, // корректируем позицию кругового элемента
					}}
				/>
			)}
		</div>
	);
};

export default MouseControlCameraView;
