import React, { FC, useState, useEffect } from 'react';
import styles from '../ModalUpdatePresets.module.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SingleValue } from 'react-select';
import { PropsCreate, TypeNumber } from '../PresetsModals.interface';
import { apiGet } from '@api/api';
import { customStyles } from '@helpers/components/SelectStyle/SelectStyles';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import presetState from '@store/presetState/presetState';
import translationState from '@store/translationState/translationState';
import connectState from '@store/connectState/connectState';
import { observer } from 'mobx-react-lite';

const CreateModal: FC<PropsCreate> = observer(({ idDevice, placeName, deviceName, handleDiscardButton }) => {
	const { getFreePresets } = connectState;
	const { setIsSavePreset } = presetState;
	const { setNewPreset } = translationState;

	const [isFocused, setIsFocused] = useState(false);
	const [freeNumber, setFreeNumber] = useState<TypeNumber[]>([]);
	const [isDisableButton, setDisableButton] = useState(true);
	const [valueName, setValueName] = useState('');
	const [selectNum, setSelectNum] = useState<number | null>(null);
	const [minTemp, setMinTemp] = useState<number | null>(null);
	const [maxTemp, setMaxTemp] = useState<number | null>(null);
	const [energy/*, setEnergy*/] = useState<number | null>(10000);
	const [corr/*, setCorr*/] = useState<number | null>(10000);
	const [frequency/*, setFrequency*/] = useState<number | null>(10000);

	useEffect(() => {
		if (!idDevice) return;

		setNewPreset({
			id_psw: idDevice,
			name: valueName,
			preset_number: selectNum,
			preset_max_temp: maxTemp,
			preset_min_temp: minTemp,
			data_sound_corr: corr,
			data_sound_energy: energy,
			data_sound_frequency: frequency,
		});
		
		const resultDisable = [valueName, selectNum, maxTemp, minTemp].every(value => value !== null && value !== '');

		setDisableButton(!resultDisable);
	}, [idDevice, valueName, selectNum, minTemp, maxTemp, corr, energy, frequency]);

	useEffect(() => {
		const controller = new AbortController();
		
		getFreePreset(`${getFreePresets}${idDevice}/getshortspresets/`, controller);

		return () => controller.abort();
	}, []);

	const getFreePreset = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const listNumbers: TypeNumber[] = [];
			let idNum = 0;
			const typedData = data as number[];

			typedData.map((number) => {
				listNumbers.push({ id: idNum++, value: number, label: number });
			});

			setFreeNumber(listNumbers);
		});
	};

	const handleSelectNum = (e: SingleValue<TypeNumber>) => {
		if (!e) return;
		setSelectNum(e.value);
	};

	const handleSavePreset = () => {
		setIsSavePreset();
		handleDiscardButton();
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalContent}>Создание пресета</div>

			<div className={styles.search}>
				<input className={styles.searchInput} value={placeName} disabled />
			</div>

			<div className={styles.search}>
				<input className={styles.searchInput} value={deviceName} disabled />
			</div>

			<Select
				styles={customStyles}
				placeholder="Номер пресета"
				defaultValue={freeNumber[0]}
				options={freeNumber}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				components={makeAnimated}
				onChange={handleSelectNum}
			/>

			<div className={styles.search}>
				<input
					className={styles.searchInput}
					placeholder="Название пресета"
					onChange={(e) => setValueName(e.target.value)}
					autoFocus={isFocused}
					onFocus={() => setIsFocused(true)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder="Максимальная температура ℃"
					onChange={(e) => setMaxTemp(e.target.value ? +e.target.value : null)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder="Минимальная температура ℃"
					onChange={(e) => setMinTemp(e.target.value ? +e.target.value : null)}
				/>
			</div>

			<ConfirmButtons
				nameOk="Сохранить"
				nameNo="Отмена"
				isDisabled={isDisableButton}
				handleOk={handleSavePreset}
				handleCancel={handleDiscardButton}
			/>
		</div>
	);
});

export default CreateModal;
