import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './TranslationPage.module.scss';
import { observer } from 'mobx-react-lite';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewBox from '@components/Translation/ViewBox/ViewBox';
import { ClickPosition } from '@components/Translation/ViewBox/ViewBox.interface';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import Controller from '@components/Translation/FourLeafCloverControl/Controller/Controller';
import SettingsEvent from '@components/Translation/ControlIconsBottom/SettingsEvent/SettingsEvent';
import { TranslationPageProps as Props, WebSocketCommands } from '../Pages.interface';
import { patrolTypes } from '@helpers/defaultsData/defaultsData';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import FieldSearchPlaces from '@components/FieldSearchPlaces/FieldSearchPlaces';
import connectState from '@store/connectState/connectState';
import { apiGet } from '@api/api';
import { checkPageReady } from '@helpers/functions/loading/translationLoading';
import { prepareMapDevices, prepareMapPlaces } from '@api/MapPage/prepareMapData';
import { PrepareDevices, DataPlace } from '@api/MapPage/prepareMapData.interface';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import FourLeafCloverControl from '@components/Translation/FourLeafCloverControl/FourLeafCloverControl';
import KeyboardControl from '@components/Translation/FourLeafCloverControl/KeyboardControl/KeyboardControl';
import MouseControl from '@components/Translation/FourLeafCloverControl/MouseControl/MouseControl';
import translationState from '@store/translationState/translationState';
import presetState from '@store/presetState/presetState';
import ControlIconsBottom from '@components/Translation/ControlIconsBottom/ControlIconsBottom';
import { prepareControlEvents, prepareEvent } from '@api/Control/prepareControlData';
import { RawDataEvents } from '@api/Control/prepareControlData.interface';
import canvasState from '@store/canvasState/canvasState';
import useWindowDimensions from '@helpers/functions/useWindowDimensions';
import Polygons from '@components/Translation/Canvas/Polygons';
import Modal from '@mui/material/Modal';
import ParamsPatrolModal from '@components/Translation/ControlIconsBottom/ParamsPatrolModal/ParamsPatrolModal';
import { TranslationStateTypeContnetModal as TypeContentModal } from '@store/translationState/interfaces';
import DeleteModalArea from '@components/Translation/Canvas/DeleteModalArea/DeleteModalArea';
import {
	prepareCameraParams,
	preparePolygonsData,
	preparePresetsData,
} from '@api/Translation/prepareTranslationData';
import { PrepareCamParams, PreparePreset } from '@api/Translation/prepareTranslationData.interface';
import autorizeState from '@store/accoutState/autorizeState';
import { Box } from '@mui/material';
import FireIcon from '@assets/Icons/ControlPage/FireIcon';
import { PreparePolygon } from '@store/presetState/interfaces';
import TempIcon from '@assets/Icons/ControlPage/TempIcon';
import CircleIcon from '@assets/Icons/Translation/CircleIcon';
import { ResultEventData } from '@store/eventState/interface';

const TranslationPage: React.FC<Props> = observer(({ metaTags }) => {
	const navigate = useNavigate();
	const contentRef = useRef<HTMLDivElement | null>(null);

	const { getPlacesLink, getDevicesLink, getEventsLink, getCameraInfo, wsLink, linkPolygons } = connectState;
	const { selectPlaceId, setPlaces } = placeState;
	const { selectDeviceId, isUpdateData, setDevices } = devicesState;
	const {
		isLiveStream,
		isMouseControl,
		isVisibleTempGrid,
		isVisibleController,
		isOpenModal,
		newPreset,
		isVisibleTemp,
		contentTypeModal,
		selectComandArrow,
		isGetEventPOLL,
		setOpenModal,
		setVisibleTempGrid,
		setVisibleTemp,
		setIsLiveStream,
	} = translationState;
	const { selectPresetId, isSavePreset, isDeletePreset, deletePreset, presets, isPatrol, patrolType, setPresets, setSelectPresetId, setSelectedPresets, setPatrolType } =
		presetState;
	const { isShowCanvas, isDeletePolygon, setCanvas, setCanvasVisible } = canvasState;
	const { checkAuth } = autorizeState;

	const [tempGrid, setTempGrid] = useState<number[][]>([]);
	const [imgBackground, setImgBackground] = useState('');
	const [isReconnect, setIsReconnect] = useState(false);
	const [socket, setSocket] = useState<WebSocket | null>(null);
	const [clickPosition, setClickPosition] = useState<ClickPosition>({ x: null, y: null });
	const [tCurrent, setTCurrent] = useState<number>(0);
	const [isVisibleMax, setVisibleMax] = useState(false);
	const [isUpdatePreset, setUpdatePreset] = useState(false);

	const [isFirstConnect, setFirstConnect] = useState(true);

	const getCameraLink = `${getCameraInfo}${selectDeviceId}`;
	const visibleControlsButton = !isMouseControl && !isShowCanvas;

	const { width, height } = useWindowDimensions();

	const changeW = width - 151;
	const changeH = height - 176;

	const newHeightCanvas = (9 / 16) * changeW;
	const newWidthCanvas = (16 / 9) * changeH;
	const compareHeight = newHeightCanvas < changeH;
	const boxSizeCanvas = { w: compareHeight ? changeW : newWidthCanvas, h: compareHeight ? newHeightCanvas : changeH };

	const contentModal: TypeContentModal = {
		DeleteArea: <DeleteModalArea />,
		PatrolParams: <ParamsPatrolModal />,
		SettingsEvent: <SettingsEvent />,
	};

	const maxTemp = useMemo(() => Math.max(...tempGrid.map((row) => Math.max(...row))), [tempGrid]);
	const minTemp = useMemo(() => Math.min(...tempGrid.map((row) => Math.min(...row))), [tempGrid]);

	useEffect(() => {
		return () => {
			try {
				if (socket instanceof WebSocket) socket.close();
			} catch (error) {
				console.error(error);
			}
		};
	}, [socket]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			const { w, h } = boxSizeCanvas;
			if (w !== 0) canvasState.setCanvasReSize(w * 0.55, h * 0.55);

			if (isShowCanvas) new Polygons(canvasState.canvas);
		}, 500);

		return () => clearTimeout(timeout);
	}, [width, height, changeW, isUpdateData]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!isShowCanvas) return;

			new Polygons(canvasState.canvas);
		}, 500);

		return () => clearTimeout(timeout);
	}, [isShowCanvas, selectDeviceId, selectPresetId, isUpdateData]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			try {
				const { token } = autorizeState;

				const linkConnect = `${wsLink}${selectDeviceId}/?token=${token}&page=camerapage`;

				const newSocket: WebSocket = new WebSocket(linkConnect);

				newSocket.onopen = () => {
					if (isFirstConnect) return;
					toast.success('Соединение установлено.', {
						autoClose: 2000,
						className: styles.toastMessage,
					});
				};

				newSocket.onmessage = (e) => socketMessage(e);

				newSocket.onerror = (e) => socketError(e);

				newSocket.onclose = (e) => socketClose(e);

				setSocket(newSocket);

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 1500);

		return () => {
			clearInterval(intervalId);
			setVisibleTempGrid(false);
			setVisibleTemp();
			setCanvasVisible(false);
			setCanvas(null);
		};
	}, [selectDeviceId, isReconnect, wsLink]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const messageSocket = {
			type: 'ptz_command',
			message: {
				command: 'MOVE',
				data: {
					pos: selectComandArrow,
				},
			},
		};
		socket.send(JSON.stringify(messageSocket));
	}, [selectComandArrow, socket]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const messageSocket = {
			type: 'ptz_command',
			message: {
				command: 'POLL',
				data: {
					pos: selectComandArrow,
				},
			},
		};
		socket.send(JSON.stringify(messageSocket));

		toast.success('Запрос отправлен.', {
			autoClose: 2000,
			className: styles.toastMessage,
		});
	}, [isGetEventPOLL, socket]);

	useEffect(() => {
		if (clickPosition.x === null || clickPosition.y === null) return;
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const point = calculatePosition(clickPosition.x, clickPosition.y);

		const messageSocket = {
			type: 'move_by_point',
			message: {
				command: 'MOVEPTZPOINT',
				data: {
					x: point.x,
					y: point.y,
				},
			},
		};

		socket.send(JSON.stringify(messageSocket));
	}, [clickPosition, socket]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const newPresetComand = {
			type: 'create_preset',
			message: {
				command: 'CREATEPRESET',
				data: newPreset,
			},
		};
		socket.send(JSON.stringify(newPresetComand));
	}, [isSavePreset]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const delPresetComand = {
			type: 'delete_preset',
			message: {
				command: 'DELETEPRESET',
				data: {
					preset_number: deletePreset,
				},
			},
		};
		socket.send(JSON.stringify(delPresetComand));
	}, [isDeletePreset]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const updatePatrol = {
			type: 'send_to_camerabackend',
			message: {
				command: 'PATROLUPDATE',
				data: {
					new_patrol_type: isPatrol ? patrolType : 'off',
				},
			},
		};
		socket.send(JSON.stringify(updatePatrol));
	}, [isPatrol, patrolType, isDeletePolygon]);

	useEffect(() => {
		if (socket === null || socket.readyState !== WebSocket.OPEN) return;

		const currNumber = presets.find(({ id }) => id === selectPresetId)?.number;

		if (!currNumber) return;

		const callPreset = {
			type: 'call_preset',
			message: {
				command: 'CALLPRESET',
				data: {
					preset_number: currNumber,
				},
			},
		};
		socket.send(JSON.stringify(callPreset));
	}, [selectPresetId]);

	const socketMessage = (event: MessageEvent) => {
		try {
			const { command, data } = JSON.parse(event.data);

			const actions: WebSocketCommands = {
				NEWEVENT: () => addNewEvent(prepareEvent(data)),
				// eslint-disable-next-line
				CAMERACHANGED: () => {},
				// eslint-disable-next-line
				ZONECHANGED: () => {},
				PRESETCHANGED: () => setUpdatePreset(prev => !prev),
				CAMERASTATUS: () => setIsLiveStream(data.status),
				// eslint-disable-next-line
				UPDATEPRESETLIST: () => {},
			};

			actions[command]();
		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};

	const socketError = (error: Event) => {
		console.error(error);
	};

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';
		const breakConnectMsg = 'Обрыв соединения';

		if (event.wasClean) console.log(closeConnectMsg);
		else {
			toast.error(`${breakConnectMsg}, Повторное соединение...`, {
				autoClose: 2000,
				className: styles.toastError,
			});
			setFirstConnect(false);
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const addNewEvent = (event: ResultEventData) => {
		setTempGrid(event.viewData.temperGrid);
		setImgBackground(event.img);

		if(!event.idPreset) return;

		setSelectPresetId(event.idPreset);
		setSelectedPresets(event.idPreset);
	};

	useEffect(() => {
		const controller = new AbortController();

		getPlaces(getPlacesLink, controller);

		return () => controller.abort();
	}, [getPlacesLink]);

	useEffect(() => {
		const controller = new AbortController();
		
		if (!selectPlaceId || !getDevicesLink) return;

		const url = `${getDevicesLink}${selectPlaceId}/getcameras/`;
		getDevices(url, controller);

		return () => controller.abort();
	}, [selectPlaceId, getDevicesLink]);

	useEffect(() => {
		const controller = new AbortController();
		if (!selectDeviceId || !getEventsLink) return;

		const url = `${getEventsLink}${selectDeviceId}/getevents/`;
		getEvents(url, controller);

		return () => controller.abort();
	}, [selectDeviceId, getEventsLink]);

	useEffect(() => {
		const controller = new AbortController();
		if (!getEventsLink) return;

		if (!selectDeviceId) {
			setSelectPresetId(null);
			setPresets([]);
			return;
		}

		const url = `${getEventsLink}${selectDeviceId}/getpresets/`;

		getPresets(url, controller);

		return () => controller.abort();
	}, [isUpdatePreset, selectDeviceId, getEventsLink]);

	useEffect(() => {
		const controller = new AbortController();
		if (!linkPolygons) return;

		if (!selectPresetId) {
			canvasState.setRawData(null, null, []);
			return;
		}

		const url = `${linkPolygons}${selectDeviceId}/${selectPresetId}/getzonelist/`;

		getPolygons(url, controller);

		return () => {
			controller.abort();
		};
	}, [selectPresetId, linkPolygons, isUpdateData]);

	useEffect(() => {
		const controller = new AbortController();
		if (!getCameraLink || !selectDeviceId || !getCameraInfo) return;

		const getCameraParameters = `${getCameraLink}/getpcameraparameters/`;

		getCameraParams(getCameraParameters, controller);

		return () => {
			controller.abort();
		};
	}, [getCameraLink]);

	const getPlaces = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const places = prepareMapPlaces(data as DataPlace[]);
			setPlaces(places);
			checkPageReady('isPlaces', true);
		});
	};

	const getDevices = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const devices = prepareMapDevices(data as PrepareDevices);
			setDevices(devices);
			checkPageReady('isDevices', true);
		});
	};

	const getPresets = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const presets = preparePresetsData(data as PreparePreset);
			
			if(!presets.length) {				
				setSelectPresetId(null);
			}
			
			setPresets(presets);
		});
	};

	const getPolygons = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const polygons = preparePolygonsData(data as PreparePolygon[]);

			canvasState.setRawData(selectDeviceId, selectPresetId, polygons);
		});
	};

	const getEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareControlEvents(data as RawDataEvents);
			const { dataEvents } = eventsData;

			if (!dataEvents.length) {
				setTempGrid([]);
				setImgBackground('');
			} else {
				setTempGrid(dataEvents[0].viewData.temperGrid);
				setImgBackground(dataEvents[0].img);
			}
		});
	};

	const getCameraParams = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const cameraParams = prepareCameraParams(data as unknown as PrepareCamParams);
			const { patrolDelay, patrolSchedule } = cameraParams;

			const patrolTypesKey = `${patrolSchedule.length > 0 && patrolSchedule[0] !== ''}-${patrolDelay > 0}`;
			setPatrolType(patrolTypes[patrolTypesKey]);
		});
	};

	const calculatePosition = (x: number, y: number) => {
		const point = {
			x: 0,
			y: 0,
		};

		const FW = 1920;
		const FH = 1080;

		const NW = boxSizeCanvas.w;
		const NH = boxSizeCanvas.h;

		point.x = (x / NW) * FW;
		point.y = (y / NH) * FH;

		return point;
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<CheckLoading page="translation">
			<ToastContainer />

			<div className={styles.container}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces />

				<div ref={contentRef} className={styles.content}>
					{isMouseControl && <span className={styles.tempButton}>Режим управления по клику</span>}

					{isVisibleTemp && !isShowCanvas && isVisibleTempGrid && (
						<Box className={styles.currentTemp}>
							<Box className={styles.currentTitle}>{`t°C Текущая: ${tCurrent} °C`}</Box>
							<Box className={styles.currentTitle}>{`t°C Макс: ${maxTemp} °C`}</Box>
						</Box>
					)}

					{isVisibleTempGrid && !isMouseControl && !isShowCanvas && (
						<div className={styles.fireIcon}>
							<MainButton onClick={() => setVisibleTemp(!isVisibleTemp)} disabled={!tempGrid.length}>
								<TempIcon fill={isVisibleTemp ? '#00C2FD' : '#D2D2D2'} disabled={!tempGrid.length} />
							</MainButton>
							<MainButton onClick={() => setVisibleMax(!isVisibleMax)} disabled={!tempGrid.length}>
								<FireIcon fill={isVisibleMax ? '#00C2FD' : '#D2D2D2'} disabled={!tempGrid.length} />
							</MainButton>
						</div>
					)}

					<div className={styles.online}>
						<div className={styles.btnOnline}>
							<CircleIcon fill={isLiveStream ? 'green' : 'red'} />
						</div>
					</div>

					<ViewBox
						width={boxSizeCanvas.w}
						height={boxSizeCanvas.h}
						isVisibleMax={isVisibleMax}
						img={imgBackground}
						tempGrid={tempGrid}
						minTemp={minTemp}
						maxTemp={maxTemp}
						clickPosition={clickPosition}
						setTCurrent={setTCurrent}
						setClickPosition={setClickPosition}
					/>

					{visibleControlsButton && isVisibleController && (
						<div className={styles.cameraControlView}>
							<FourLeafCloverControl />
						</div>
					)}

					<div className={styles.additionalButtons}>
						<Controller />

						<KeyboardControl />

						<MouseControl />
					</div>
				</div>

				<Modal open={isOpenModal} onClose={() => setOpenModal(false)}>
					<div>{contentModal[contentTypeModal]}</div>
				</Modal>

				<ControlIconsBottom />
			</div>
		</CheckLoading>
	);
});

export default TranslationPage;
