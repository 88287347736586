import React, { useMemo } from 'react';
import styles from './CustomTable.module.scss';
import CustomHeader from '../CustomHeader/CustomHeader';
import ItemTable from '../CustomCard/ItemTable/ItemTable';
import { CustomTableProps as Props } from './CustomTable.interface';
import {
	getGridStringOperators,
	GridColDef,
	GridFilterOperator
} from '@mui/x-data-grid';

const CustomTable: React.FC<Props> = ({ item, role, groupRole }) => {
	const { title, data, create, type, change, actionsTable } = item;

	const columnsLocations: GridColDef[] = [
		{ field: 'firstName', headerName: 'Имя ', flex: 1 },
		{ field: 'lastName', headerName: 'Фамилия ', flex: 1 },
		{ field: 'ruGroupRole', headerName: 'Доступ', flex: 1, filterable: false },
		{ field: 'groupName', headerName: 'Группа', flex: 1 },
	];

	const columnsActions: GridColDef[] = [
		{ field: 'firstName', headerName: 'Имя ', flex: 1, filterable: false },
		{ field: 'message', headerName: 'Действия ', flex: 1, filterable: false },
		{ field: 'date', headerName: 'Дата и время', flex: 1, filterable: false },
	];

	const columnsWithFilter = useMemo(() => {
		const commonFilterOperators = getGridStringOperators().filter(
			(operator) => operator.value === 'equals' || operator.value === 'contains'
		);

		return columnsLocations.map((col) => {
			let filterOperators: GridFilterOperator[] = [...commonFilterOperators];
			switch (col.field) {
			case 'id':
			case 'fistName':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'contains'
				);
				break;

			case 'lastName':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'contains'
				);
				break;

			case 'ruGroupRole':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'contains'
				);
				break;

			case 'groupName':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'contains'
				);
				break;

			default:
				break;
			}


			return {
				...col,
				filterOperators,
			};
		});
	}, [columnsLocations]);

	const columnsDataGrid: GridColDef[] = type === 'manage' ? columnsWithFilter : columnsActions;

	return (
		<div className={styles.box}>
			<CustomHeader title={title} type={type} create={create} role={role} groupRole={groupRole} />

			<ItemTable
				data={data}
				columnsDataGrid={columnsDataGrid}
				change={change}
				role={role}
				type={type}
				actionsTable={actionsTable}
				groupRole={groupRole}
			/>
		</div>
	);
};
export default CustomTable;
