import React, { useEffect, useState } from 'react';
import {
	ItemTableProps,
	ItemTableModalContent as ModalContent,
	DoubleActionGroup,
} from './ItemTable.interface';
import {DataGrid, GridFilterModel, GridRowParams, ruRU} from '@mui/x-data-grid';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import ContentAction from './ContentAction/ContentAction';
import connectState from '@store/connectState/connectState';
import { apiGet } from '@api/api';
import { ActionLog, RowDataUsersActions } from '@store/userActionsState/interfaces';
import { prepareUsersActionsData } from '@api/AccountPage/prepareUsersActionsData';
import userActionsState from '@store/userActionsState/userActionsState';
import { PaginationProps } from '../../../Control/TableEvents/TableEvents.interface';
import autorizeState from '@store/accoutState/autorizeState';
import {observer} from 'mobx-react-lite';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const ModDataGrid = styled(DataGrid)({
	mxHeight: 'calc(100% - 50px)',
	minHeight: 'calc(100% - 50px)',
	width: '100%',

	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},

	'& .MuiDataGrid-selectedRowCount': {
		display: 'none',
	},

	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},

	'& .MuiDataGrid-cell': {
		fontSize: '16px'
	},

	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},

	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},

	'& ::-webkit-scrollbar': {
		width: '6px'
	},

	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	}
});


const filteredFields: { [key: string]: string } = {
	firstName: 'first_name',
	lastName: 'last_name',
	groupName: 'group__name',
	ruGroupRole: 'grouprole',
};


const ItemTable: React.FC<ItemTableProps> = observer(({data, columnsDataGrid, change, groupRole, type, actionsTable}) => {
	const { getUsersActionsLink, getAllUsersLink } = connectState;
	const { setUserActions, userActions } = userActionsState;
	const { countRows, handleChangePage} = actionsTable;
	const { setUsersFilterLink, loadingTables, setLoadingTables } = autorizeState;

	const stopLoad = {isUsers: false, isUsersActions: false};

	const [isOpen, setOpen] = useState(false);
	const [countRowsUser, setCountRowsUser] = useState<number>(0);
	const [prevLinkUser, setPrevLinkUser] = useState('');
	const [nextLinkUser, setNextLinkUser] = useState('');
	const [userId, setUserId] = useState<number>(0);
	const [pageCounterUser, setPageCounterUser] = useState<number>(0);
	const [isFilter, setFilter] = useState(false);
	const [filteredMod, setFilteredMod] = useState('');
	const [isLoad, setLoad] = useState(stopLoad);


	useEffect(() => {
		const load = {
			isUsers: loadingTables.isUsers,
			isUsersActions: loadingTables.isUsersActions
		};
		setLoad(load);
	}, [loadingTables.isUsersActions, loadingTables.isUsers]);

	useEffect(() => {
		if (!filteredMod) return;
		const urlParts = getAllUsersLink.split('?');
		let updatedLink = getAllUsersLink;

		if (urlParts.length > 1) {
			const searchParams = new URLSearchParams(urlParts[1]);

			const eventType = searchParams.get('event_type');
			const ordering = searchParams.get('ordering');
			const pageSize = searchParams.get('page_size');
			const pageNumber = searchParams.get('page');

			const newQueryParams = new URLSearchParams();

			newQueryParams.set('event_type', eventType ? eventType : '');
			newQueryParams.set('ordering', ordering ? ordering : '');
			newQueryParams.set('page_size', pageSize ? pageSize : '');
			newQueryParams.set('page', pageNumber ? pageNumber : '1');

			updatedLink = `${urlParts[0]}?${newQueryParams.toString()}&${filteredMod}`;
		} else {
			updatedLink = `${getAllUsersLink}?${filteredMod}`;
		}

		setUsersFilterLink(updatedLink);
	}, [filteredMod, isFilter]);


	const typeModal = type as keyof ModalContent;
	const handleRowDoubleClickAction = (params: GridRowParams) => {
		const selectedIndex = data.findIndex(({id}) => id === params.id);
		if (selectedIndex === -1 || !data || data.length === 0 ) return;
		const row = data[selectedIndex] as ActionLog;

		const findedId = row.userId;
		setUserId(findedId);
		getInfoUser(findedId, getUsersActionsLink);
	};

	const handleRowDoubleClickManage = (params: GridRowParams) => {
		change('manage', params.id);
	};

	const getInfoUser = (id: number, link: string,  controller = new AbortController()) => {
		const url = `${link}?user=${id}`;
		apiGet(url, {}, controller).then(({ data, error }) => {
			if (error.isError) return;

			const actionsData = prepareUsersActionsData(data as RowDataUsersActions);

			const { actions, pagination } = actionsData;
			setCountRowsUser(pagination.count);
			setPrevLinkUser(pagination.previous);

			setNextLinkUser(pagination.next);
			setUserActions(actions);
			setOpen(true);
		});
	};

	const updateEvents = (link: string) => {
		const controller = new AbortController();

		getInfoUser(userId, link, controller);
		return () => {
			controller.abort();
		};
	};

	const handleChangePageUser = (infoPage: PaginationProps) => {
		let linkMove = pageCounterUser < infoPage.page ? nextLinkUser : prevLinkUser;
		if (!linkMove) {
			linkMove = getUsersActionsLink;

		}
		const urlParts = linkMove.split('&');

		let updatelink = linkMove;
		if (urlParts.length > 1) {

			const queryParams = new URLSearchParams(urlParts[1]);
			urlParts.forEach((item, index) => {
				if (index < 2) return;

				const [key, value] = item.split('=');
				queryParams.set(key, value);
			});

			queryParams.set('page_size', infoPage.pageSize.toString());
			updatelink = `${urlParts[0]}&${queryParams.toString()}`;
		} else {
			updatelink = `${linkMove}&page_size=10`;
		}

		updateEvents(updatelink);
		setPageCounterUser(infoPage.page);
		setLoadingTables('isUsersActions', true);
	};

	const doubleActions: ModalContent = {
		action:  handleRowDoubleClickAction,
		manage: handleRowDoubleClickManage,
	};

	const doubleClick = doubleActions[typeModal];

	const groupActions: DoubleActionGroup = {
		groupadmin: doubleClick,
		groupuser: undefined,
		camerauser: undefined,
	};

	const dictionary = {
		'администратор группы': 'groupadmin',
		'пользователь': 'groupuser',
		'техническая': 'camerauser'
	};

	type valueType = 'администратор группы' | 'пользователь' | 'техническая';

	const roleAction = groupActions[groupRole];
	const handleCloseModal = () => {
		setOpen(false);
		setLoadingTables('isUsersActions', false);
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		setFilter(false);

		if (!model.items.length) return;

		const { field, value } = model.items[0];
		if (value === undefined || value === '') {
			setFilteredMod(getAllUsersLink);
			setFilter(false);

			setLoad(stopLoad);
			return;
		}

		setLoadingTables('isUsers', true);

		if (field === 'ruGroupRole') {

			const englishValue = dictionary[value as valueType] || '';
			setFilter(true);
			setFilteredMod(`grouprole=${englishValue}`);
			return;

		}

		setFilter(true);

		setFilteredMod(`${filteredFields[field]}__icontains=${value}`);
	};

	return (
		<ThemeProvider theme={darkTheme}>
			{type === 'action' &&
				(
					<ModDataGrid
						rows={data}
						columns={columnsDataGrid}
						getRowId={(row) => row.id}
						paginationMode={'server'}
						rowCount={countRows}
						loading={isLoad.isUsersActions}
						localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
						initialState={{
							pagination: { paginationModel: { pageSize: 10 } },
						}}
						pageSizeOptions={[10]}
						onRowDoubleClick={roleAction}
						onPaginationModelChange={handleChangePage}
					/>
				)
			}

			{type === 'manage' &&
				(
					<ModDataGrid
						rows={data}
						columns={columnsDataGrid}
						getRowId={(row) => row.id}
						rowCount={data.length}
						loading={isLoad.isUsers}
						localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
						initialState={{
							pagination: { paginationModel: { pageSize: 10 } },
						}}
						pageSizeOptions={[10, 25, 50]}
						filterMode={'server'}
						onRowDoubleClick={roleAction}
						onPaginationModelChange={handleChangePage}
						onFilterModelChange={handleFilterModelChange}
					/>
				)
			}

			<Modal open={isOpen} onClose={handleCloseModal}>
				<div>
					<ContentAction
						dataUser={userActions}
						rowCount={countRowsUser}
						handleChangePage={handleChangePageUser}
					/>
				</div>
			</Modal>
		</ThemeProvider>
	);
});

export default ItemTable;


