import React from 'react';
import styles from './InfoBox.module.scss';
import {Box} from '@mui/material';
import {Props} from './InfoBox.interface';

const InfoBox: React.FC<Props> = ({data, simbol}) => {
	return (
		<div className={styles.contentBox}>
			{data.map(({ id, title, value}) => (
				<Box className={styles.labelAndBox} key={`Input-Settings-Event-${id}-${name}`}>
					{title}
					<div className={styles.inputBox}>{value}{simbol}</div>
					<p className={styles.errorTitle}/>
				</Box>
			))}
		</div>
	);
};

export default InfoBox;
