import React from 'react';
import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const AddIcon: React.FC<IconSvgProps> = ({ width = 20, height = 20, fill = '#D2D2D2' }) => {
	return(
		<svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>Добавить</title>
			<path d='M9.65625 0.0485535C9.33203 0.169647 9.08203 0.450897 9.00781 0.775116C8.98047 0.888397 8.96484 2.36887 8.96484 4.96262V8.97433L4.85547 8.98605C1.18359 8.99777 0.726562 9.00558 0.605469 9.06027C0.402344 9.15402 0.226562 9.31418 0.105469 9.52121C0.015625 9.67355 0 9.74387 0 9.99387C0 10.2439 0.015625 10.3142 0.105469 10.4665C0.226562 10.6736 0.402344 10.8337 0.605469 10.9275C0.726562 10.9821 1.18359 10.99 4.85547 11.0017L8.96484 11.0134V15.0134C8.96484 17.7556 8.97656 19.0759 9.00781 19.2126C9.0625 19.4626 9.24219 19.7009 9.49219 19.8571C9.66016 19.9587 9.71484 19.9743 9.97656 19.9743C10.2305 19.9743 10.3008 19.9587 10.4531 19.8689C10.6602 19.7478 10.8203 19.572 10.9141 19.3689C10.9688 19.2478 10.9766 18.7907 10.9883 15.1228L11 11.0134L15.1094 11.0017C18.7773 10.99 19.2344 10.9821 19.3555 10.9275C19.5586 10.8337 19.7344 10.6736 19.8555 10.4665C19.9453 10.3142 19.9609 10.2439 19.9609 9.99387C19.9609 9.74387 19.9453 9.67355 19.8555 9.52121C19.7344 9.31418 19.5586 9.15402 19.3555 9.06027C19.2344 9.00558 18.7773 8.99777 15.1094 8.98605L11 8.97433L10.9883 4.86496C10.9766 0.357147 10.9922 0.64621 10.7422 0.353241C10.4922 0.056366 10.0039 -0.0803528 9.65625 0.0485535Z' fill={fill}/>
		</svg>
	); 
};

export default AddIcon;