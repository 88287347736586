import React from 'react';
import styles from './CustomCard.module.scss';
import ItemCard from './ItemCard/ItemCard';
import {CustomCardPlaceProps as Props} from './CustomCard.interface';
import { List } from '@mui/material';
import { AccountPlace } from '@store/placeState/interfaces';
import { ItemCardData } from './ItemCard/ItemCard.interface';
import { DeviceAccount } from '@store/devicesState/interface';
import CustomHeader from '../CustomHeader/CustomHeader';

const CustomCard: React.FC<Props> = ({ item, role, groupRole }) => {
	const { title, data, create, type, change, url, updateTitle } = item;

	const createTableRow = (data: DeviceAccount | AccountPlace) => {
		const isWorking =  false;
		const { name, isCanEdit } = data;

		const id = type === 'camera' ? (data as DeviceAccount).idPsw : data.id;
		const item: ItemCardData = { name, isWorking, id, type, isCanEdit };

		const key = `Devices-and-Locations-Card-${name}-${isWorking}-${id}`;

		return <ItemCard key={key} data={item} change={handleChange} url={url} updateTitle={updateTitle} role={role} groupRole={groupRole}/>;
	};

	const handleChange = (id: number | string, isCanEdit: boolean) => {
		change(type, id, isCanEdit);
	};

	return (
		<div className={styles.box}>
			<CustomHeader title={title} type={type} create={create} role={role} groupRole={groupRole}/>
			<div className={styles.content}>
				<List>
					{data.length
						? data.map(createTableRow)
						: <p className={styles.titleStyle}>Нет подключенных устройств</p>
					}
				</List>
			</div>
		</div>
	);
};

export default CustomCard;
