import React, {useRef, useState} from 'react';
import { Formik, Form } from 'formik';
import { FormValues, initialValues } from './SigninForm.interface';
import connectState from '@store/connectState/connectState';
import autorizeState from '@store/accoutState/autorizeState';
import { ServerAns, prepareAutrizeData } from '@api/SigninPage/prepareAutrizeData';
import { apiPostAuthorize } from '@api/SigninPage/apiSignin';
import LogoIconAuth from '@assets/Auth/LogoIconAuth';
import styles from './SinginForm.module.scss';
import ContactUs from './ContactUs/ContactUs';
import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
	'& .MuiInputLabel-root': {
		right: 0,
		top: 10,
		shrink: true,
		color: '#000000',
		borderRadius: '10px',
	},
	'& .MuiInputLabel-shrink': {
		display: 'none',
	},
	'& .MuiOutlinedInput-root.Mui-focused': {
		'& legend ': {
			display: 'none',
		},
	},
	'& .MuiInputBase-root': {
		height: '100%',

		color: '#000000',

		fontSize: '24px',
		fontFamily: 'Inter',
		fontWeight: 400,
		borderRadius: '10px',
	},

	'& input:-webkit-autofill': {
		fontSize: '24px !important',
		borderRadius: '10px',
		height: '25px',
	},

	'& .MuiInput-underline:after': {
		border: 'none',
		borderRadius: '10px',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: 'none',
			borderRadius: '10px',
		},
		'&:hover fieldset': {
			border: 'none',
			borderRadius: '10px',
		},
		'&.Mui-focused fieldset': {
			border: 'none',
			borderRadius: '10px',
		},
	},
}));

const SigninForm: React.FC = () => {
	const windowWidth = useRef(window.innerWidth);
	const isLittle = windowWidth.current < 500;

	const [error, setError] = useState(false);

	const logoSize = [
		{ width: 340, height: 118 },
		{ width: 240, height: 83 },
	];

	const handleSubmit = (values: FormValues) => {
		const { postAutrizeLink } = connectState;
		
		apiPostAuthorize(postAutrizeLink, values, {}).then(({ data, error }) => {
			const { isError } = error;
			setError(isError);
			if (isError) return;

			const { isAuthorize, token, role } = prepareAutrizeData(data as ServerAns);
			const { setIsAutorize, setToken, setUserRole } = autorizeState;

			setIsAutorize(isAuthorize);
			setToken(token);
			setUserRole(role);
		});
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.card}>
				<div className={styles.logo}>
					<LogoIconAuth width={logoSize[+isLittle].width} height={logoSize[+isLittle].height} fill="#00C2FD" />
				</div>
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{({ values, handleChange, handleBlur, errors, touched }) => (
						<Form className={styles.formikCard}>
							<div className={styles.textFieldCard}>
								<StyledTextField
									className={styles.textfieldStyle}
									type="text"
									name="email"
									margin="normal"
									placeholder="Почта"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								{errors.email && touched.email && <div>{errors.email}</div>}

								<StyledTextField
									className={styles.textfieldStyle}
									type="password"
									name="password"
									margin="normal"
									placeholder="Пароль"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								{errors.password && touched.password && <div>{errors.password}</div>}
								{error && <div className={styles.error}>Неверный логин или пароль</div>}
							</div>
							<div className={styles.downCardButtons}>
								<button className={styles.btnSubmit} type="submit">
									Войти
								</button>
								{/*<div className={styles.contactUS}>Связаться с нами</div>*/}
							</div>
						</Form>
					)}
				</Formik>
			</div>
			<ContactUs />
		</div>
	);
};

export default SigninForm;
