import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ControllerIcon: React.FC<IconSvgProps> = ({ width = 35, height = 35, fill = '#D2D2D2', disabled = false }) => {
	const disableFill = disabled ? 'hsl(0, 0%, 50%)' : fill;

	return (
		<svg width={width} height={height} viewBox="0 0 20 20">
			<title>Контроллер</title>

			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Dribbble-Light-Preview" transform="translate(-100.000000, -7199.000000)" fill={disableFill}>
					<g id="icons" transform="translate(56.000000, 160.000000)">
						<path
							d="M47.5857,7049 L48.9997,7047.797 L48.9997,7050.203 L47.5857,7049 Z M58.9997,7047.797 L60.4147,7049 L58.9997,7050.203 L58.9997,7047.797 Z M52.7967,7054 L55.2027,7054 L53.9997,7055.414 L52.7967,7054 Z M53.9997,7042.586 L55.2027,7044 L52.7967,7044 L53.9997,7042.586 Z M61.9997,7051 C61.9997,7051.552 61.5527,7052 60.9997,7052 L58.9997,7052 C57.8957,7052 56.9997,7052.895 56.9997,7054 L56.9997,7056 C56.9997,7056.552 56.5527,7057 55.9997,7057 L51.9997,7057 C51.4477,7057 50.9997,7056.552 50.9997,7056 L50.9997,7054 C50.9997,7052.895 50.1047,7052 48.9997,7052 L46.9997,7052 C46.4477,7052 45.9997,7051.552 45.9997,7051 L45.9997,7047 C45.9997,7046.448 46.4477,7046 46.9997,7046 L48.9997,7046 C50.1047,7046 50.9997,7045.105 50.9997,7044 L50.9997,7042 C50.9997,7041.448 51.4477,7041 51.9997,7041 L55.9997,7041 C56.5527,7041 56.9997,7041.448 56.9997,7042 L56.9997,7044 C56.9997,7045.105 57.8957,7046 58.9997,7046 L60.9997,7046 C61.5527,7046 61.9997,7046.448 61.9997,7047 L61.9997,7051 Z M61.9997,7044 L59.9997,7044 C59.4477,7044 58.9997,7043.552 58.9997,7043 L58.9997,7041 C58.9997,7039.895 58.1047,7039 56.9997,7039 L50.9997,7039 C49.8957,7039 48.9997,7039.895 48.9997,7041 L48.9997,7043 C48.9997,7043.552 48.5527,7044 47.9997,7044 L45.9997,7044 C44.8957,7044 43.9997,7044.895 43.9997,7046 L43.9997,7052.001 C43.9997,7053.105 44.8947,7054 45.9987,7054 L48.0007,7054 C48.5527,7054 48.9997,7054.45 48.9997,7055.002 L48.9997,7057 C48.9997,7058.105 49.8957,7059 50.9997,7059 L56.9997,7059 C58.1047,7059 58.9997,7058.105 58.9997,7057 L58.9997,7055 C58.9997,7054.448 59.4477,7054 59.9997,7054 L61.9997,7054 C63.1047,7054 63.9997,7053.105 63.9997,7052 L63.9997,7046 C63.9997,7044.895 63.1047,7044 61.9997,7044 L61.9997,7044 Z"
							id="controller-[#222]"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ControllerIcon;
