import { PrepareDashboardRawEvent as RawEvent } from './prepareDashboardData.interface';

export const prepareDownloadReportEvents = (data: RawEvent[]) => {
	const dataEvents = data.map((event) => {
		const {
			id,
			id_message,
			data_sound_frequency,
			date,
			time,
			preset_name,
			zone_name,
			event_type,
			image_base64,
			ir_max,
			ir_mean, ir_min,
			data_sound_corr,
			data_sound_energy,
		} = event;

		const newDate = new Date(date);

		return {
			id,
			id_message,
			date: newDate.toLocaleDateString(),
			time,
			presetName: !preset_name ? 'Не установлена' : preset_name,
			zoneName: !zone_name ? 'Нет зоны' : zone_name,
			messageType: event_type,
			maxTemp: `${ir_max.toFixed(1)} °C`,
			meanTemp: `${ir_mean.toFixed(1)} °C`,
			minTemp: `${ir_min.toFixed(1)} °C`,
			soundFrequency: data_sound_frequency?.toString(),
			soundCorr: data_sound_corr,
			soundEnergy: data_sound_energy,
			img: image_base64,
		};
	});

	return dataEvents;
};