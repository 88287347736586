import { useRef, useState, useEffect, useMemo } from 'react';
import styles from './ScrinEventCamera.module.scss';
import controlState from '../../../store/controlState/controlState';
import eventState from '../../../store/eventState/eventState';
import { observer } from 'mobx-react-lite';
import html2canvas from 'html2canvas';
import { ScrinEventCamera as Props } from './ScrinEventCamera.interface';
import { Box } from '@mui/material';
import CanvasGridTemp from '../../CanvasGridTemp/CanvasGridTemp';
import useWindowDimensions from '../../../helpers/functions/useWindowDimensions';
import MainButton from '../../../helpers/components/MainButton/MainButton';
import FireIcon from '../../../assets/Icons/ControlPage/FireIcon';
import TempIcon from '../../../assets/Icons/ControlPage/TempIcon';
// import canvasState from '../../../store/canvasState/canvasState';
// import Polygons from '../../Translation/Canvas/Polygons';
import CheckEmpty from '../../../helpers/components/CheckEmpty/CheckEmpty';

const ScrinEventCamera: React.FC<Props> = observer(({ widthScr, heightScr, tempGrid, imageEvent }) => {
	const { isVisibleTempGrid, isVisibleTemp, setVisibleTemp } = controlState;
	const { isShowPrepareProtocol, /*selectEventId,*/ setEventScreen } = eventState;
	// const { setCanvas } = canvasState;

	// const canvasRef = useRef(null);

	const maxTemp = useMemo(() => Math.max(...tempGrid.map((row) => Math.max(...row))), [tempGrid]);
	const minTemp = useMemo(() => Math.min(...tempGrid.map((row) => Math.min(...row))), [tempGrid]);

	const imgContainerRef = useRef<HTMLDivElement>(null);

	const [tCurrent, setTCurrent] = useState<number>(0);
	const [isVisibleMax, setVisibleMax] = useState(false);

	const { width, height } = useWindowDimensions();
	const changeW = Number(((width - 151) * 0.62 * 0.55).toFixed(1));
	const changeH = Number(((height - 186) * 0.7 * 0.55).toFixed(1));

	// useEffect(() => {
	// 	if (!isVisibleTempGrid) return;

	// 	// setCanvas(canvasRef.current);
	// }, [selectEventId, isVisibleTempGrid]);

	// useEffect(() => {
	// 	if (changeW !== 0) canvasState.setCanvasReSize(changeW, changeH);

	// 	if (isVisibleTempGrid) new Polygons(canvasState.canvas);
	// }, [width, height, changeW]);

	// useEffect(() => {
	// 	if (!isVisibleTempGrid) return;

	// 	new Polygons(canvasState.canvas);
	// }, [isVisibleTempGrid, selectEventId]);

	useEffect(() => {
		takeScreenshot();
	}, [isShowPrepareProtocol]);

	const takeScreenshot = () => {
		const parentEl = imgContainerRef.current;

		if (!parentEl) return;
		const options = {
			useCORS: true,
			scrollX: 0,
			scrollY: -window.scrollY,
			windowWidth: document.documentElement.clientWidth,
			windowHeight: document.documentElement.clientHeight,
			width: parentEl.offsetWidth,
			height: parentEl.offsetHeight,
		};
		html2canvas(parentEl, options).then((canvas) => {
			const dataUrl = canvas.toDataURL();

			setEventScreen(dataUrl);
		});
	};

	const memoTemperatureField = useMemo(
		() => (
			<>
				<Box className={styles.tempZone}>
					<CanvasGridTemp
						data={tempGrid}
						width={changeW}
						height={changeH}
						minTemp={minTemp}
						maxTemp={maxTemp}
						isTempMax={isVisibleMax}
						handleCur={(temp) => setTCurrent(temp)}
					/>

					{/* <canvas
						ref={canvasRef}
						width={changeW}
						height={changeH}
						className={styles.areaCanvas}
						style={{ zIndex: isVisibleTemp ? '0' : '10' }}
					/> */}
				</Box>

				<div style={{ width: changeW }} className={styles.colorLine}>
					<div style={{ marginLeft: '10px' }}>{Math.round(minTemp)}°С</div>
					<div className={styles.colorBlock} />
					<div style={{ marginRight: '10px' }}>{Math.round(maxTemp)}°С</div>
				</div>
			</>
		),
		[tempGrid, isVisibleMax, /*isVisibleTemp,*/ changeH, changeW, maxTemp, minTemp]
	);

	return (
		<CheckEmpty isCheck={!!tempGrid.length}>
			<div 
				ref={imgContainerRef}
				style={{
					width: widthScr,
					height: heightScr,
					backgroundImage: `url(${imageEvent})`,
				}} 
				className={styles.container} 
			>
				{isVisibleTemp && (
					<Box className={styles.currentTemp}>
						<Box className={styles.currentTitle}>{`t°C Текущая: ${tCurrent} °C`}</Box>
						<Box className={styles.currentTitle}>{`t°C Макс: ${maxTemp} °C`}</Box>
					</Box>
				)}

				{isVisibleTempGrid && memoTemperatureField}

				{isVisibleTempGrid && (
					<div className={styles.fireIcon}>
						<MainButton onClick={() => setVisibleTemp(!isVisibleTemp)}>
							<TempIcon fill={isVisibleTemp ? '#00C2FD' : '#D2D2D2'} />
						</MainButton>
						<MainButton onClick={() => setVisibleMax(!isVisibleMax)}>
							<FireIcon fill={isVisibleMax ? '#00C2FD' : '#D2D2D2'} />
						</MainButton>
					</div>
				)}
			</div>
		</CheckEmpty>
	);
});

export default ScrinEventCamera;
