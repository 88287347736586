import {
	DataDevice,
	DataUserFix,
	UserDataFix,
	ProfileFix,
	AutorizeKeysUserRoles,
	SelectedUser,
	Group,
	UserRolesGroupCreate,
	UsersAll
} from '@store/accoutState/interfaces';
import { AccessPlace } from '@store/placeState/interfaces';
import autorizeState from '@store/accoutState/autorizeState';
import {
	PrepareDevice,
	PrepareUserAccess,
	UserAccess,
	RuNameRolesAccount,
	PrepareTechUser,
	TechUser
} from './prepareAccountData.interface';
import {DeviceAccount, PrepareDeviceAccount} from '@store/devicesState/interface';


export const prepareUserDataFix = (data: DataUserFix): ProfileFix => {
	const { profile, spots, cameras, users, role } = data;

	const roles: RuNameRolesAccount = {
		staff: 'суперпользователь',
		spotadmin: 'администратор спота',
		spotoperator: 'оператор',
	};

	const groupRoles: UserRolesGroupCreate = {
		groupadmin: 'администратор группы',
		groupuser: 'пользователь',
		camerauser: 'техническая',
	};

	const { first_name, last_name, group, grouprole } = profile;

	const ruRole = roles[role];
	const ruGroupRole = groupRoles[grouprole];

	const newSpots = spots.map((spot) => {
		return {
			...spot,
			isCanEdit: grouprole === 'groupadmin',
			isSelected: false
		};
	});


	const newCameras = cameras.map((camera) => {
		const {id, id_psw, name, spot, spot__name} = camera;
		return {
			id,
			idPsw: id_psw,
			name,
			spot,
			spotName: spot__name,
			isCanEdit: grouprole === 'groupadmin'
		};
	});

	const usersData: UserDataFix[] = users.map(user => {
		const { id, email, first_name, group, last_name, is_staff, group_name, grouprole, image, phone } = user;
		const ruGroupRole = groupRoles[grouprole];

		return {
			id,
			email,
			firstName: first_name,
			group,
			lastName:last_name,
			isStaff: is_staff,
			groupName: group_name,
			grouprole,
			image,
			phone,
			role,
			ruRole: roles[role],
			ruGroupRole: ruGroupRole
		};
	});

	return {
		profile: {
			firstName: first_name,
			lastName: last_name,
			role,
			group,
			ruGroupRole: ruGroupRole,
			ruRole,
			grouprole,
			id: profile.id,
			phone: profile.phone,
			email: profile.email,
			image: profile.image,
		},
		spots: newSpots,
		cameras: newCameras,
		users: usersData,
		ruGroupRole,
		role,
		ruRole
	};
};

export const prepareDeviceData = (data: PrepareDevice):DataDevice => {
	const { spot, id, lat, lng, name, id_psw, cameraUser, cameraUserEmail } = data;
	const { accountPlaces } = autorizeState;
	const findPlaceIndex = accountPlaces.findIndex(({id}) => id === spot);
	const place = findPlaceIndex !== -1 ? accountPlaces[findPlaceIndex] : accountPlaces[0];
	return  {
		id,
		idPsw: id_psw,
		lat,
		lng,
		name,
		place,
		cameraUser,
		cameraUserEmail
	};
};

export const prepareUsersData = (data: UsersAll[]):UserDataFix[] => {
	const roles: RuNameRolesAccount = {
		staff: 'суперпользователь',
		spotadmin: 'администратор спота',
		spotoperator: 'оператор',
	};

	const groupRoles: UserRolesGroupCreate = {
		groupadmin: 'администратор группы',
		groupuser: 'пользователь',
		camerauser: 'техническая',
	};
	const usersData: UserDataFix[] = data.map(user => {
		const { id, email, first_name, group, last_name, is_staff, group_name, grouprole, image, phone, role } = user;
		const ruGroupRole = groupRoles[grouprole];

		return {
			id,
			email,
			firstName: first_name,
			group,
			lastName:last_name,
			isStaff: is_staff,
			groupName: group_name,
			grouprole,
			image,
			phone,
			role,
			ruRole: roles[role],
			ruGroupRole: ruGroupRole
		};
	});
	return usersData;
};

export const prepareSelectedUser = (data: SelectedUser): UserDataFix => {
	const { id, email, phone, first_name, last_name, group, grouprole, role } = data;
	const roles: RuNameRolesAccount = {
		staff: 'суперпользователь',
		spotadmin: 'администратор спота',
		spotoperator: 'оператор',
	};

	const groupRoles: UserRolesGroupCreate = {
		groupadmin: 'администратор группы',
		groupuser: 'пользователь',
		camerauser: 'техническая',
	};

	const { allGroups, user } = autorizeState;

	const findGroup = {
		false: allGroups,
		true: allGroups.length && allGroups.find(({id}) => id === group),
	};

	const ruRole = roles[role];
	const ruGroupRole = groupRoles[grouprole];

	return {
		id,
		email,
		phone,
		firstName: first_name,
		lastName: last_name,
		image: '',
		group: findGroup[`${user.role === 'staff'}`] as Group,
		grouprole,
		role,
		ruRole,
		ruGroupRole
	};
};


export const prepareAccessPlaces = (data: PrepareUserAccess[]):AccessPlace[] => {
	const filteredData = data.filter((item) => item.role === 'spotadmin')
		.map((item) => ({ name: item.spot_name, id: item.spot, isSelected: false }));
	return filteredData;
};

export const prepareUserDevices = (data: PrepareDeviceAccount[]):DeviceAccount[] => {
	const devices = data.map((camera) => {
		const {id, id_psw, name, spot, spot__name, isCanEdit} = camera;
		return {
			id,
			idPsw: id_psw,
			name,
			spot,
			spotName: spot__name,
			isCanEdit
		};
	});
	return devices;
};



export const prepareUserAccess = (data: PrepareUserAccess[]):UserAccess[] => {
	const userAccess = data.map(({date_joined, id, role, spot, spot_name, user}) => {
		const newRole = role as keyof AutorizeKeysUserRoles;
		return {
			dateJoined: date_joined,
			id,
			role: newRole,
			spot,
			spotName: spot_name,
			user
		};
	}); 
	return userAccess;
};

export const prepareTechUsers = (data: PrepareTechUser[]) :TechUser[] => {
	const techUsers = data.map(({email, first_name, last_name, id}) => {
		return {
			id,
			email,
			firstName: first_name,
			lastName: last_name
		};
	});
	return techUsers;
};
