import { makeAutoObservable } from 'mobx';

class ControlState {
	isVisibleTempGrid = false;
	isVisibleSoundInfo = false;
	isVisibleTemp = false;
	isUnloadingEvents = false;
	isControlPage = true;

	constructor() {
		makeAutoObservable(this);
	}

	setVisibleTempGrid = (isVisible: boolean) => {
		this.isVisibleTempGrid = isVisible;
	};

	setVisibleSoundInfo = () => {
		this.isVisibleSoundInfo = !this.isVisibleSoundInfo;
	};

	setVisibleTemp = (isVisible = false) => {
		this.isVisibleTemp = isVisible;
	};

	setUnloadingEvent = () => {
		this.isUnloadingEvents = !this.isUnloadingEvents;
	};

	setControlPage = () => {
		this.isControlPage = !this.isControlPage;
	};
}

const controlState = new ControlState();
export default controlState;
