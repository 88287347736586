import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './ControlButton.module.scss';
import {
	ControlButtonProps as Props,
	ControlButtonGetStyle as GetStyle,
	ControlButtonButtonStyles as ButtonStyles,
} from './ControlButton.interface';
import translationState from '@store/translationState/translationState';
import { TypeSelectArrow } from '@store/translationState/interfaces';

const ControlButton: React.FC<Props> = observer(({ style, position = 'ArrowUp', children }) => {
	const { setSelectComandArrow } = translationState;

	const controlButtonRef = useRef<HTMLDivElement>(null);

	const [zIndex, setZIndex] = useState(1);

	const valueArrows = {
		'ArrowUp': 'UP',
		'ArrowLeft': 'LEFT',
		'ArrowRight': 'RIGHT',
		'ArrowDown': 'DOWN',
	};

	useEffect(() => {
		translationState.setKeyboardKey(position, controlButtonRef);

		return () => {
			translationState.setKeyboardKey(position, null);
		};
	}, []);

	const getStyle: GetStyle = (style): string => {
		const { keysSelected } = translationState;

		const buttonStyles: ButtonStyles = {
			ArrowUp: styles.top,
			ArrowLeft: styles.left,
			ArrowRight: styles.right,
			ArrowDown: styles.bottom,
		};

		const keyboardClickStyle = `${styles.customButtonKeyboard} ${buttonStyles[style]}`;
		const mouseClickStyle = `${styles.customButton} ${buttonStyles[style]}`;

		return keysSelected[position] ? keyboardClickStyle : mouseClickStyle;
	};

	const mouseEnter = () => {
		setZIndex(2);
	};
	const mouseLeave = () => {
		setZIndex(1);
	};

	const handleMouseDown = () => {
		setSelectComandArrow(valueArrows[position] as TypeSelectArrow);
	};

	const handleMouseUp = () => {
		setSelectComandArrow('STOP');
	};

	return (
		<div
			ref={controlButtonRef}
			className={getStyle(position)}
			style={{ zIndex, ...style }}
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
		>
			<span>{children}</span>
		</div>
	);
});

export default ControlButton;
