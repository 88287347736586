import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styles from './MapDevicesPage.module.scss';
import { apiGet } from '../../api/api';
import { prepareMapDevices } from '../../api/MapPage/prepareMapData';
import { PrepareDevices } from '../../api/MapPage/prepareMapData.interface';
import { MapDevicesPageProps as Props } from '../Pages.interface';
import { checkPageReady } from '../../helpers/functions/loading/mapDevicesLoading';
import HelmetTags from '../../helpers/components/HelmetTags/HelmetTags';
import { CheckLoading } from '../../components/CheckLoading/CheckLoading';
import connectState from '../../store/connectState/connectState';
import placeState from '../../store/placeState/placeState';
import MainButton from '../../helpers/components/MainButton/MainButton';
import BackIcon from '../../assets/Icons/LeftMenu/BackIcon';
import MapDevices from '../../components/MapDevices/MapDevices';
import mapState from '../../store/mapState/mapState';
import FieldSearchPlaces from '../../components/FieldSearchPlaces/FieldSearchPlaces';
import devicesState from '../../store/devicesState/devicesState';

type GetDevices = (url: string, controller: AbortController) => void;

const MapDevicesPage: React.FC<Props> = observer(({ metaTags }) => {
	const { setCenterMap, setNamePopup } = mapState;
	const { getDevicesLink } = connectState;
	const { selectPlaceId } = placeState;
	const { devices, selectDeviceId, setDevices } = devicesState;
	
	const navigate = useNavigate();
	
	useEffect(() => {
		if (selectPlaceId === null) {
			navigate('/');
		}
		
		const controller = new AbortController();

		if (!getDevicesLink || !selectPlaceId) return;

		const url = `${getDevicesLink}${selectPlaceId}/getcameras/`;
		getDevices(url, controller);

		return () => {
			controller.abort();
		};
	}, [getDevicesLink]);

	useEffect(() => {
		if(!devices.length) return;

		const index = devices.findIndex(({id}) => id === selectDeviceId);

		const { name, coords } = devices[index ?? 0];

		setNamePopup(name);
		setCenterMap(coords);
	}, [devices]);

	const getDevices: GetDevices = (url, controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error }) => {
			if (error.isError) {
				if (!error.isAborted) navigate('/');
				return;
			}

			const devices = prepareMapDevices(data as PrepareDevices);
			setDevices(devices);

			checkPageReady('isDevices', true);
		});
	};

	const handleGoBack = () => {
		navigate('/');
	};

	return (
		<CheckLoading page="mapDevices">
			<div className={styles.parent}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces isDeviceMap={true} />

				<div className={styles.container}>
					<MapDevices />
				</div>
			</div>
		</CheckLoading>
	);
});

export default MapDevicesPage;
