import { observer } from 'mobx-react-lite';
import { CircularProgress, styled } from '@mui/material';
import makeAnimated from 'react-select/animated';
import styles from './EditMenu.module.scss';
import Select from 'react-select';
import { Box } from '@mui/material';
import { customStyles } from '../../../../helpers/components/SelectStyle/SelectStyles';
import { typesArea } from '../PolygonMenu';

const ModLoaded = styled(CircularProgress)({
	position: 'absolute',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	zIndex: '1000',
	margin: 'auto',
	color: '#00c2fd',
});

const EditMenu = observer(({ isLoading, name, minTemp, maxTemp, setName, setMin, setMax, setType }) => {

	return (
		<>
			<Box className={styles.titleBox}>
				<span className={styles.title}>Редактирование</span>
			</Box>

			{isLoading && <ModLoaded size={50}/>}

			<div className={styles.content}>
				<div>
					<div className={styles.subTitle}>Название</div>
					<div className={styles.search}>
						<input
							className={styles.searchInput}
							value={name}
							placeholder='Новое название'
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				</div>

				<div className={styles.subTitle}>Минимальная температура</div>
				<div className={styles.search}>
					<input
						className={styles.searchInput}
						value={minTemp}
						placeholder='Укажите температуру'
						type='number'
						onChange={(e) => setMin(e.target.value)}
					/>
				</div>

				<div className={styles.subTitle}>Максимальная температура</div>
				<div className={styles.search}>
					<input
						className={styles.searchInput}
						value={maxTemp}
						placeholder='Укажите температуру'
						type='number'
						onChange={(e) => setMax(e.target.value)}
					/>
				</div>

				<div className={styles.subTitle}>Тип зоны</div>
				<Select
					styles={customStyles}
					defaultValue={typesArea[0]}
					options={typesArea}
					components={makeAnimated}
					onChange={(e) => setType(e.value)}
				/>
			</div>
		</>
	);
});

export default EditMenu;
