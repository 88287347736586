import Polygon from './Polygon';
import { hideMenu } from 'react-contextmenu/modules/actions';
import devicesState from '../../../store/devicesState/devicesState';
import canvasState from '../../../store/canvasState/canvasState';
import presetState from '../../../store/presetState/presetState';
import controlState from '../../../store/controlState/controlState';

const getCameraId = () => {
	return devicesState.selectDeviceId;
};

const getPresetId = () => {
	return presetState.selectPresetId;
};

export default class Polygons {
	canvas = null;
	socket = null;
	id = '';

	startX = null;
	startY = null;
	width = null;
	height = null;
	saved = '';
	isCreateRect = false;

	cameraId = null;
	presetId = null;
	polygons = [];
	curPolygon = null;
	isDrag = false;
	handlesSize = null;
	mousePos = { x: null, y: null };

	constructor(canvas, socket = null, id = null) {
		this.canvas = canvas;
		this.socket = socket;
		this.id = id;

		this.destroyEvents();

		this.ctx = canvas.getContext('2d');

		this.cameraId = getCameraId();
		this.presetId = getPresetId();

		this.polygons = this.getPolygonsCopy();

		this.drawPolygons();
		this.polygons = this.showCenterPoint();

		this.handlesSize = 8;
		this.currentHandle = -1;

		this.listen();
	}

	getPolygonsCopy = () => {
		const isNotEmpty = !!canvasState.saveDataTest?.[this.cameraId]?.[this.presetId]?.length;

		if (isNotEmpty) {

			return canvasState.rawData[this.cameraId][this.presetId].map((rawPolygon) => {
				const { id, name, points, startSize, attributes } = rawPolygon;
				const polygon = new Polygon(id, startSize.x, startSize.y, startSize.w, startSize.h);

				const newPoints = points.map((point) => ({ ...point }));
				const newAttributes = { ...attributes };

				polygon.setName(name);
				polygon.setPoints(newPoints);
				polygon.setAttribute(newAttributes);

				return polygon;
			});
		} else return [];
	};

	setPolygons = (polygons) => {
		this.polygons = polygons;
	};
	setCurPolygon = (num) => {
		this.curPolygon = num;
	};

	listen() {
		this.canvas.onmousemove = this.mouseMoveHandler.bind(this);
		this.canvas.onmousedown = this.mouseDownHandler.bind(this);
		this.canvas.onmouseup = this.mouseUpHandler.bind(this);
		this.canvas.oncontextmenu = this.mouseRightClickHandler.bind(this);
	}

	mouseDownHandler(e) {
		if (!canvasState.isCreatePolygon && !canvasState.isEditPolygon) return;
		
		this.findMousePosition(e);

		switch (e.which) {
		case 1: {
			this.lmbDown(e);
			break;
		}
		case 2: {
			this.cmbDown(e);
			break;
		}
		case 3: {
			this.rmbDown(e);
			break;
		}
		default: {
			console.log('Нажатие какой-то кнопкой мыши');
		}
		}
	}

	lmbDown = (/*e*/) => {
		hideMenu();
		this.isDrag = true;

		this.findAnyPoint();

		this.currentHandle < 0 ? this.startCreateRect() : this.changePolygonPointPosition();
	};
	cmbDown = () => {
		console.log('Нажата СКМ');
	};
	rmbDown = () => {
		if (this.selectPolygon()) {
			this.polygonSelection();
		} else {
			hideMenu();
			canvasState.setCurrentPolygonNum(-1);
		}
	};

	mouseUpHandler(e) {
		switch (e.which) {
		case 1: {
			this.lmbUp(e);
			break;
		}
		case 2: {
			this.cmbUp(e);
			break;
		}
		case 3: {
			this.rmbUp(e);
			break;
		}
		default: {
			console.log('Отпущена какая-то кнопка мыши');
		}
		}
	}

	lmbUp = () => {
		if (this.isCreateRect && !canvasState.isOneReadyPolygon) {
			this.createNewPolygon();
		}

		this.isDrag = false;
		this.currentHandle = -1;
		const isSelectPolygon = this.selectPolygon();

		if (isSelectPolygon) {
			this.polygons = this.showCenterPoint();
			this.polygonSelection();
		} else {
			this.drawPolygons();
		}
	};

	cmbUp = () => {
		const { isCreatePolygon, isEditPolygon } = canvasState;

		this.findAnyPoint();

		const isPolygonSelected = Number.isInteger(this.curPolygon);
		const isPointExist = this.currentHandle !== -1;

		const isCorrectData = isPolygonSelected && isPointExist && (isCreatePolygon || isEditPolygon);
		
		if (!isCorrectData) return;
		
		const { points } = this.polygons[this.curPolygon];
		const { id } = points[this.currentHandle];

		this.polygons[this.curPolygon].deletePoint(id);
		const isTooFewPoints = this.polygons[this.curPolygon].getPoints().length <= 2;

		if (isTooFewPoints) {
			this.deletePolygon();

			this.curPolygon = null;
			this.currentHandle = -1;
		} else {
			this.polygons[this.curPolygon].points = this.polygons[this.curPolygon].getPoints();
			this.polygons = this.showCenterPoint();
		}

		this.drawPolygons();
	};

	rmbUp = () => {
		console.log('Отпущена ПКМ');
	};

	/* FUNCTION: lmbUp */
	createNewPolygon = () => {
		this.isCreateRect = false;
		this.polygons.push(
			new Polygon(canvasState.incReadyRectCounter(), this.startX, this.startY, this.width, this.height)
		);
		canvasState.setIsOneReadyPolygon(true);
	};

	mouseMoveHandler(e) {
		this.bounds = e.target.getBoundingClientRect();
		this.findMousePosition(e);

		if (!canvasState.isCreatePolygon && !canvasState.isEditPolygon) return;

		if (this.isDrag && this.currentHandle < 0) {
			const minSize = 20;

			const isMinWidth = Math.abs(this.mousePos.x - this.startX) >= minSize;
			const isMinHeight = Math.abs(this.mousePos.y - this.startY) >= minSize;

			if (isMinWidth && isMinHeight) this.isCreateRect = true;
			if (this.isDrag && this.isCreateRect) {
				this.drawNewRect();
				canvasState.setPointChanged(true);
			}
		}

		if (this.currentHandle !== -1 && this.isDrag) {
			this.changePointPosition();
			this.redrawPoint();
		}
	}

	/* FUNCTION: mouseMoveHandler */
	findAnyPoint = () => {
		for (let i = 0; i < this.polygons.length; i++) {
			const { points } = this.polygons[i];
			this.currentHandle = this.getHandle(points);

			if (this.currentHandle !== -1) {
				this.curPolygon = i;
				break;
			}
		}
	};

	changePointPosition = () => {
		const point = this.polygons[this.curPolygon].points[this.currentHandle];

		point.x = this.mousePos.x;
		point.y = this.mousePos.y;
		canvasState.setPointChanged(true);
	};

	redrawPoint = () => {
		const pointsWithId = [...this.polygons];
		pointsWithId[this.curPolygon] = {
			...this.polygons[this.curPolygon],
			points: this.polygons[this.curPolygon].getPoints(),
		};

		this.drawPolygons(pointsWithId);
		this.preparationPoints(pointsWithId[this.curPolygon], pointsWithId[this.curPolygon].points);
	};

	polygonSelection = () => {
		this.drawPolygons();

		if (this.curPolygon >= this.polygons.length) return;

		const polygon = this.polygons[this.curPolygon];
		const points = this.polygons[this.curPolygon].getPoints();
		const centerPoints = this.polygons[this.curPolygon].points.filter(({ id }) => id === null);

		this.preparationPoints(polygon, points);
		this.preparationCenterPoints(polygon, centerPoints);
	};

	mouseRightClickHandler = (e) => e.preventDefault();
	findMousePosition = (e) => {
		this.mousePos = { x: e.offsetX, y: e.offsetY };
	};

	point = (x, y) => ({ x, y });
	newPoint = (x, y) => ({ id: null, x, y });
	dist = (p1, p2) => Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));

	getHandle = (points) => {
		const pointIndex = points.findIndex(({ x, y }) => {
			const distance = this.dist(this.mousePos, this.point(x, y));

			return distance <= this.handlesSize;
		});

		return pointIndex;
	};

	addPoints = (first, second) => this.newPoint((first.x + second.x) / 2, (first.y + second.y) / 2);
	deletePolygon = (index = this.curPolygon) => this.polygons.splice(index, 1);

	showCenterPoint = () =>
		this.polygons.map((polygon) => {
			const pointsWithId = polygon.getPoints();

			const points = [];

			pointsWithId.forEach((point, index) => {
				const isMaxLenght = index + 1 === pointsWithId.length;
				const nextIndex = isMaxLenght ? 0 : index + 1;
				
				const nextPoint = pointsWithId[nextIndex];
				const newPoint = this.addPoints(point, nextPoint);

				points.push(point, newPoint);
			});

			return { ...polygon, points };
		});

	preparationPoints = (polygon, points, visible = true) => {
		const pointSize = visible ? polygon.getPointSize() : 0;
		const pointColor = polygon.getPointColor();

		points.forEach(({ x, y }) => this.drawPoints(x, y, pointSize, pointColor));
	};
	preparationCenterPoints = (polygon, points, visible = true) => {
		const centerPointSize = visible ? polygon.getCenterPointSize() : 0;
		const centerPointColor = polygon.getCenterPointColor();

		points.forEach(({ x, y }) => this.drawPoints(x, y, centerPointSize, centerPointColor));
	};

	startCreateRect = () => {
		this.startX = this.mousePos.x;
		this.startY = this.mousePos.y;
		this.ctx.moveTo(this.startX, this.startY);
		this.ctx.beginPath();
		this.saved = this.canvas.toDataURL();
	};
	drawNewRect = () => {
		if(canvasState.isOneReadyPolygon) return;
		this.width = this.mousePos.x - this.startX;
		this.height = this.mousePos.y - this.startY;

		const img = new Image();
		img.src = this.saved;
		img.onload = () => {
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
			this.ctx.beginPath();
			this.ctx.rect(this.startX, this.startY, this.width, this.height);
			this.ctx.stroke();
			this.ctx.closePath();
		};
	};
	changePolygonPointPosition = () => {
		const point = this.polygons[this.curPolygon].points[this.currentHandle];
		if (point.id === null) {
			point.id = this.polygons[this.curPolygon].getIdCounter();
			this.polygons[this.curPolygon].incCounter();
			const points = this.polygons[this.curPolygon].points.filter(({ id }) => id !== null);
			this.polygons[this.curPolygon].setPoints(points);
		}
	};
	selectPolygon = () => {
		if (controlState.isVisibleTempGrid) return false;

		if (this.polygons.length === 0) return false;

		for (let i = 0; i < this.polygons.length; ++i) {
			const points = this.polygons[i].getPoints();
			this.ctx.beginPath();
			this.ctx.moveTo(points[0].x, points[0].y);
			points.forEach(({ x, y }) => this.ctx.lineTo(x, y));
			this.ctx.closePath();

			if (this.ctx.isPointInPath(this.mousePos.x, this.mousePos.y)) {
				this.curPolygon = i;
				canvasState.setCurrentPolygonNum(i);

				return true;
			}
		}

		return false;
	};

	drawPolygons(polygons = this.polygons) {
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

		polygons.forEach((polygon, index) => {
			const points = polygon.getPoints();
			this.startX = points[0].x;
			this.startY = points[0].y;

			for (let i = 1; i < points.length; ++i) {
				this.startX = points[i - 1].x;
				this.startY = points[i - 1].y;

				this.drawLine(points[i].x, points[i].y);
			}

			this.startX = points[points.length - 1].x;
			this.startY = points[points.length - 1].y;
			this.drawLine(points[0].x, points[0].y);

			this.fillPolygon(this.polygons[index].getPoints(), this.polygons[index].getAttributeFillColor());
		});
	}

	drawLine = (x, y) => {
		this.ctx.strokeStyle = this.polygons[this.curPolygon]?.getLineColor();
		this.ctx.lineWidth = this.polygons[this.curPolygon]?.getLineWidth();
		this.ctx.beginPath();
		this.ctx.moveTo(this.startX, this.startY);
		this.ctx.lineTo(x, y);
		this.ctx.stroke();
	};
	fillPolygon = (points, fillColor = 'rgba(170, 170, 170, 0.3)') => {
		this.ctx.beginPath();
		this.ctx.moveTo(points[0].x, points[0].y);
		points.forEach(({ x, y }) => this.ctx.lineTo(x, y));
		this.ctx.fillStyle = fillColor;
		this.ctx.fill();
		this.ctx.closePath();
	};
	drawPoints = (x, y, pointSize, pointColor) => {
		this.ctx.beginPath();
		this.ctx.fillStyle = pointColor;
		// this.ctx.globalCompositeOperation = 'xor';
		this.ctx.arc(x, y, pointSize, 0, 2 * Math.PI);
		this.ctx.fill();

		this.ctx.globalCompositeOperation = 'source-over';
	};

	destroyEvents() {
		this.canvas.onmousemove = null;
		this.canvas.onmousedown = null;
		this.canvas.onmouseup = null;
		this.canvas.oncontextmenu = null;
	}
}
