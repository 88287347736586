import {IconSvgProps} from '../../../helpers/Interfaces/IconSvg.interface';

const VoltmeterIcon: React.FC<IconSvgProps> = ({ width = 38, height = 38, fill = '#D2D2D2' }) => {
	return (
		<svg fill={fill} height={height} width={width} version='1.1' id='Layer_1'  viewBox='0 0 580 503.607' xmlSpace='preserve'>
			<g transform='translate(1 1)'>
				<g>
					<g>
						<path d='M460.639,19.984H40.967C17.466,19.984-1,38.449-1,61.951v377.705c0,23.502,18.466,41.967,41.967,41.967h419.672
				c23.502,0,41.967-18.466,41.967-41.967V61.951C502.607,38.449,484.141,19.984,460.639,19.984z M485.82,439.656
				c0,14.269-10.911,25.18-25.18,25.18H40.967c-14.269,0-25.18-10.911-25.18-25.18V61.951c0-14.269,11.751-25.18,25.18-25.18
				h419.672c14.269,0,25.18,10.911,25.18,25.18V439.656z'/>
						<path d='M435.459,61.951H66.148c-14.269,0-25.18,10.911-25.18,25.18v201.443c0,14.269,10.911,25.18,25.18,25.18h134.295h100.721
				h134.295c14.269,0,25.18-10.911,25.18-25.18V87.131C460.639,72.862,449.728,61.951,435.459,61.951z M209.675,296.967
				c4.197-19.305,20.984-33.574,41.128-33.574c20.144,0,36.931,14.269,41.128,33.574H209.675z M443.852,288.574
				c0,5.036-3.357,8.393-8.393,8.393H308.965c-2.245-16.058-10.841-29.92-23.224-39.012l60.657-111.205
				c24.066,9.424,46.655,22.583,67.238,39.423c3.357,2.518,9.233,1.679,11.751-1.679c2.518-3.357,1.679-9.233-1.679-11.751
				c-21.395-17.043-44.673-30.632-69.354-40.579l13.118-24.05c2.518-4.197,0.839-9.233-3.357-11.751
				c-4.197-1.679-9.233-0.839-11.751,3.357l-14.3,26.476c-27.727-9.163-57.021-13.886-87.261-13.886
				c-62.111,0-123.384,21.823-172.905,61.272c-4.197,2.518-4.197,8.393-1.679,11.751c1.679,2.518,4.197,3.357,6.715,3.357
				c1.679,0,4.197,0,5.036-1.679c47.003-36.931,104.918-57.915,162.833-57.915c27.474,0,54.076,4.004,79.264,11.905l-58.946,109.137
				c-6.317-2.289-13.157-3.534-20.318-3.534c-29.88,0-54.153,21.684-58.161,50.361H66.148c-5.036,0-8.393-3.357-8.393-8.393V87.131
				c0-4.197,4.197-8.393,8.393-8.393h369.311c5.036,0,8.393,3.357,8.393,8.393V288.574z'/>
						<path d='M393.492,338.934c-27.698,0-50.361,22.662-50.361,50.361c0,27.698,22.662,50.361,50.361,50.361
				c5.515,0,10.825-0.912,15.801-2.569c1.212-0.166,1.825-0.662,1.825,0.051c6.715-2.518,12.59-5.875,17.626-10.911
				c2.255-2.706,4.501-5.663,6.496-8.854c5.432-8.035,8.612-17.703,8.612-28.077C443.852,361.597,421.19,338.934,393.492,338.934z
				 M396.849,401.046c0-2.518,0.839-4.197,2.518-5.875c1.679-1.679,3.357-2.518,5.875-2.518c2.518,0,4.197,0.839,5.875,2.518
				l10.911,10.911c-1.679,2.518-3.357,5.036-5.036,6.715c-1.679,1.679-4.197,3.357-6.715,5.036l-10.911-10.911
				C397.689,405.243,396.849,403.564,396.849,401.046z M359.918,389.295c0-18.466,15.108-33.574,33.574-33.574
				c18.177,0,33.089,14.642,33.552,32.712l-4.175-4.175c-9.233-10.072-25.18-10.072-35.252,0
				c-5.036,4.197-7.554,10.911-7.554,17.626c0,6.715,2.518,12.59,7.554,17.626l3.244,3.244
				C373.613,421.4,359.918,406.874,359.918,389.295z'/>
						<path d='M250.803,338.934c-20.39,0-38.037,12.287-45.938,29.817c-0.074,0.136-0.155,0.257-0.226,0.399
				c-0.059,0.157-0.111,0.315-0.169,0.472c-2.59,6.052-4.027,12.702-4.027,19.672c0,6.971,1.438,13.621,4.027,19.672
				c0.058,0.157,0.11,0.315,0.169,0.472c0.216,0.862,0.599,1.612,1.104,2.253c8.281,16.536,25.396,27.964,45.06,27.964
				c27.698,0,50.361-22.662,50.361-50.361C301.164,361.597,278.502,338.934,250.803,338.934z M218.299,397.689
				c-0.107-0.411-0.199-0.828-0.291-1.245c-0.187-2.31-0.779-5.025-0.779-7.148c0-2.896,0.372-5.708,1.07-8.393h15.717
				c5.036,0,8.393,3.357,8.393,8.393c0,5.036-3.357,8.393-8.393,8.393H218.299z M250.803,422.869
				c-8.462,0-16.213-3.179-22.134-8.393h5.347c14.269,0,25.18-10.911,25.18-25.18s-10.911-25.18-25.18-25.18h-5.347
				c5.921-5.214,13.672-8.393,22.134-8.393c18.466,0,33.574,15.108,33.574,33.574S269.269,422.869,250.803,422.869z'/>
						<path d='M99.721,338.934c-27.698,0-50.361,22.662-50.361,50.361c0,20.47,12.384,38.178,30.025,46.032
				c0.064,0.044,0.128,0.089,0.191,0.132c0.157,0.059,0.314,0.111,0.471,0.169c6.052,2.59,12.702,4.028,19.673,4.028
				c6.971,0,13.621-1.438,19.673-4.028c0.157-0.058,0.314-0.11,0.472-0.169c0.862-0.216,1.612-0.599,2.253-1.104
				c16.536-8.281,27.964-25.396,27.964-45.06C150.082,361.597,127.42,338.934,99.721,338.934z M108.115,421.799
				c-2.685,0.698-5.498,1.07-8.393,1.07s-5.708-0.372-8.393-1.07v-15.717c0-5.036,3.357-8.393,8.393-8.393s8.393,3.357,8.393,8.393
				V421.799z M124.902,411.429v-5.347c0-14.269-10.911-25.18-25.18-25.18s-25.18,10.911-25.18,25.18v5.347
				c-5.214-5.921-8.393-13.672-8.393-22.134c0-18.466,15.108-33.574,33.574-33.574s33.574,15.108,33.574,33.574
				C133.295,397.757,130.116,405.508,124.902,411.429z'/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default VoltmeterIcon;