import { CheckEmptyProps } from './CheckEmpty.interface';
import { List, ListItem } from '@mui/material';
import styles from './CheckEmpty.module.scss';

const CheckEmpty: React.FC<CheckEmptyProps> = ({ isCheck, children }) => {
	return (
		<>
			{isCheck ? (
				children
			) : (
				<List>
					<ListItem className={styles.zeroData}>{'Данных нет.'}</ListItem>
				</List>
			)}
		</>
	);
};

export default CheckEmpty;
