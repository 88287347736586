import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {Preset} from './interfaces';
import {PresetSettings} from '../../api/Translation/prepareTranslationData.interface';

class PresetState {
	presets: Preset[] = [];
	selectPresetId: Preset['id'] | null = null;
	deletePreset: Preset['number'] | null = null;
	isSavePreset = false;
	isDeletePreset = false;
	presetSettings: PresetSettings = {
		dataSoundCorr: 10000,
		dataSoundEnergy: 10000,
		dataSoundFrequency: 10000,
		id: 0,
		idPsw: '0',
		isDeleted: false,
		name: 'string',
		presetMaxTemp: 0,
		presetMinTemp: 0,
		presetNumber: 0,
		thresholdUv: 0,
	};
	isPatrol = false;
	patrolType = 'off';

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: 'presetId',
			properties: ['selectPresetId'],
			storage: window.localStorage,
		});
	}

	setIsPatrol = (isHandler = false) => {
		this.isPatrol = isHandler;
	};

	setDeletePreset = (presetNum: Preset['number']) => {
		this.deletePreset = presetNum;
	};

	setSettingsPreset = (data: PresetSettings) => {
		this.presetSettings = data;
	};

	setIsSavePreset = () => {
		this.isSavePreset = !this.isSavePreset;
	};

	setIsDeletePreset = () => {
		this.isDeletePreset = !this.isDeletePreset;
	};

	setPresets = (newPresets: Preset[]) => {
		this.presets = newPresets;
	};

	setSelectPresetId = (selectId: Preset['id'] | null) => {
		this.selectPresetId = selectId;
	};

	setSelectedPresets = (idPreset: Preset['id'] | null) => {
		if(!idPreset || !this.presets.length) return;
		
		try {
			this.presets.forEach((preset) => (preset.isActive = false));

			const index = this.presets.findIndex(({ id }: Preset) => id === idPreset);

			this.presets[index].isActive = true;
		} catch (error) {
			console.log(error);
		}
	};

	setPatrolType = (patrolType: string) => {
		this.patrolType = patrolType;
	};
}

const presetState = new PresetState();
export default presetState;
