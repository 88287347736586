import React from 'react';
import styles from './CustomHeader.module.scss';
import AddIcon from '../../../assets/Icons/Account/AddIcon';
import {CustomHeaderProps as Props, CustomHeaderUserGroupRole} from './CustomHeader.inteface';

const CustomHeader: React.FC<Props> = ({ title, create, type, groupRole}) => {
	const isActionType = type === 'action';

	const groupRoleComponents: CustomHeaderUserGroupRole = {
		groupadmin: true,
		groupuser: false,
		camerauser: false,
	};

	const roleComponent = groupRoleComponents[groupRole];
	return (
		<div className={styles.headerCard}>
			<div className={styles.title}>
				{title}
			</div>

			{roleComponent &&
				(
					<div className={styles.iconsContainer}>
						<div>
							{!isActionType &&
								(
									<div className={styles.icon} onClick={() => create(type)}>
										<AddIcon/>
									</div>
								)
							}
						</div>
					</div>
				)}
		</div>
	);
};

export default CustomHeader;
