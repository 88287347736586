import styles from './InfographicsEvent.module.scss';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import connectState from '../../../../../store/connectState/connectState';
import devicesState from '../../../../../store/devicesState/devicesState';
import { apiGet } from '../../../../../api/api';
import { prepareInfoGraficEvents } from '../../../../../api/Control/prepareControlData';
import { ChartGraficInfo as GraficInfo, ChartProps as Props, ChartLineGraf as LineGraf } from './Chart.interface';
import { RawGraficEvent } from '../../../../../api/Control/prepareControlData.interface';
import CheckEmpty from '../../../../../helpers/components/CheckEmpty/CheckEmpty';

const getKeys = (data: GraficInfo): string[] => {
	const keys = [];

	for (const key in data) {
		if (key === 'id' || key === 'date' || key === 'time') continue;
		keys.push(key);
	}

	return [...keys];
};

const Chart: React.FC<Props> = observer(({ dateInfo }) => {
	const { getEventsLink } = connectState;
	const { selectDeviceId } = devicesState;

	const colors = ['#00b336', '#00C2FD', 'red', 'gold'];

	const [keys, setKeys] = useState<string[]>([]);
	const [dataGrafic, setDataGrafic] = useState<GraficInfo[]>([]);

	const [showLines, setShowLines] = useState<LineGraf>({
		isChange: false,
		lines: keys.map((name) => ({ isShow: true, name })) || [],
	});

	useEffect(() => {
		if (!dateInfo) return;
		const partsDate = dateInfo.split('.');
		const formatDate = `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`;

		const datePartLink = `/dayevents/?search=${formatDate}`;

		getEvents(`${getEventsLink}${selectDeviceId}${datePartLink}`);
	}, [getEventsLink, selectDeviceId, dateInfo]);

	useEffect(() => {
		setKeys(getKeys(dataGrafic[0]) || []);
	}, [dataGrafic]);

	useEffect(() => {
		if (!dataGrafic.length) return;

		if (!Number.isFinite(dataGrafic[0][keys[0] as keyof GraficInfo])) return;

		setShowLines({
			...showLines,
			isChange: false,
			lines: keys.map((name) => ({ isShow: true, name })),
		});
	}, [dataGrafic, keys]);

	const getEvents = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const infografics = prepareInfoGraficEvents(data as RawGraficEvent[]);
			setDataGrafic(infografics);
		});
	};

	const handleLegendClick = (a: any) => {
		const tempLines = [...showLines.lines];
		const index = tempLines.findIndex(({ name }) => name === a.dataKey);
		tempLines[index].isShow = !tempLines[index].isShow;

		setShowLines({
			...showLines,
			isChange: !showLines.isChange,
			lines: [...tempLines],
		});
	};

	return (
		<div className={styles.container}>
			<CheckEmpty isCheck={!!dataGrafic.length}>
				<ResponsiveContainer width='90%' height='80%'>
					<LineChart margin={{ top: 0, left: -32, right: 0, bottom: 0 }} data={dataGrafic}>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='time' />
						<YAxis />
						<Tooltip />
						<Legend onClick={handleLegendClick} />

						{keys.map((name, index) => {
							const key = `View-Graph-Camera-${name}-${index}`;
							const color = colors[index];

							const lineIndex = showLines.lines.findIndex((line) => line.name === name);
							const isShowLine = showLines.lines[lineIndex]?.isShow;

							return (
								<Line
									key={key}
									type={'monotone'}
									dataKey={name}
									stroke={isShowLine ? color : '#fff'}
									activeDot={{ r: 8 }}
									dot={false}
								/>
							);
						})}
					</LineChart>
				</ResponsiveContainer>
			</CheckEmpty>
		</div>
	);
});

export default Chart;
