import React from 'react';
import styles from './Notification.module.scss';
import { ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import placeState from '../../../../store/placeState/placeState';
import { Notifications } from '../../../../api/Notifications/prepareNotifications.interface';
import {apiPost} from '../../../../api/api';
import connectState from '../../../../store/connectState/connectState';
import autorizeState from '../../../../store/accoutState/autorizeState';
import notificationsState from '../../../../store/notificationsState/notificationsState';

interface PopoverItemProps{
	event: Notifications;
	handleClosePopover: () => void;
}

export const Notification: React.FC<PopoverItemProps> = ({ event, handleClosePopover }) => {
	const { id, spot, datetime, eventId, message, cameraName} = event;

	const { setSelectPlaceId, setSelectedPlaces } = placeState;
	const { readNotificationLink } = connectState;
	const { setUpdateData } = autorizeState;
	const { setEventID } = notificationsState;

	const handleSelectItem = () => {
		setEventID(eventId);
		const url = `${readNotificationLink}${id}/read/`;
		readNotification(url);

		setSelectPlaceId(spot);
		setSelectedPlaces(spot, true);
	};

	const readNotification = (url: string) => {
		apiPost(url, {}, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			setUpdateData('isNotifications');
			handleClosePopover();
		});
	};

	return (
		<div>
			<ListItem className={styles.item} onClick={handleSelectItem} >
				<div className={styles.listItemIcon}/>

				<NavLink
					className={styles.navLink}
					to={'/control'}
				>
					<ListItemText className={styles.descriptionText}>
						{cameraName}
					</ListItemText>
					<ListItemText className={styles.messageText}>
						{message}
					</ListItemText>
					<ListItemText className={styles.dateText}>
						<div>{datetime}</div>
					</ListItemText>
				</NavLink>
			</ListItem>
		</div>
	);
};
