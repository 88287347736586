import { GraficInfo } from '@components/Control/InfographicsEvent/InfographicsEvent.interface';
import { RowTable } from '@components/Control/UnloadingEvents/UnloadingEvents.interface';
import { getDefEvent } from '@helpers/defaultsData/defaultsData';
import { middleTemperature } from '@helpers/functions/helpFunctions';
import canvasState from '@store/canvasState/canvasState';
import { PreparePolygonControl, ResultPolygonControl } from '@store/presetState/interfaces';
import { RawDataEvents, RawEvent, RawGraficEvent } from './prepareControlData.interface';
import { ResultEventData } from '@store/eventState/interface';

export const prepareEvent = (data: RawEvent): ResultEventData => {
	const {
		id,
		id_message,
		camera_name,
		img_ir,
		sound,
		data_sound_corr,
		data_sound_energy,
		data_sound_frequency,
		date,
		time,
		event_type,
		image_base64,
		ir_max,
		ir_mean,
		ir_min,
		preset,
		preset_name,
		zone,
		zone_name,
	} = data;

	const defEvent = getDefEvent();

	const prepareGrid = img_ir
		.replace(/[\\[\]]/g, '')
		.split('; ')
		.map((value) => value.split(', ').map((item) => Number((+item).toFixed(1))));
	// Транспонируем матрицу данных
	const temperGrid = prepareGrid[0].map((_, colIndex) => prepareGrid.map((row) => row[colIndex])).flat();

	const rows = 24;
	const columns = 32;
	const resultGrid = [];

	for (let i = 0; i < rows; i++) {
		const row = [];
		for (let j = 0; j < columns; j++) {
			const index = i * columns + j;
			row.push(temperGrid[index]);
		}
		resultGrid.push(row);
	}

	//Поворачиваем матрицу на 90градусов
	// const transposedMatrix = [];
	// for (let col = 0; col < columns; col++) {
	// 	const newRow = [];
	// 	for (let row = rows - 1; row >= 0; row--) {
	// 		newRow.push(resultGrid[row][col]);
	// 	}
	// 	transposedMatrix.push(newRow);
	// }

	const newDate = new Date(date);

	const truncatedTime = time.split('.')[0];

	return {
		...defEvent,
		id,
		id_message: id_message,
		cameraName: camera_name,
		idPreset: preset,
		time: truncatedTime,
		date: newDate.toLocaleDateString(),
		name: `Event #${id_message}`,
		presetName: !preset_name ? 'Не установлена' : preset_name,
		messageType: event_type,
		soundEnergy: data_sound_energy,
		soundCorrelation: data_sound_corr,
		soundFrequency: data_sound_frequency,
		zone,
		zoneName: !zone_name ? 'Нет зоны' : zone_name,
		t_max: ir_max.toFixed(1),
		t_mean: ir_mean.toFixed(1),
		t_min: ir_min.toFixed(1),
		viewData: {
			...defEvent.viewData,
			temperGrid: resultGrid,
			temperGraph: {
				name: 'Average Temperature',
				value: middleTemperature(img_ir),
			},
			airTemp: {
				name: 'Air Temperature',
				value: ir_min,
			},
		},
		img: image_base64,
		sound: {
			track: sound,
			corr: data_sound_corr,
			energy: data_sound_energy,
		},
	};
};

export const prepareControlEvents = (data: RawDataEvents) => {
	const { count, next, previous, results } = data;

	const pagination = { next, previous, count };

	const dataEvents = results.map((event) => prepareEvent(event));

	return { pagination, dataEvents };
};

export const prepareInfoGraficEvents = (data: RawGraficEvent[]): GraficInfo[] => {
	const infoResult = data.map((event: RawGraficEvent) => {
		const { id_message, date, ir_mean, ir_min, ir_max, time } = event;
		const newDate = new Date(date);

		const truncatedTime = time.split('.')[0];

		return {
			id: id_message,
			time: truncatedTime,
			date: newDate.toLocaleDateString(),
			['Минимальная Температура']: Number(ir_min.toFixed(1)),
			['Средняя Температура']: Number(ir_mean.toFixed(1)),
			['Максимальная Температура']: Number(ir_max.toFixed(1)),
		};
	});

	return infoResult;
};

export const prepareUnloadingEvents = (data: RawEvent[]): RowTable[] => {
	const dataEvents = data.map((event) => {
		const { id, id_message, data_sound_frequency, date, time, event_type, preset_name, zone_name, image_base64, ir_max, ir_mean, ir_min } = event;
		
		const newDate = new Date(date);

		return {
			id,
			id_message,
			date: newDate.toLocaleDateString(),
			time,
			presetName: !preset_name ? 'Не установлена' : preset_name,
			zoneName: !zone_name ? 'Нет зоны' : zone_name,
			messageType: event_type,
			maxTemp: `${ir_max.toFixed(1)} °C`,
			meanTemp: `${ir_mean.toFixed(1)} °C`,
			minTemp: `${ir_min.toFixed(1)} °C`,
			soundFrequency: data_sound_frequency?.toString(),
			img: image_base64,
		};
	});

	return dataEvents;
};

export const preparePolygon = (polygon: PreparePolygonControl): ResultPolygonControl[] => {
	const { name, type_zone, irPoints, startSize } = polygon;

	irPoints.forEach((point) => {
		const { newSize } = canvasState.canvasReSize;

		const FW = 1920;
		const FH = 1080;

		const NW = newSize.width;
		const NH = newSize.height;

		point.x = Math.round((point.x / FW) * NW);
		point.y = Math.round((point.y / FH) * NH);
	});

	const resultPolygon = {
		name,
		points: irPoints,
		startSize,
		type_zone,
		attributes: {
			type: 'notype',
			fillColor: 'rgba(170, 170, 170, 0.3)',
			rgba: { r: 170, g: 170, b: 170, a: 0.3 },
		},
	};

	return [resultPolygon];
};
