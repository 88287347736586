import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ValuesIcon: React.FC<IconSvgProps> = ({ width = 38, height = 38, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 18 18' version='1.1' fill='none' stroke={fill} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
			<rect height='4.5' width='3' y='1.75' x='3.25'/>
			<path d='m9.75 6.25h3m-3-4.5h1.5v4'/>
			<rect height='4.5' width='3' y='9.75' x='9.75'/>
			<path d='m3.25 14.25h3m-3-4.5h1.5v4'/>
		</svg>
	);
};

export default ValuesIcon;
