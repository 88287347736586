import { IconSvgProps as Props } from '../../../helpers/Interfaces/IconSvg.interface';

const BrushIcon: React.FC<Props> = ({ width = 28, height = 28, fill = '#D2D2D2', disabled = false }) => {
	const newFill = disabled ? 'hsl(0, 0%, 50%)' : fill;

	return (
		<svg version='1.0' id='Layer_1' width={width} height={height} viewBox='0 0 64 64' enableBackground='new 0 0 64 64'>
			<title>Работа с зонами</title>
			<g>
				<path
					fill={newFill}
					d='M61.946,2.055c-2.74-2.739-7.18-2.739-9.92,0L19.368,34.713c-3.036-0.691-6.808-0.276-10.559,3.475
		c-4.702,4.702-5.243,9.118-5.815,13.795c-0.407,3.319-0.827,6.751-2.861,10.527c-0.209,0.391-0.139,0.872,0.175,1.184
		c0.312,0.313,0.793,0.384,1.183,0.174c3.777-2.034,7.209-2.454,10.528-2.86c4.676-0.573,9.092-1.113,13.794-5.815
		c3.751-3.751,4.167-7.522,3.475-10.56l32.659-32.657C64.685,9.234,64.685,4.794,61.946,2.055z M24.396,53.774
		c-4.213,4.214-8.109,4.691-12.619,5.243c-2.767,0.339-5.605,0.687-8.699,1.906c1.22-3.095,1.567-5.934,1.905-8.699
		c0.553-4.51,1.03-8.407,5.243-12.62c6.977-6.977,13.881-0.285,14.169-0.001C24.682,39.895,31.373,46.798,24.396,53.774z
		 M28.591,42.495c-1.1-2.588-2.74-4.271-2.777-4.308c-0.038-0.037-1.72-1.678-4.308-2.777l23.437-23.436l7.084,7.084L28.591,42.495z
		 M53.443,17.642l-7.084-7.085l1.417-1.417l7.084,7.085L53.443,17.642z M56.278,14.808l-7.086-7.085l4.251-4.251
		c1.957-1.956,5.129-1.956,7.085,0.001c1.957,1.956,1.957,5.128,0,7.084L56.278,14.808z'
				/>
				<path
					fill={newFill}
					d='M21.562,41.022c-0.392,0.392-0.392,1.025,0,1.417c2.348,2.347,2.348,6.153,0,8.502
		c-0.393,0.392-0.392,1.025,0,1.417c0.392,0.391,1.025,0.391,1.417,0c3.13-3.131,3.13-8.206,0-11.336
		C22.587,40.63,21.953,40.63,21.562,41.022z'
				/>
			</g>
		</svg>
	);
};

export default BrushIcon;
