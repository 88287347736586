import React, {useEffect, useState} from 'react';
import styles from './CustomAnaliticaCard.module.scss';
import { CustomAnaliticaCardProps as Props } from './CustomAnaliticaCard.interface';
import Chart from '../Chart/Chart';
import {apiGet} from '../../../api/api';
import {prepareUVEvents} from '../../../api/AnaliticaPage/prepareAnaliticaData';
import devicesState from '../../../store/devicesState/devicesState';
import connectState from '../../../store/connectState/connectState';
import {UVAnalitica} from '../../../store/analiticaState/interface';
import BackIcon from '../../../assets/Icons/LeftMenu/BackIcon';
import {UVResults} from '../../../api/AnaliticaPage/interface';
import presetState from '../../../store/presetState/presetState';
import CheckEmpty from '../../../helpers/components/CheckEmpty/CheckEmpty';

const CustomAnaliticaCard: React.FC<Props> = ({ item }) => {
	const { selectDeviceId } = devicesState;
	const { aiEventLink } = connectState;
	const { selectPresetId } = presetState;

	const { title } = item;

	const typePeriods = ['d', 'w', 'm'];

	const [uvAnalitica, setUvAnalitica] = useState<UVAnalitica[]>([]);
	const [selectPeriod, setSelectPeriod] = useState<number>(0);
	const [selectDates, setSelectDates] = useState<string[]>([]);
	const [countRows, setCountRows] = useState<number>(0);
	const [pageCounter, setPageCounter] = useState<number>(0);
	const [prevLink, setPrevLink] = useState('');
	const [nextLink, setNextLink] = useState('');
	const [currentLink, setCurrentLink] = useState('');
	const [titleDates, setTitleDates] = useState('');

	const handleChangePeriod = ( id: number ) => {
		setSelectPeriod(id);
		setPageCounter(0);
	};

	const buttonsPeriod = [
		{id: 0, title: 'День', action: handleChangePeriod},
		{id: 1, title: 'Неделя', action: handleChangePeriod},
		{id: 2 ,title: 'Месяц', action: handleChangePeriod},
	];

	useEffect(() => {
		if (countRows === 0) return;
		const date = selectPeriod === 0 ? uvAnalitica[0]?.date :`${uvAnalitica[0]?.dateBegin} - ${uvAnalitica[0]?.dateFinish}`;
		setTitleDates(date);
		// if (selectDates[0] === undefined) {
		// 	const newDate = selectDates[2];
		//
		// 	setTitleDates(newDate);
		// } else {
		// 	const firstDate = selectDates[0];
		// 	const secondDate = selectDates[1];
		// 	setTitleDates(`${firstDate} - ${secondDate}`);
		// }
	}, [selectDates]);

	useEffect(() => {
		const controller = new AbortController();
		if(!selectPresetId || !selectDeviceId) return;

		const url = `${aiEventLink}${selectDeviceId}/getaievents/?id_psw=${selectDeviceId}&preset=${selectPresetId}&analytic_type=uv&period_type=${typePeriods[selectPeriod]}&page=1`;
		getUVEvents(url, controller);

		return () => controller.abort();
	}, [aiEventLink, selectPeriod, selectPresetId]);

	const getUVEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			setUvAnalitica([] as UVAnalitica[]);
			if (isError) return;

			const uvEvents = prepareUVEvents(data as UVResults);
			const { analitica, pagination} = uvEvents;

			setCountRows(pagination.count);
			setPrevLink(pagination.previous);
			setNextLink(pagination.next);
			setCurrentLink(url);

			setUvAnalitica(analitica);
			setSelectDates([analitica[0]?.dateBegin, analitica[0]?.dateFinish, analitica[0]?.date ? analitica[0]?.date : '', analitica[0]?.dtCreated]);
		});
	};

	const handleChangePage = (page: number) => {
		let linkMove = page === 1 ? nextLink : prevLink;

		if (!linkMove) {
			linkMove = currentLink;
		}

		const urlParts = linkMove.split('&');
		let updatelink = linkMove;

		if (urlParts.length > 1) {
			const queryParams = new URLSearchParams(urlParts[1]);

			urlParts.forEach((item, index) => {
				if (index < 2) return;

				const [key, value] = item.split('=');
				queryParams.set(key, value);
			});

			updatelink = `${urlParts[0]}&${queryParams.toString()}`;
		} else {
			updatelink = linkMove;
		}

		updateEvents(updatelink);
		setPageCounter(pageCounter + page);
	};

	const updateEvents = (link: string) => {
		const controller = new AbortController();

		getUVEvents(link, controller);

		return () => {
			controller.abort();
		};
	};

	return (
		<div className={styles.box}>
			<div className={styles.card}>
				<div className={styles.header}>
					{title}
				</div>
				<div className={styles.containerButtons}>
					{buttonsPeriod.map(({title, action, id}) => {
						const isActive = id === selectPeriod;
						return(
							<div key={`Buttons-Period-${title}-${id}`} onClick={() => action(id)} className={`${styles.buttonPeriod} ${isActive && styles.active}`}>
								{title}
							</div>
						);
					})}
				</div>
				<CheckEmpty isCheck={!!uvAnalitica?.length}>

					<div className={styles.chartsBox}>
						<div className={styles.charts}>
							<Chart dataInfo={uvAnalitica} type={selectPeriod} numberChart={0} />
						</div>

						<div className={styles.charts}>
							<Chart dataInfo={uvAnalitica} type={selectPeriod}  numberChart={1}/>
						</div>

						<div className={styles.chartsTitle}>Дата аналитики: {selectDates[3]}</div>
					</div>
				</CheckEmpty>

				<div className={styles.center}>
					<button
						className={
							(pageCounter === 0)
								? styles.arrowsIconBtnDisabled
								: styles.arrowsIconBtn
						}
						disabled={pageCounter === 0}
						onClick={() => handleChangePage(-1)}
					>
						<BackIcon />
					</button>

					<div className={styles.textPage}>
						{titleDates}
					</div>
					<button
						className={
							((pageCounter === countRows - 1) || (countRows === 0))
								? `${styles.arrowsIconBtnDisabled} ${styles.rightArrow}`
								: `${styles.arrowsIconBtn} ${styles.rightArrow}`
						}
						disabled={(pageCounter === countRows - 1) || (countRows === 0)}
						onClick={() => handleChangePage(1)}
					>
						<BackIcon />
					</button>
				</div>

			</div>
		</div>
	);
};

export default CustomAnaliticaCard;
