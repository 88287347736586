import React from 'react';
import styles from './Confirm.module.scss';
import { apiDelete } from '../../../../../api/api';
import { ConfirmProps as Props } from './Confirm.interface';
import autorizeState from '../../../../../store/accoutState/autorizeState';
import {postUserAction} from '../../../../../pages/AccountPage/AccountPage';

const Confirm: React.FC<Props> = ({
	name,
	handleClose,
	url,
	setDisableButton,
	isDisableButton,
	updateTitle,
	title = 'устройства',
	placeholder = 'Название',
}) => {
	const { setUpdateData, setLoadingTables } = autorizeState;

	const handleDelete =  () => {
		apiDelete(url,  {}).then(({ error }) => {
			const { isError } = error;
			if (isError) return;
			postUserAction(`Удаление ${title}`, name);
			setLoadingTables(updateTitle, true);
		});
		const seconds = 3000;
		setTimeout(() => {
			setUpdateData(updateTitle);
		}, seconds);

		handleClose();
	};

	const checkMatch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isMatched = e.target.value === name;
		setDisableButton(!isMatched);
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalContent}>
				Удаление {title}
				<span style={{color: '#00C2FD'}}> {name}</span>
			</div>
			<input
				className={styles.checkInput}
				placeholder={placeholder}
				onChange={checkMatch}
			/>
			<div className={styles.modalButtons}>
				<button
					className={styles.modalDeleteButton}
					disabled={isDisableButton}
					onClick={handleDelete}
				>
					Удалить
				</button>
				<button
					className={styles.modalButton}
					onClick={handleClose}>
					Отмена
				</button>
			</div>
		</div>
	);
};

export default Confirm;
