import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Card.module.scss';
import { CardProps as Props } from './Card.interface';
import { RowTable } from '../../Control/UnloadingEvents/UnloadingEvents.interface';
// import { DeviceListProps } from './DeviceList/DeviceList.interface';
import { TableProps } from '../Table/Table.interface';
import { ControlIconProps } from './ControlIcons/ControlIcons.interface';
import { RawDataEvents, RawEvent } from '@api/Control/prepareControlData.interface';
import { apiGet } from '@api/api';
import {prepareDownloadReportEvents} from '@api/Dashboard/prepareDashboardData';
import {prepareControlEvents} from '@api/Control/prepareControlData';
import connectState from '@store/connectState/connectState';
import devicesState from '@store/devicesState/devicesState';
import eventState from '@store/eventState/eventState';
import dashboardState from '@store/dashboardState/dashboardState';
import placeState from '@store/placeState/placeState';
import Table from '../Table/Table';
import Chart from '../Chart/Chart';
import ControlIcons from './ControlIcons/ControlIcons';
import DashboardMap from './DashboardMap/DashboardMap';
import DownloadReport from '../DownloadReport/DownloadReport';
// import DeviceList from './DeviceList/DeviceList';

const GET_CV_EVENTS = 'vd';
const GET_UV_EVENTS = 'uv';
const GET_IR_EVENTS = 'ir';
const GET_SOUND_EVENTS = 'sound';

const Card: React.FC<Props> = observer(({ columns, cardData , num, numberRowsOnPage }) => {
	const { getPlaceInfo, getEventsLink } = connectState;
	const { /*devices,*/ selectDeviceId } = devicesState;
	const { setDividedEvents } = eventState;
	const { reportDates, setNoSelectEvent } = dashboardState;
	const { selectPlaceId } = placeState;

	// const selectDeviceIndex = devices.findIndex((item) => item.id === selectDeviceId);
	// const availableDevices = devices.map(device => device.name);

	const [isMap, setMap] = useState(false);
	const [isTable, setTable] = useState(true);
	const [isLoadingTable, setIsLoadingTable] = useState(false);

	const [countRows, setCountRows] = useState<number>(0);
	const [prevLink, setPrevLink] = useState('');
	const [nextLink, setNextLink] = useState('');
	const [currentLink, setCurrentLink] = useState('');
	const [elementName, setElementName] = useState('table');
	const [reportData, setReportData] = useState<RowTable[]>([]);
	// const [selectedDevices, setSelectedDevices] = useState([availableDevices[selectDeviceIndex]]);

	const isFullWin = numberRowsOnPage === 10;
	const isDatesExist = reportDates.length === 2;

	const tableTypes = {
		0: [GET_CV_EVENTS],
		1: [GET_IR_EVENTS],
		2: [GET_UV_EVENTS],
		3: [GET_SOUND_EVENTS],
	};

	const type = tableTypes[num as keyof typeof tableTypes];
	const isChart = elementName === 'chart';

	// const selectedDevicesString = selectedDevices.join(', ');
	// const selectedDevicesId = selectedDevices.map(device => devices.find(item => item.name === device)?.id);

	// useEffect(() => {
	// 	setSelectedDevices([availableDevices[selectDeviceIndex]]);
	// }, [devices]);

	useEffect(() => {
		if (selectPlaceId === null) return;
		const controller = new AbortController();

		if(!getPlaceInfo || !selectPlaceId) return;

		const url = `${getPlaceInfo}${selectPlaceId}/getspotevents/?event_type=${type}`;

		getEvents(url, controller);
		setNoSelectEvent();

		return () => {
			controller.abort();
		};
	}, [getPlaceInfo, selectPlaceId]);

	useEffect(() => {
		if (selectDeviceId === null) return;
		if (!isDatesExist) return;
		const controller = new AbortController();

		setIsLoadingTable(true);

		const urlReportEvents = `${getEventsLink}${selectDeviceId}/eventsfullnopag/?event_type=${type}${isDatesExist ? `&date__gte=${reportDates[0]}&date__lte=${reportDates[1]}` : ''}`;
		getReportEvents(urlReportEvents, controller);

		return () => controller.abort();
	}, [reportDates[0], reportDates[1], isDatesExist]);

	const getEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareControlEvents(data as RawDataEvents);
			const { dataEvents, pagination } = eventsData;

			setDividedEvents(dataEvents, num);
			setCountRows(pagination.count);
			setPrevLink(pagination.previous);
			setNextLink(pagination.next);

			setIsLoadingTable(false);
			setCurrentLink(url);
		});
	};

	const getReportEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareDownloadReportEvents(data as RawEvent[]);

			setIsLoadingTable(false);
			setReportData(eventsData);
		});
	};

	const updateEvents = (link: string) => {
		const controller = new AbortController();

		getEvents(link, controller);

		return () => {
			controller.abort();
		};
	};

	const handleChangeTable = () => {
		setTable(prevValue => !prevValue);
		setMap(false);

		setElementName(isTable ? 'chart' : 'table');
	};

	const handleSetMap = () => {
		setMap(prevValue => !prevValue);
		setTable(true);

		setElementName(isMap ? 'table' : 'map');
	};

	const tableData: TableProps = {
		numberCard: num,
		columns,
		prevLink,
		nextLink,
		currentLink,
		isLoad: isLoadingTable,
		setLoad: setIsLoadingTable,
		rowCount: countRows,
		updateEvents,
		reportData
	};

	const controlData: ControlIconProps = {
		changeTable: handleChangeTable,
		handleSetMap,
		isTable,
		isChart,
		numberCard: num,
		reportData
	};

	// const deviceListData: DeviceListProps = {
	// 	availableDevices,
	// 	selectedDevices,
	// 	setSelectedDevices,
	// 	selectedDevicesString
	// };

	const elementOnCard = {
		table: <Table {...tableData} />,
		chart: <Chart numberCard={num}/>,
		map:  <DashboardMap />,
	};

	return (
		<div className={styles.card} >
			<div className={styles.headerCard}>
				<div>{cardData}</div>

				{isFullWin && !isChart && !isMap && <DownloadReport />}

				{/*{isChart && <DeviceList {...deviceListData} />}*/}

				<ControlIcons {...controlData} />
			</div>

			<div className={`${styles.table} ${isFullWin && styles.fullWin}`}>
				{elementOnCard[elementName as keyof typeof elementOnCard]}
			</div>
		</div>
	);
});

export default Card;