import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const UpdateIcon: React.FC<IconSvgProps> = ({ width = 35, height = 35, fill = '#D2D2D2', disabled = false }) => {
	const disableFill = disabled ? 'hsl(0, 0%, 50%)' : fill;

	return (
		<svg width={width} height={height} fill={disableFill} viewBox='0 0 24 24'>
			<title>Опрос устройства</title>
			<path d='M12,3A9,9,0,0,0,6,5.32V3A1,1,0,0,0,4,3V8a1,1,0,0,0,.92,1H10a1,1,0,0,0,0-2H7.11A7,7,0,0,1,19,12a1,1,0,0,0,2,0A9,9,0,0,0,12,3Z'></path>
			<path d='M19.08,15H14a1,1,0,0,0,0,2h2.89A7,7,0,0,1,5,12a1,1,0,0,0-2,0,9,9,0,0,0,15,6.68V21a1,1,0,0,0,2,0V16A1,1,0,0,0,19.08,15Z'></path>
		</svg>
	);
};

export default UpdateIcon;
