import loadingState from '../../../store/loadingState/loadingState';
import { AllPagesGetData as ReadyPages, ControlsPageIsReadyData as ControlsIsReady } from '../../../store/loadingState/loading.interface';

interface DataWithDelay {
	(page: keyof ReadyPages, setReadyPages: (key: keyof ReadyPages, isValue: boolean) => void): void;
}

const setDataWithDelay: DataWithDelay = (page, setReadyPages) => {
	setTimeout(() => {
		setReadyPages(page, true);
	}, 1500);
};

export const checkPageReady = (key: keyof ControlsIsReady, isValue = false) => {
	const { controlsReady, setControlsReady, setReadyPages } = loadingState;
	const page: keyof ReadyPages = 'controls';

	const editData: ControlsIsReady = {...controlsReady};
	editData[key] = isValue;

	setControlsReady(editData);

	const readyData = Object.values(editData);
	const isPageReady = readyData.reduce((isReady, isItem) => isReady && isItem, true);

	isPageReady && setDataWithDelay(page, setReadyPages);
};