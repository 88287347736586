import { Helmet } from 'react-helmet-async';
import { HelmetTagsProps } from './HelmetTags.interface';
import { useMemo } from 'react';

const HelmetTags: React.FC<HelmetTagsProps> = ({ metaTags }) => {
	const { title } = metaTags;

	const newMetaTags: React.JSX.Element[] = [];
	for (const key in metaTags) {
		if (key === 'title') continue;

		newMetaTags.push(<meta key={key} name={key} content={metaTags[key]} />);
	}
	
	return useMemo(() => (
		<Helmet>
			<title>{title}</title>

			{newMetaTags}
		</Helmet>
	), [metaTags]);
};

export default HelmetTags;
