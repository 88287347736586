export const ZONE_TYPE_DEFAULT = 'notype';
export const ZONE_TYPE_IN_OUT = 'inoutcontrol';
export const ZONE_TYPE_PARKING = 'parkingrea';
export const ZONE_TYPE_RESTRICTED_AREA = 'closedarea';

export default class Polygon {
	id = null;
	#idCounter = 0;

	#name = '';
	#startSize = { x: null, y: null, w: null, h: null };
	#points = [];

	#lineWidth = null;
	#lineColor = '';

	#pointSize = null;
	#pointColor = '';

	#centerPointSize = null;
	#centerPointColor = '';

	#tMin = null;
	#tMax = null;

	#attribute = {
		type: ZONE_TYPE_DEFAULT,
		fillColor: 'rgba(170, 170, 170, 0.3)',
		rgba: { r: 170, g: 170, b: 170, a: 0.3 },
	};
	#standardAttributeType = {
		[ZONE_TYPE_DEFAULT]: { r: 170, g: 170, b: 170, a: 0.3 },
		[ZONE_TYPE_IN_OUT]: { r: 32, g: 162, b: 211, a: 0.3 },
		[ZONE_TYPE_PARKING]: { r: 211, g: 168, b: 32, a: 0.3 },
		[ZONE_TYPE_RESTRICTED_AREA]: { r: 211, g: 32, b: 32, a: 0.3 },
	};

	constructor(id, x, y, w, h) {
		this.id = id;
		this.#name = `Area #${this.id}`;
		this.#startSize = { x, y, w, h };
		this.#points = this.setPolygonData(x, y, w, h);
		this.#lineWidth = 2;
		this.#lineColor = '#000';

		this.#pointSize = 8;
		this.#pointColor = '#ff0000';

		this.#centerPointSize = 5;
		this.#centerPointColor = 'gold';
	}

	getId = () => this.id;

	incCounter = () => {
		++this.#idCounter;
	};
	getIdCounter = () => this.#idCounter;

	setPolygonData(x, y, w, h) {
		return [
			{ id: this.#idCounter++, x: x, y: y },
			{ id: this.#idCounter++, x: x + w, y: y },
			{ id: this.#idCounter++, x: x + w, y: y + h },
			{ id: this.#idCounter++, x: x, y: y + h },
		];
	}

	getStartSize = () => this.#startSize;

	addPoint(index, point) {
		this.#points.splice(index, 0, point);
	}

	setPoints = (points) => {
		this.#points = points;
	};
	getPoints = () => [...this.#points];

	setLineWidth = (lineWidth) => {
		this.#lineWidth = lineWidth;
	};
	getLineWidth = () => this.#lineWidth;

	setLineColor = (lineColor) => {
		this.#lineColor = lineColor;
	};
	getLineColor = () => this.#lineColor;

	setPointSize = (pointSize) => {
		this.#pointSize = pointSize;
	};
	getPointSize = () => this.#pointSize;

	setPointColor = (pointColor) => {
		this.#pointColor = pointColor;
	};
	getPointColor = () => this.#pointColor;

	setCenterPointSize = (centerPointSize) => {
		this.#centerPointSize = centerPointSize;
	};
	getCenterPointSize = () => this.#centerPointSize;

	setCenterPointColor = (centerPointColor) => {
		this.#centerPointColor = centerPointColor;
	};
	getCenterPointColor = () => this.#centerPointColor;

	deletePoint = (id) => {
		const isCorrectId = Number.isInteger(id);
		if (!isCorrectId) return;

		const index = this.#points.findIndex((point) => point.id === id);
		this.#points.splice(index, 1);
	};

	setAttributeType = (type) => {
		this.#attribute.type = type;

		const { r, g, b, a } = this.#standardAttributeType[type];
		this.setAttributeFillColor(r, g, b, a);
	};
	getAttributeType = () => this.#attribute.type;
	getStandardAttributeTypeColor = (type) => this.#standardAttributeType[type];

	setAttributeFillColor = (r, g, b, a = 0.3) => {
		this.#attribute.fillColor = `rgba(${r}, ${g}, ${b}, ${a})`;
		this.#attribute.rgba = { r, g, b, a };
	};
	getAttributeFillColor = () => this.#attribute.fillColor;
	getAttributeRgba = () => this.#attribute.rgba;

	setAttribute = (attributes) => {
		const { r, g, b } = attributes.rgba;
		this.setAttributeType(attributes.type);
		this.setAttributeFillColor(r, g, b);
	};
	getAttribute = () => this.#attribute;

	setName = (name) => {
		this.#name = name;
	};
	getName = () => this.#name;

	setMin = (temp) => {
		this.#tMin = temp;
	};
	getMin = () => this.#tMin;

	setMax = (temp) => {
		this.#tMax = temp;
	};
	getMax = () => this.#tMax;
}
