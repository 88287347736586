import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Redirect from '../helpers/components/Redirect/Redirect';
import MapPage from '../pages/MapPage/MapPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import ControlPage from '../pages/ControlPage/ControlPage';
import LeftMenu from '../components/LeftMenu/LeftMenu';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import { MetaTags } from '../pages/Pages.interface';
import AccountMenu from '../components/AccountMenu/AccountMenu';
import MapDevicesPage from '../pages/MapDevicesPage/MapDevicesPage';
import TranslationPage from '../pages/TranslationPage/TranslationPage';
import AnaliticaPage from '../pages/AnaliticaPage/AnaliticaPage';

const UserAccessRouter: React.FC = () => {
	const path = '/';
	const author = 'Dark Front End';

	const metaControlPage: MetaTags = {
		title: 'События',
		description: 'Страница просмотра событий и управления камерой',
		keywords: 'события, msd',
		author,
	};

	const metaTranslationPage: MetaTags = {
		title: 'Трансляция',
		description: 'Страница просмотра прямого эфира с камеры',
		keywords: 'translation, msd',
		author,
	};

	const metaMapPage: MetaTags = {
		title: 'Карта - объекты',
		description: 'Основная страница сайта MSD. Поиск элементов на карте',
		keywords: 'msd',
		author,
	};

	const metaMapDevicesPage: MetaTags = {
		title: 'Карта - камеры',
		description: 'Страница отображения камер по выбранному месту',
		keywords: 'cameras',
		author,
	};

	const metaDashboardPage: MetaTags = {
		title: 'Информационная панель',
		description: 'Описание страницы',
		keywords: 'ключевые слова',
		author,
	};

	const metaAccountPage: MetaTags = {
		title: 'Аккаунт',
		description: 'Описание страницы',
		keywords: 'ключевые слова',
		author,
	};

	const metaAnaliticaPage: MetaTags = {
		title: 'Аналитика событий',
		description: 'Описание страницы',
		keywords: 'ключевые слова',
		author,
	};

	return (
		<>
			<LeftMenu />
			<AccountMenu />
			<Routes>
				<Route path='/' element={<MapPage metaTags={metaMapPage} />} />
				<Route path='/device/:spot' element={<MapDevicesPage metaTags={metaMapDevicesPage} />} />
				<Route path='/control' element={<ControlPage metaTags={metaControlPage} />} />
				<Route path='/account' element={<AccountPage metaTags={metaAccountPage} />} />
				<Route path='/dashboard' element={<DashboardPage metaTags={metaDashboardPage} />} />
				<Route path='/translation' element={<TranslationPage metaTags={metaTranslationPage} />} />
				<Route path='/analitica' element={<AnaliticaPage metaTags={metaAnaliticaPage} />} />
				<Route path='*' element={<Redirect to={path} />} />
			</Routes>
		</>
	);
};

export default UserAccessRouter;
