import { FC } from 'react';
import styles from './MobilePage.module.scss';

const MobilePage: FC = () => {
	return (
		<div className={styles.container} >
			<p className={styles.text}>Извините, мобильная версия недоступна.</p>
		</div>
	);
};

export default MobilePage;
