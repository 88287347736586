import styles from './AnaliticaPage.module.scss';
import { AnaliticaPageProps } from '../Pages.interface';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '../../assets/Icons/LeftMenu/BackIcon';
import { useNavigate } from 'react-router-dom';
import Analitica from '../../components/Analitica/Analitica';
import FieldSearchPlaces from '../../components/FieldSearchPlaces/FieldSearchPlaces';
import { prepareMapDevices, prepareMapPlaces } from '@api/MapPage/prepareMapData';
import { PrepareDevices, DataPlace } from '@api/MapPage/prepareMapData.interface';
import connectState from '@store/connectState/connectState';
import {useEffect, useState} from 'react';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import { apiGet } from '@api/api';
import { checkPageReady } from '@helpers/functions/loading/analiticaLoading';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import {observer} from 'mobx-react-lite';
import {
	PreparePreset,
	prepareHeatEvents,
	prepareIREvents,
	preparePresetsData,
} from '@api/AnaliticaPage/prepareAnaliticaData';
import analiticaState from '@store/analiticaState/analiticaState';
import presetState from '@store/presetState/presetState';
import {HeatResults, IRResults} from '@api/AnaliticaPage/interface';
import {HeatAnalitica, IRAnalitica, PeriodTypeKeys} from '@store/analiticaState/interface';
import autorizeState from '@store/accoutState/autorizeState';

const AnaliticaPage: React.FC<AnaliticaPageProps> = observer(({ metaTags }) => {
	const navigate = useNavigate();
	const { getPlacesLink, getDevicesLink, aiEventLink, getPresetsAnaliticaLink } = connectState;
	const { selectPlaceId, setPlaces } = placeState;
	const { selectDeviceId, setDevices } = devicesState;
	const { setHeatAnalitica, setIRAnalitica } = analiticaState;
	const { setPresets, selectPresetId, setSelectPresetId } = presetState;
	const { checkAuth } = autorizeState;

	const [countRows, setCountRows] = useState<number>(0);
	const [pageCounter, setPageCounter] = useState<number>(0);
	const [prevLink, setPrevLink] = useState<string>('');
	const [nextLink, setNextLink] = useState<string>('');
	const [countRowsIR, setCountRowsIR] = useState<number>(0);
	const [pageCounterIR, setPageCounterIR] = useState<number>(0);
	const [prevLinkIR, setPrevLinkIR] = useState<string>('');
	const [nextLinkIR, setNextLinkIR] = useState<string>('');
	const [selectedPeriod, setSelectedPeriod] = useState<keyof PeriodTypeKeys>('d');
	const [selectedPeriodIR, setSelectedPeriodIR] = useState<keyof PeriodTypeKeys>('d');

	useEffect(() => {
		const controller = new AbortController();

		getPlaces(getPlacesLink, controller);

		return () => controller.abort();
	}, [getPlacesLink]);

	useEffect(() => {
		const controller = new AbortController();
		if(!selectPresetId || !aiEventLink || !selectDeviceId || !selectPlaceId) return;

		const url = `${aiEventLink}${selectDeviceId}/getaievents/?id_psw=${selectDeviceId}&preset=${selectPresetId}&analytic_type=ir&period_type=${selectedPeriodIR}`;
		getIREvents(url, controller);

		return () => controller.abort();
	}, [aiEventLink,  selectedPeriodIR, selectPresetId, selectPlaceId]);

	useEffect(() => {
		const controller = new AbortController();

		if (!getDevicesLink || !selectPlaceId) return;

		const url = `${getDevicesLink}${selectPlaceId}/getcameras/`;
		getDevices(url, controller);

		return () => controller.abort();
	}, [getDevicesLink, selectPlaceId]);

	useEffect(() => {
		const controller = new AbortController();

		if( !getPresetsAnaliticaLink || !selectDeviceId ) return;
		const url = `${getPresetsAnaliticaLink}${selectDeviceId}/getshortpresetsforaipage/`;
		getPresets(url, controller);

		return () => controller.abort();
	}, [getPresetsAnaliticaLink, selectDeviceId]);

	useEffect(() => {
		const controller = new AbortController();

		if (!selectPresetId || !aiEventLink || !selectDeviceId) return;

		const url = `${aiEventLink}${selectDeviceId}/getaievents/?id_psw=${selectDeviceId}&analytic_type=heat&preset=${selectPresetId}&period_type=${selectedPeriod}`;
		getHeatEvents(url, controller);

		return () => controller.abort();
	}, [aiEventLink, selectedPeriod, selectPresetId, selectPlaceId]);

	const getHeatEvents = async (url = '', controller = new AbortController()) => {
		if (!selectPresetId || !aiEventLink || !selectDeviceId) return;
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;

			setHeatAnalitica([] as HeatAnalitica[]);
			if (isError) return;
			const heatEvents = prepareHeatEvents(data as HeatResults);

			const { analitica, pagination} = heatEvents;

			setCountRows(pagination?.count);
			setPrevLink(pagination?.previous);
			setNextLink(pagination?.next);

			setHeatAnalitica(analitica);
		});
	};

	const getIREvents = async (url = '', controller = new AbortController()) => {
		if (!selectPresetId || !aiEventLink || !selectDeviceId || !selectPlaceId) return;

		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;

			setIRAnalitica([] as IRAnalitica[]);
			if (isError) return;
			const irEvents = prepareIREvents(data as IRResults);

			const { analitica, pagination} = irEvents;

			setCountRowsIR(pagination?.count);
			setPrevLinkIR(pagination?.previous);
			setNextLinkIR(pagination?.next);
			setIRAnalitica(analitica);
		});
	};

	const getPlaces = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			const places = prepareMapPlaces(data as DataPlace[]);
			setPlaces(places);
			checkPageReady('isPlaces', true);
		});
	};

	const getDevices = async (url = '', controller = new AbortController()) => {

		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			const devices = prepareMapDevices(data as PrepareDevices);
			setDevices(devices);
			checkPageReady('isDevices', true);
		});
	};

	const getPresets  = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			const presets = preparePresetsData(data as PreparePreset[]);

			if(!presets.length) {
				setSelectPresetId(null);
			}

			setPresets(presets);

			checkPageReady('isDevices', true);
		});
	};


	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<CheckLoading page='analitica'>
			<div className={styles.main}>
				<HelmetTags metaTags={metaTags} />
				<MainButton className={styles.buttonPos} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces />

				<Analitica
					countRows={countRows}
					countRowsIR={countRowsIR}
					pageCounter={pageCounter}
					pageCounterIR={pageCounterIR}
					prevLink={prevLink}
					prevLinkIR={prevLinkIR}
					nextLink={nextLink}
					nextLinkIR={nextLinkIR}
					updateHeat={getHeatEvents}
					updateIR={getIREvents}
					setCountRows={setCountRows}
					setCountRowsIR={setCountRowsIR}
					setPageCounter={setPageCounter}
					setPageCounterIR={setPageCounterIR}
					setPrevLink={setPrevLink}
					setPrevLinkIR={setPrevLinkIR}
					setNextLink={setNextLink}
					setNextLinkIR={setNextLinkIR}
					setSelectedPeriodIR={setSelectedPeriodIR}
					setSelectedPeriod={setSelectedPeriod}
					selectePeriodIR={selectedPeriodIR}
					selectePeriod={selectedPeriod}
				/>
			</div>
		</CheckLoading>
	);
});

export default AnaliticaPage;
