import { makeAutoObservable } from 'mobx';

import {
	TranslationStateKeyboardKeys as KeyboardKeys,
	TranslationStateSetKeyboardKey as SetKeyboardKey,
	TranslationStateKeysSelected as KeysSelected,
	TranslationStateSetKeysSelected as SetKeysSelected,
	TranslationStateTypeContnetModal as TypeContentModal,
	TypeCreateNewPreset as TypeNewPreset,
	TypeSelectArrow
} from './interfaces';

class TranslationState {
	keyboardKeys: KeyboardKeys = {
		ArrowUp: null,
		ArrowLeft: null,
		ArrowRight: null,
		ArrowDown: null,
	};

	keysSelected: KeysSelected = {
		ArrowUp: false,
		ArrowDown: false,
		ArrowLeft: false,
		ArrowRight: false,
	};

	isMouseControl = false;

	isVisibleTemp = false;
	isVisibleTempGrid = false;
	isVisibleController = false;

	isOpenModal = false;
	contentTypeModal: keyof TypeContentModal = 'PatrolParams';

	selectComandArrow: TypeSelectArrow = 'STOP';

	isGetEventPOLL = false;

	isLiveStream = false;

	newPreset: TypeNewPreset = {
		id_psw: '',
		preset_number: null,
		name: '',
		preset_max_temp: null,
		preset_min_temp: null,
		data_sound_corr: null,
		data_sound_energy: null,
		data_sound_frequency: null
	};

	constructor() {
		makeAutoObservable(this);
	}

	setIsLiveStream = (isLive = false) => {
		this.isLiveStream = isLive;
	};

	setVisibleTemp = (isVisible = false) => {
		this.isVisibleTemp = isVisible;
	};

	setVisibleController = () => {
		this.isVisibleController = !this.isVisibleController;
	};

	setNewPreset = (preset: TypeNewPreset) => {
		this.newPreset = preset;
	};

	setIsGetEventPOLL = () => {
		this.isGetEventPOLL = !this.isGetEventPOLL;
	};

	setSelectComandArrow = (arrow: TypeSelectArrow) => {
		this.selectComandArrow = arrow;
	};

	setKeyboardKey: SetKeyboardKey = (key, divRef) => {
		this.keyboardKeys[key] = divRef;
	};

	setKeysSelected: SetKeysSelected = (key, isSelected) => {
		this.keysSelected[key] = isSelected;
	};

	setIsMouseControl = (isMouseControl: boolean) => {
		this.isMouseControl = isMouseControl;
	};

	setVisibleTempGrid = (isVisible: boolean) => {
		this.isVisibleTempGrid = isVisible;
	};

	setOpenModal = (isOpen: boolean) => {
		this.isOpenModal = isOpen;
	};

	setContentType = (typeModal: keyof TypeContentModal) => {
		this.contentTypeModal = typeModal;
	};
}

const translationState = new TranslationState();
export default translationState;
