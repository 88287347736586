import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './PersonCardHeader.module.scss';
import { PersonCardHeaderProps as Props } from './PersonCardHeader.interface';
import Modal from '@mui/material/Modal';
import ResetPassword from './ResetPassword/ResetPassword';
import SettingIcon from '@assets/Icons/Translation/SettingIcon';
import { ClickAwayListener } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import connectState from '@store/connectState/connectState';


const PersonCardHeader: React.FC<Props> = ({actionBtn}) => {
	const { userLink } = connectState;
	const darkTheme = createTheme({
		palette: {
			mode: 'dark',
		},
	});

	const [isOpenResetPassword, setOpenResetPassword] = useState(false);
	const [isDisableButton, setDisableButton] = useState(true);
	const [isSettings, setSettings] = useState(false);

	const handleOpenSettings = () => {
		setSettings((prevState) => !prevState);
	};

	const openResetPassword = () => {
		setOpenResetPassword(true);
	};

	const closeResetPassword = () => {
		setOpenResetPassword(false);
	};

	const handleCloseSettings = () => {
		setSettings(false);
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<div className={styles.headerCard}>
				<p className={styles.title}>Персональная информация</p>
				<div className={styles.btnTitle} onClick={handleOpenSettings}>
					<SettingIcon width={18} height={18} title={'Насстройки профиля'}/>
				</div>
				{isSettings && (
					<ClickAwayListener onClickAway={handleCloseSettings}>
						<div className={styles.popoverContent}>
							<ListItem className={styles.popoverBtn} onClick={() => actionBtn(true)}>
								Настройки
							</ListItem>
							<ListItem onClick={openResetPassword} className={styles.popoverBtn}>
								Сменить пароль
							</ListItem>
						</div>
					</ClickAwayListener>
				)}

				<Modal className={styles.modal} open={isOpenResetPassword} onClose={closeResetPassword}>
					<div>
						<ResetPassword
							isDisableButton={isDisableButton}
							setDisableButton={setDisableButton}
							handleClose={closeResetPassword}
							url={userLink}
						/>
					</div>
				</Modal>
			</div>
		</ThemeProvider>
	);
};

export default PersonCardHeader;
