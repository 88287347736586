import { FC, MouseEvent, useEffect, useRef } from 'react';
import { canvasRGBA } from 'stackblur-canvas';
import { GridTempProps } from './CanvasGridTemp.interface';
import canvasState from '../../store/canvasState/canvasState';

const CanvasGridTemp: FC<GridTempProps> = ({ minTemp, maxTemp, data, width, height, isTempMax, handleCur }) => {
	const { isShowCanvas } = canvasState;
	
	const canvasRef = useRef<HTMLCanvasElement>(null);
	
	useEffect(() => {
		if (!canvasRef.current) return;
		if(!data?.length) return;

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		const canvasWidth = canvas.width;
		const canvasHeight = canvas.height;
		const matrixWidth = data[0].length;
		const matrixHeight = data.length;

		const heatmapWidth = canvasWidth * 1;
		const heatmapHeight = canvasHeight * 1;
		
		const heatmapX = (canvasWidth - heatmapWidth) / 2;
		const heatmapY = (canvasHeight - heatmapHeight) / 2;

		const colorPalette = [
			'#0000FF', // Светло-синий
			'#0088FF', // Голубой
			'#00FFFF', // Голубой
			'#00FF88', // Светло-зеленый
			'#00FF00', // Зеленый
			'#88FF00', // Светло-желтый
			'#FFFF00', // Желтый
			'#FF8800', // Оранжевый
			'#FF0000', // Красный
		];

		if (!ctx) return;

		ctx.clearRect(0, 0, canvasWidth, canvasHeight); // Очищаем канвас
		ctx.globalAlpha = 0.5;

		// Находим индексы строки и столбца точки с максимальной температурой
		let maxTempRow = 0;
		let maxTempCol = 0;

		for (let i = 0; i < matrixHeight; i++) {
			for (let j = 0; j < matrixWidth; j++) {				
				const value = data[i][j];
				const colorIndex = Math.floor(((value - minTemp) / (maxTemp - minTemp)) * (colorPalette.length - 1));
				ctx.fillStyle = colorPalette[colorIndex];

				if (data[i][j] === maxTemp) {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					maxTempRow = i;
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					maxTempCol = j;
				}

				const x = heatmapX + (j / matrixWidth) * heatmapWidth;
				const y = heatmapY + (i / matrixHeight) * heatmapHeight;
				const width = (1 / matrixWidth) * heatmapWidth;
				const height = (1 / matrixHeight) * heatmapHeight;

				ctx.fillRect(x, y, width, height);
			}
		}
		
		const blurRadius = 5;
		canvasRGBA(canvas, heatmapX, heatmapY, heatmapWidth, heatmapHeight, blurRadius);
		
		ctx.globalAlpha = 1;

		if(isTempMax && !isShowCanvas) {
			const centerX = heatmapX + (maxTempCol / matrixWidth) * heatmapWidth + (heatmapWidth / matrixWidth) / 2;
			const centerY = heatmapY + (maxTempRow / matrixHeight) * heatmapHeight + (heatmapHeight / matrixHeight) / 2;

			ctx.beginPath();
			ctx.arc(centerX, centerY, 20, 0, 2 * Math.PI);
			ctx.strokeStyle = 'black';
			ctx.lineWidth = 2;
			ctx.stroke();
		}
	}, [data, width, height, isTempMax]);

	const handleMouseEnter = (event: MouseEvent<HTMLCanvasElement>) => {
		const x = event.nativeEvent.offsetX;
		const y = event.nativeEvent.offsetY;

		if (!canvasRef.current) return;
		if(!data.length) return;

		const matrixWidth = data[0].length;
		const matrixHeight = data.length;
		const heatmapWidth = canvasRef.current.width * 1;
		const heatmapHeight = canvasRef.current.height * 1;
		const heatmapX = (canvasRef.current.width - heatmapWidth) / 2;
		const heatmapY = (canvasRef.current.height - heatmapHeight) / 2;

		const cellWidth = heatmapWidth / matrixWidth;
		const cellHeight = heatmapHeight / matrixHeight;

		const cellX = Math.floor((x - heatmapX) / cellWidth);
		const cellY = Math.floor((y - heatmapY) / cellHeight);

		if (cellX >= 0 && cellX < matrixWidth && cellY >= 0 && cellY < matrixHeight) {
			const value = data[cellY][cellX];
			handleCur(value);
		}
	};

	const handleGridItem = (event: MouseEvent<HTMLCanvasElement>) => {
		const x = event.nativeEvent.offsetX;
		const y = event.nativeEvent.offsetY;

		if (!canvasRef.current) return;

		const matrixWidth = data[0].length;
		const matrixHeight = data.length;
		const heatmapWidth = canvasRef.current.width * 1;
		const heatmapHeight = canvasRef.current.height * 1;
		const heatmapX = (canvasRef.current.width - heatmapWidth) / 2;
		const heatmapY = (canvasRef.current.height - heatmapHeight) / 2;

		const cellWidth = heatmapWidth / matrixWidth;
		const cellHeight = heatmapHeight / matrixHeight;

		const cellX = Math.floor((x - heatmapX) / cellWidth);
		const cellY = Math.floor((y - heatmapY) / cellHeight);

		if (cellX >= 0 && cellX < matrixWidth && cellY >= 0 && cellY < matrixHeight) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const value = data[cellY][cellX];
			// console.log(value);
		}
	};

	const handleMouseLeave = () => {
		handleCur(0);
	};

	return (
		<canvas 
			ref={canvasRef} 
			width={width} 
			height={height} 
			style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} 
			onClick={handleGridItem}
			onMouseMove={handleMouseEnter} 
			onMouseLeave={handleMouseLeave} 
		/>
	);
};

export default CanvasGridTemp;
