import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { PlaceDevices as Place } from './interfaces';

class PlaceState {
	places: Place[] = [];

	selectPlaceId: Place['id'] | null = null;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: 'placeId',
			properties: ['selectPlaceId', 'places'],
			storage: window.localStorage,
		});
	}

	setPlaces = (newPlaces: Place[]) => {
		this.places = newPlaces;
	};

	setSelectPlaceId = (id: Place['id']) => {
		this.selectPlaceId = id;
	};

	setFindPlaceEvent = (spot: string) => {
		if (this.places.length === 0) return;

		const index = this.places.findIndex((place) => place.id === spot);
		this.places[index].isHaveEvents = true;
	};


	setSelectedPlaces = (idPlace: Place['id'], isSelected: boolean) => {		
		try {
			this.places.forEach((place) => (place.isSelected = false));

			const index = this.places.findIndex(({ id }: Place) => id === idPlace);

			this.places[index].isSelected = isSelected;
		} catch (error) {
			console.log(error);
		}
	};
}

const placeState = new PlaceState();
export default placeState;
