import React from 'react';
import styles from './ContentAction.module.scss';
import { ContentActionProps as Props} from './ContentAction.interface';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { styled } from '@mui/material/styles';
import { ActionLog } from '../../../../../store/userActionsState/interfaces';
import userActionsState from '../../../../../store/userActionsState/userActionsState';

const ModDataGrid = styled(DataGrid)({
	mxHeight: 'calc(100% - 50px)',
	width: '100%',

	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},

	'& .MuiDataGrid-selectedRowCount': {
		display: 'none',
	},

	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},

	'& .MuiDataGrid-cell': {
		fontSize: '16px'
	},

	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},

	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},

	'& ::-webkit-scrollbar': {
		width: '6px'
	},

	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	}
});

const ContentAction: React.FC<Props> = ({  rowCount, handleChangePage }) => {
	const {  userActions } = userActionsState;

	const createTableRow = (row: ActionLog) => {
		const { message, firstName, id, ip, date }  = row;
		const key = `Table--Action--${date}-${message}-${id}-${firstName}`;

		return {
			id: key,
			date: date,
			ip,
			message,
		};
	};

	const rows = userActions.map(createTableRow);

	const columns = [
		{ field: 'date', headerName: 'Дата и время', flex: 1 },
		{ field: 'ip', headerName: 'Устройство', flex: 1 },
		{ field: 'message', headerName: 'Действие', flex: 1 },
	];

	return (
		<div className={styles.modal}>
			<div className={styles.titleCard}>
				{userActions[0]?.firstName} {userActions[0]?.userId}
			</div>
			<div className={styles.content}>
				<ModDataGrid
					rows={rows}
					columns={columns}
					getRowId={(row) => row.id}
					paginationMode={'server'}
					rowCount={rowCount}
					initialState={{
						pagination: { paginationModel: { pageSize: 10 } },
					}}
					onPaginationModelChange={handleChangePage}
				/>
			</div>
		</div>
	);
};

export default ContentAction;