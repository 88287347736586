import { ResultEventData } from '../../../../store/eventState/interface';
import styles from './SoundOptions.module.scss';
import { Box, List, ListItem } from '@mui/material';

interface SoundOptionsProps {
	event: ResultEventData;
}

const SoundOptions: React.FC<SoundOptionsProps> = ({ event }) => {
	const isSound = !!event?.sound?.track?.length;

	const infoSound = [
		{ id: 1, name: 'Звуковая энергия', value: event.soundEnergy, symbol: '%' },
		// { id: 2, name: 'Звуковое сравнение:', value: event.soundCorrelation, symbol: '%' },
		{ id: 3, name: 'Частота звука:', value: event.soundFrequency, symbol: 'Гц' },
	];

	return (
		<div className={styles.container}>
			<div className={styles.titleInfo}>Звуковые параметры</div>

			<List>
				{infoSound.map(({ id, name, value, symbol }) => (
					<ListItem key={`${id}-${name}`} sx={{ justifyContent: 'space-between' }}>
						<Box>{name}</Box>

						<div style={{display: 'flex'}}>
							<Box style={{marginRight: '5px'}}>{value}</Box>
							<Box>{symbol}</Box>
						</div>
					</ListItem>
				))}

				<ListItem>
					{isSound ? (
						<audio src={event.sound.track} controls/>
					) : (
						<Box>{'Нет звукозаписей'}</Box>
					)}
				</ListItem>
			</List>
		</div>
	);
};

export default SoundOptions;
