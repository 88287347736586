import { makeAutoObservable } from 'mobx';

class DashboardState {
	isSelectEvent = false;

	selectedTableId = -1;

	selectedRowId = -1;

	isOpenFullWin = false;

	numberFullTable = -1;
	imgUrlDownload = '';

	reportDates: string[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	setNewRow = (tableId: number, rowId: number) => {
		this.isSelectEvent = true;
		this.selectedTableId = tableId;
		this.selectedRowId = rowId;
	};

	setNoSelectEvent = () => {
		this.isSelectEvent = false;
	};

	changeOpenFullWin = () => {
		this.isOpenFullWin = !this.isOpenFullWin;
	};

	setOpenFullWin = (isOpenFullWin: boolean) => {
		this.isOpenFullWin = isOpenFullWin;
	};

	setNumberFullTable = (numberFullWin: number) => {
		this.numberFullTable = numberFullWin;
	};

	setImgUrlDownload = (imgUrl: string) => {
		this.imgUrlDownload = imgUrl;
	};

	setReportDates = (date: string, num: number) => {
		this.reportDates[num] = date;
	};

	setReportFullDates = (dates: []) => {
		this.reportDates = dates;
	};
}

const dashboardState = new DashboardState();
export default dashboardState;
