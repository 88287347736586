import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAccessRouterInterface } from './Redirect.interface';

const Redirect: React.FC<UserAccessRouterInterface> = ({ to = '' }) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(to);
	}, [navigate, to]);

	return null;
};

export default Redirect;
