import React, {useEffect, useState} from 'react';
import styles from './Chart.module.scss';
import { ChartProps as Props, ChartGraficMergeInfo as GraficMergeInfo } from './Chart.interface';
import ChartLineBar from './ChartTypes/ChartLineBar/ChartLineBar';
import {GraficInfo, GraficBarInfo} from '../../Control/InfographicsEvent/InfographicsEvent.interface';
import {apiGet} from '@api/api';
import CheckEmpty from '@helpers/components/CheckEmpty/CheckEmpty';
import eventState from '@store/eventState/eventState';
import dashboardState from '@store/dashboardState/dashboardState';
import connectState from '@store/connectState/connectState';
import devicesState from '@store/devicesState/devicesState';

const GET_CV_EVENTS = 'vd';
const GET_UV_EVENTS = 'uv';
const GET_IR_EVENTS = 'ir';
const GET_SOUND_EVENTS = 'sound';

const Chart: React.FC<Props> = ({ numberCard }) => {
	const { eventsDividedByType } = eventState;
	const { isSelectEvent, selectedTableId, selectedRowId } = dashboardState;
	const { selectDeviceId } = devicesState;
	const { getEventsLink } = connectState;

	const [isFirstTypeChart, setFirstTypeChart] = useState(false);
	const [barEvents, setBarEvents] = useState<GraficBarInfo[]>([]);
	const [typeMergeEvents, setTypeMergeEvents] = useState<number>(1);

	const selectedRowIndex = eventsDividedByType[numberCard]?.findIndex(item => item?.id && +item.id === selectedRowId);
	const dateChart = `${(isSelectEvent && (selectedTableId === numberCard)) ? eventsDividedByType[numberCard][selectedRowIndex]?.date : eventsDividedByType[numberCard]?.[0]?.date ?? '' }`;

	const candles: { [key: string]: GraficMergeInfo } = {};
	const groupedEvents: { [key: string]: { [key: string]: number | string} } = {};

	const tableTypes = {
		0: [GET_CV_EVENTS],
		1: [GET_IR_EVENTS],
		2: [GET_UV_EVENTS],
		3: [GET_SOUND_EVENTS],
	};

	useEffect(() => {
		const type = tableTypes[numberCard as keyof typeof tableTypes];

		const partsDate = dateChart.split('.');

		const formatDate = `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`;

		const datePartBarLink = `/dayeventsforbars/?search=${formatDate}&event_type=${type}&ordering=id_message`;

		getBarEvents(`${getEventsLink}${selectDeviceId}${datePartBarLink}`);
	}, [getEventsLink, selectDeviceId, eventsDividedByType[numberCard], selectedRowId]);

	const getBarEvents = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			setBarEvents(data as GraficBarInfo[]);
		});
	};

	barEvents.forEach(item => {
		const eventType = item.event_type;
		const time = item.time;
		const hour = Number(time.split(':')[0]);

		const key1Hour = `${item.date} ${hour}:00:00`;
		const key2Hours = `${item.date} ${Math.floor(hour / 2) * 2}:00:00`;
		const key3Hours = `${item.date} ${Math.floor(hour / 3) * 3}:00:00`;

		const keys = [key1Hour, key2Hours, key3Hours];

		const key = keys[typeMergeEvents-1];

		if (!candles[key]) {
			if (!groupedEvents[key]) {
				groupedEvents[key] = {
					date: item.date,
					id: item.id,
					time: `${hour}:00 - ${hour + typeMergeEvents-1}:59`,
				};
			}

			if (!groupedEvents[key][eventType]) {
				groupedEvents[key][eventType] = 0;
			}

			groupedEvents[key][eventType] = (groupedEvents[key][eventType] || 0) as number + 1;
		}
	});

	const candleBarEvents: GraficInfo[] = Object.values(groupedEvents).map(candle => {
		const { date, id, time, ir, uv, sound, vd, pl } = candle;

		const filteredCandle: GraficInfo = {
			date: String(date),
			id: String(id),
			time: String(time),
			...(!!ir && { ir }),
			...(!!uv && { uv }),
			...(!!sound && { sound }),
			...(!!vd && { vd }),
			...(!!pl && { pl }),
		};

		return filteredCandle;
	});

	return (
		<CheckEmpty isCheck={!!candleBarEvents.length}>
			<div className={styles.parent}>
				<ChartLineBar
					dataChart={candleBarEvents}
					firstType={isFirstTypeChart}
					dateInfo={dateChart}
					isFirstTypeChart={isFirstTypeChart}
					setFirstTypeChart={setFirstTypeChart}
					typeMergeEvents={typeMergeEvents}
					setTypeMergeEvents={setTypeMergeEvents}
					visible={false}
				/>
			</div>
		</CheckEmpty>
	);
};

export default Chart;