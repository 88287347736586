import React, {useEffect, useState} from 'react';
import styles from './ParamsPatrolModal.module.scss';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import { patrolTypes } from '@helpers/defaultsData/defaultsData';
import Parameters from './Parameters/Parameters';
import translationState from '@store/translationState/translationState';
import connectState from '@store/connectState/connectState';
import devicesState from '@store/devicesState/devicesState';
import presetState from '@store/presetState/presetState';
import {apiGet, apiPatch} from '@api/api';
import { prepareCameraParams } from '@api/Translation/prepareTranslationData';
import { PrepareCamParams } from '@api/Translation/prepareTranslationData.interface';

const ParamsPatrolModal: React.FC = () => {
	const { setOpenModal } = translationState;
	const { getCameraInfo } = connectState;
	const { selectDeviceId } = devicesState;
	const { setPatrolType } = presetState;

	const [scheduleValueTimePicker, setScheduleValueTimePicker] = useState<string>('0');
	const [forcedValueTimePicker, setForcedValueTimePicker] = useState<string>('0');
	const [patrolPickers, setPatrolPickers] = useState<string[]>([]);
	const [forcedPickers, setForcedPickers] = useState<string[]>([]);

	const getCameraLink = `${getCameraInfo}${selectDeviceId}`;
	const isDisableButton = scheduleValueTimePicker === null;

	useEffect(() => {
		const controller = new AbortController();
		if (!getCameraLink || !selectDeviceId || !getCameraInfo) return;

		const getCameraParameters = `${getCameraLink}/getpcameraparameters/`;

		getCameraParams(getCameraParameters, controller);

		return () => {
			controller.abort();
		};
	}, [getCameraLink]);

	const getCameraParams = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;
			if (isError) return;

			const cameraParams = prepareCameraParams(data as unknown as PrepareCamParams);
			const { patrolDelay, patrolSchedule, pollDelay, pollSchedule } = cameraParams;

			setScheduleValueTimePicker(patrolDelay.toString());
			setForcedValueTimePicker(pollDelay.toString());
			setPatrolPickers(patrolSchedule);
			setForcedPickers(pollSchedule);
		});
	};

	const handleDiscardButton = () => {
		setOpenModal(false);
	};

	const handleConfirmButton = () => {
		const patchCameraParametersLink = `${getCameraLink}/updatecamera/`;

		const editPatrolSchedule = patrolPickers
			.filter(picker => picker !== '')
			.map(picker => picker.slice(0, 5));

		const editPollSchedule = forcedPickers
			.filter(picker => picker !== '')
			.map(picker => picker.slice(0, 5));

		const editScheduleValueTimePicker = scheduleValueTimePicker === '' ? 0 : Number(scheduleValueTimePicker) * 3600;
		const editPollDelay = forcedValueTimePicker === '' ? 0 : Number(forcedValueTimePicker) * 3600;

		const combinedData = {
			patrol_every_mins: 15,
			patrol_delay: editScheduleValueTimePicker,
			patrol_schedule: editPatrolSchedule,
			poll_delay: editPollDelay,
			poll_schedule:editPollSchedule
		};

		const patrolTypesKey = `${editPatrolSchedule.length > 0 && editPatrolSchedule[0] !== ''}-${editScheduleValueTimePicker > 0}`;
		setPatrolType(patrolTypes[patrolTypesKey]);

		apiPatch(patchCameraParametersLink, combinedData, {}).then(({ data, error }) => {
			const { isError } = error;
			console.log(data);

			if (isError) return;
		});

		setOpenModal(false);
	};

	return (
		<div className={styles.modalContainer} >
			Периодичность патрулирования
			<Parameters
				// pickers={patrolPickers}
				valueTimePicker={scheduleValueTimePicker}
				setValueTimePicker={setScheduleValueTimePicker}
				// setPickers={setPatrolPickers}
			/>

			Периодичность принудительного опроса
			<Parameters
				// pickers={forcedPickers}
				valueTimePicker={forcedValueTimePicker}
				setValueTimePicker={setForcedValueTimePicker}
				// setPickers={setForcedPickers}
			/>

			<ConfirmButtons 
				isDisabled={isDisableButton}
				handleOk={handleConfirmButton}
				handleCancel={handleDiscardButton}
			/>
		</div>
	);
};

export default ParamsPatrolModal;
