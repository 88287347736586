import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const ControlIcon: React.FC<IconSvgProps> = ({ width = 30, height = 30, fill = '#D2D2D2' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>События</title>
			<path
				d='M12.5 18.3101H2.5C2.09 18.3101 1.75 17.9701 1.75 17.5601C1.75 17.1501 2.09 16.8101 2.5 16.8101H12.5C12.91 16.8101 13.25 17.1501 13.25 17.5601C13.25 17.9801 12.91 18.3101 12.5 18.3101Z'
				fill={fill}
			/>
			<path
				d='M10.2798 23.25H4.72974C2.53974 23.25 1.75977 22.48 1.75977 20.3V16.2C1.75977 14.02 2.53974 13.25 4.72974 13.25H10.2798C12.4698 13.25 13.2498 14.02 13.2498 16.2V20.31C13.2498 22.48 12.4698 23.25 10.2798 23.25ZM4.71973 14.75C3.35973 14.75 3.24976 14.86 3.24976 16.2V20.31C3.24976 21.65 3.35973 21.76 4.71973 21.76H10.2698C11.6298 21.76 11.7397 21.65 11.7397 20.31V16.2C11.7397 14.86 11.6298 14.75 10.2698 14.75H4.71973Z'
				fill={fill}
			/>
			<path
				d='M15.5002 23.25C15.2302 23.25 14.9802 23.1 14.8502 22.87C14.7202 22.63 14.7202 22.35 14.8602 22.11L15.9102 20.36C16.1202 20.01 16.5802 19.89 16.9402 20.1C17.3002 20.31 17.4102 20.77 17.2002 21.13L16.9302 21.58C19.6902 20.93 21.7602 18.45 21.7602 15.49C21.7602 15.08 22.1002 14.74 22.5102 14.74C22.9202 14.74 23.2602 15.08 23.2602 15.49C23.2502 19.77 19.7702 23.25 15.5002 23.25Z'
				fill={fill}
			/>
			<path
				d='M2.5 10.25C2.09 10.25 1.75 9.91 1.75 9.5C1.75 5.23 5.23 1.75 9.5 1.75C9.77 1.75 10.02 1.9 10.15 2.13C10.28 2.37 10.28 2.65 10.14 2.89L9.09003 4.64C8.88003 4.99 8.42 5.11 8.06 4.9C7.7 4.69 7.58999 4.23 7.79999 3.87L8.07001 3.42C5.31001 4.07 3.23999 6.55 3.23999 9.51C3.24999 9.91 2.91 10.25 2.5 10.25Z'
				fill={fill}
			/>
			<path
				d='M19 12.25C16.11 12.25 13.75 9.9 13.75 7C13.75 4.1 16.1 1.75 19 1.75C21.9 1.75 24.25 4.1 24.25 7C24.25 9.9 21.89 12.25 19 12.25ZM19 3.25C16.93 3.25 15.25 4.93 15.25 7C15.25 9.07 16.93 10.75 19 10.75C21.07 10.75 22.75 9.07 22.75 7C22.75 4.93 21.07 3.25 19 3.25Z'
				fill={fill}
			/>
		</svg>
	);
};

export default ControlIcon;
