import { makeAutoObservable } from 'mobx';
import type { LatLngExpression } from 'leaflet';
import { Device } from '../devicesState/interface';

class MapState {
	centerMap: LatLngExpression = [54.19514, 37.6225];
	zoomMap = 14;

	popupPosition: LatLngExpression = [0, 0];

	namePopup = '';

	selectDevices: Device[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	setCenterMap = (center: LatLngExpression) => {
		this.centerMap = center;
	};

	setZoomMap = (zoom: number) => {
		this.zoomMap = zoom;
	};

	setZeroPopupPosition = () => {
		this.popupPosition = [0, 0];
	};

	setPopupPosition = (position: LatLngExpression) => {
		this.popupPosition = position;
	};

	setNamePopup = (name: string) => {
		this.namePopup = name;
	};

	setSelectDevices = (devices: Device[]) => {
		this.selectDevices = devices;	
	};
}

const mapState = new MapState();
export default mapState;
