import React, { ChangeEvent, useState } from 'react';
import styles from './ResetPassword.module.scss';
import { ResetPasswordProps as Props } from './ResetPassword.interface';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClosedEyeIcon from '../../../../../assets/Icons/Account/ClosedEye';
import ConfirmButtons from '../../../../../helpers/components/ConfirmButtons/ConfirmButtons';
import { apiPatch } from '../../../../../api/api';

const ResetPassword: React.FC<Props> = ({handleClose, isDisableButton, setDisableButton, url}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [userPassword, setUserPassword] = useState<string>('');
	const [step, /*setStep*/] = useState(0);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const resetPassword = () => {
		const data = {password: userPassword};
		apiPatch(url, data, {}).then(({ error }) => {
			const { isError } = error;

			if (isError) return;
			handleClose();
		});
	};


	const checkMatchNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
		const isMatched = event.target.value.length > 6;

		setDisableButton(!isMatched);
		const value = event.target.value;
		setUserPassword(value);
	};

	const steps = [
		{
			title: 'Сброс пароля',
			placeholder: 'Новый пароль',
			actionBtn: resetPassword,
			onChangeInput: checkMatchNewPassword,
			btnTitle: 'Сохранить'
		}
	];

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalContent}>
				{steps[0].title}
			</div>
			<div className={styles.inputBox}>
				<input
					className={styles.styleInput}
					type={showPassword ? 'text' : 'password'}
					placeholder={steps[0].placeholder}
					id={userPassword}
					value={userPassword}
					onChange={steps[0].onChangeInput}
				/>
				<div className={styles.passwordToggle} onClick={togglePasswordVisibility}>
					{showPassword ? <ClosedEyeIcon/> : <VisibilityIcon/>}
				</div>
			</div>

			<ConfirmButtons 
				nameOk={steps[step].btnTitle}
				isDisabled={isDisableButton}
				handleOk={steps[step].actionBtn}
				handleCancel={handleClose}
			/>
		</div>
	);
};

export default ResetPassword;
