import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from '../ModalUpdatePresets.module.scss';
import { PropsEdit } from '../PresetsModals.interface';
import { apiPatch } from '@api/api';
import ConfirmButtons from '@helpers/components/ConfirmButtons/ConfirmButtons';
import presetState from '@store/presetState/presetState';
import connectState from '@store/connectState/connectState';
import translationState from '@store/translationState/translationState';

const EditModal: FC<PropsEdit> = observer(({ idDevice, selectPreset, handleDiscardButton }) => {
	const { presetSettings, selectPresetId, presets } = presetState;
	const { presetMaxTemp, presetMinTemp/*, dataSoundCorr, dataSoundEnergy, dataSoundFrequency*/ } = presetSettings;
	const { newPreset, setNewPreset } = translationState;
	const { getEventsLink } = connectState;

	const [isFocused, setIsFocused] = useState(false);
	const [isDisableButton, setDisableButton] = useState(true);

	const [valueName, setValueName] = useState('');
	const [minTemp, setMinTemp] = useState<number | null>(null);
	const [maxTemp, setMaxTemp] = useState<number | null>(null);
	const [energy/*, setEnergy*/] = useState<number>(10000);
	const [corr/*, setCorr*/] = useState<number>(10000);
	const [frequency/*, setFrequency*/] = useState<number>(10000);

	useEffect(() => {
		if (!idDevice) return;

		setNewPreset({
			id_psw: idDevice,
			name: valueName,
			preset_max_temp: maxTemp,
			preset_min_temp: minTemp,
			data_sound_corr: corr,
			data_sound_energy: energy,
			data_sound_frequency: frequency,
		});

		const resultDisable = [valueName, maxTemp, minTemp].every(value => value !== null && value !== '');

		setDisableButton(!resultDisable);
	}, [idDevice, valueName, minTemp, maxTemp, corr, energy, frequency]);

	const handleChangePresetName = () => {
		const currNumber = presets.find(({ id }) => id === selectPresetId)?.number;

		const url = `${getEventsLink}${idDevice}/updatepreset/${currNumber}/`;

		const { id_psw, preset_number, ...other } = newPreset;

		console.log(id_psw, preset_number);

		apiPatch(url, other, {}).then(({ data, error }) => {
			const { isError } = error;
			console.log(data);

			if (isError) return;
		});

		handleDiscardButton();
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalContent}>
				Изменение пресета <span style={{ color: '#00C2FD' }}>{selectPreset?.name}</span>
			</div>

			<div className={styles.search}>
				<input
					className={styles.searchInput}
					placeholder="Новое название"
					onChange={(e) => setValueName(e.target.value)}
					autoFocus={isFocused}
					onFocus={() => setIsFocused(true)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder={`Максимальная температура: ${presetMaxTemp} ℃`}
					onChange={(e) => setMaxTemp(e.target.value ? +e.target.value : null)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder={`Минимальная температура: ${presetMinTemp} ℃`}
					onChange={(e) => setMinTemp(e.target.value ? +e.target.value : null)}
				/>
			</div>

			{/* <div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder={`Энергия: ${dataSoundEnergy} %`}
					onChange={(e) => setEnergy(+e.target.value)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder={`Корреляция: ${dataSoundCorr} %`}
					onChange={(e) => setCorr(+e.target.value)}
				/>
			</div>

			<div className={styles.search}>
				<input
					type="number"
					className={styles.searchInput}
					placeholder={`Частота: ${dataSoundFrequency} %`}
					onChange={(e) => setFrequency(+e.target.value)}
				/>
			</div> */}

			<ConfirmButtons
				nameOk="Сохранить"
				nameNo="Отмена"
				isDisabled={isDisableButton}
				handleOk={handleChangePresetName}
				handleCancel={handleDiscardButton}
			/>
		</div>
	);
});

export default EditModal;
