import {ActionLog} from './interfaces';
// import {apiPost} from '../../api/api';
// import connectState from '../connectState/connectState';

// const { postUserAction } = connectState;
class UserActions {
	actionsOnPage: ActionLog[] = [];
	actionsOnPageFiltered = [];

	usersActions: ActionLog[]  = [];
	userActions: ActionLog[]  = [];


	addUsersActions = (newAction: ActionLog[]) => {
		this.usersActions = newAction;
	};
	setUserActions= (newAction: ActionLog[]) => {
		this.userActions = newAction;
	};

	// userAction = (userAction = '', type = '' ) => {
	// 	const message = `${userAction} ${type}`;
	//
	//
	// 	const data = {message: message};
	// 	this.postUserAction(data);
	// };

	// postUserAction = (newAction: {message: string}) => {
	// 	apiPost(postUserAction, newAction, {}).then(({ data, error }) => {
	// 		const { isError } = error;
	// 		console.log(data, error);
	// 		if (isError) return;
	// 	});
	// };
}

const userActionsState = new UserActions();
export default userActionsState;
