import React, { useEffect, useState } from 'react';
import styles from './ChartLineBar.module.scss';
import {ChartTypesLineProps as Props, ChartTypesPoints as Points, ChartTypesYAxisTickProps as YAxisTickProps, ChartTypesYAxisPayload as YAxisPayload} from '../ChartTypes.interface';
import { ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid, Brush, Legend, Bar, Line } from 'recharts';
import { GraficInfo, LineGraf } from '../../../../Control/InfographicsEvent/InfographicsEvent.interface';
import ControlTypeChartIcons from '../../ControlTypeChartIcons/ControlTypeChartIcons';

const getKeys = (data: GraficInfo): string[] => {
	const keys = [];

	for (const key in data) {
		if (key === 'id' || key === 'date' || key === 'time') continue;
		keys.push(key);
	}

	return [...keys];
};

const ChartLineBar: React.FC<Props> = ({ dataChart , firstType, dateInfo, isFirstTypeChart, setFirstTypeChart, typeMergeEvents, setTypeMergeEvents, visible }) => {
	const colors = ['#00C2FD', '#00b336', '#FF2300', '#FFB300', '#CB005D'];
	const isControls = isFirstTypeChart !== undefined && setFirstTypeChart !== undefined && typeMergeEvents !== undefined && setTypeMergeEvents !== undefined;
	const [keys, setKeys] = useState<string[]>([]);

	const [showLines, setShowLines] = useState<LineGraf>({
		isChange: false,
		lines: keys.map((name) => ({ isShow: true, name })) || [],
	});

	useEffect(() => {
		if (!dataChart.length) return;

		if (!Number.isFinite(dataChart[0][keys[0] as keyof GraficInfo])) return;

		setShowLines({
			...showLines,
			isChange: false,
			lines: keys.map((name) => ({ isShow: true, name })),
		});
	}, [dataChart, keys]);

	useEffect(() => {
		setKeys(getKeys(dataChart[0]) || []);
	}, [dataChart]);

	const CustomYAxisTick = (prop: YAxisTickProps) => {
		const {payload, x, y} = prop;

		return (
			<text x={x} y={y} dy={16} textAnchor='middle' fill='#888'>{payload.value}</text>
		);
	};
	
	const CustomizedDot = (prop: Points) => {
		const { cx, cy, lineIndex } = prop;

		const isShowDot = showLines.lines[lineIndex as number]?.isShow;
		if (!isShowDot) return null;

		return (
			<circle cx={cx} cy={cy} r={3} stroke={colors[lineIndex as number]} strokeWidth={1.5} fill='#3F3F3F'/>
		);
	};

	const handleLegendClick = (legend: YAxisPayload) => {
		const tempLines = [...showLines.lines];
		const index = tempLines.findIndex(({ name }) => name === legend.dataKey);
		tempLines[index].isShow = !tempLines[index].isShow;

		setShowLines({
			...showLines,
			isChange: !showLines.isChange,
			lines: [...tempLines],
		});
	};

	return (
		<div className={styles.chart}>
			<div className={styles.header}>
				{dateInfo &&
					<div className={styles.date}>Дата: {dateInfo}</div>
				}

				{isControls &&
					<ControlTypeChartIcons
						isFirstTypeChart={isFirstTypeChart}
						setFirstTypeChart={setFirstTypeChart}
						typeMergeEvents={typeMergeEvents}
						setTypeMergeEvents={setTypeMergeEvents}
						visible={visible}
					/>
				}
			</div>

			<ResponsiveContainer>
				<ComposedChart data={dataChart} style={{background: '#1F1F1F'}}>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='time' tick={(props) => <CustomYAxisTick {...props} />} />
					<YAxis hide />
					<Tooltip
						contentStyle={{
							background: '#3F3F3F',
							color: '#CDCDCD',
						}}
					/>
					<Brush height={20} stroke='#00C2FD' fill='#1F1F1F' />

					<Legend
						onClick={(props) => handleLegendClick(props)}
						formatter={(value) => {
							const lineIndex = showLines.lines.findIndex((line) => line.name === value);
							const isShowLine = showLines.lines[lineIndex]?.isShow;

							const color = colors[lineIndex];

							return(
								<span style={{ color: isShowLine ? color : '#666666' }}>{value}</span>
							);
						}}
					/>

					{keys.map((name, index) => {
						const key = `View-Graph-Camera-${name}-${index}`;
						const color = colors[index];

						const lineIndex = showLines.lines.findIndex((line) => line.name === name);
						const isShowLine = showLines.lines[lineIndex]?.isShow;

						if (firstType)
							return (
								<Line
									key={key}
									type={'monotone'}
									dataKey={name}
									stroke={isShowLine ? color : 'transparent'}
									dot={ <CustomizedDot lineIndex={lineIndex}/> }
								/>
							);

						else return (
							<Bar
								key={key}
								dataKey={name}
								stroke={isShowLine ? color : 'transparent'}
								fill={isShowLine ? color : 'transparent'}
								background={{ fill: isShowLine ? 'rgba(45, 45, 45, 0.4)' : 'rgba(45, 45, 45, 0' }}
							/>
						);
					})}
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};

export default ChartLineBar;