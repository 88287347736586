import React from 'react';
import {ThemeProvider, createTheme, styled} from '@mui/material/styles';
import { ItemTimePickerProps as Props } from './ItemTimePicker.interface';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {LocalizationProvider, TimeView} from '@mui/x-date-pickers';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const ModTimePicker = styled(TimePicker)({
	'& .MuiInputBase-root': {
		color: '#CDCDCD',
		border: '1px solid #CDCDCD',
	},

	'& .MuiSvgIcon-root': {
		color: '#CDCDCD',
	},

	WebkitAppearance: 'none', /* Safari and Chrome */
});

const ItemTimePicker: React.FC<Props> = ({ fullTime, valueTimePicker, setValueTimePicker }) => {
	const typeTimePicker: TimeView[] = fullTime ? ['hours', 'minutes'] : ['hours'];

	const initialTimeNotFull = dayjs().set('hour', Number(valueTimePicker)).set('minute', 0).set('second', 0);
	const initialTimeFull = dayjs().set('hour', Number(valueTimePicker.slice(0, 2))).set('minute', Number(valueTimePicker.slice(3, 5))).set('second', 0);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
			<ThemeProvider theme={darkTheme}>
				<ModTimePicker
					timeSteps={{ minutes: 1 }}
					slotProps={{ textField: { size: 'small' } }}
					views={typeTimePicker}
					value={Number(valueTimePicker) === 0 ? null : fullTime ? initialTimeFull : initialTimeNotFull}
					onChange={(newTime: any) => {
						if (newTime !== null) {
							setValueTimePicker(newTime.$d.toLocaleTimeString('ru'));
						} else setValueTimePicker('');
					}}
					localeText={{
						fieldHoursPlaceholder: () => 'час',
						fieldMinutesPlaceholder: () => 'мин',
					}}
				/>
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default ItemTimePicker;
