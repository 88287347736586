import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import styles from './Tabs.module.scss';
import { TabPanelProps, TabsProps as Props } from './Tabs.interface';
import { PersonInfo } from '../PersonInfo/PersonInfo';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material';
import { UserDataFix } from '../../../../../store/accoutState/interfaces';

const CustomTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		backgroundColor: '#00C2FD'
	},
});

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<div>
					<div>{children}</div>
				</div>
			)}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
};

export const TabsComponent: React.FC<Props> = ({ handleClose, selectedItem, type, isProfile = false }) => {
	const [value, setValue] = useState(0);
	const [openRoleAccess, setOpenRoleAccess] = useState(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		if (type === 'create') return;
		setValue(newValue);
		setOpenRoleAccess(newValue === 1);
	};
	const nextStep = (index: number) => {
		setValue(index);
	};

	const data = selectedItem as UserDataFix;

	return (
		<div className={styles.modal}>
			<CustomTabs
				value={value}
				onChange={handleChange}
				textColor="inherit"
				variant="fullWidth"
				aria-label="full width tabs example"
				className={styles.tabs}
			>
				<Tab label="1 Пользователь" {...a11yProps(0)} />
				<Tab label="2 Роли и права" {...a11yProps(1)}/>
			</CustomTabs>
			<CustomTabPanel value={value} index={0}>
				<PersonInfo handleClose={handleClose} selectedItem={data} type={type} openRoleAccess={openRoleAccess} setOpenRoleAccess={setOpenRoleAccess} nextStep={nextStep} isProfile={isProfile}/>
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				<PersonInfo handleClose={handleClose} selectedItem={data} type={type} openRoleAccess={openRoleAccess} setOpenRoleAccess={setOpenRoleAccess} nextStep={nextStep}/>
			</CustomTabPanel>
		</div>
	);
};