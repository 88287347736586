import { makeAutoObservable } from 'mobx';
import {ResultEventData} from './interface';

class EventState {
	events: ResultEventData[] = [];
	selectEventId: ResultEventData['id'] = null;
	zoneSelectEvent: ResultEventData['zone'] = null;
	selectPresetId: ResultEventData['idPreset'] = null;

	eventsDividedByType: ResultEventData[][] = [];

	isShowPrepareProtocol = false;
	isGetNewEvents = true;

	eventScreen = '';

	constructor() {
		makeAutoObservable(this);
	}

	setIsGetNewEvents = (isGet: boolean) => {
		this.isGetNewEvents = isGet;
	};

	setEvents = (events: ResultEventData[]) => {
		this.events = events;
	};

	setAddNewEvent = (event: ResultEventData) => {
		this.events = [event, ...this.events.slice(0, - 1)];
	};

	setSelectedEventId = (id: ResultEventData['id']) => {
		this.selectEventId = id;
	};

	setZoneSelectEvent = (id: ResultEventData['zone']) => {
		this.zoneSelectEvent = id;
	};

	setSelectPresetId = (id: ResultEventData['idPreset']) => {
		this.selectPresetId = id;
	};

	setDividedEvents = (events: ResultEventData[], cardNumber: number) => {
		this.eventsDividedByType[cardNumber] = events;
	};

	setOneEvent = (event: ResultEventData, cardNumber: number) => {
		this.eventsDividedByType[cardNumber] = [event, ...this.eventsDividedByType[cardNumber].slice(0, - 1)];
	};

	setShowPrepareProtocol = () => {
		this.isShowPrepareProtocol = !this.isShowPrepareProtocol;
	};

	setEventScreen = (newScreen: string) => {
		this.eventScreen = newScreen;
	};
}

const eventState = new EventState();
export default eventState;
