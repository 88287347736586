import React from 'react';
import { PresetsModalsProp as Props, PresetsMadalsTypes as Types } from './PresetsModals.interface';
import CreateModal from './CreateModal/CreateModal';
import EditModal from './EditModal/EditModal';
import DeleteModal from './DeleteModal/DeleteModal';

const PresetsModals: React.FC<Props> = ({
	type,
	selectPreset,
	idDevice,
	deviceName,
	placeName,
	setOpenModal,
	setEditPreset,
	setModalType,
}) => {
	const handleCloseModal = () => {
		setOpenModal(false);
		setEditPreset(false);
		setModalType('');
	};

	const modalTypes: Types = {
		create: (
			<CreateModal
				idDevice={idDevice}
				placeName={placeName}
				deviceName={deviceName}
				handleDiscardButton={handleCloseModal}
			/>
		),
		editName: <EditModal idDevice={idDevice} selectPreset={selectPreset} handleDiscardButton={handleCloseModal} />,
		delete: <DeleteModal selectPreset={selectPreset} handleDiscardButton={handleCloseModal} />,
	};

	return modalTypes[type];
};

export default PresetsModals;
