import React, { useEffect } from 'react';
import styles from './MapPage.module.scss';
import Map from '../../components/Map/Map';
import { useNavigate } from 'react-router-dom';
import { MapPageProps as Props } from '../Pages.interface';
import HelmetTags from '../../helpers/components/HelmetTags/HelmetTags';
import placeState from '../../store/placeState/placeState';
import { apiGet } from '../../api/api';
import connectState from '../../store/connectState/connectState';
import { prepareMapDevices, prepareMapPlaces } from '../../api/MapPage/prepareMapData';
import { DataPlace, PrepareDevices } from '../../api/MapPage/prepareMapData.interface';
import { observer } from 'mobx-react-lite';
import { checkPageReady } from '../../helpers/functions/loading/mapLoading';
import { CheckLoading } from '../../components/CheckLoading/CheckLoading';
import FieldSearchPlaces from '../../components/FieldSearchPlaces/FieldSearchPlaces';
import MainButton from '../../helpers/components/MainButton/MainButton';
import BackIcon from '../../assets/Icons/LeftMenu/BackIcon';
import devicesState from '../../store/devicesState/devicesState';
import autorizeState from '../../store/accoutState/autorizeState';

const MapPage: React.FC<Props> = observer(({ metaTags }) => {
	const { getPlacesLink, getDevicesLink } = connectState;
	const { setDevices } = devicesState;
	const { selectPlaceId, setPlaces } = placeState;
	const { checkAuth } = autorizeState;


	const navigate = useNavigate();

	useEffect(() => {
		const controller = new AbortController();

		if (!getPlacesLink) return;

		getPlaces(getPlacesLink, controller);

		return () => {
			controller.abort();
		};
	}, [getPlacesLink]);

	useEffect(() => {
		const controller = new AbortController();

		if (!getDevicesLink || !selectPlaceId) return;

		const url = `${getDevicesLink}${selectPlaceId}/getcameras/`;
		getDevices(url, controller);

		return () => {			
			controller.abort();
		};
	}, [getDevicesLink, selectPlaceId]);

	const getPlaces = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const places = prepareMapPlaces(data as DataPlace[]);			
			setPlaces(places);

			checkPageReady('isPlaces', true);
		});
	};

	const getDevices = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ error, data, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const devices = prepareMapDevices(data as PrepareDevices);
			setDevices(devices);

			checkPageReady('isDevices', true);
		});
	};

	const handleGoBack = () => {
		navigate('/');
	};

	return (
		<CheckLoading page='map'>
			<div className={styles.parent}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={`${styles.buttonPos} ${styles.topLeft}`} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces isMapPage={true} />

				<div className={styles.container}>
					<Map />
				</div>
			</div>
		</CheckLoading>
	);
});

export default MapPage;
