import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './FourLeafCloverControl.module.scss';
import { FourLeafCloverControlGetButtonsPositionCross as GetButtonsPositionCross } from './FourLeafCloverControl.interface';
import ControlButton from './ControlButton/ControlButton';

const getButtonsPositionCross: GetButtonsPositionCross = (delta) => {
	const top = '50%';
	const left = '50%';

	return {
		pTop: {
			top: `calc(${top} - ${delta})`,
			left: `calc(${left})`,
			transform: `translate(calc(-${top}), calc(-${left} - ${delta}))`,
		},
		pLeft: {
			top: `calc(${top})`,
			left: `calc(${left} - ${delta})`,
			transform: `translate(calc(-${top} - ${delta}), calc(-${left}))`,
		},
		pRight: {
			top: `calc(${top})`,
			left: `calc(${left} + ${delta})`,
			transform: `translate(calc(-${top} + ${delta}), calc(-${left}))`,
		},
		pBottom: {
			top: `calc(${top} + ${delta})`,
			left: `calc(${left})`,
			transform: `translate(calc(-${top}), calc(-${left} + ${delta}))`,
		},
	};
};

const FourLeafCloverControl: React.FC = observer(() => {
	const delta = '16px';

	const { pTop, pLeft, pRight, pBottom } = getButtonsPositionCross(delta);

	return (
		<div className={styles.parent}>
			<div className={styles.child}>
				<ControlButton style={pTop} position={'ArrowUp'}>
					U
				</ControlButton>
				<ControlButton style={pRight} position={'ArrowRight'}>
					R
				</ControlButton>
				<ControlButton style={pBottom} position={'ArrowDown'}>
					D
				</ControlButton>
				<ControlButton style={pLeft} position={'ArrowLeft'}>
					L
				</ControlButton>
			</div>
		</div>
	);
});

export default FourLeafCloverControl;
