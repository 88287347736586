import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './LeftMenu.module.scss';
import { LeftMenuMenuIcons as MenuIcons } from './LeftMenu.interface';
import { ControlIcon, DashboardIcon, LogoIcon, MapIcon } from '@assets/Icons/LeftMenu/index';
import CameraIcon from '@assets/Icons/LeftMenu/CameraIcon';
import AnaliticaIcon from '@assets/Icons/LeftMenu/AnaliticaIcon';
import MainButton from '@helpers/components/MainButton/MainButton';
import mapState from '@store/mapState/mapState';

const LeftMenu: React.FC = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const { setZeroPopupPosition } = mapState;

	const [isVisibleMenu, setIsVidibleMenu] = useState(false);
	const [menuIcons, setMenuIcons] = useState<MenuIcons[]>([
		{ id: 1, path: '/', Icon: MapIcon, isSelected: false },
		{ id: 2, path: '/control', Icon: ControlIcon, isSelected: false },
		{ id: 3, path: '/translation', Icon: CameraIcon, isSelected: false },
		{ id: 4, path: '/analitica', Icon: AnaliticaIcon, isSelected: false },
		{ id: 5, path: '/dashboard', Icon: DashboardIcon, isSelected: false },
	]);

	useEffect(() => {
		setZeroPopupPosition();

		const selectIndex = menuIcons.findIndex(({ path }) => path === pathname);

		if (selectIndex === -1) {
			menuIcons.forEach((icon) => (icon.isSelected = false));
			setMenuIcons([...menuIcons]);
			return;
		}

		menuIcons[selectIndex].isSelected = true;
		setMenuIcons([...menuIcons]);

		return () => {
			menuIcons.forEach((icon) => (icon.isSelected = false));
		};
	}, [pathname]);

	const selectVisibleMenu = () => {
		setIsVidibleMenu((prev) => !prev);
	};

	const createMenuItem = ({ id, path, Icon, isSelected }: MenuIcons) => {
		const fill = isSelected ? '#00C2FD' : '#D2D2D2';
		const key = `Menu-Icon-Item-${id}`;

		return (
			<MainButton key={key} className={styles.brick} onClick={() => navigate(path)}>
				<Icon fill={fill}/>
			</MainButton>
		);
	};

	return (
		<div className={styles.parent}>
			<div className={`${styles.bricks} ${isVisibleMenu && styles.show}`}>{menuIcons.map(createMenuItem)}</div>

			<div className={styles.container} onClick={selectVisibleMenu}>
				<div className={styles.logoMenu}>
					<LogoIcon />
				</div>
			</div>
		</div>
	);
};

export default LeftMenu;
