import styles from './SigninPage.module.scss';
import SigninForm from '../../components/Authorize/SigninForm/SigninForm';
import HelmetTags from '../../helpers/components/HelmetTags/HelmetTags';
import { SinginPageProps } from '../Pages.interface';

const SigninPage: React.FC<SinginPageProps> = ({ metaTags }) => {
	return (
		<div className={styles.main}>
			<HelmetTags metaTags={metaTags} />
			<SigninForm />
		</div>
	);
};

export default SigninPage;
