import { IconSvgProps } from '../../../helpers/Interfaces/IconSvg.interface';

const DashboardIcon: React.FC<IconSvgProps> = ({ width = 30, height = 30, fill = '#D2D2D2', title = 'Страница событий' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<title>{title}</title>
			<path
				d='M14.5 22.4999H4.5C3.96408 22.5186 3.44448 22.3138 3.06529 21.9347C2.6861 21.5555 2.48138 21.0359 2.5 20.4999V10.4999C2.48138 9.96402 2.6861 9.44442 3.06529 9.06524C3.44448 8.68605 3.96408 8.48132 4.5 8.49995H8.5V4.49995C8.48138 3.96402 8.6861 3.44442 9.06529 3.06524C9.44448 2.68605 9.96408 2.48132 10.5 2.49995H20.5C21.0359 2.48132 21.5555 2.68605 21.9347 3.06524C22.3139 3.44442 22.5186 3.96402 22.5 4.49995V14.4999C22.5183 15.0358 22.3135 15.5552 21.9344 15.9343C21.5553 16.3134 21.0358 16.5183 20.5 16.4999H16.5V20.4999C16.5183 21.0358 16.3135 21.5552 15.9344 21.9343C15.5553 22.3134 15.0358 22.5183 14.5 22.4999ZM4.5 10.4999V20.4999H14.5V16.4999H10.5C9.96416 16.5183 9.44474 16.3134 9.06562 15.9343C8.6865 15.5552 8.48168 15.0358 8.5 14.4999V10.4999H4.5ZM10.5 4.49995V14.4999H20.5V4.49995H10.5Z'
				fill={fill}
			/>
		</svg>
	);
};

export default DashboardIcon;
