import { makeAutoObservable } from 'mobx';
import {HeatAnalitica, IRAnalitica, PeriodTypeKeys, UVAnalitica} from './interface';

class AnaliticaState {
	presetsIR = [];
	irAnalitica: IRAnalitica[] = [];
	uvAnalitica: UVAnalitica[] = [];
	selectedPeriod: keyof PeriodTypeKeys = 'd';

	heatAnalitica: HeatAnalitica[] = [];


	constructor() {
		makeAutoObservable(this);
	}

	setHeatAnalitica = (data: HeatAnalitica[]) => {
		this.heatAnalitica = data;
	};

	setIRAnalitica = (data: IRAnalitica[]) => {
		this.irAnalitica = data;
	};
}

const analiticaState = new AnaliticaState();
export default analiticaState;
