import React, {useEffect, useMemo, useState} from 'react';
import styles from './Table.module.scss';
import {TableProps as Props} from './Table.interface';
import { DataGrid, getGridDateOperators, getGridStringOperators, GridCellParams, GridFilterModel, GridFilterOperator, GridRowParams, GridSortModel, ruRU } from '@mui/x-data-grid';
import {ThemeProvider, createTheme, styled} from '@mui/material/styles';
import {observer} from 'mobx-react-lite';
import {saveAs} from 'file-saver';
import { PaginationProps } from '../../Control/TableEvents/TableEvents.interface';
import ReportTable from './ReportTable/ReportTable';
import Modal from '@mui/material/Modal';
import {IconButton} from '@mui/material';
import CheckEmpty from '@helpers/components/CheckEmpty/CheckEmpty';
import DownloadIcon from '@assets/Icons/ControlPage/DownloadIcon';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import eventState from '@store/eventState/eventState';
import dashboardState from '@store/dashboardState/dashboardState';
import {filterOperator} from '@helpers/defaultsData/defaultsData';

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const StyledDataGrid = styled(DataGrid)(() => ({
	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},

	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},

	'& .MuiDataGrid-cell': {
		fontSize: '16px',
	},

	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},

	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},

	'& ::-webkit-scrollbar': {
		width: '6px',
		borderLeft: '1px solid #D2D2D2',
	},

	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	},
}));

const sortedFields: { [key: string]: string } = {
	id: 'id',
	cameraName: 'camera_name',
	presetName: 'preset_name',
	zoneName: 'zone_name',
	date: 'date',
	time: 'time',
};

const signSort = {
	desc: '-',
	asc: '',
};

const filteredFields: { [key: string]: string } = {
	id: 'id',
	messageType: 'event_type',
	cameraName: 'id_psw__name',
	presetName: 'preset__name',
	zoneName: 'zone__name',
	date: 'date',
	time: 'time',
};

const Table: React.FC<Props> = observer(({
	numberCard,
	columns,
	prevLink,
	nextLink,
	currentLink,
	isLoad,
	setLoad,
	rowCount,
	updateEvents,
	reportData
}) => {
	const { reportDates, selectedTableId, selectedRowId, imgUrlDownload, setNewRow, setImgUrlDownload } = dashboardState;
	const { eventsDividedByType } = eventState;

	const cardEvents = eventsDividedByType[numberCard];
	const isDatesExist = reportDates.length === 2;

	const [selectedRow, setSelectedRow] = useState<number | null>(null);
	const [pageSize, setPageSize] = useState<number>(10);
	const [pageCounter, setPageCounter] = useState<number>(0);
	const [isSort, setSort] = useState(false);
	const [sortedMod, setSortedMod] = useState('');
	const [isFilter, setFilter] = useState(false);
	const [filteredMod, setFilteredMod] = useState('');
	const [imgLink, setImgLink] = useState<string>('');
	const [isOpenModal, setOpenModal] = useState(false);
	const [hovered, setHovered] = useState<number | null>(null);

	useEffect(() => {
		const selectTable = selectedTableId === numberCard;

		setSelectedRow(selectTable ? +selectedRowId : null);
	}, [selectedTableId, selectedRowId]);

	useEffect(() => {
		if (!sortedMod) return;

		const urlParts = currentLink.split('?');
		let updatedLink = currentLink;

		if (urlParts.length > 1) {
			const queryParams = new URLSearchParams(urlParts[1]);
			queryParams.set('ordering', sortedMod);
			updatedLink = `${urlParts[0]}?${queryParams.toString()}`;
		} else {
			updatedLink = `${currentLink}?ordering=${sortedMod}`;
		}

		if (!isSort) {
			const searchParams = new URLSearchParams(urlParts[1]);
			searchParams.delete('ordering');

			updatedLink = `${urlParts[0]}?${searchParams.toString()}`;
		}

		updateEvents(updatedLink);
	}, [sortedMod, isSort]);

	useEffect(() => {
		if (!filteredMod) return;

		const urlParts = currentLink.split('?');
		let updatedLink = currentLink;

		if (urlParts.length > 1) {
			const searchParams = new URLSearchParams(urlParts[1]);

			const eventType = searchParams.get('event_type');
			const ordering = searchParams.get('ordering');
			const pageSize = searchParams.get('page_size');

			const newQueryParams = new URLSearchParams();

			newQueryParams.set('event_type', eventType ? eventType : '');
			newQueryParams.set('ordering', ordering ? ordering : '');
			newQueryParams.set('page_size', pageSize ? pageSize : '');

			if (filteredMod === 'null') {
				updatedLink = `${urlParts[0]}?${newQueryParams.toString()}`;
			} else {
				updatedLink = `${urlParts[0]}?${newQueryParams.toString()}&${filteredMod}`;
			}
		} else {
			updatedLink = `${currentLink}?${filteredMod}`;
		}

		updateEvents(updatedLink);
	}, [filteredMod, isFilter]);

	const handleSelectRow = (row: GridRowParams) => {
		setNewRow(numberCard, +row.id);

		setImgUrlDownload(row.row.img);
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		setFilter(false);

		if (!model.items.length) return;
		setPageCounter(0);

		const { field, operator, value } = model.items[0];
		if (value === undefined || value === '') {
			setFilteredMod('null');
			return;
		}

		setLoad(true);
		setFilter(true);

		if (field === 'time') {
			const dateTime = value.split('T');

			setFilteredMod(`date${filterOperator[operator]}=${dateTime[0]}&time${filterOperator[operator]}=${dateTime[1]}:00`);
			return;
		}

		setFilteredMod(`${filteredFields[field]}${filterOperator[operator]}=${value}`);
	};

	const handleChangePage = (infoPage: PaginationProps) => {
		setPageSize(infoPage.pageSize);

		let linkMove = pageCounter < infoPage.page ? nextLink : prevLink;

		if (!linkMove) {
			linkMove = currentLink;
		}

		const urlParts = linkMove.split('?');
		let updatelink = linkMove;

		const queryParams = new URLSearchParams(urlParts[1]);
		queryParams.set('page_size', infoPage.pageSize.toString());

		updatelink = `${urlParts[0]}?${queryParams.toString()}`;

		updateEvents(updatelink);

		setLoad(true);
		setPageCounter(infoPage.page);
	};

	const handleSortedModelChange = (model: GridSortModel) => {
		setSort(false);
		if (!model.length) return;
		const { field, sort } = model[0];

		setLoad(true);
		setSort(true);
		setSortedMod(`${signSort[sort ?? 'desc']}${sortedFields[field]}`);
	};

	const handleCellClick = (cell: GridCellParams) => {
		const { field, formattedValue } = cell;

		if (field !== 'img') return;

		setImgLink(formattedValue as string);
		setOpenModal(true);
	};

	const downloadEventImg = async () => {
		saveAs(imgUrlDownload, 'example.jpg');
	};

	const icons = [
		{
			id: 0,
			name: 'download',
			icon: (fill = '#D2D2D2') =>  <DownloadIcon fill={fill} width={25} height={25} />,
			action: downloadEventImg,
		},
		{
			id: 1,
			name: 'close',
			icon: (fill = '#D2D2D2') => <CloseIcon fill={fill} />,
			action: () => setOpenModal(false),
		}
	];

	const columnsWithFilter = useMemo(() => {
		const defValues = ['is', 'onOrAfter', 'onOrBefore', 'before', 'after'];
		const defOpefators = ({ value }: { value: string }) => defValues.includes(value);
		
		const commonFilterOperators = getGridStringOperators().filter(
			(operator) =>
				operator.value === 'equals' || operator.value === 'contains'
		);

		return columns[numberCard].map((col) => {
			let filterOperators: GridFilterOperator[] = [...commonFilterOperators];

			switch (col.field) {
			case 'id':
			case 'messageType':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'equals'
				);
				break;

			case 'time':
				filterOperators = getGridDateOperators(true).filter(defOpefators);
				break;

			case 'date':
				filterOperators = getGridDateOperators().filter(defOpefators);
				break;

			default:
				break;
			}

			return {
				...col,
				filterOperators,
			};
		});
	}, [columns, numberCard]);

	return (
		<ThemeProvider theme={darkTheme}>
			<CheckEmpty isCheck={!!cardEvents}>
				{isDatesExist
					? <ReportTable reportData={reportData} numberCard={numberCard} isLoad={isLoad} />
					: <StyledDataGrid
						rows={cardEvents ? cardEvents : []}
						columns={columnsWithFilter}
						loading={isLoad}
						paginationMode={'server'}
						sortingMode={'server'}
						filterMode={'server'}
						rowCount={rowCount}
						rowSelectionModel={selectedRow !== null ? [selectedRow] : []}
						localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
						initialState={{
							pagination: { paginationModel: { pageSize: 10 } },
						}}
						paginationModel={{ page: pageCounter, pageSize: pageSize }}
						pageSizeOptions={[10, 25, 50]}
						hideFooterSelectedRowCount={true}
						onRowClick={handleSelectRow}
						onCellClick={handleCellClick}
						onPaginationModelChange={handleChangePage}
						onSortModelChange={handleSortedModelChange}
						onFilterModelChange={handleFilterModelChange}
					/>
				}
			</CheckEmpty>

			<Modal open={isOpenModal} onClose={() => setOpenModal(false)}>
				<div className={styles.imgContainer}>
					<div className={styles.icons}>
						{icons.map(({ icon , action}, index) => {
							const isHovered = hovered === index;
							const color = isHovered ? '#00C2FD' : '#D2D2D2';

							return (
								<IconButton
									key={`ControlIcon-Icon-${index}`}
									className={styles.icon}
									onMouseEnter={() => setHovered(index)}
									onMouseLeave={() => setHovered(null)}
									onClick={() => action()}
								>
									{icon(color)}
								</IconButton>
							);
						})}
					</div>

					<img className={styles.img} src={imgLink} alt={'image'}/>
				</div>
			</Modal>
		</ThemeProvider>
	);
});

export default Table;