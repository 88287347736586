import React, {useEffect, useState} from 'react';
import styles from './ControlIcons.module.scss';
import { ControlIconProps as Props } from './ControlIcons.interface';
import ChartIcon from '@assets/Icons/Dashboard/ChartIcon';
import TableIcon from '@assets/Icons/Dashboard/TableIcon';
import FullWinIcon from '@assets/Icons/Dashboard/FullWinIcon';
import FullWinOffIcon from '@assets/Icons/Dashboard/FullWinOffIcon';
import DownloadIcon from '@assets/Icons/ControlPage/DownloadIcon';
import MapIcon from '@assets/Icons/Dashboard/MapIcon';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import dashboardState from '@store/dashboardState/dashboardState';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { columnsReport } from '../../data';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import {observer} from 'mobx-react-lite';

const ControlIcons: React.FC<Props> = observer(({ changeTable, handleSetMap, isTable, isChart, numberCard , reportData}) => {
	const { imgUrlDownload, reportDates, isOpenFullWin, setImgUrlDownload, changeOpenFullWin, setNumberFullTable, setReportFullDates } = dashboardState;

	const [selected, setSelected] = useState<number | null>(null);
	const [hovered, setHovered] = useState<number | null>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			if(selected === 1) setSelected(null);
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [selected]);

	const handleChangeFullWin = () => {
		changeOpenFullWin();
		setNumberFullTable(numberCard);
		setImgUrlDownload('');

		isOpenFullWin && setReportFullDates([]);
	};

	const downloadReport = () => {
		setNumberFullTable(numberCard);
	};

	const downloadChartPng = () => {
		const chart = document.querySelector('.recharts-wrapper');
		if (chart) {
			html2canvas(chart as HTMLElement)
				.then((canvas) => {
					const image = canvas.toDataURL('image/png');
					const link = document.createElement('a');
					link.href = image;
					link.download = 'chart.png';
					link.click();
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		}
	};

	const downloadEventImg = async () => {
		if (imgUrlDownload === '') return;

		saveAs(imgUrlDownload, 'example.jpg');
	};

	const DownloadExcel = ({ fill = '#D2D2D2' }) => (
		<ReactHTMLTableToExcel
			className={styles.excel}
			table='archiveTable'
			filename='ReportExcel'
			sheet='Sheet'
			buttonText={
				<IconButton>
					<DownloadIcon fill={fill} width={25} height={25} />
				</IconButton>
			}
		/>
	);

	const icons = [
		{
			id: 0,
			name: 'map',
			icon: (fill = '#D2D2D2') => <MapIcon fill={fill} />,
			action: handleSetMap,
		},
		{
			id: 1,
			name: 'download',
			icon: (fill = '#D2D2D2') =>
				isChart
					? <DownloadIcon fill={fill} width={25} height={25} />
					: reportDates.length > 0 ? <DownloadExcel fill={fill} /> : <DownloadIcon disabled={(imgUrlDownload === '' || numberCard !== 0) || isChart} width={25} height={25} /> ,
			action: isChart ? downloadChartPng : reportDates.length > 0 ? downloadReport : downloadEventImg,
		},
		{
			id: 2,
			name: 'table',
			icon: (fill = '#D2D2D2') =>
				isTable ? <ChartIcon fill={fill} /> : <TableIcon fill={fill} />,
			action: changeTable,
		},
		{
			id: 3,
			name: 'windowSize',
			icon: (fill = '#D2D2D2') =>
				isOpenFullWin ? <FullWinOffIcon fill={fill} /> : <FullWinIcon fill={fill} />,
			action: handleChangeFullWin,
		},
	];

	const handleSelectedItem = (index: number) => {
		const tSelected = selected === index ? null : index;
		
		setSelected(tSelected);

		icons[index].action();
	};

	const handleHoveredItem = (index: number) => {
		const hoveredIndex = selected === index ? null : index;
		setHovered(hoveredIndex);
	};

	return (
		<div className={styles.setIcons}>
			{icons.map(({ icon }, index) => {
				const isSelected = selected === index;
				const isHovered = hovered === index;
				const isActiveItem = isSelected || isHovered;

				const color = isActiveItem ? '#00C2FD' : '#D2D2D2';
				const isShow = isOpenFullWin || (index !== 0 && index !== 1);

				return (
					isShow && (
						<IconButton
							key={`ControlIcon-Icon-${index}`}
							className={styles.icon}
							onMouseEnter={() => handleHoveredItem(index)}
							onMouseLeave={() => setHovered(null)}
							onClick={() => handleSelectedItem(index)}
						>
							{icon(color)}
						</IconButton>
					)
				);
			})}

			<TableContainer sx={{ maxHeight: 200, display: 'none' }}>
				<Table id='archiveTable' sx={{ maxWidth: 300 }} size='small' aria-label='customized table'>
					<TableHead>
						<TableRow>
							<TableCell align='center'>За период</TableCell>
							<TableCell align='center'>
								с {reportDates[0]} по {reportDates[1] ? reportDates[1] : reportDates[0]}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align='center'></TableCell>
							<TableCell align='center'></TableCell>
						</TableRow>
					</TableHead>

					<TableHead>
						<TableRow>
							{columnsReport.map(({headerName }, id) => {
								return (
									<TableCell
										key={`Cell-table-${id}`}
										content='center'
										align='center'
										style={{ fontSize: '18px' }}
									>
										{headerName}
									</TableCell>
								);
							})}
						</TableRow>
					</TableHead>
					<TableBody>
						{reportData.map((row) => {
							const { id } = row;
							const key = `Event-Dates-${id}`;

							return (
								<TableRow key={key}>
									{Object.entries(row).map(([key, value]: [string, string]) => {
										if (key === 'id') return;
										const imgKey = key === 'img';

										return (
											<TableCell
												height={140}
												key={`Table-cell-${key}`}
												scope='row'
												component='th'
												align='center'
											>
												{imgKey ? <img width={177} height={100} src={value} /> : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
});

export default ControlIcons;