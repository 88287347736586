import React from 'react';
import styles from './PdfButtons.module.scss';
import BackIcon from '../../../../assets/Icons/LeftMenu/BackIcon';
import { PdfButtonsProps as Props } from './PdfButtons.interface';
import DownloadIcon from '../../../../assets/Icons/ControlPage/DownloadIcon';
import CameraIcon from '../../../../assets/Icons/LeftMenu/CameraIcon';
import AdminIcon from '../../../../assets/Icons/LeftMenu/AdminIcon';


const PdfButtons: React.FC<Props> = ({ currentPage, lastPage, handleChangePage, handleChangePdf, index, handleDownloadPdf }) => {
	const {arrowsIconBtnDisabled, arrowsIconBtn, rightArrow} = styles;
	const isDisabledRight = currentPage === lastPage;
	const isDisabledLeft = currentPage === 1;
	const classNameRight = `${isDisabledRight ? arrowsIconBtnDisabled : arrowsIconBtn} ${rightArrow}`;
	const classNameLeft = isDisabledLeft ? arrowsIconBtnDisabled : arrowsIconBtn;
	const fillUserPdfIcon = index === 0 ? '#00C2FD' : '#D2D2D2';
	const fillDevicePdfIcon = index === 1 ?  '#00C2FD' : '#D2D2D2';

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div onClick={() => handleChangePdf(1)}>
					<button
						className={arrowsIconBtn}>
						<CameraIcon fill={fillDevicePdfIcon} title={'Паспорт устройства'}/>
					</button>
				</div>
				<div onClick={() => handleChangePdf(0)}>
					<button
						className={arrowsIconBtn}>
						<AdminIcon fill={fillUserPdfIcon}/>
					</button>
				</div>
			</div>

			<div className={styles.center}>
				<button
					className={classNameLeft}
					disabled={isDisabledLeft}
					onClick={() => handleChangePage(-1)}
				>
					<BackIcon title={'Назад'}/>
				</button>

				<div className={styles.textPage}>
					{currentPage}/{lastPage}
				</div>
				<button
					className={classNameRight}
					disabled={isDisabledRight}
					onClick={() => handleChangePage(1)}
				>
					<BackIcon title={'Дальше'}/>
				</button>
			</div>

			<div onClick={handleDownloadPdf} className={styles.right}>
				<button
					className={arrowsIconBtn}>
					<DownloadIcon />
				</button>
			</div>
		</div>
	);
};

export default PdfButtons;
