import styles from './ControlEventsIcons.module.scss';
import controlState from '../../../store/controlState/controlState';
import eventState from '../../../store/eventState/eventState';
import { observer } from 'mobx-react-lite';
import MainButton from '../../../helpers/components/MainButton/MainButton';
import GridIcon from '../../../assets/Icons/ControlPage/GridIcon';
import SoundIcon from '../../../assets/Icons/ControlPage/SoundIcon';
import DownloadIcon from '../../../assets/Icons/ControlPage/DownloadIcon';
import PdfIcon from '../../../assets/Icons/ControlPage/PdfIcon';
// import SensorPageIcon from '../../../assets/Icons/ControlPage/SensorPageIcon';
import UpdateIcon from '../../../assets/Icons/ControlPage/UpdateIcon';
import translationState from '../../../store/translationState/translationState';

const ControlEventsIcons: React.FC = observer(() => {
	const { 
		isVisibleTempGrid, 
		isVisibleSoundInfo, 
		isUnloadingEvents,
		// isControlPage,
		setVisibleTempGrid,
		setVisibleSoundInfo, 
		setUnloadingEvent,
		// setControlPage
	} = controlState;
	const { isShowPrepareProtocol, events, setShowPrepareProtocol } = eventState;
	const { setIsGetEventPOLL } = translationState;
	
	const handleEventPOLL = () => {
		setIsGetEventPOLL();
	};

	const handleTempVisible = () => {
		setVisibleTempGrid(!isVisibleTempGrid);
	};

	const menuIcons = [
		{ id: 1, Icon: GridIcon, fill: isVisibleTempGrid ? '#00C2FD' : '#D2D2D2', disable: !events.length, visibleClick: handleTempVisible },
		{ id: 2, Icon: SoundIcon, fill: isVisibleSoundInfo ? '#00C2FD' : '#D2D2D2', disable: !events.length, visibleClick: setVisibleSoundInfo },
		{ id: 3, Icon: PdfIcon, fill: isShowPrepareProtocol ? '#00C2FD' : '#D2D2D2', disable: !events.length, visibleClick: setShowPrepareProtocol },
		{ id: 4, Icon: DownloadIcon, fill: isUnloadingEvents ? '#00C2FD' : '#D2D2D2', disable: !events.length, visibleClick: setUnloadingEvent },
		// { id: 5, Icon: SensorPageIcon, fill: !isControlPage ? '#00C2FD' : '#D2D2D2', disable: !events.length, visibleClick: setControlPage },
		{ id: 6, Icon: UpdateIcon, disable: !events.length, visibleClick: handleEventPOLL},
	];

	return (
		<div className={styles.container}>
			{menuIcons.map((icon) => {
				const { id, Icon, fill, disable, visibleClick } = icon;
				const key = `Menu-Icon-${id}`;

				return (
					<div key={key} className={styles.bottomIcons} >
						<MainButton disabled={disable} onClick={visibleClick}>
							<Icon fill={fill} disabled={disable} />
						</MainButton>
					</div>
				);
			})}
		</div>
	);
});

export default ControlEventsIcons;
