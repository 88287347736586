import React from 'react';
import { observer } from 'mobx-react-lite';
import Loading from '../Loading/Loading';
import loadingState from '../../store/loadingState/loadingState';
import { AllPagesGetData as ReadyPages } from '../../store/loadingState/loading.interface';

interface Props {
	children: React.ReactNode;
	page: keyof ReadyPages;
}

export const CheckLoading: React.FC<Props> = observer(({ page, children }) => {
	const { readyPages } = loadingState;

	return readyPages[page] ? <>{children}</> : <Loading />;
});
